/**
 * Cookie Message
 * Show/Hide the cookie message and handle its localStorage object
 * 
 * @dependencies
 * * toolkit.getLocalStorageObject – (toolkit.js)
 * * toolkit.setLocalStorageObject – (toolkit.js)
 * 
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * Thirdparty.getInitPlaceholderElement – (third-party.js)
 * * Thirdparty.isEnabled – (third-party.js)
 * 
 * @commit
 * * h.bode (2020-03-26) – migrated cookie message logic and integrated localStorage
 * * j.pereira (2021-09-02) – feat(third-party): support activation of all cookies by activation button
 * * j.pereira (2021-09-15) – fix(third-party): prevent init fallback flashing when cookies are accepted
 */

CookieMessage = {

  // CSS class to show/hide the cookie message
  class: 'js-cookie-message',
  registeredObserverCallbacks: [],

  // const values
  ACTION_ALLOW: 'allow',

  cookiesAreEnabled: function () {
    return !!window.toolkit.getLocalStorageObject('cookieMessage');
  },

  // Check if the cookie message should be displayed
  check: function () {

    // Check localStorage for the cookieMessage object
    const cookieMessageObjectExists = this.cookiesAreEnabled();
    const cookieMessageDisagree = sessionStorage.getItem('cookieMessageDisagree');

    if (cookieMessageObjectExists) {
      // Notifying all observers on cookie allowance
      this.triggerObserverCallbacks(this.ACTION_ALLOW);
    }

    // Return if there is a cookieMessage object in localStorage
    if (cookieMessageObjectExists || cookieMessageDisagree) {
      return;
    } else {

      // Show the cookie message
      CookieMessage.show();
    }
  },

  // Show the cookie message
  show: function () {

    // open cookiemessage
    document.dispatchEvent(new Event('showCookieMessage'));

    // Submit buttons of the cookie message
    const buttonAgree = document.querySelector('[data-cookie-btn="nav_cookie_agree"]');
    const buttonDisagree = document.querySelector('[data-cookie-btn="nav_cookie_disagree"]');

    // Return if there are no buttons
    if (!buttonAgree || !buttonDisagree) return;

    // Click handler for the submit button (agreed)
    buttonAgree.onclick = function () {
      // Hide the cookie message on submit
      CookieMessage.hide(true);
    };

    // Click handler for the submit button (disagreed)
    buttonDisagree.onclick = function () {

      // Hide the cookie message on submit
      CookieMessage.hide(false);
    };
  },

  // Hide the cookie message
  hide: function (agreed = true) {
    if (agreed) {
      // allow cookies
      this.allowCookies();
    } else {
      sessionStorage.setItem('cookieMessageDisagree', true);
    }

    // hide cookieMessage
    document.dispatchEvent(new Event('hideCookieMessage'));

  },

  registerObserverCallback: function (cb) {
    this.registeredObserverCallbacks.push(cb);
  },

  triggerObserverCallbacks: function (action) {
    // signaling an action triggered in the cookie message
    this.registeredObserverCallbacks.forEach(function (cb) {
      cb(action);
    });
  },

  allowCookies: function () {
    // Create the cookieMessage object
    let cookieMessageStorageObject = {
      accepted: true,
    };

    // Set cookieMessage object in localStorage
    window.toolkit.setLocalStorageObject('cookieMessage', cookieMessageStorageObject, 365 * 24 * 60) // Expires in 1 year

    // And remove an existing cookieMessageDisagree sessionStorage item
    sessionStorage.removeItem('cookieMessageDisagree');

    // Notifying all observers on cookie allowance
    this.triggerObserverCallbacks(this.ACTION_ALLOW);
  }
}