/**
 * newsflags für max 60 tage alte projekte
 * 
 * @dependencies
 * * jquery
 * 
 * @dependents
 * * $(document).ready(function() { ... });
 * 
 * @commit 
 * * s.schaefermeier (2018-03-16): update from gw2.erco.org
 * * h.bode (2020-03)
 */

newsflags = {
  init: function () {
    var neu = {
      "de": "neu",
      "en": "new",
      "en_us": "new",
      "en_gb": "new",
      "es": "nuevo",
      "fr": "nouveau",
      "it": "novità",
      "nl": "nieuw",
      "sv": "nytt"
    };

    $links = $("a[data-creation-date]");

    // Klasse setzen
    $.each($links, function () {
      var $this = $(this);
      var date = $this.attr('data-creation-date');//20140109
      var old = new Date() - new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
      old = Math.round(old / (1000 * 60 * 60 * 24));

      if (old <= 60) {
        $this.addClass("new");
      }
    });

    // Tooltipp setzen auf Klasse
    $divs = $("a");
    $.each($divs, function () {
      var $this = $(this);
      if ($this.hasClass("new")) {
        $this.parent().attr("title", "" + neu[k3vars.S_Sprache] + "");
      }
      //$this.parent().tooltip();
    });
  }
}