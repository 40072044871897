/**
 * ModalData
 *
 * Modales Fenster ss 20140415
 *
 * @dependencies
 * * jquery
 * * onload_functions
 * * newsletter
 *
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * overlayBox – (global/lichtwissen.js)
 * * data attribute [data-link-type="ajaxmodal"] used in (dist/kunden/ls2014/vorlagen/html/layout/onepager/bild_bu.php) in cms-backend
 * * id #myModal used in cms-backend
 *
 * @commit
 * * Initial commit 2.0
 * * k.tennie (2019-07-18): add querybuild and redirect for glossar- and vacancie-cards
 * * c.unger (2021-04-27): fix(JS->modal): separate modal creation, init eventlisteners after ajax call in loadOverlay
 *
 *
 * @note
 * File global/modalbox-km.js seems to be deprecated (same base code as this file + not referenced in default.json)
 * -> But directly referenced in (e.g. dist/kunden/ls2014/vorlagen/html/layout/career_content_grid_2019.htm) in cms-backend
 */

var modaldata = new Object();
modaldata.func = (function () {

  return {
    init: function (checkURL = true) {
      modaldata.func.create();
      
      if (checkURL) {
        modaldata.func.checkURL();
      }

      // Beim Klick auf den Datentypen den entsprechenden Content nachladen
      $("[data-link-type='ajaxmodal']").off('click').on('click', function (e) {
        e.preventDefault();
        modaldata.func.load($(this), "push");
      });
    },

    create: function () {
      var myModals = document.querySelectorAll('[id="myModal"]');
      if (myModals.length === 0) {
        // append myModal, if it does not exist
        const modalEl = document.createElement('div');
        modalEl.id = 'myModal';
        modalEl.className = 'modal fade';
        modalEl.setAttribute('tabindex', '-1');
        modalEl.setAttribute('role', 'dialog');
        modalEl.setAttribute('aria-labelledby', 'myModalLabel');
        modalEl.setAttribute('aria-hidden', 'true');
        document.body.appendChild(modalEl);
      } else if (myModals.length > 1) {
        // remove duplicated modals
        for (let i = 1; i < myModals.length; i++) {
          myModals[i].remove();
        }
      }
    },

    checkURL: function () {
      // Check if we got a query with an modal and open it
      const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.has('modal')) { 
				modaldata.func.load(undefined, "push", urlParams.get('modal'));
			}
    },

    // Seite nachladen
    load: function (ele, modus, extUrl = false) {
      // URL extrahieren
      if (!extUrl) {
        var url = ele.attr("href");
      } else {
        var url = extUrl;
      }
      // set modal in query
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('modal', url);
      history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);

      // Daten via Ajax ziehen
      $.get(url, function (data) {
        // Header und Menü weg strippen

        var $new = data.split('<!-- EO header.php -->')[1];	// Content von menü befreien
        $new = $new.replace(/<!--([\s\S]*?)-->/mig, ''); // ss20150215 Kommentare raus - dir führen in der nächsten Zeile zu einem 'undefined'
        var html = $("" + $new + "").find('script').remove().end().html(); // scripte raus
        html = '<div class="modal-dialog">' + html + '</div>'; // div wird oben entfernt warum?

        $("#myModal").html(html);
        $('#myModal').modal();

        modaldata.func.init(false); // neu initieren für weitere Links
        newsletter.initCheckbox(); // Appending the newsletter checkboxes bk 20171025

        // add class for hiding background content in print view   cu 20181015
        $('body').addClass('modal-active');
        $('#myModal').one('hide.bs.modal', function () {
          // Reset URL
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('modal')) {
            urlParams.delete('modal');
            history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
          }

          $('body').removeClass('modal-active');
        });
      });
    },
    // ss 20150605
    // öffnet eine modales Fenster, sobald an der locationhref ein #mb:url hängt
    trigger: function (url) {

      // kodierte url decodieren
      if (url.match(/%2F/)) {
        url = decodeURIComponent(url);
      }

      //element erzeugen
      var el = $("<a>", { href: url });
      modaldata.func.load(el, "push");
    }
  }
}());

onload_functions.push(modaldata.func.init);
