class EmarsysDataLayer {
  /**
   * var isInitialized {boolean} - indicates if the EmarsysDataLayer is initialized
   */
  isInitialized = false;

  /**
   * var userData {object} - contains the user data:
   *   logged_in {boolean} - indicates if the user is logged in
   *   is_staff {boolean} - indicates if the user is staff
   *   email {string} - the email of the user
   *   groups {array} - the groups the user is in
   */
  userData = null;

  /**
   * var eventActionQueue {array} - contains the event actions that are triggered before the
   *                                EmarsysDataLayer has initialized. They are processed once the
   *                                EmarsysDataLayer is initialized.
   */
  eventActionQueue = [];

  /**
   * var actionsTracked {array} - contains the actions that have been tracked. This is used to
   *                              prevent tracking the same action multiple times if action.once
   *                              is true.
   */
  actionsTracked = [];

  /**
   * var utmLifeTimeBeforeExpiry {number} - time in milliseconds after which the utm params are overwritten
   *                                        (default: 600000 ms = 10 minutes)
   */
  // TODO: move to config file
  utmLifeTimeBeforeExpiry = 600000;

  constructor() {
    // collect necessary data
    this.referrerOptions = emarsysConfig.referrerOptions;
    this.staticActions = emarsysConfig.staticActions;
    this.eventActions = emarsysConfig.eventActions;
    this.externalActions = emarsysConfig.externalActions;

    // start initialization
    this.init();
  }

  /**
   * Initialize the EmarsysDataLayer
   * 1. Initialize the event tracking
   * 2. Load the scarab script
   * 3. Get the user data
   * 4. When 2 and 3 both finished, track static events and process the event action queue
   */
  init() {
    this.storeUTMParams();
    this.initEventTracking();
    this.updateUtmExpiryTime();

    const scarabScriptLoaded = this.loadScarabScript();

    const userDataLoaded = authHelper.user.then((userData) => {
      this.userData = userData;
    });

    Promise.all([scarabScriptLoaded, userDataLoaded]).then(() => {
      this.isInitialized = true;
      this.trackStaticEvents();
      this.processEventActionQueue();
    });
  }

  storeUTMParams() {
    // utm params from url have priority over referrer information
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let utmParams = {
      utm_campaign: params.utm_campaign,
      utm_source: params.utm_source,
      utm_medium: params.utm_medium,
      utm_content: params.utm_content,
      utm_term: params.utm_term,
    };

    utmParams = Object.fromEntries(
      Object.entries(utmParams).filter(([_, value]) => value !== null && value !== undefined)
    );

    // Obtain utm params primarily from URL, secondarily from storage or else use referrer
    if (Object.keys(utmParams).length === 0) {

      if (this.utmExpiryTime && this.utmExpiryTime < new Date().getTime()) {
        // utm params from storage are expired => reset
        this.UTMApiParams = {};
        this.UTMInteractionParams = {};
      }

      // Check if utm params are in storage else use referrer
      if (this.UTMApiParams && Object.keys(this.UTMApiParams).length > 0) {
        utmParams = this.UTMApiParams;
      } else {
        const referrer = this.retrieveReferrerInfo();
        if (referrer !== null) {
          utmParams.utm_source = referrer.source;
          utmParams.utm_medium = referrer.medium;
        }
      }
    }

    // add GCLID if given
    if (params.gclid) {
      utmParams.gclid = params.gclid;
    }

    if (Object.keys(utmParams).length > 0) {
      this.UTMApiParams = utmParams;
      this.UTMInteractionParams = utmParams;
      this.updateUtmExpiryTime();
    }
  }

  retrieveReferrerInfo() {
    if (!document.referrer) {
      return null;
    }

    const { ignore, searchEngines } = this.referrerOptions;
    const referrerUrl = new URL(document.referrer);
    const referrerHostname = referrerUrl.hostname;

    const ignoreRegexString = `(${ignore.join('|')})`;
    const ignoreRegex = new RegExp(ignoreRegexString, 'i');

    if (ignoreRegex.test(referrerHostname)) {
      return null;
    }

    const referrerInfo = {
      source: 'referrer',
      medium: referrerHostname,
    };

    const searchEnginesRegexString = `(${searchEngines.join('|')})\\.`;
    const searchEnginesRegex = new RegExp(searchEnginesRegexString, 'i');

    if (searchEnginesRegex.test(referrerHostname)) {
      referrerInfo.source = 'search';
    }

    return referrerInfo;
  }

  updateUtmExpiryTime() {
    const utmExpiryTime = new Date().getTime() + this.utmLifeTimeBeforeExpiry;

    localStorage.setItem('emarsys_utm_expiry_time', utmExpiryTime);
  }

  get utmExpiryTime() {
    const emarsysUtmExpiryTime = localStorage.getItem('emarsys_utm_expiry_time');

    if (emarsysUtmExpiryTime === null) {
      return null;
    }

    return parseInt(emarsysUtmExpiryTime, 10);
  }

  // TODO: use camelCase for getters and setters
  get UTMApiParams() {
    return this.getUTMParams('api');
  }

  set UTMApiParams(obj) {
    this.setUtmParams('api', obj);
  }

  get UTMInteractionParams() {
    return this.getUTMParams('interaction');
  }

  set UTMInteractionParams(obj) {
    this.setUtmParams('interaction', obj);
  }

  getUTMParams(index) {
    const storageIdx = `emarsys_${index}_utm`;
    const utmParamsFromStorage = localStorage.getItem(storageIdx);
    if (utmParamsFromStorage === null) {
      return false;
    }
    return JSON.parse(utmParamsFromStorage);
  }

  setUtmParams(index, obj) {
    const storageIdx = `emarsys_${index}_utm`;

    localStorage.setItem(storageIdx, JSON.stringify(obj));
  }

  trackStaticEvents() {
    const staticActionKeys = Object.keys(this.staticActions);
    if (staticActionKeys.length > 0) {
      this.pushEmarsysEventStart();
      staticActionKeys.forEach((actionKey) => {
        const { tag, condition, valueObject } = this.staticActions[actionKey];
        const con = typeof condition === 'function' ? condition(this) : condition;
        if (condition === undefined || con === true) {
          const vo = typeof valueObject === 'function' ? valueObject(this) : valueObject;
          const enrichedValueObject = this.enrichEmarsysEventValueObject(vo);
          this.pushEmarsysEventTag(tag, enrichedValueObject);
          this.actionsTracked.push(actionKey);
        }
      });
      this.pushEmarsysEventEnd();
    }
  }

  loadScarabScript() {
    return new Promise((resolve) => {
      if (document.getElementById('scarab-js-api')) {
        resolve(true);
      } else {
        window['ScarabQueue'] = window['ScarabQueue'] || [];
        const key = '1500E13FF557E08F';
        const emarsysScript = document.createElement('script');
        emarsysScript.id = 'scarab-js-api';
        emarsysScript.src = `https://recommender.scarabresearch.com/js/${key}/scarab-v2.js`;
        const fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(emarsysScript, fs);

        document.getElementById('scarab-js-api').addEventListener('load', () => {
          this.scarabQueueLoaded = true;
          resolve(true);
        });
      }
    });
  }

  get isUserLoggedIn() {
    return this.userData?.logged_in === true;
  }

  /**
   * Getter for the website labeling. Ensures that all keys are present.
   *
   * @return {object} - website labeling data
   *   clusters {array} - the clusters of the current page
   *   funnel {string} - the funnel of the current page: LOWER, UPPER, null
   *   interests {array} - the interests of the current page
   *   market_segments {array} - the market segments of the current page
   *   product_families {array} - the product families of the current page
   */
  get websiteLabeling() {
    const emptyLabeling = {
      clusters: [],
      funnel: '',
      interests: [],
      market_segments: [],
      product_families: [],
    };

    if (window.k3vars && window.k3vars.labeling) {
      return { ...emptyLabeling, ...window.k3vars.labeling };
    }

    return emptyLabeling;
  }

  trackInteractionEvent(actionName, tag, valueObject, once, fallbackEmail = null) {
    if (!once || this.actionsTracked.indexOf(actionName) === -1) {
      if (this.isInitialized) {
        this.pushEmarsysEvent(tag, valueObject, fallbackEmail);
      } else {
        this.eventActionQueue.push({ tag, valueObject, fallbackEmail });
      }
    }

    // keep track of actions that should only be tracked once
    if (once === true) {
      this.actionsTracked.push(actionName);
    }
  }

  pushEmarsysEventStart(fallbackEmail = null) {
    if (this.isUserLoggedIn) {
      ScarabQueue.push(['setEmail', this.userData.email]);
    } else if (fallbackEmail) {
      ScarabQueue.push(['setEmail', fallbackEmail]);
    }
  }

  pushEmarsysEventTag(tag, valueObject) {
    ScarabQueue.push(['tag', tag, valueObject]);
  }

  pushEmarsysEventEnd() {
    ScarabQueue.push(['go']);
  }

  pushEmarsysEvent(tag, valueObject, fallbackEmail = null) {
    this.pushEmarsysEventStart(fallbackEmail);
    const enrichedValueObject = this.enrichEmarsysEventValueObject(valueObject);
    this.pushEmarsysEventTag(tag, enrichedValueObject);
    this.pushEmarsysEventEnd();
  }

  enrichEmarsysEventValueObject(valueObject) {
    if (!valueObject.URL) {
      valueObject.URL = window.location.href;
    }
    valueObject.MarketSegment = this.websiteLabeling.market_segments.join(',');
    valueObject.Cluster = this.websiteLabeling.clusters.join(',');
    valueObject.Interest = this.websiteLabeling.interests.join(',');
    valueObject.ProductFamily = this.websiteLabeling.product_families.join(',');
    valueObject.Date = new Date().toISOString().split('T')[0];

    const utmParams = this.UTMInteractionParams;
    if (utmParams) {
      for (const [key, value] of Object.entries(utmParams)) {
        if (value) {
          valueObject[key] = value;
        }
      }
    }

    return valueObject;
  }

  triggerAction(actionName, e) {
    if (this.externalActions.hasOwnProperty(actionName)) {
      const action = this.externalActions[actionName];
      const { tag, valueObject, once } = action;
      if (typeof valueObject === 'function') {
        valueObject = valueObject(e);
      }
      actionName = action.name ?? actionName;
      this.trackInteractionEvent(actionName, tag, valueObject, once);
    }
  }

  processEventActionQueue() {
    while (this.eventActionQueue.length > 0) {
      const { tag, valueObject, fallbackEmail } = this.eventActionQueue.shift();
      this.pushEmarsysEvent(tag, valueObject, fallbackEmail);
    }
  }

  /**
   * enable event tracking
   *
   * @return {void}
   */
  initEventTracking() {
    Object.keys(this.eventActions).forEach((actionKey) => {
      const action = this.eventActions[actionKey];
      if (action.event) {
        // default: add event listener to event selector targets
        let eventSelectorTargets = [...document.querySelectorAll(action.event.selector)];
        this.addPushEventTrigger(eventSelectorTargets, actionKey);

        // if event relies on DOM mutations, add event trigger by using DOMObserver
        if (action.event.domMutation && window.DOMObserver) {
          window.DOMObserver.observe(document.body, (mutationRecords) => {
            mutationRecords
              // filter mutationRecords that are not of type 'childList'
              .filter((mr) => mr.type === 'childList')
              // iterate over mutationRecords
              .forEach((mutationRecord) => {
                // transform node list of added nodes to array
                [...mutationRecord.addedNodes]
                  // filter: only use nodes of type 'element'
                  .filter((n) => n.nodeType === 1)
                  // iterate over added nodes
                  .forEach((addedNode) => {
                    // retrieve all elements that match the event selector
                    let addedEventSelectorTargets = [
                      ...addedNode.querySelectorAll(action.event.selector),
                    ];
                    this.addPushEventTrigger(addedEventSelectorTargets, actionKey);
                  });
              });
          });
        }
      }
    });
  }

  addPushEventTrigger(triggerElements, actionKey, observer) {
    if (triggerElements.length > 0) {
      const action = this.eventActions[actionKey];
      const { tag, once, event, valueObject } = action;

      const eventFunc = (e) => {
        if (
          event.type === 'click' ||
          event.type === 'submit' ||
          (event.type.indexOf('key') === 0 && e.keyCode === event.keyCode)
        ) {
          // get value object based on event
          const vo = typeof valueObject === 'function' ? valueObject(e) : valueObject;

          // stop observer if one is given
          if (event.domMutation && observer !== undefined) {
            observer.disconnect();
          }

          // get email address from input field as fallback
          let fallbackEmail;
          const emailInputField = e.target.closest('form')?.querySelector('input[type="email"]');
          if (emailInputField) {
            fallbackEmail = emailInputField.value;
          }

          // keep track of triggered event
          const actionName = action.name ?? actionKey;
          this.trackInteractionEvent(actionName, tag, vo, once, fallbackEmail);

          // remove event listeners if once is set
          if (once) {
            [...document.querySelectorAll(event.selector)].forEach((el) => {
              el.removeEventListener(event.type, eventFunc);
            });
          }
        }
      };

      triggerElements.forEach((el) => {
        el.addEventListener(event.type, eventFunc);
      });
    }
  }
}
