window.ABTesting = {
  init: function () {
    // Check if posthog and k3vars are loaded (@see headjs.php)
    if (typeof window.posthog === 'undefined') return;
    if (typeof window.k3vars === 'undefined') return;


    posthog.onFeatureFlags(function () {

      /**
       * A/B Test Lichtwissen (73)
       * @author FG
       */
      (() => {
        if (!k3vars.PKM_Spezifikation === '73') return;

        const elToc = document.querySelector('[data-js-ab-test="toc"]');
        const elCta = document.querySelector('[data-js-ab-test="cta"]');

        if (!elToc || !elCta) return;

        if (!posthog.isFeatureEnabled('lichtwissen-order-toc-cta')) {
          console.log('A/B not activated')
          return;
        }

        // Track Goal
        const firstQuestion = document.querySelector('.lichtlexikon-question');
        let goalTracked = false;
        window.addEventListener('scroll', () => {
          if (window.toolkit.isElementTopInViewport(firstQuestion) && !goalTracked) {
            goalTracked = true;
            posthog.capture('lichtwissen-order-toc-cta-goal', {
              'group': posthog.getFeatureFlag('lichtwissen-order-toc-cta'),
            });
          }
        });

        // Group Feature
        const featureGroup = posthog.getFeatureFlag('lichtwissen-order-toc-cta');

        if (featureGroup === 'test') {
          console.log('A/B Test Group');
          // test group => toc on top of cta
          elToc.after(elCta);

        } else if (featureGroup === 'control') {
          console.log('A/B Control Group');
          // control group => toc below cta
          elCta.after(elToc);

          let $sectionBeforeToc = elCta.previousElementSibling;
          while ($sectionBeforeToc && $sectionBeforeToc.offsetParent === null) {
            $sectionBeforeToc = $sectionBeforeToc.previousElementSibling;
          }

          $sectionBeforeToc.classList.remove("p-b-0");
          $sectionBeforeToc.classList.add("p-b-6");
        }
      })();

    });
  }
};
