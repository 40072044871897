/* menu.php
------------------------------------------------------------------*/
// only used as a stub after menu refresh in 04.2021 for legacy code; pe
// TODO: remove last few calls to barHide and showSearch | 20230221 pe
var menuContainer = {
  showSearch() {
    document.dispatchEvent(new Event('showSearch'));
  },
  barHide() {},
};

const languageForwardConfig = { 
  6797 : {
    'de' : `${confEnv.pdb_api_url}/myerco/de?api=v1.1`,
    'en' : `${confEnv.pdb_api_url}/myerco/en?api=v1.1`,
    'es' : `${confEnv.pdb_api_url}/myerco/es?api=v1.1`,
    'fr' : `${confEnv.pdb_api_url}/myerco/fr?api=v1.1`,
    'it' : `${confEnv.pdb_api_url}/myerco/it?api=v1.1`,
    'nl' : `${confEnv.pdb_api_url}/myerco/nl?api=v1.1`,
    'en_us': `${confEnv.pdb_api_url}/myerco/en_us?api=v1.1`,
  },
}

// TODO: switch to vanilla js
var languageChange = (function () {
  var exports = {};
  var init = function () {
    /* BO old code
       keeping old code for now, but will be removed in future,
       when all pages include new multilang_urls variable */
    // TODO remove this block when all pages include new multilang_urls variable
    var extractFirstPartOfURL = function (url) {
      const regex = /^(?:.*?\/\/)?[^/]*\/([^/]+)/;
      const matches = url.match(regex);

      if (matches?.length >= 2) {
        return matches[1];
      }

      return null;
    };

    const hreflang = [];
    document.querySelectorAll('link[rel="alternate"]').forEach(function (item) {
      const href = item.getAttribute('href');
      const lang = extractFirstPartOfURL(href);
      if (lang) {
        hreflang[lang] = href;
      }
    });
    /* EO old code */

    const pkmID = k3vars?.PKM_Objekt;


    document.querySelectorAll('[data-lang-url]').forEach((langEl) => {
      const lang = langEl.dataset.triggerSprachwechsel;
      let langUrl = '';

      if (lang) {
        if (languageForwardConfig[pkmID]?.[lang]) {
          langUrl = languageForwardConfig[pkmID][lang];
        } else
        if (k3vars?.multilang_urls?.[lang]) {
          langUrl = k3vars.multilang_urls[lang];
        } else if (hreflang[lang]) {
          // TODO remove this branch when all pages include new multilang_urls variable
          langUrl = hreflang[lang];
        }
      }

      langEl.dataset.langUrl = langUrl;
      langEl.setAttribute('data-lang-url', langUrl);

      if (langUrl.length === 0) {
        langEl.closest('li')?.classList.add('_lang_disabled');
      }

      if (langEl.dataset.langUrl.length > 0) {
        langEl.addEventListener('click', (e) => {
          e.preventDefault();
          window.location = langEl.dataset.langUrl;
        });
      }
    });
  };
  exports.init = init;

  return exports;
})();

/* push functions
------------------------------------------------------------------*/
onload_functions.push(languageChange.init);
