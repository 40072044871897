/**
 * Transition Objekt
 * 
 * @dependencies
 * * jquery
 * * holy
 * * header obj (erco.js) -> probably deprecated (pe)
 * * listener obj (erco.js)
 * * after_ajax obj (erco.js)
 * 
 * @dependents
 * –
 * 
 * @note
 * 
 * 
 * @commit
 * * Initial commit 2.0
 * * p.erasmus (2021-05-21) – remove History.js
 * * p.erasmus (2021-05-21) – test history stub for previous transition history construct
 */

transition = {
  timer: 500,
  og_title: document.title,
  og_url: location.href,
  in_view: []
};
transition.ausklappen = function (me) {

  var self = $(me).closest('.expander_outer'),
    url = $(me).attr('href');

  //$('html, body').stop().animate({scrollTop: self.offset().top },(this.timer * 0.6));
  holy.scrollTo(self, (this.timer * 0.6))


  header.scroll.ele.addClass('spezial_hideHead')
  self.one('holy:scrollDone', function () {
    $(document).one('holy:scrollStart', function () {
      header.scroll.ele.removeClass('spezial_hideHead')
      return false;
    })
  })



  $.get(url, function (data) {
    var $new = $('<div>' + data.replace(/<body/g, "<div data-ajax-wrap") + '</div>')	// body-tag ersetzen und selektierbar machen
      .find('[data-ajax-wrap]').empty().removeAttr('data-ajax-wrap')				// selektieren, leeren und data-attribut entfernen
      .addClass("ajax bottom-1")													// notwendige Klassen anheften
      .append(data.split('<!-- EO header.php -->')[1])							// Content von menü befreien
      .find('#copyright').remove().end()											// copyright entfernen
      .find('script').remove().end()												// scripte entfernen
      .insertAfter(self);															// an die entsprechende Stelle im Dom einheften

    $new.on('holy:observeIn', function () {
      side_menu_animate.assignClose($new)
      side_menu_animate.showClose()
      return false; // stop bubbling
    })
    $new.on('holy:observeOut', function () {
      side_menu_animate.assignClose()
      side_menu_animate.hideClose()
      return false; // stop bubbling
    })

    self.addClass('fullscreen');
    setTimeout(function () {
      $new.fadeIn(transition.timer, function () {
        $new.css({ 'top': '0px' });
        self.hide();
        holy.addToObserve($new).checkObserve();
        window.dispatchEvent(new Event('km:ajax'));
      }).css({ 'top': '-' + self.outerHeight(true) + 'px' })
        .find('.expander_close')
        .attr('data-transition-url', url)
        .on('click', function () {

          var index = transition.in_view.indexOf(url);
          transition.in_view.splice(index, 1); // url aus dem aktuellen State entfernen

          transition.einklappen(this);
          history.pushState({ 'data': transition.og_title, 'in_view': transition.in_view }, transition.og_title, transition.og_url);
          side_menu_animate.hideClose()
        });

      // browser url und title anpassen
      var $h2 = $new.find('h2');

      transition.in_view.push(url); // url zum aktuellen State hinzufügen
      history.pushState({ 'data': $h2.text(), 'in_view': transition.in_view }, $h2.text(), url);

      listener.start();
      after_ajax.start();



      //Wird verwendet umd die Module 2.1. zu initialisieren
      document.dispatchEvent(new Event('km:ajaxTransitionHomepageReady'));
    }, transition.timer);

  });
};

transition.einklappen = function (me) {

  var $this = $(me).closest('.ajax');
  var self = $this.prev();

  self.show()
  $this.css('top', -self.outerHeight(true) + 'px');
  //$('html, body').stop().animate({scrollTop: self.offset().top },(this.timer * 0.6));

  holy.scrollTo(self, (transition.timer * 0.6))

  header.scroll.ele.addClass('spezial_hideHead')
  self.one('holy:scrollDone', function () {
    $(document).one('holy:scrollStart', function () {
      header.scroll.ele.removeClass('spezial_hideHead')
      return false;
    })
  })


  setTimeout(function () {
    $this.fadeOut(this.timer, function () {
      holy.rmObserve($this)
      $this.remove();
      if (!self.hasClass('stay-large')) self.removeClass('fullscreen');
      //header.hide();
    });
  }, this.timer * 0.2);
}

transition.history = function (State) {
  if (State.data) {
    if (JSON.stringify(State.data.in_view) !== JSON.stringify(transition.in_view)) {

      // ms - ist nicht in der Artikelnummern-Suche definiert
      if (!State.data.in_view || !transition.in_view) return;

      var temp_state = State.data.in_view.slice();
      var temp_inview = transition.in_view.slice();

      for (var i = 0; i < temp_inview.length; i++) {
        if (temp_state.indexOf(temp_inview[i]) > -1) {
          temp_state.splice(i, 1);

        } else {
          var index = transition.in_view.indexOf(temp_inview[i]);
          transition.in_view.splice(index, 1); // url aus dem aktuellen State entfernen
          transition.einklappen($('[data-transition-url="' + temp_inview[i] + '"]'));
        }
      }

      for (var i = 0; i < temp_state.length; i++) {
        transition.ausklappen($('a[href="' + temp_state[i] + '"]'));
      }

    }
  }
}

// 20210521 pe - stub transition history function, replaces history_js.init()
// handle pop state
window.addEventListener('popstate', (event) => {
  if (event.state?.in_view) {
    transition.history({ data: event.state });
  }
});

// History.js initialisieren
var history_js = {
  init: function () {
    // 20210521 pe: remove History.js
    console.warn('history_js.init() is deprecated');

    // on esc go back in history
    if ($('[data-specification="startseite"][data-category="homepage"], [data-specification="fokus"][data-category="home"]').length) {
      // push current init state
      //History.replaceState({'data':transition.og_title,'in_view':transition.in_view},transition.og_title,transition.og_url);
      // History.pushState({'data':transition.og_title,'in_view':transition.in_view},transition.og_title,transition.og_url);
      // History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
      // 	var State = History.getState(); // Note: We are using History.getState() instead of event.state
      // 	transition.history(State);
      // });
    }
  }
}

/*
 * 18022014
 * Änderung PE & MS
 * - Live-Event eingebunden, damit nach Ajax auf der Startseite, die Funktionalität der Objekte erhalten bleibt
 *
*/
transition.start = function (me) {

  //Wenn der Wert von data-expander Attribut gefüllt ist, dann soll die Seite nicht über ein Overlay geöffnet werden //vs 20180226
  $('body').on('click', '.expander_outer[data-expander=""] a:not([data-slide])', function (event) {
    event.preventDefault();
    transition.ausklappen(this);
  });
}
