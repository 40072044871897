var arrowShape = {
  x0: 10,
  x1: 30,
  y1: 50,
  x2: 45,
  y2: 50,
  x3: 25
},
  GRIDGUTTER_WIDTH = 15;

/**
 * fügt zwei Objekte zu einem Object zusammen
 * @param   Object obj Zielobject
 * @param   Object src Quellobject
 * @returns Object Zielbojekt in welches das Quellobjekt eingefügt wurde
 */
function extend(obj, src) {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
}



(function ($) {
  'use strict';


  /* jQuery-Plugin für Hero-Slider.
  ============================================================*/

  $.fn.imagesHeadlinesGallery = function (gallery_headlines) {

    var $gallery_headlines = $(gallery_headlines),
      $gallery_images = this,
      headline_index = 0,
      headline_links = $gallery_headlines.find('.js-headline'),
      imageCells = null,
      fadeSlideshows = [],
      //currentFadeSlideshow = null,
      itemChangeTimer = null,
      numberImages = headline_links.length,
      currentImageIndex = 0,
      currentHeadlineIndex = 0,
      showNextImage = null,
      imageSlider = null,
      selectedIndex = 0,
      fadeLastIndex = 0,
      pageDots = null;



    //Gallerie, die an eine Gallerie mit Headlines gekoppelt ist
    $gallery_images.flickity({
      autoPlay: 6000,
      pauseAutoPlayOnHover: true,
      cellSelector: '.gallery-cell',
      cellAlign: 'left',
      groupCells: true,
      bgLazyLoad: 2,
      imagesLoaded: true,
      percentPosition: true,
      setGallerySize: true,
      wrapAround: true, // infinite loop
      arrowShape: arrowShape
    });

    imageCells = $gallery_images.data('flickity').cells;
    numberImages = imageCells.length;
    imageSlider = $gallery_images.find('.flickity-slider')[0];
    fadeLastIndex = selectedIndex = $gallery_images.data('flickity').selectedIndex;

    //Dots in Slideshow-Navigation selektieren
    pageDots = $gallery_images.find('.flickity-page-dots .dot');

    setTimeout(function () {

    }, 0)
    //Gallerie mit Headlines, die von einer anderen Gallerie gesteurt werden kann
    $gallery_headlines.flickity({
      asNavFor: $gallery_images[0],
      cellSelector: '.gallery-cell',
      cellAlign: 'left',
      draggable: false,
      groupCells: false,
      imagesLoaded: true,
      pageDots: false,
      percentPosition: true,
      prevNextButtons: false,
      setGallerySize: true,
      wrapAround: true // infinite loop

    });

    setTimeout(function () {
      $gallery_headlines.flickity('resize');
    }, 100);


    //Autoplay wird nach Userinteraktion mit der Slideshow deaktivert
    $gallery_images.on('select.flickity', function () {
      $gallery_images.flickity('playPlayer');
    });


    //Positionen von allen Zelen merken
    for (var i = 0; i < numberImages; i++) {

      var cell = imageCells[i].element;
      //Ursprungsposition merken
      cell.positionLeft = cell.style.left;
    }


    //Fadeslideshow im ersten Slide der Flickityslideshow speichern
    //currentFadeSlideshow = fadeSlideshows[0];

    headline_links.removeClass('-selected');

    //Erste Headline initial highlighten
    $(headline_links[currentImageIndex]).addClass('-selected');

    //Eventlistener auf Headlines aktivieren
    $(headline_links).off('mouseover').on('mouseover', handleMouseOver);
    $(headline_links).off('mouseout').on('mouseout', handleMouseOut);



    var timerMouseOut = null;
    function handleMouseOver(e) {
      clearTimeout(timerMouseOut);
      $gallery_images.flickity('pausePlayer');
    }

    function handleMouseOut(e) {
      clearTimeout(timerMouseOut);
      timerMouseOut = setTimeout(function () {
        $gallery_images.flickity('unpausePlayer');

      }, 200);

    }




    return this;
  };


  /* jQuery-Plugin für Product-Gallery
  ============================================================*/

  $.fn.productGallery = function () {
    var $product_gallery = this,
      flickityConfig = {
        cellSelector: '.gallery-cell',
        cellAlign: calculateCellAlign(),
        imagesLoaded: true,
        groupCells: false,
        percentPosition: true,
        resize: true,
        setGallerySize: true,
        wrapAround: true, // infinite loop
        arrowShape: arrowShape
      },
      flickityData,
      firstRound = true,
      //imageCells = document.qu
      numberItems,
      selectedIndex,
      containerProducts = $product_gallery.closest('.js-container-products')[0],
      galleryCells = $product_gallery[0].querySelectorAll('.gallery-cell'),
      galleryCell = galleryCells[0],
      numberOfMaxCells = 2,
      numberOfVisibleCells = numberOfMaxCells,
      lowerLimit = null,
      upperLimit = null;


    $product_gallery.flickity(flickityConfig);
    flickityData = $product_gallery.data('flickity');
    selectedIndex = flickityData.selectedIndex;
    numberItems = flickityData.cells.length;
    //refreshProductGallery();

    media.breakpoint.refreshValue();

    //slideshowNavi.init($product_gallery);
    if (media.breakpoint.value === 'lg' || media.breakpoint.value === 'xl') {
      $product_gallery.addClass('_hide-first-slides _hide-previous-button');
    }
    else {
      $product_gallery.removeClass('_hide-first-slides _hide-previous-button');
    }

    $product_gallery.find('.imageposition-bottom figcaption').each(function () {
      $(this).clone().prependTo($(this).parent());
    });


    /**
     * Berechnet die Anschlagsposition der ersten Zelle für eine Slideshow
     * wenn sich die Slideshow in einem container-fluid befindet, der Anschlag
     * der ersten Zelle der Slideshow sich jedoch an einem fixen Container aus soll.
     * @returns {number} Prozentwert als Dezimalzahl um welche die Slideshow verschoben werden muss
     */
    function calculateCellAlign() {
      var cellWidth = $product_gallery[0].querySelector('.gallery-cell').offsetWidth,
        containerWidth = document.querySelector('section>.container').offsetWidth,
        containerProductsWidth = $product_gallery.closest('.js-container-products')[0].offsetWidth,
        //containerWidth = $product_gallery.closest('.js-container-products')[0].offsetWidth,
        windowWith = document.body.offsetWidth;



      //Erster Faktor muss mit einberechnet werden, da der Parameter 'cellAlign'
      //den man man bei der Initialisierung der Slideshow übergibt
      //von Flickity nicht korrekt übernommen wird bzw. Flickity sich verrechnet.
      //return (1 / (1 - (cellWidth / windowWith)) * (1 - (containerWidth / windowWith)) / 2);
      return (1 / (1 - (cellWidth / containerProductsWidth)) * (1 - ((containerWidth + GRIDGUTTER_WIDTH - 1) / containerProductsWidth)) / 2);
    }

    function handleFlickitySelect() {



      var nextIndex = flickityData.selectedIndex;
      var i;

      //Alle Zellen ausblenden
      for (i = 0; i < numberItems; i++) {
        galleryCells[i].classList.remove('-visible');
      }

      //Zellen die nach der aktuellen Zelle und innerhalb des Viewports sind einblenden
      for (i = 0; i < upperLimit; i++) {
        //this._currentItem = (index % this._numberItems + this._numberItems) % this._numberItems;
        galleryCells[(nextIndex + i) % numberItems].classList.add('-visible');
      }

      //Zellen die vor der aktuellen Zelle und innerhalb des Viewports sind einblenden
      for (i = lowerLimit; i > 0; i--) {
        galleryCells[((nextIndex - i) % numberItems + numberItems) % numberItems].classList.add('-visible');
      }

      //Ist das Ende der Slideshow erreicht und soll die erste Zelle wieder angezeigt werden?
      if (selectedIndex === numberItems - 1 && nextIndex === 0) {
        $product_gallery.removeClass('_hide-first-slides _hide-previous-button');
        firstRound = false;
      }

      //Wurden alle Bilder noch nicht einmal durchgeschaltet?
      if (firstRound) {
        if (nextIndex > 0) {
          $product_gallery.removeClass('_hide-previous-button');

        }
        else {
          $product_gallery.addClass('_hide-previous-button');
        }
      }
      selectedIndex = nextIndex;
    }



    function refreshProductGallery() {
      /*
           upperLimit <------------------------------------------+
                                     |
          nextImage <----------+                               |
                     |                               |
                   +-----------------------+           |
           lowerLimit <--+   |   |                   |           |
                 |   |   |                   |           |
                 +-+-+ | +-+-+   +---+   +---+ | +---+   +-+-+
                 |   | | |   |   |   |   |   | | |   |   |   |
                 +---+ | +---+   +---+   +---+ | +---+   +---+
                   |                       |
      container-prodcuts ------+                       |
                   +-----------------------+
      */


      var windowWith = null;
      media.breakpoint.refreshValue();
      if (media.breakpoint.value === 'xs' || media.breakpoint.value === 'sm' || media.breakpoint.value === 'md') {



        //Breite des Viewports für den Slider festlegen
        containerProducts.style.width = '100%';

        $product_gallery.data('flickity').options.cellAlign = 'center';
        //flickityConfig.cellAlign = 'center';

        $product_gallery.off('select.flickity', handleFlickitySelect);

        numberOfVisibleCells = numberItems;
        //Grenzen
        lowerLimit = 0;
        upperLimit = 0;

      } else {
        windowWith = document.body.offsetWidth;

        //Es werden immer die nächstniedrigere Anzahl an ungeraden Zellen, die in den Viewport passen angezeigt.
        //numberOfVisibleCells = (Math.floor(windowWith / galleryCell.offsetWidth + 1) | 1) - 2;
        numberOfVisibleCells = numberOfMaxCells;

        //Grenzen
        lowerLimit = 0; //(numberOfVisibleCells - 3) / 2;
        upperLimit = numberOfMaxCells; //3 + (numberOfVisibleCells - 3) / 2;


        //Breite des Viewports für den Slider festlegen
        //containerProducts.style.width = galleryCell.offsetWidth * numberOfVisibleCells + 'px';

        //$product_gallery.data('flickity').options.cellAlign =  calculateCellAlign();
        $product_gallery.data('flickity').options.cellAlign = 'left';
        $product_gallery.off('select.flickity').on('select.flickity', handleFlickitySelect);
      }

      $product_gallery.flickity('resize');
      handleFlickitySelect();


    }
    refreshProductGallery();

    window.addEventListener('holy:resizeDone', () => {
      refreshProductGallery();
    });

    return this;
  };

  /* jQuery-Plugin für Lichtwissen-Gallery
  ============================================================*/

  $.fn.lichtwissenGallery = function () {

    //Falls keine Slides existieren
    if (this[0].querySelectorAll('.gallery-cell').length <= 1) {

      //Dann wird Flickity nicht initialisiert
      return false;
    }


    var $lichtwissen_gallery = this,
      flickityConfig = {
        cellSelector: '.gallery-cell',
        cellAlign: 'left',
        imagesLoaded: true,
        percentPosition: true,
        setGallerySize: true,
        wrapAround: true,
        arrowShape: arrowShape,
        contain: true,
        watchCSS: true
      },
      selectedIndex = null,
      flickityData = null,
      firstRound = true,
      numberOfCellsOutsideViewport = null,
      navDots = null,
      navigation = null,
      gallery = $lichtwissen_gallery[0],
      computedStyleCell = getComputedStyle(gallery.querySelector('.gallery-cell')),
      cellWidth = parseFloat(computedStyleCell.width),

      //Bei der Breite der Gallery muss einmal das linke und rechte Padding einer Zelle dazu addiert werden
      galleryWidth = gallery.closest('.container, .container-fluid').offsetWidth + parseFloat(computedStyleCell.paddingLeft) + parseFloat(computedStyleCell.paddingRight),
      numberOfCellsInViewport = Math.floor(galleryWidth / cellWidth),
      numberItems = gallery.querySelectorAll('.gallery-cell').length,

      dataFlickityConfig = $(this).attr('data-flickity-config');

      numberOfCellsInViewport = numberOfCellsOutsideViewport = numberItems - numberOfCellsInViewport;

      //existiert ein Config-Attribute
      if (dataFlickityConfig) {
        dataFlickityConfig = JSON.parse(dataFlickityConfig);

        //Vorhandenes config-Object mit Objekt mergen, dass aus dem Config-Attribut generiert wurde
        flickityConfig = window.toolkit.extend(flickityConfig, dataFlickityConfig);
      }


      //Gibt es Slide, die nicht in den Viewport passen?
      if (numberOfCellsOutsideViewport > 0) {
        //Dann aktivieren wir
        gallery.classList.add('_slideshowactive');
      }

      $lichtwissen_gallery.flickity(flickityConfig);
      flickityData = $lichtwissen_gallery.data('flickity');
      selectedIndex = flickityData.selectedIndex;
      $lichtwissen_gallery.addClass('_hide-first-slides _hide-previous-button');

      //Wenn sich die Gallery in einem Container mit fester Breite befindet, muss die Anschlagsposition
      //der Gallery bei einem Window Resize nicht neu berechnet werden //vs20180418

      if ((!$lichtwissen_gallery.hasClass('js-not-fluid')) && $lichtwissen_gallery.hasClass('_slideshowactive')) {

        $lichtwissen_gallery.data('flickity').options.cellAlign = calculateCellAlign();
        window.addEventListener('holy:resizeDone', () => {
          flickityData.options.cellAlign = calculateCellAlign();
        });
      }


    //Wenn wrapAround auf false
    if (flickityConfig.wrapAround === false) {

      $lichtwissen_gallery.flickity('resize');
      setupNavigation();

      $lichtwissen_gallery.on('select.flickity', function () {

        let flickity = $lichtwissen_gallery.data('flickity');

        //Wenn der letzte Slide sichtbar ist und der Nächste angezeigt werden soll
        if (flickity.selectedIndex >= numberOfCellsOutsideViewport + 1) {

          //Soll dieser nicht mehr angezeigt werden
          $lichtwissen_gallery.flickity('select', numberOfCellsOutsideViewport);

          setupNavigation();
        }

        //Wenn der letzte Slide angezeigt wird
        if (flickity.selectedIndex >= numberOfCellsOutsideViewport) {

          $lichtwissen_gallery.addClass('_hide-next-button');
        }
        else {
          $lichtwissen_gallery.removeClass('_hide-next-button');
        }
      });
    }

    $(this).flickity('resize');

    window.addEventListener('holy:resizeDone', () => {

      var mob = 'ontouchstart' in window;
      media.breakpoint.refreshValue();

      //Haben wir ein Gerät mit Tochgesten und befinden wir uns auf einem Tablet
      if (mob && media.breakpoint.value === 'md') {
        flickityData.options.freeScroll = true;
      }
      else {
        flickityData.options.freeScroll = false;
      }
      $lichtwissen_gallery.flickity('resize');
      setupNavigation();

    });


    $lichtwissen_gallery.on('select.flickity', function () {
      var nextIndex = flickityData.selectedIndex;

      //Ist das Ende der Slideshow erreicht und soll die erste Zelle wieder angezeigt werden?
      if (selectedIndex === numberItems - 1 && nextIndex === 0) {
        $lichtwissen_gallery.removeClass('_hide-first-slides _hide-previous-button');
        firstRound = false;
      }

      if (firstRound) {
        if (nextIndex > 0) {
          $lichtwissen_gallery.removeClass('_hide-previous-button');
        }
        else {
          $lichtwissen_gallery.addClass('_hide-previous-button');
        }
      }
      selectedIndex = nextIndex;
    });


    /**
     * Aktualisiert die Slideshow Navigation so, dass Dots für Slides
     * ausgeblendet werden, die sich innerhalb des Viewports befinden
     */
    function setupNavigation() {

      computedStyleCell = getComputedStyle(gallery.querySelector('.gallery-cell')),
        cellWidth = parseFloat(computedStyleCell.width),

        //Bei der Breite der Gallery muss einmal das linke und rechte Padding einer Zelle dazu addiert werden
        galleryWidth = gallery.closest('.container').offsetWidth + parseFloat(computedStyleCell.paddingLeft) + parseFloat(computedStyleCell.paddingRight);

      numberOfCellsInViewport = Math.floor(galleryWidth / cellWidth);
      numberOfCellsOutsideViewport = numberItems - numberOfCellsInViewport;

      //Gibt es Slide, die nicht in den Viewport passen?
      if (numberOfCellsOutsideViewport > 0) {
        //Dann aktivieren wir
        gallery.classList.add('_slideshowactive');

        navigation = gallery.querySelector('.flickity-page-dots');
        navDots = navigation.querySelectorAll('.dot');

        //Alle Navdots einblenden
        for (let i = 0; i < numberItems; i++) {
          navDots[i].classList.remove('hidden');
        }

        //Navdots für Cells innerhalb des Viewports ausblenden
        for (let i = numberItems - numberOfCellsInViewport + 1; i < numberItems; i++) {
          navDots[i].classList.add('hidden');
        }
      }
      else {
        gallery.classList.remove('_slideshowactive');
      }
    }

    /**
     * Berechnet die Anschlagsposition der ersten Zelle für eine Slideshow
     * wenn sich die Slideshow in einem container-fluid befindet, der Anschlag
     * der ersten Zelle der Slideshow sich jedoch an einem fixen Container ausrichten soll.
     * @returns {number} Prozentwert als Dezimalzahl um welche die Slideshow verschoben werden muss
     */
    function calculateCellAlign() {

      var cellWidth = $lichtwissen_gallery[0].querySelector('.gallery-cell').offsetWidth,
        containerWidth = document.querySelector('section>.container').offsetWidth,
        windowWith = document.body.offsetWidth,
        offsetSmall = (GRIDGUTTER_WIDTH * 1.5) / windowWith,
        offset = null;

      media.breakpoint.refreshValue();


      //Anschlag des Sliders ist abhängig von der Breite des Viewports
      if (windowWith <= 800) {

        offset = offsetSmall
      }
      else {
        offset = (1 - containerWidth / windowWith) / 2;
      }

      //Erster Faktor muss mit einberechnet werden, da der Parameter 'cellAlign'
      //den man man bei der Initialisierung der Slideshow übergibt
      //von Flickity nicht korrekt übernommen wird bzw. Flickity sich verrechnet.
      return (1 / (1 - (cellWidth / windowWith)) * offset);
    }

    //Nochmal rezisen, da Slideshows, die ausgeblendet sind und erst eingeblendet werden
    //Wenn sie sich in den Viewport bewegen
    holy.addToObserve($lichtwissen_gallery);
    $lichtwissen_gallery.one('holy:observeIn', function () {
      $(this).flickity('resize');
    });
    return this;
  };


}(jQuery));


/* Lichtbilder
============================================================*/
var jsLichtbilder = (function () {

  // vars
  var exports = {};

  // functions
  var init = function () {

    setTimeout(function () {

      $('.js-lichtbilder').each(function () {

        // elemente
        var root = $(this);
        var fix = $('<div class="flickity-fixed">');
        var nav = root.find('.flickity-page-dots');

        // event-elemente
        var slider = root.find('.flickity-slider');
        var cells = root.find('.gallery-cell');
        cells.each(function () {
          var self = $(this);
          self.data('header', self.find('.align-above_ > ._inner'));
        });

        // vorbereiten
        root.data('flickity').options.adaptiveHeight = true;
        document.body.classList.add('undoPadding');


        // dom manipulieren
        fix.prependTo(root.find('.flickity-viewport'));
        fix.append(root.find('._fixed-img'));
        fix.after(nav);
        fix.children().each(function (idx) {
          var self = $(this);
          self.css('left', idx * 100 + '%');

          if (idx === 0) {
            var lidx = fix.children().length;
            self.clone().insertAfter(self).css('left', lidx * 100 + '%');
          };
        });


        // funktionen
        var matchHeight = function () {
          var aligner = cells.find('.align-above_ > ._inner').children();
          var header = aligner.children().map(function () { return $(this).height() });
          var max = Math.max.apply(null, header);

          aligner.height(max);
          nav.css('transform', 'translateY(-' + max + 'px)');
        };

        var scrollDone = function () {
          var direction = holySnap.getStore().direction;
          if (direction === 'down') {
            root.flickity('unbindDrag');
            root.data('flickity').options.accessibility = false;
          } else {
            root.flickity('bindDrag');
            root.data('flickity').options.accessibility = true;
          }
        };

        var flick = function (event, progress) {
          var transform = slider.css('transform');
          fix.css('transform', transform);
        }


        // ausführen und anheften
        matchHeight();
        scrollDone();


        // Events anheften
        window.addEventListener('holy:resizeDone', matchHeight);
        window.addEventListener('holy:scrollDone', scrollDone);

        // Bilder Paralax
        root.on('scroll.flickity', flick);
      });

    }, 0);
  };

  // publish
  exports.init = init;

  // return
  return exports;
})();

class FlickityAsNavFor {
  static init(slideshow, navSlideshow) {
    if(!slideshow || !navSlideshow) {
      return false;
    }

    new Flickity(navSlideshow, {
      asNavFor: slideshow,
      draggable: false,
      pageDots: false,
    })
  }
}

/* Zur Initialisierung von zusätzlichen Schaltflächen
   in Slideshows
============================================================*/
var slideshowNavi = {
  _slideShows: null,
  init: function (selector) {
    
    if(!selector) {
      return false;
    }

    this._slideShows = selector;
    
    //Wurde ein Element als Parameter uebergeben?
    if (this._slideShows) {

      //Schaltflächen entfernen, falls Navi schon einmal initialisiert wurde. Sonst werden die Schaltflächen doppelt angzeigt
      this._slideShows.find('.flickity-page-dots .button-item').remove();

      //Fuer Fullscreen Slider: Schaltflächen zum Vor- und Zurueckblaettern der Bilder neben den Punkten einfuegen
      this._slideShows.find('.flickity-page-dots')
        .prepend('<li class="button-item"><button class="button js-button-previous" type="button" aria-label="previous"><svg viewBox="0 0 100 100"><path d="M 10,50 L 35,75 L 35,65 L 20,50  L 35,35 L 35,25 Z" class="arrow"></path></svg></button></li>');
      this._slideShows.find('.flickity-page-dots')
        .append('<li class="button-item"><button class="button js-button-next" type="button" aria-label="next"><svg viewBox="0 0 100 100"><path d="M 10,50 L 35,75 L 35,65 L 20,50  L 35,35 L 35,25 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg></button></li>');

      //Eventlistener fuer die Schaltflaechen zum Vor- und Zurueckblaettern hinzufuegen
      this._slideShows.find('.flickity-page-dots .js-button-previous').off('click').on('click', function (event) {
        $(event.target).closest('.js-gallery').flickity('previous');
      });

      this._slideShows.find('.flickity-page-dots .js-button-next').off('click').on('click', function (event) {
        $(event.target).closest('.js-gallery').flickity('next');
      });


      //DesignbilderDL Link einfügen
      //JSON-linkobject_design_download kommt aus pdb-api (referenziert im template products_head.htm im cms-backend)
      if (typeof linkobject_slideshow_download !== 'undefined') {
        this._slideShows.each((index, element) => {
          $element = $(element);

          if ($element.closest('.feature-head').length > 0) {

            // If page dots exists (more than one slide in slide show)
            if ($element.find('.flickity-page-dots').length) {
              // Wir fügen das vorher und nachher ein, damit es zentriert bleibt und blenden das linke Element aus
              $element.find('.flickity-page-dots').first()
                .prepend('<li class="button-item download-items" style="visibility:hidden"><a href="' + linkobject_slideshow_download.url + '">' + linkobject_slideshow_download.linktext + '</a></li>');
              $element.find('.flickity-page-dots').first()
                .append('<li class="button-item download-items"><a href="' + linkobject_slideshow_download.url + '">' + linkobject_slideshow_download.linktext + '</a></li>');
            }
            // page dots doesn't exit, appnd link to slide show
            else {
              $element.append(`<div class="Aligner top-1"><a href="${linkobject_slideshow_download.url}">${linkobject_slideshow_download.linktext}</a>`);
            }
          }
        });
      }
    }
  }
}



/* Lichtinspiration
============================================================*/
var li_gallery = (function () {

  var exports = {};

  var init = function () {

    var $carousel = $('.js-li-gallery').flickity({
      cellSelector: '._item',
      cellAlign: 'left',
      imagesLoaded: true,
      wrapAround: true,
      lazyLoad: 1

    });

    $carousel.on('select.flickity', function () {
      var self = $(this);

      // set image caption using img's alt
      var caption = self.data('flickity').selectedElement.querySelector('._imgcaption').innerHTML;
      self.find('._caption').html(caption);
    });

    slideshowNavi.init($carousel);
    setTimeout(function () {
      $carousel.flickity('resize');
    }, 0)

  };


  exports.init = init;

  return exports;
})();




// external js: flickity.pkgd.js
$(document).on('initModule-2-1 ajaxTransitionHomepageReady', function () {
  'use strict';
  var resizeId,
  $gallery = $('.js-gallery').not('.js-gallery-lichtwissen .js-li-gallery'),

  flickityConfig = {
    cellSelector: '.gallery-cell',
    cellAlign: 'left',
    imagesLoaded: true,  // re-position cells when images load
    lazyLoad: 1,
    percentPosition: true,
    setGallerySize: true,
    wrapAround: false,
    arrowShape: arrowShape
  };



  document.querySelectorAll('.js-imagestrip-gallery').forEach((galleryElement) => {
    // If there are no slides
    if (galleryElement.querySelectorAll('.gallery-cell').length <= 1) {

      // then Flickity will not be initialized
      return true;
    }

    let $currentFlickity = $(galleryElement).flickity(flickityConfig);

  });

  flickityConfig.wrapAround = true;

  $('.js-fullscreen-gallery').each(function () {

    //Config-Attribut des Elements holen
    var dataFlickityConfig = $(this).attr('data-flickity-config');

    //existiert ein Config-Attribute
    if (dataFlickityConfig) {
      dataFlickityConfig = JSON.parse(dataFlickityConfig);

      //Vorhandenes config-Object mit Objekt mergen, dass aus dem Config-Attribut generiert wurde
      flickityConfig = window.toolkit.extend(flickityConfig, dataFlickityConfig)
    }



    //Haben wir nur eine Zelle in der Slideshow
    if ($(this).find('.gallery-cell').length <= 1) {

      //Dann wird Funktion zum Draggen deaktiviert
      flickityConfig.draggable = false;

      //und alle Steuerelemente ausgeblendet
      flickityConfig.prevNextButtons = false;
      flickityConfig.pageDots = false;

    }
    //Haben wir mehr als eine Zelle
    else {
      //Dann wird Funktion zum Draggen aktiviert
      flickityConfig.draggable = true;

      //und alle Steuerelemente eingeblendet
      flickityConfig.prevNextButtons = true;
      flickityConfig.pageDots = true;

    }
    $(this).flickity(flickityConfig);

    if (this.hasAttribute('data-js-flickity-nav')) {
      FlickityAsNavFor.init(this, this.getAttribute('data-js-flickity-nav'));
    }
  });

  //Configs zurücksetzen
  flickityConfig.draggable = true;
  flickityConfig.prevNextButtons = true;
  flickityConfig.pageDots = true;
  flickityConfig.cellAlign = 'center';
  flickityConfig.bgLazyLoad = 3,

    $('.js-projects-gallery').flickity(flickityConfig);
  var jsProjectsGalleryHandler = function () {

    // get css property
    var el = $('.js-projects-gallery');
    if (!el.length) return;

    media.breakpoint.refreshValue();

    if (media.breakpoint.value === 'lg' || media.breakpoint.value === 'xl') {
      //el.data('flickity').options.adaptiveHeight = true;
      el.data('flickity').options.adaptiveHeight = false;
      el.data('flickity').options.groupCells = 2

    } else {

      el.data('flickity').options.adaptiveHeight = false;
      el.data('flickity').options.groupCells = 1
    }


    var mob = 'ontouchstart' in window;

    media.device.refreshValue();
    media.orientation.refreshValue();
    //Haben wir ein Gerät mit Touchgesten und haben wir ein Hochkantformat oder
    //Haben wir ein Gerät mit Touchgesten und haben wir ein Gerät in der Bildschirmbreite SM und ein Querformat
    if (media.device.value === media.device.DESKTOP || (media.device.value === media.device.MOBILE && (media.orientation.value === 'portrait') || (media.orientation.value === 'landscape') && (media.breakpoint.value === 'sm'))) {
      el.data('flickity').options.freeScroll = false;
    }
    else {

      el.data('flickity').options.freeScroll = true;
    }

    el.flickity('resize');
  };

  setTimeout(function () {
    jsProjectsGalleryHandler();
  });



  window.addEventListener('holy:resizeDone', jsProjectsGalleryHandler);

  //Gallerie, die an eine Gallerie mit Headlines gekoppelt ist
  var $gallery_control_headlines = $('.js-gallery-control-headlines'),

    //Gallerie mit Headlines, die von einer anderen Gallerie gesteurt werden kann
    $gallery_headlines = $('.js-gallery-headlines'),

    //Zum Speichern der initialisierten Slideshow-Plugins. Diese werden beim Resizen des Browserfensters benoetigt
    gallery_array = new Array(),

    //Product Gallerien
    $gallery_products = $('.js-gallery-products'),

    //Lichtwissen Gallerien
    $gallery_lichtwissen = $('.js-gallery-lichtwissen');


  //Gallerien initialisieren
  $gallery_control_headlines.each(function (index, element) {
    gallery_array.push($(element).imagesHeadlinesGallery($gallery_headlines[index]));
  });


  //Gallerie mit Produkten
  $gallery_products.each(function (index, element) {
    $(element).productGallery();
  });

  //Gallerie mit Lichtwissen
  $gallery_lichtwissen.each(function (index, element) {
    $(element).lichtwissenGallery();
  });


  $('.js-init-invisible').each(function (index, element) {
    $(element).imagesLoaded().done(function (instace) {


      element.classList.add('-visible');
    });
  });




  /* Twitter Tweetbox
  ============================================================*/

  //Tweetboxen selektieren
  var $tweetBoxes = $('.js-twitter-tweet');

  //JS für Twitterwidgets darf nur eingebunden werden, wenn Tweetboxen im Dokument existieren
  if ($tweetBoxes.length > 0) {
    window.twttr = (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "http://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    }(document, "script", "twitter-wjs"));

    //Styling des eingebetten Twitter-Widgets muss via JavaScript vorgenommen werden, da es Aufgrund von
    //content security policy nicht via CSS vorgenommen werden kann.
    var styleTweet = function (element) {
      var ibody = element.contents().find('body');
      var links = ibody.find('.Tweet-body a');
      links.not('.TweetAction').css('border-bottom', '1px dotted');


      links.css('color', '#777777');
      links.hover(
        function () {
          $(this).css('color', '#000000');
        },
        function () {
          $(this).css('color', '#777777');
        }
      );

      ibody.find('.EmbeddedTweet, .MediaCard-borderOverlay, .EmbeddedTweet-tweet').css('border-radius', '0');
    };

    twttr.ready(function (twttr) {
      $tweetBoxes.each(function () {
        var tweet_id = $(this).attr('data-tweet-id');
        var that = this;
        twttr.widgets.createTweet(
          tweet_id,
          that
        ).then(function (element) {
          styleTweet($(element));
        });
      });

    });
  }







  //Fuer Imagestrip Slider: Schalflaechen in Wrapper verschieben
  var $button = $('.js-imagestrip-gallery .flickity-prev-next-button.previous');
  $button.closest('.flickity-wrapper').append($button);

  $button = $('.js-imagestrip-gallery .flickity-prev-next-button.next');
  $button.closest('.flickity-wrapper').append($button);

  slideshowNavi.init($gallery);
  holySnap.init();
  jsLichtbilder.init();
  li_gallery.init();


});

