/**
 * grid_gallery
 * Wird verwendet in
 * - Produktfamilienseiten => Anwendungsbilder
 * 
 * @dependencies
 * * jquery
 * * flickity
 * 
 * @dependents
 * * $(document).ready(function()
 * * data attribute [data-application-images-grid] used in several views in pdp-api
 * * data attribute [data-link-index] used in several views in pdp-api
 * 
 * @commit Initial commit 2.0
 */


grid_gallery = {
  tabs: new Array(),
  carousels: new Array(),
  init: function () {
    var applicationImagesGrid = document.querySelectorAll('[data-application-images-grid]');

    //Durch Grid-Container iterieren
    for (var i = 0; i < applicationImagesGrid.length; i++) {

      //generierte ID holen
      var id = applicationImagesGrid[i].getAttribute('data-application-images-grid');
      //Tab Navigation zum Umschalten des Layouts

      var tabs = document.querySelector('[href="#view_gallery_' + id + '"]');
      grid_gallery.tabs[id] = tabs;


      //Kacheln in der Gridansicht
      var buttons = applicationImagesGrid[i].querySelectorAll('[data-link-index]');

      for (var j = 0; j < buttons.length; j++) {
        buttons[j].addEventListener('click', function (e) {
          e.preventDefault();

          //Index für das Carousel holen
          var index = e.currentTarget.getAttribute('data-link-index');

          //ID holen, passende Carousel holen und den zum
          var currentId = e.currentTarget.closest('[data-application-images-grid]').getAttribute('data-application-images-grid');

          //Das zur id passende Carousel holen
          var currentCarousel = grid_gallery.carousels[currentId];

          //Slide auswählen
          currentCarousel.flickity('select', index);

          currentCarousel.one('settle.flickity', function () {
            $(grid_gallery.tabs[currentId]).tab('show');
          });
        });
      };


      //Wird gefeuert, wenn der Tab zum Anzeigen des Carousels ausgewählt wurde
      $(tabs).on('shown.bs.tab', function (e) {
        var id = e.currentTarget.getAttribute('data-id');
        //Carousel resizen, damit es korrekt dargestellt wird
        grid_gallery.carousels[id].flickity('resize');
      });

      var flickityOptions = {
        cellAlign: 'center',
        cellSelector: '[data-cell]',
        imagesLoaded: true,
        initialIndex: 0,
        setGallerySize: true,
        bgLazyLoad: 1,
        percentPosition: true,
        pageDots: false,
        prevNextButtons: true,
        arrowShape: {
          x0: 45,
          x1: 60,
          y1: 40,
          x2: 70,
          y2: 40,
          x3: 55
        }
      }

      var projectImagesCarousel = document.querySelectorAll('[data-application-images-carousel]');

      //Durch Carsousels iterieren
      for (var k = 0; k < projectImagesCarousel.length; k++)(function (k) {

        //Carousel initialiseren
        var carouselElement = projectImagesCarousel[k];

        //Befindet sich mehrere Zellen in der Slideshow?
        if (carouselElement.querySelectorAll(flickityOptions.cellSelector).length > 1) {

          flickityOptions.draggable = true;
        }
        else {

          flickityOptions.draggable = false;
        }

        $carousel = $(carouselElement).flickity(flickityOptions);

        //Carousel merken
        var carouselID = carouselElement.getAttribute('data-application-images-carousel');
        grid_gallery.carousels[carouselID] = $carousel;

        //Wir horchen darauf, wenn ein Slide ausgewählt wird
        $carousel.on('select.flickity', function (event) {

          //Ausgewählter Slide
          var selectedElement = $(event.currentTarget).data('flickity').selectedElement;

          //Daten aus dem Slide holen
          var text = selectedElement.getAttribute('data-description');
          var linkToProject = selectedElement.getAttribute('data-link-to-project');
          var ratio = selectedElement.getAttribute('data-ratio');

          //Textfeld holen
          var node = event.currentTarget.closest('[data-tab-pane]').querySelector('[data-description-field]');


          // NOTE: Wird das noch benötigt(?)
          if (ratio == 0) {
            ratio = 150.282485876;
          }

          //Breite für das Textfeld berechnen und setzen
          var desWidth = ratio < (16 / 9 * 100) ? (ratio / 16 * 9) + '%' : '100%';

          node.style.width = desWidth;

          //Link definierten, falls benötigt
          if (linkToProject && text) {
            text = "<a target='_blank' href='" + linkToProject + "'>" + text + "</a>";
          }

          //Textfeld füllen
          node.innerHTML = text ? text : "";

        });

        //Resize, damit Carousel korrekt dargestellt wird
        setTimeout(function () {
          $carousel.flickity('resize');
        }, 800);

      })(i);
    }
  }
}