/**
 * Activate Tooltips
 *
 * @dependencies
 * * jQuery
 *
 * @dependents
 * * PDB-API: dist/spot-on/struktur/lib/bootstrap/js/tests/unit/tooltip.js (only tests)
 * * /js/products/articletable-km-v2.1.js (fires show-event)
 * * is called in erco.js
 *
 * @commit
 * * c.unger, (September 22nd, 2020 5:02 PM): add click event listener for tooltips; close tooltips, when clicking on other content than the tooltip
 */
var activateTooltips = function () {
  if ($('[data-original-title]').length > 0) {
    $tooltips = $('[data-original-title]');
    $tooltips.tooltip();

    var activeTooltipsSelector = '[aria-describedby^="tooltip"]';

    var tooltipSelectors = '.tooltip, [data-tooltip], [data-trigger-click*="tooltip"]';

    var bodyClass = '';

    $tooltips.on('show.bs.tooltip', function (event) {
      // close tooltip by clicking close button
      setTimeout(() => {
        const tooltipID = event.currentTarget.getAttribute('aria-describedby');
        document
          .querySelector(`#${tooltipID} [data-close-tooltip]`)
          .addEventListener('click', handleTooltipCloseClick);
      }, 0);

      var $tooltipTrigger = $(event.currentTarget);
      bodyClass = $tooltipTrigger.data('body-class');
      if (bodyClass !== '') {
        document.body.classList.add(bodyClass);
      }

      // hide active tooltips when opening a new one
      var $activeTooltips = $(activeTooltipsSelector);
      if ($activeTooltips.length) {
        $($activeTooltips).tooltip('hide');
      }

      // add timeout, so it won't be triggered directly when opening tooltips
      setTimeout(function () {
        // listen to click events named 'mytooltip' that are triggered on non-tooltip elements
        $('*:not(' + tooltipSelectors + ')').on('click.mytooltip', function (e) {
          // do not propagate, because it would bubble till an element outside the tooltip
          // is reached and the tooltip would be closed
          e.stopPropagation();

          // check if there are any opened tooltips
          var $activeTooltips = $(activeTooltipsSelector);
          if ($activeTooltips.length) {
            // check if click target is child of a tooltip (e.g. content in a tooltip)
            $target = $(e.currentTarget);
            isNoTooltipChild = $target.closest(tooltipSelectors).length === 0;

            // if no, then hide the tooltip and remove event listener
            if (isNoTooltipChild) {
              $($activeTooltips).tooltip('hide');
              $('*:not(' + tooltipSelectors + ')').off('click.mytooltip');
            }
          } else {
            // if there are no opened tooltips, we should remove remaining event listeners
            // to prevent that newly opened tooltips could directly be closed by this function
            $('*:not(' + tooltipSelectors + ')').off('click.mytooltip');
          }
        });
      }, 300);
    });

    function handleTooltipCloseClick(e) {
      $(e.currentTarget.closest('.tooltip')).tooltip('hide');
      e.currentTarget.removeEventListener('click', handleTooltipCloseClick);
    }

    // the click event listener for 'mytooltip' should always be removed, if a tooltip was closed
    // to prevent remaining event listeners and  double trigger of the same event
    $tooltips.on('hide.bs.tooltip', function (e) {
      $('*:not(' + tooltipSelectors + ')').off('click.mytooltip');
      if (bodyClass !== '') {
        document.body.classList.remove(bodyClass);
      }
    });
  }
};
