/* UserAction - CU 2021-12-07
 ******************************************************/

class UserAction {

  init(contextEl) {
    this.contextEl = contextEl;
  }


  getDefaultParams() {
    const params = {};

    // always send a client ID, if possible
    params.clientId = this.getTrackingClientId();

    // set origin page URL, if none is given
    params.originPageUrl = window.location.href;

    return params;
  }


  getUserActionParams(currentTrigger, presetParams) {
    if (currentTrigger === undefined ||
        currentTrigger.dataset.userActionParams === undefined ||
        currentTrigger.dataset.userActionParams === '') {
      return {};
    }

    const triggerParams = JSON.parse(currentTrigger.dataset.userActionParams);
    // merge params by overwriting preset
    return { ...(presetParams || {} ), ...triggerParams };
  }


  getTrackingClientId() {
    return (window.Tracking && window.Tracking.isEnabled() && window.Tracking.getClientId()) || '';
  }


  addActionTrigger(eventType, eventTargetSelector, eventFunc) {
    const listenerTargetEl = this.contextEl;
    listenerTargetEl.addEventListener(eventType, (event) => {
      const foundEventTargetSelector = document.querySelectorAll(eventTargetSelector);
      if (foundEventTargetSelector.length > 0 && Array.from(foundEventTargetSelector).includes(event.target)) {
        this.currentTrigger = event.target;
        eventFunc.bind(this)(event.target);
      }
    });
  }


  sendActionToApi(type, params) {
    $.ajax({
      method: 'POST',
      url: [confEnv['pdb_api_url'], 'useraction', type.toLowerCase()].join('/'),
      data: JSON.stringify(params) || '',
      contentType: 'application/json',
      xhrFields: {
        withCredentials: true,
      },
    }).fail(() => {
      console.warn(`Action ${type.toLowerCase()} couldn't be sent. Data: `, JSON.stringify(params));
    });
  }


  /*
   * Functions to be called by the registered triggers:
   */

  contentShared(eventTarget) {
    const urlPath = 'content_shared';
    const params = this.getUserActionParams(eventTarget, this.getDefaultParams());
    this.sendActionToApi(urlPath, params);
  }

  lightFinderSearchCopiedToClipboard(eventTarget) {
    const urlPath = 'light_finder_search_copied_to_clipboard';
    const params = this.getDefaultParams();
    this.sendActionToApi(urlPath, params);
  }

  phoneNumberClicked(eventTarget) {
    const urlPath = 'phone_number_clicked';
    const params = this.getDefaultParams();
    this.sendActionToApi(urlPath, params);
  }

  productDataDownloaded(eventTarget) {
    const urlPath = 'product_data_downloaded';
    const params = this.getDefaultParams();
    this.sendActionToApi(urlPath, params);
  }
}


(function (document) {
  const userAction = new UserAction();
  userAction.init(document.body);

  /* Content Shared */
  userAction.addActionTrigger('click', '[data-user-action-type="content_shared"]', userAction.contentShared);

  /* Light Finder Search Copied To Clipboard */
  userAction.addActionTrigger('click', '[data-user-action-type="light_finder_search_copied_to_clipboard"]', userAction.lightFinderSearchCopiedToClipboard);

  /* Phone Number Clicked */
  userAction.addActionTrigger('click', 'a[href^="tel"]', userAction.phoneNumberClicked);

  /* Product Data Downloaded */
  userAction.addActionTrigger('click', '[data-user-action-type="product_data_downloaded"]', userAction.productDataDownloaded);

}(document));