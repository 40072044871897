/**
 * @class Accordion
 * @description Adds accordion functionality to elements with the data-accordion attribute
 */
class Accordion {
  /**
   * @param {HTMLElement} container - The container element that holds the accordion
   */
  constructor(container) {
    this.container = container;
    this.items = container.querySelectorAll('[data-accordion-item]');
    this.accordionState = this.getAccordionData();
    this.init();
  }

  getAccordionData() {
    // example: data-accordion='{"state": [{"id":"header-1", "open":true}, {"id":"header-2", "open":false}]}'
    const dataAttribute = this.container.dataset.accordion;
    return dataAttribute ? JSON.parse(dataAttribute).state : [];
  }

  init() {
    this.items.forEach((item, index) => {
      const header = item.querySelector('[data-accordion-header]');
      this.setupHeader(header, index);
      this.setupContent(header, index);
      this.setInitialState(header, index);
      this.addEventListeners(header);
    });
  }

  setupHeader(header, index) {
    header.setAttribute('role', 'button');
    header.setAttribute('aria-expanded', 'false');
    header.setAttribute('aria-controls', `content-${index}`);
    header.setAttribute('tabindex', '0');
  }

  setupContent(header, index) {
    const content = header.nextElementSibling;
    content.setAttribute('role', 'region');
    content.setAttribute('id', `content-${index}`);
    content.setAttribute('aria-hidden', 'true');
  }

  setInitialState(header, index) {
    const content = header.nextElementSibling;
    const accordionConfig = this.accordionState.find((acc) => acc.id === header.id);

    if (accordionConfig?.open) {
      header.setAttribute('data-accordion-active', 'true');
      header.setAttribute('aria-expanded', 'true');
      content.setAttribute('aria-hidden', 'false');
      content.style.height = 'auto';
    }
  }

  addEventListeners(header) {
    header.addEventListener('click', () => this.toggleAccordion(header));
    header.addEventListener('keydown', (event) => this.handleKeyDown(event, header));

    const content = header.nextElementSibling;
    content.addEventListener('transitionend', () => this.onTransitionEnd(header, content));
  }

  handleKeyDown(event, header) {
    if (['Enter', ' '].includes(event.key)) {
      event.preventDefault();
      header.click();
    }
  }

  onTransitionEnd(header, content) {
    if (header.getAttribute('data-accordion-active') === 'true') {
      content.style.height = 'auto';
    }
  }

  toggleAccordion(header) {
    const isActive = header.getAttribute('data-accordion-active') === 'true';
    const content = header.nextElementSibling;

    header.setAttribute('data-accordion-active', !isActive);
    header.setAttribute('aria-expanded', !isActive);
    content.setAttribute('aria-hidden', isActive);

    if (isActive) {
      content.style.height = content.scrollHeight + 'px';
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    } else {
      content.style.height = 'auto';
      const height = content.scrollHeight;
      content.style.height = '0';
      requestAnimationFrame(() => {
        content.style.height = height + 'px';
      });
    }
  }

  /**
   * @description Initializes all accordions with the data-accordion attribute
   */
  static initAccordions() {
    const accordionContainers = document.querySelectorAll('[data-accordion]');
    accordionContainers.forEach((container) => {
      new Accordion(container);
    });
  }
}

document.addEventListener('DOMContentLoaded', Accordion.initAccordions);
