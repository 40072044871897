const slideEffect = () => {
  const inViewport = (elements, observer) => {
    elements.forEach(element => {
      element.target.classList.toggle("in-viewport", element.isIntersecting);
      if (element.isIntersecting) {
        observer.unobserve(element.target);
      };
    });
  };
  
  const IntObs = new IntersectionObserver(inViewport);
  const obsOptions = {};
  
  const elementsInViewport = document.querySelectorAll('[data-inviewport]');
  elementsInViewport.forEach(element => {
    IntObs.observe(element, obsOptions);
  });
}

window.addEventListener("load", (event) => {
  slideEffect();
});
