class Maintenance {
  
  maintenanceBannerElement = null;
  translations = null;
  
  constructor() {
    // check if maintenance mode is enabled
    if (confEnv['maintenance_banner_enabled'] !== 'true') {
      return;
    }

    if (!this.translations) {
      new Promise((resolve) => {
        jsTranslation.getContext('maintenance', resolve);
      }).then(() => {
        this.translations = globals.trans.maintenance;
        this.generateMaintenanceBanner();
      });
    }
  }

  static init() {
    new Maintenance();
  }

  generateMaintenanceBanner() {
    const header = document.querySelector('[data-menu]');

    // create maintenance banner div 
    this.maintenanceBannerElement = document.createElement('div');
    this.maintenanceBannerElement.classList.add('theme-dark', 'theme-dark--9');
    this.maintenanceBannerElement.style.backgroundColor = '#1478a0';

    // create maintenance banner wrapper element and append it to the maintenance banner element
    const wrapper = document.createElement('div');
    wrapper.classList.add('wrapper', 'al-l-r', 'al-v-c', 'p-y-2');
    this.maintenanceBannerElement.appendChild(wrapper);

    // create maintenance banner text element and append it to the wrapper element
    const maintenanceBannerText = document.createElement('p');
    maintenanceBannerText.classList.add('body-font-s', 'color-text-contrast-6');
    maintenanceBannerText.textContent = this.translations['maintenance_info'];
    wrapper.appendChild(maintenanceBannerText);

    // create maintenance banner close button element and append it to the wrapper element
    const maintenanceBannerClose = document.createElement('button');
    maintenanceBannerClose.addEventListener('click', this.closeMaintenance.bind(this)); // Bind this to the class instance
    maintenanceBannerClose.classList.add('maintenance__close', 'button', 'button--text', 'button--small', 'button--with-icon-only', 'al-v-c');

    const maintenanceBannerCloseIcon = document.createElement('span');
    maintenanceBannerCloseIcon.classList.add('icon', 'icon--close-small');
    maintenanceBannerClose.appendChild(maintenanceBannerCloseIcon);
    wrapper.appendChild(maintenanceBannerClose);
    header.appendChild(this.maintenanceBannerElement);
  }

  closeMaintenance() {
    this.maintenanceBannerElement.remove();
  }
}
