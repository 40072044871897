/*
 * Klasse zum Nachladen von Inhalten auf der ERCO-Startseite
 *
 * @date 18.02.2014
 * @author Marvin Strauch <m.strauch@klickmeister.de>
*/

var deferred_load = {

    /*
     * - nachzuladene Inhalte
     * @var array
    */
    
    data: [],
    
    /*
     * - Anzahl der Elemente, die nachgeladen werden sollen
     * @var integer
    */
    
    offset: 5,

    /*
     * - URL zur Tapete
     * @var string
    */
        
    url: '',
    
    /*
     * - die Daten nächsten, die geladen werden
     * @var array
    */
    
    prepared_data: [],
    
    /*
     * - ist der Update-Prozess momentan im Gange?
     * @var boolean
    */
    
    active: false,
    
    /*
     * - Selektor um Daten im DOM anzubinden
     * @var string
    */
    
    selector: 'section.container',
    
    /*
     * - initialisieren Nachladen-Funktionalität mit den URLs, die nachgeladen werden sollen
     * @param array
    */
    
    datastat: 0,
    
    
    init : function(obj) {
        deferred_load.data = obj;
        
        puffer = 0;
		//if(display_modus == "phone"){ puffer = 100; }
		
		// Nachladen beim Scrollen - nachempfunden: unamono.com
		
		if( typeof holy === 'undefined' ){
				
			$(window).on('scroll', function() {
				
				if($(window).scrollTop()+puffer >= $(document).height() - $(window).height()) {
				
					if(!deferred_load.active) deferred_load.load();
				}
			});
		} else {
			
			$(document).on('holy:toBottom',function(){
				if(!deferred_load.active) deferred_load.load();
				return false //stop bubbling
			})
			
		}
    },
    
    /*
     * - setze URL zum tapezieren
     * @param string
    */
        
    set_tapete : function(url) {
        deferred_load.url = url;
    },
    
    /*
     * - getter für URL
    */
    
    get_tapete : function() {
        return deferred_load.url;
    },
    
    /*
     * - getter für Daten
    */
        
    get_data: function() {
        return deferred_load.data;
    },
    
    /*
     * - bereiten die nächsten Daten zum Laden vor
    */
    
    next: function() {
       var arr = new Array();
       if(deferred_load.offset > deferred_load.data.length) deferred_load.offset = deferred_load.data.length;
       for(var i = 0; i < deferred_load.offset; i++) {
           arr[i] = deferred_load.data.shift();
       } 
       
       deferred_load.prepared_data = arr;
    },
    
    /*
     * - lade die nächsten Objekte in den DOM
    */
        
    load: function() {
        // blockieren
        
        deferred_load.active = true;
        
        // Loader einfügen
		var ajax_loader = $("<img src=\"/struktur/img/ajax_loader.gif\" id=\"ajax-loader\" alt=\"please wait while loading\" />");
		
        $(deferred_load.selector).last().after(ajax_loader.addClass("ajax_loading_ohne_rahmen"));
		
		// bereite die nächsten Objekte vor
        deferred_load.next();
        
        // gibt es überhaupt Objekte?
        if(deferred_load.prepared_data.length<=0) {
            $('#ajax-loader').remove();
            return;
        }

        // holen wir uns die nächsten Daten
        jQuery.ajax({
            url: deferred_load.url,
            type: 'GET',
            data: {array: deferred_load.prepared_data.join(',') }
        }).done(function(data){
            $('#ajax-loader').replaceWith(data);
            deferred_load.datastat += 1;
            ga('send', 'event','scrolling', 'scroll', 'homepage'+deferred_load.datastat, 1);
            // Events initialisieren
			after_ajax.start();
            listener.start();
            
            deferred_load.active = false;
        }).fail(function() {
            $('#ajax-loader').remove();
            deferred_load.active = false;
        });
    }
    
    
    
};
