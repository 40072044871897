class ImageFadeOver {
  constructor(wrapper) {
    this.wrapperElement = wrapper;
      if(this.wrapperElement.getAttribute('data-js-image-fade-over') === 'initialized') {
        return false;
      }
      this.wrapperElement.setAttribute('data-js-image-fade-over', 'initialized');
      let options = {
        rootMargin: '-40% 0% -60% 0%',
        threshold: 0
      };

      const observer = new IntersectionObserver(this.handleObserve, options);
      observer.observe(wrapper);
  }

  static init() { 
    const wrappers = document.querySelectorAll('[data-js-image-fade-over]');
    wrappers.forEach((wrapper) => {
      if(wrapper.getAttribute('data-js-image-fade-over') !== 'initialized') {
        new ImageFadeOver(wrapper);
      }
    });
  }

  handleObserve(entries, observer) {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        const img = entry.target.querySelectorAll('[data-js-image-fade-over-img]')[1];
        img.style.opacity = '1';
        // observer.unobserve(entry.target);
      }
    });
  }
}