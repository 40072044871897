/* the holy scroller
========================*/
	
var holy = new function(){
	
	/* Variablen
	------------------------*/
	
	// wichtige Elemente
	var container, scrollContainer, observe, notifyer, self = this;
	
	// aktueller Status/Werte
	var stat = {
		position:  0,
		scrolling: false,
		direction: true,
		scrollMax: null,
		nearEdge: null,
		containerHeight: null,
		inView: null,
		timer: 100
	};
	var stat_og = {};
	
	// stupide helfer
	var ie = document.documentMode !== undefined;
	var ff = typeof InstallTrigger !== 'undefined';
	
	
	/* Funktionen
	------------------------*/
	
	// Container setzen
	/* TODO: Die gesamte Funktion muss ggf. noch mal überarbeitet werden
	   Es wirkt nicht sehr robust bezüglich Crossbrowser Kompatibilität vs 10.2017 */
	function setContainer(element){
		var element = $(element);
		if( !element.length ) {

			//Fixing the scrollTop bug siehe: https://dev.opera.com/articles/fixing-the-scrolltop-bug //vs 11.2017
			if ('scrollingElement' in document) {
				element = $(document.scrollingElement);
			}
			// Fallback for legacy browsers
			else if (navigator.userAgent.indexOf('WebKit') != -1) {
				element = $(document.body);
			}
			else {
				element = $(document.documentElement);
			}
		}
		
		// komische Falluntescheidung für ie und ff
		if( element.is('body') || element.is('html')  ){
			//container       = (ie||ff)?element.parent() : element;
			container       = ((ie||ff) && element.is('body')) ? element.parent() : element;
			scrollContainer = $(window);
		} else {
			container       = element;
			scrollContainer = element;
		}
		return self;
	}
	
	// Notifyer setzen
	function setNotifyer(element){	
		notifyer = $(element);
		if(!notifyer.length) notifyer = $(document);
		
		return self;
	}
	
	// Gesamtes Set an Observe Elementen abgeben
	function setToObserve(elements){
		observe = $(elements);
		
		return self;
	}
	
	// einzelne(s) Element(e) anfügen
	function addToObserve(elements){
		observe = $(observe).add( $(elements) );
		
		return self;
	}
	
	// einzelne(s) Element(e) entfernen
	function rmObserve(elements){
		elements = $(elements);
		if (observe.length) {
			observe = observe.not( elements );

			elements.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:observeRemove', {
					bubbles: true,
				}));
			});
		}
		
		return self;
	}
	
	// prüfe ob ein observtes element im view ist
	function checkObserve(){
		var center    = stat.containerHeight * 0.7;
		var newInView = $();
		var oldInView = stat.inView || $();
		
		observe.each(function(){
			var box = this.getBoundingClientRect();
			if( box.top <= center && box.bottom > center  ) { 
				newInView = $(this);
			} 
			
			if (box.top > stat.containerHeight || box.bottom < 0) {
				if (this.inViewport !== false) {
					this.inViewport = false;
					this.dispatchEvent(new CustomEvent('holy:observeOutViewport', {
						bubbles: true,
					}));
				}
			} else {
				if (this.inViewport !== true) {
					this.inViewport = true;
					this.dispatchEvent(new CustomEvent('holy:observeInViewport', {
					bubbles: true,
				}));
				}
			}
		});
		
		if( !newInView.is(oldInView) ){
			if (oldInView[0]) {
				oldInView[0].dispatchEvent(new CustomEvent('holy:observeOut', {
					bubbles: true,
				}));
			}
			if (newInView[0]) {
				newInView[0].dispatchEvent(new CustomEvent('holy:observeIn', {
					bubbles: true,
				}));
			}
			stat.inView = newInView;
		}
		
		return self;
	}
	
	// scrolle animiert zu einem Element
	function scrollTo(element,duration,offset){
		if( typeof element === 'number' ){
			duration = element;
			element  = undefined;
		}
		
		if( duration === undefined ) duration = 'fast';
		if( offset === undefined) offset = 0;
		element = $(element);
		
		var cScroll = scrollContainer.scrollTop();
		var nScroll = 0;
		
		if( element.length ) {
			var nBox    = element[0].getBoundingClientRect();
			var nScroll = nBox.top + cScroll;
		}
		
		nScroll -= offset;
		
		// nur scrollen, wenn wir noch nicht da sind
		if( cScroll !== nScroll ){	
			container.stop('scroll_queue',true,false);
			container.animate({scrollTop: nScroll},{
				duration: duration, 
				queue: 'scroll_queue',
				complete: function(){
					if (element[0]) {
						element[0].dispatchEvent(new CustomEvent('holy:scrollDone', {
							bubbles: true,
						}));
					}
				}
			});
			container.dequeue('scroll_queue');
		} else {
			if (element[0]) {
				element[0].dispatchEvent(new CustomEvent('holy:scrollDone', {
					bubbles: true,
				}));
			}
		}
		
		return self;
	}

	/* Event Funktionen
	------------------------*/
	const scrollPollThrottle = window.toolkit.throttle(scrollPoll, stat.timer);
	
	// Events anheften
	function eventsOn(){

		// Eventlistener anhängen
		window.addEventListener('holy:resize', resizeEnd);
		scrollContainer.toArray().forEach(function(element){
			element.addEventListener('scroll', scrollPollThrottle);
		});
		
		// initieren durch triggern
		$(window).trigger('holy:resize');
		window.dispatchEvent(new Event('holy:resize'));
		scrollContainer.toArray().forEach(function(element){
			element.dispatchEvent(new CustomEvent('scroll', {
					bubbles: true,
				}));
		});
	}
	
	// Events entfernen
	function eventsOff(){
		window.removeEventListener('holy:resize', resizeEnd);
		if (scrollContainer !== undefined) {
			scrollContainer.toArray().forEach(function(element){
				element.removeEventListener('scroll', scrollPollThrottle);
			});
		}
	}
	
	// event-handler -> scroll start
	//function scrollStart(e){
	var scrollStart = window.toolkit.debounce(function(){
		stat.scrolling = true;
		notifyer.toArray().forEach(function(element){
			element.dispatchEvent(new CustomEvent('holy:scrollStart', {
				bubbles: true,
			}));
		});
	}, (stat.timer+1), true);
	
	// event-handler -> scroll ende
	//function scrollEnd(e){
	var scrollEnd = window.toolkit.debounce(function(){
		stat.scrolling = false;
		notifyer.toArray().forEach(function(element){
			element.dispatchEvent(new CustomEvent('holy:scrollEnd', {
				bubbles: true,
			}));
		});
	}, (stat.timer+1));

	// event-handler -> während dem scrollen
	function scrollPoll(e){
		var newPosition  = container[0].scrollTop;
		
		var oldPosition  = stat.position || 0;
		
		var newOffset = window.innerHeight;
		var oldOffset = stat.offset || newOffset;
		
		var newScrollMax = container[0].scrollHeight - scrollContainer.innerHeight();
		
		
		newPosition = Math.max(0, Math.min(newScrollMax, newPosition));
		
		var newDirection = (oldPosition - newPosition) > 0;
		var newDiff      = (oldOffset   - newOffset);
		
		stat.position  = newPosition;
		stat.scrollMax = newScrollMax;
		stat.offset    = newOffset;
		
		if (newPosition === oldPosition + newDiff) return;
		
		scrollStart();
		scrollEnd();
		
		// nur etwas ausführen, wenn eine Richtungsänderung vorliegt
		if( newDirection !== stat.direction && newPosition < stat.scrollMax){
			stat.direction = newDirection;
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent(`holy:${stat.direction ? 'scrollUp' : 'scrollDown'}`, {
					bubbles: true,
				}));
			});
		}

		// Randbereiche
		var upper = stat.containerHeight;
		var lower = stat.scrollMax - stat.containerHeight;
		
		if( newPosition === 0 ) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:edgeTop', {
					bubbles: true,
				}));
			});
		}
		if( newPosition === stat.scrollMax) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:edgeBottom', {
					bubbles: true,
				}));
			});
		}
		
		if( oldPosition < upper && newPosition >= upper ) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:fromTop', {
					bubbles: true,
				}));
			});
		}

		if( oldPosition > upper && newPosition <= upper ) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:toTop', {
					bubbles: true,
				}));
			});
		}
		
		if( oldPosition < lower && newPosition >= lower ) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:toBottom', {
					bubbles: true,
				}));
			});
		}
		if( oldPosition > lower && newPosition <= lower ) {
			notifyer.toArray().forEach(function(element){
				element.dispatchEvent(new CustomEvent('holy:fromBottom', {
					bubbles: true,
				}));
			});
		}
		
		// testweise im Poll
		checkObserve();
	}
	
	// event-handler resize window
	function resizeEnd(e){
		stat.containerHeight = scrollContainer.innerHeight();
		if (scrollContainer !== undefined) {
			scrollContainer.toArray().forEach(function (element) {
				element.dispatchEvent(new CustomEvent('scroll', {
					bubbles: true,
				}));
			});
		}
	}
	
	
	/* Status für debug?
	------------------------*/
	function status(){
		var output = {
			scrollContainer: scrollContainer,
			container: container,
			observe: observe, 
			notifyer: notifyer
		};
		for( var key in stat ) output[key] = stat[key];
		
		return output;
	}
	
	
	/* Funktionen export
	------------------------*/
	self.status       = status;
	self.scrollTo     = scrollTo;
	
	self.checkObserve = checkObserve;
	self.addToObserve = addToObserve;
	self.rmObserve    = rmObserve;
	
	// die init-funktion
	self.init = function(options){
		if( options === undefined ) options = {};
		for( var key in stat ) stat[key] = stat_og[key];
		
		eventsOff();
		
		setContainer( options.container );
		setToObserve( options.observe   );
		setNotifyer ( options.notifyer  );
		
		eventsOn();
		
		return self;
	};
	
	// global ans resizen anhängen
	window.addEventListener('resize', window.toolkit.debounce(function(){
		window.dispatchEvent(new Event('holy:resize')); // deprecated
		window.dispatchEvent(new Event('holy:resizeDone')); // deprecated

		window.dispatchEvent(new Event('resizeDone'));
	}, stat.timer));
};
