  /**
   * Class representing YouTube videos embedded in the page.
   */
  class YouTubeVideo {
    /**
     * Create a YouTubeVideo instance.
     * @param {HTMLElement} element - The HTML element that contains the video data and elements.
     */
    constructor(element, deferInit = false) {
      this.wrapperVideo = element;
      this.poster = this.wrapperVideo.querySelector('[data-js-youtube-video-poster]');
      this.playButton = this.wrapperVideo.querySelector('[data-js-youtube-video-init]');
      this.videoAnchor = this.wrapperVideo.querySelector('[data-js-youtube-video-anchor]');
      this.youTubeVideo = null;

      if (!this.playButton || !this.poster || !this.wrapperVideo) return;

      try {
        this.config = JSON.parse(this.wrapperVideo.getAttribute('data-js-youtube-video'));
      } catch (e) {
        console.error('Error parsing JSON', e);
        return;
      }

      this.videoId = this.config?.id || null;
      if (!this.videoId) return;

      // If third-party scripts are enabled, initialize the video immediately.
      if (deferInit === false) { 
        this.init();

      // Otherwise, defer the initialization until the userer agrees to third-party scripts via the cookie message.
      } else {
        this.wrapperVideo.setAttribute('data-video-show-play-button', 'true');
        const deferName = 'YouTubeVideo';
        const deferInitFunction = () =>  {
          this.playButton.addEventListener('click', () => { 
            this.wrapperVideo.setAttribute('data-video-hide-poster', 'true');
            this.videoAnchor.classList.add('wrapper-video__activator-message');
            ThirdParty.youtube.init(this.videoAnchor, this.videoId, [ThirdParty.style.STYLE_THEME_DARK, ThirdParty.style.HIDE_BORDER]);
          }, { once: true });
        }

        const deferActivatorFunction = () => { 
          YouTubeVideo.initYoutubeApi(this.init.bind(this));
        }
        ThirdParty.defer(deferName, deferInitFunction, deferActivatorFunction);
      }
    }

    prepareVideo() {
      this.playButton.addEventListener('click', () => { 
        this.wrapperVideo.setAttribute('data-video-hide-poster', 'true');
        this.videoAnchor.classList.add('wrapper-video__activator-message');
        ThirdParty.youtube.init(this.videoAnchor, this.videoId, [ThirdParty.style.STYLE_THEME_DARK, ThirdParty.style.HIDE_BORDER]);
      }, { once: true });      
    }

    /**
     * Initialize the YouTube video by setting up event listeners.
     */
    init() {
      this.youTubeVideo = new YT.Player(this.videoAnchor, {
        videoId: this.videoId,
        height: '100%',
        width: '100%',
        playerVars: {
          'autoplay': 0, // Do not autoplay the video
          'controls': 1, // Display video controls
          'rel': 0, // Do not show related videos at the end
          'modestbranding': 1, // Use modest branding (no YouTube logo in the control bar)
          'playsinline': 0, // Play videos inline on mobile (do not go fullscreen)
          'origin': window.location.origin, // Specify the origin of the request
          'hl': document.documentElement.lang || 'en', // Set the interface language to the page's language or default to English
        },
        events: {
          'onReady': this.onPlayerReady.bind(this),
        },
        host: 'https://www.youtube-nocookie.com',
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture'
      });
    }


    /**
     * Handle the YouTube player ready event.
     * @param {Event} event - The event object.
     */
    onPlayerReady(event) {
      this.wrapperVideo.setAttribute('data-video-show-play-button', 'true');
      this.playButton.addEventListener('click', () => {
        this.wrapperVideo.setAttribute('data-video-hide-poster', 'true');
        event.target.playVideo();
      });
    }

    /**
     * Initialize the YouTube API if it is not already loaded.
     */
    static initYoutubeApi(callback) {
      if (!window.YT) {
        window.onYouTubeIframeAPIReady = () => {
          callback();
        };

        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        callback();
      }
    }

    /**
     * Initialize all YouTubeVideo instances on the page.
     */
    static initYouTubeVideos(defereInit = false) {
      const videoElements = document.querySelectorAll('[data-js-youtube-video]');
      videoElements.forEach((element) => {
        new YouTubeVideo(element, defereInit);
      });
    }

    static start() {
      if (ThirdParty.isEnabled()) { 
        YouTubeVideo.initYoutubeApi(YouTubeVideo.initYouTubeVideos);
      } else {
        YouTubeVideo.initYouTubeVideos(true);   
      }
    }
  }


  // Initialize YouTubeVideo instances when the DOM is fully loaded.
  document.addEventListener('DOMContentLoaded', () => {
    YouTubeVideo.start();
  });