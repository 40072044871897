/**
 * Erstellt FadeSlideshow
 * @param {object} element Umschließendes Element, aus dem die Slideshow generiert werden soll
 * @param {object} options Konfigurationsobjekt {fadespeed:int}
 * 
 * 
 * 
 * @dependencies
 * –
 * 
 * @dependents
 * * comparOverlay (global/compar.js)
 * 
 * @commit
 * * Initial commit 2.0
 * 
 * @note
 * * Maybe something for a web component?
 * * Combine with FadeSlideShowWithNavi
 */
function FadeSlideshow(element, options) {
  var self = this;
  this._element = element;
  this._wrapAround = false;
  this._fadeSpeed = 1;
  this._items = this._element.children;
  this.numberOfItems = this._element.children.length;
  this._currentItemIndex = null;
  this._element.fadeSlideShowInited = false;

  if (options) {
    this._wrapAround = (options.wrapAround === "true" ? true : false);
    this._fadeSpeed = (options.fadeSpeed >= 0 ? options.fadeSpeed : 1);
  }

  /**
   * Initialisiert FadeSlideshow
   */
  this.init = function () {
    //Wurde ein Element übergeben und wurde Slideshow für das Element noch nicht initialisiert
    if (self._element && self._element.fadeSlideShowInited === false) {

      self._element.fadeSlideShowInited = true;

      for (var i = 0; i < self._items.length; i++) {
        self._items[i].classList.remove('hidden');
        self._items[i].classList.add('fadeslide');

      }

      self._element.classList.add('fade-slide-show');
      self._currentItemIndex = 0;
      self.fadeIn(0);

      setTimeout(function () {
        self._element.classList.add('-active');
      });

    }
  }

  /**
   * Deaktiviert FadeSlideshow
   */
  this.destroy = function () {
    if (self._element.fadeSlideShowInited === true) {
      self._element.classList.remove('fade-slide-show');
      self._element.classList.remove('-active');
      self._element.fadeSlideShowInited = false;
      for (var i = 0; i < self._items.length; i++) {
        self._items[i].classList.remove('-active');
        self._items[i].classList.remove('fadeslide');
        self._items[i].style.WebkitTransition = '';
        self._items[i].style.MozTransition = '';
        self._items[i].style.Transition = '';
      }
    }
  }

  /**
   * Blendet den vorherigen Slide ein
   */
  this.fadeInPrev = function () {

    if (self._wrapAround === true || (self._wrapAround === false && self._currentItemIndex >= 1)) {
      self.fadeIn(self._currentItemIndex - 1);
    }
  }

  /**
   * Blendet den nächsten Slide ein
   */
  this.fadeInNext = function () {
    if (self._wrapAround === true || (self._wrapAround === false && self._currentItemIndex < self.numberOfItems - 1)) {
      self.fadeIn(self._currentItemIndex + 1);
    }
  }

  /**
   * Blendet den Slide mit dem übergebenen Index ein
   * @param {number} index Index des Slides, welcher eingeblendet werden soll
   */
  this.fadeIn = function (index) {

    if (typeof index !== 'undefined') {
      self._addTransition(self._currentItemIndex);
      self._items[self._currentItemIndex].classList.remove('-active');
      self._items[self._currentItemIndex].removeAttribute('data-state')

      self._currentItemIndex = (index % self.numberOfItems + self.numberOfItems) % self.numberOfItems;
      self._removeTransition(self._currentItemIndex);
      self._items[self._currentItemIndex].classList.add('-active');
      self._items[self._currentItemIndex].setAttribute('data-state', 'active');
    }
  }

  this._addTransition = function (index) {
    self._items[index].style.msTransition = 'opacity ' + this._fadeSpeed + 's';
    self._items[index].style.WebkitTransition = 'opacity ' + this._fadeSpeed + 's';
    self._items[index].style.MozTransition = 'opacity ' + this._fadeSpeed + 's';
    self._items[index].style.Transition = 'opacity ' + this._fadeSpeed + 's';
  }

  this._removeTransition = function (index) {
    self._items[index].style.msTransition = '';
    self._items[index].style.WebkitTransition = '';
    self._items[index].style.MozTransition = '';
    self._items[index].style.Transition = '';

  }


  this.getCurrentItem = function () {
    return this._currentItemIndex;
  }
};
