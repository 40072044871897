
/* Product Header Tooltip Config - hb 2021

  Config File that is used in /struktur/js/products/product-header-tooltip.js

  Current use cases:
  - Custom tooltips
  - Product phase out

------------------------------------------------------

  Construction of an object for Product Header Tooltip Data Array
  Fields with ? suffix are optional

  {
    pkmObjectId: number, // ID of the product; Type "k3vars.PKM_Objekt" in JS console on product page to retrieve

    type: ProductHeaderTooltipType, // Type of Tooltip

    title: { // Title of tooltip
      default: string,
      translationIdentifier?: string, // dbo_Translate s_Platzhalter value for translations in s_Kontext="product-header-tooltip"
    },

    content?: { // Content of tooltip; Will be automatically generated when type PHASEOUT
      default: string,
      translationIdentifier?: string, // dbo_Translate s_Platzhalter value for translations in s_Kontext="product-header-tooltip"
    },

    phaseOutData?: { // Additional data for tooltips of type PHASEOUT
      date: string, // Date of phase out for product

      alternative?: { // Optionally an alternative is given
        link: string, // Link to alternative product

        name: { // Name of alternative product
          default: string,
          translationIdentifier?: string, // dbo_Translate s_Platzhalter value for translations in s_Kontext="product-header-tooltip"
        }
      }
    },
  }

  ------------------------------------------------------

  Example 1: DEFAULT tooltip
  {
    pkmObjectId: 7311, // IKU
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'My Default Title',
      translationIdentifier: 'my-translation-identifier',
    },
    content: {
      default: 'My Default Content',
      translationIdentifier: 'my-translation-identifier',
    }
  },

  ------------------------------------------------------

  Example 2: PHASEOUT tooltip with alternative:
  {
    pkmObjectId: 7311, // IKU
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2022',
    },
    phaseOutData: {
      date: '01.01.2022',
      alternative: {
        link: '/products/indoor/recessed-spot/gimbal-with-mounting-bracket-6641/',
        name: {
          default: 'Gimbal with mounting bracket',
          translationIdentifier: 'gimbal-with-mounting-bracket-6641',
        },
      },
    },
  },


How to get Translations:


SELECT CONCAT(
"INSERT INTO `dbo_Translate` (`S_Platzhalter`, `b_Translated`, `de_Translate`, `en_Translate`, `fr_Translate`, `es_Translate`, `en_gb_Translate`, `en_us_Translate`, `it_Translate`, `no_Translate`, `sv_Translate`, `nl_Translate`, `s_Kontext`) VALUES ('quintessence_rund_3847','j',", 
"'",S_de_Objekt,"',",
"'",S_en_Objekt,"',",
"'",S_fr_Objekt,"',", 
"'",S_es_Objekt,"',",
"'",S_en_gb_Objekt,"',",
"'",S_en_us_Objekt,"',",
"'",S_it_Objekt,"',", 
"'",S_no_Objekt,"',",
"'",S_sv_Objekt,"',",
"'",S_nl_Objekt,"','product-header-tooltip'
);") FROM dbo_Objekt WHERE PKM_Objekt = 3847;


INSERT INTO `dbo_Translate` (`S_Platzhalter`, `b_Translated`, `de_Translate`, `en_Translate`, `fr_Translate`, `es_Translate`, `en_gb_Translate`, `en_us_Translate`, `it_Translate`, `no_Translate`, `sv_Translate`, `nl_Translate`, `s_Kontext`) VALUES ('quintessence_rund_3847','j','Quintessence rund','Quintessence round','Quintessence circulaire','Quintessence redondo','Quintessence round','Quintessence round','Quintessence rotondo','Quintessence rund','Quintessence rund','Quintessence rond','product-header-tooltip'
);


------------------------------------------------------*/


const ProductHeaderTooltipType = {
  DEFAULT: 'default',
  PHASEOUT: 'phaseout',
};



const productHeaderTooltipData = [
  {
    pkmObjectId: 9999999997311, // IKU
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Lieferbar ab 10.2021', // Default title of tooltip
      translationIdentifier: 'iku-tooltip-title', // s_Platzhalter in dbo_Translate im s_Kontext "product-header-tooltip"
    },
    content: {
      default: 'IKU Deckeneibauleuchten sind lieferbar ab 10.2021', // Default text content
      translationIdentifier: 'iku-tooltip-content', // s_Platzhalter in dbo_Translate im s_Kontext "product-header-tooltip"
    }
  },
  {
    pkmObjectId: 6919, // Quintessence Pinhole Deckeneinbau
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/3847/',
        name: {
          default: 'Quintessence rund Deckeneinbau',
          translationIdentifier: 'quintessence_rund_3847',
        }
      },
    }
  },
  {
    pkmObjectId: 6061, // Starpoint Deckenaufbau
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/6227/',
        name: {
          default: 'Quintessence',
          translationIdentifier: 'quintessence-6227',
        }
      },
    }
  },
  {
    pkmObjectId: 6064, // Starpoint Deckeneinbau
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/7311/',
        name: {
          default: 'Iku Deckeneinbau',
          translationIdentifier: 'iku-7311',
        }
      }
    }
  },
  {
    pkmObjectId: 6056, // Starpoint Pendelleuchte
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/6228/',
        name: {
          default: 'Quintessence Pendelleuchte',
          translationIdentifier: 'quintessence_pendelleuchte-6228',
        }
      }
    }
  },
  {
    pkmObjectId: 5419, // Compact Deckeneinbau Innen
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/7311/',
        name: {
          default: 'Iku',
          translationIdentifier: 'iku-7311',
        }
      }
    }
  },
  {
    pkmObjectId: 85, // Visor Fassadenleuchten
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/1472/',
        name: {
          default: 'Lightmark Fassadenleuchte',
          translationIdentifier: 'lightmark-fassadenleuchten-1472',
        }
      }
    }
  },
  {
    pkmObjectId: 102, // Beamer
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/7337/',
        name: {
          default: 'Beamer New',
          translationIdentifier: 'beamer-new-7337',
        }
      }
    }
  },
  {
    pkmObjectId: 2331, // Grasshopper
    type: ProductHeaderTooltipType.PHASEOUT,
    title: {
      default: 'Phase Out 2023',
    },
    phaseOutData: {
      date: '01.01.2023',
      alternative: {
        link: '/s/5744/',
        name: {
          default: 'Light Scan Schweinwerfer',
          translationIdentifier: 'light-scan-scheinwerfer-5744',
        }
      }
    }
  },
  {
    pkmObjectId: 96, // Nadir IP67 rund
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Phase Out 2023',
      date: '01.01.2023'
    },
    content: {
      default: 'Diese Produktfamilie wird abgekündigt.',
      translationIdentifier: 'phase_out_text_without_alternative',
    }
  },
  {
    pkmObjectId: 6930, // Nadir IP67 quadratisch
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Phase Out 2023',
      date: '01.01.2023',
    },
    content: {
      default: 'Diese Produktfamilie wird abgekündigt.',
      translationIdentifier: 'phase_out_text_without_alternative',
    }
  },
  {
    pkmObjectId: 6057, // Starpoint Wand
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Phase Out 2023',
      date: '01.01.2023',
    },
    content: {
      default: 'Diese Produktfamilie wird abgekündigt.',
      translationIdentifier: 'phase_out_text_without_alternative',
    }
  },
  {
    pkmObjectId: 173, // Bodenfluter rund
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Phase Out 2023',
      date: '01.01.2023',
    },
    content: {
      default: 'Diese Produktfamilie wird abgekündigt.',
      translationIdentifier: 'phase_out_text_without_alternative',
    }
  },
  {
    pkmObjectId: 6928, // Bodenfluter quadratisch
    type: ProductHeaderTooltipType.DEFAULT,
    title: {
      default: 'Phase Out 2023',
      date: '01.01.2023',
    },
    content: {
      default: 'Diese Produktfamilie wird abgekündigt.',
      translationIdentifier: 'phase_out_text_without_alternative',
    }
  }
];