
/**
 * Data Table to wrap data elements.
 * @class DataTableElement
 */
class DataTableElement extends DataTableCommon {

  /**
   * Template for DataTableElement.
   * @param {string[]} columns List of column ids.
   * @param {string[]} sortable List of column ids that should be sortable.
   * @param {string[]} sticky List of column ids that should be sticky.
   * @param {string|null} initialSortBy Column id that the data is initially sorted by. Null if no initial sorting should be applied.
   */
  static template(columns = [], sortable = [], sticky = [], initialSortBy = null, numOfRows = 0) {
    return /*html*/ `
      <template>
        <style>
          ${super.styles}

          :host {
            position: relative;
            display: block;
            font-size: var(--body-font-size-s);
            overflow-x: auto;
          }

          .table {
            display: table;
            width: 100%;
            height: 100%; /* CSS Bugfix for Safari */
          }

          .table__header {
            display: table-header-group;
            background-color: var(--color-contrast-1);
          }

          :host([variant="flat"]) .table__header {
            background-color: transparent;
          }

          .table__body {
            display: table-row-group;
          }

          .table__cell .inner {
            background-color: var(--color-contrast-1);
          }

          :host([variant="flat"]) .table__cell .inner {
            background-color: transparent;
          }

          .table__cell--head {
            font-weight: bold;
            white-space: nowrap;
          }

          .table__sort-icon {
            display: inline-block;
            width: 16px;
            height: 20px;
            margin-top: -5px;
            margin-bottom: -5px;
            background-color: var(--color-contrast-2);
            cursor: pointer;
            -webkit-mask-image: url(${window.confEnv?.www_url || "//erco.com"}/struktur/builds/sprite-basic.svg);
            mask-image: url(${window.confEnv?.www_url || "//erco.com"}/struktur/builds/sprite-basic.svg);
            -webkit-mask-position: -1170px -30px;
            mask-position: -1170px -30px;
            transform: rotate(0deg);
            transition:
              background-color var(--duration-moderate-02),
              transform var(--duration-moderate-02);
          }
          .table__sort-icon[data-current="true"] {
            background-color: var(--color-contrast-7);
          }

          :host([sort-direction="desc"]) .table__sort-icon[data-current="true"] {
            transform: rotate(180deg);
          }

          :host([collapse-enabled]) ::slotted(data-table-item:nth-of-type(n + ${numOfRows + 1})) {
            display: none;
          }
        </style>
        <div class="table" role="table" aria-rowcount="${numOfRows}">
          <div class="table__header" role="rowgroup">
            <div class="table__row" part="row" role="row">
              ${columns?.map(id => /* html */`
                <div
                  role="columnheader"
                  ${sortable?.includes(id) ? /* html */`
                    aria-sort="${initialSortBy === id ? 'ascending' : 'none'}"
                  ` : ''}
                  part="col-${id} col"
                  data-col="${id}"
                  class="table__cell table__cell--head ${sticky?.includes(id) ? 'table__cell--sticky' : ''}"
                  ${sticky?.includes(id) ? `
                    style="--sticky-cell-offset-left: var(--sticky-cell-offset-left-${id},0); --sticky-cell-offset-right: var(--sticky-cell-offset-right-${id},0)"
                    data-sticky-active="false"
                  ` : ''}
                >
                  <div class="inner">
                    <slot name="${id}"></slot>
                     ${sortable?.includes(id) ? /* html */`
                      <button class="table__sort-icon" data-js-sort-icon="${id}" data-current="${initialSortBy === id}"></button>
                    ` : ''}
                  </div>
                </div>
              `).join('') ?? ''}
            </div>
          </div>
          <div class="table__body" role="rowgroup">
            <slot></slot>
          </div>
        </div>
      </template>
    `;
  }

  /** @type Object.<string,number> */
  stickyCellWidth = {};

  /** @type Object.<string,{left: number, right: number}> */
  stickyCellOffset = {};

  /** @type ResizeObserver */
  resizeObserver;

  constructor() {
    super();
    
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cellElement = entry.target;
        const slotElement = cellElement.querySelector('slot');

        this.stickyCellWidth[slotElement.getAttribute('name')] = cellElement.offsetWidth;
      }
      this._updateStickyCellOffset();
    });
  }

  render() {
    const numOfRows = this.querySelectorAll('data-table-item').length;

    this.shadowRoot.innerHTML = this.constructor.template(this.columns, this.sortable, this.sticky, this.sortBy, this.maxVisibleRows, numOfRows);

    const template = this.shadowRoot.querySelector('template');
    const clone = template.content.cloneNode(true);
    this.shadowRoot.appendChild(clone);

    this.resizeObserver.disconnect();
    this.stickyCellWidth =
      Object.fromEntries(this.sticky.map(column => ([column, 0])));

    this.$headerCells = this.shadowRoot.querySelectorAll('.table__cell--head');
    this.$headerCells.forEach($cell => {
      const $slot = $cell.querySelector('slot');
      const column = $slot.getAttribute('name');

      // add sticky cells to array
      if (this.sticky.includes(column)) {
        this.resizeObserver.observe($cell);
        this.stickyCellWidth[column] = $cell.offsetWidth;
        this._updateStickyCellOffset();
      }

      // check if column is sortable
      if (!this.sortable.includes(column)) {
        return;
      }

      $cell.addEventListener('click', () => {
        const newSortBy = column;
        const newSortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

        this.sortDirection = (this.sortBy !== newSortBy) ? 'asc' : newSortDirection;
        this.sortBy = newSortBy;

        this.dispatchEvent(new CustomEvent('sort-click', {
          detail: {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
          }
        }));
      });
    });

    this.$mainSlot = this.shadowRoot.querySelector('slot:not([name])');

    this.$mainSlot.addEventListener('slotchange', this._onSlotChange.bind(this));
  }

  static get observedAttributes() {
    return [
      'sort-by',
      'sort-direction',
      'columns',
      'sortable',
      'sticky',
      'variant',
      'collapse-enabled',
      'max-visible-rows'
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this[name] = newValue;
    }
  }

  get sortBy() {
    return this.getAttribute('sort-by');
  }

  set sortBy(value) {
    this.setAttribute('sort-by', value);
    this.sort();
    this._changeSortIcon();
  }

  get sortDirection() {
    return this.getAttribute('sort-direction');
  }

  set sortDirection(value) {
    this.setAttribute('sort-direction', value);
    this.sort();
    this._changeSortIcon();
  }

  get columns() {
    return (this.getAttribute('columns') ?? '').split(',');
  }

  set columns(value) {
    if (Array.isArray(value)) {
      value = value.join(',');
    }

    this.setAttribute('columns', value);
    this.render();

    // rerender all rows
    const rows = Array.from(this.shadowRoot.querySelector('slot:not([name])').assignedElements());
    rows.forEach(rowElement => {
      if (!rowElement.render) {
        return;
      }

      rowElement.render();
    });
  }

  get sortable() {
    return (this.getAttribute('sortable') ?? '').split(',');
  }

  set sortable(value) {
    if (Array.isArray(value)) {
      value = value.join(',');
    }

    this.setAttribute('sortable', value);
    this.render();
  }

  get sticky() {
    return (this.getAttribute('sticky') ?? '').split(',');
  }

  set sticky(value) {
    if (Array.isArray(value)) {
      value = value.join(',');
    }

    this.setAttribute('sticky', value);

    this.render();
  }

  get variant() {
    return this.getAttribute('variant');
  }

  set variant(value) {
    this.setAttribute('variant', value);
  }

  get collapseEnabled() {
    return this.hasAttribute('collapse-enabled')
      && this.getAttribute('collapse-enabled') !== 'false';
  }

  set collapseEnabled(value) {
    if (value) {
      this.setAttribute('collapse-enabled', '');
    } else {
      this.removeAttribute('collapse-enabled');
    }
  }

  get maxVisibleRows() {
    return parseInt(this.getAttribute('max-visible-rows')) || 0;
  }

  set maxVisibleRows(value) {
    this.setAttribute('max-visible-rows', value);
    this.render();
  }

  sort() {
    if (this.sortTimeout) {
      clearTimeout(this.sortTimeout);
    }

    this.sortTimeout = setTimeout(() => {
      const sortBy = this.sortBy;
      const sortDirection = this.sortDirection;

      const rows = Array.from(this.shadowRoot.querySelector('slot:not([name])').assignedElements());

      rows.sort((a, b) => {
        const aVal = a.querySelector(`[slot="${sortBy}"]`)?.innerText ?? "";
        const bVal = b.querySelector(`[slot="${sortBy}"]`)?.innerText ?? "";

        if (sortDirection === 'asc') {
          return aVal.localeCompare(bVal, undefined, { numeric: true });
        } else {
          return bVal.localeCompare(aVal, undefined, { numeric: true });
        }
      });

      this.append(...rows);
    }, 5);
  }

  /**
   * Shows the sort icon of the current sort column.
   * @protected
   */
  _changeSortIcon() {
    const currentSortIconElement = this.shadowRoot.querySelector(`[data-js-sort-icon="${this.sortBy}"]`);
    const sortIconElements = this.shadowRoot.querySelectorAll('[data-js-sort-icon]');

    // hide all sort icons
    sortIconElements.forEach(iconElement => {
      iconElement.dataset.current = false;
    });

    if (!currentSortIconElement) {
      return;
    }

    // show sort icon of current sort column
    currentSortIconElement.dataset.current = true;
  }

  /**
   * Updates the offset of the sticky cells depending on the width of other sticky cells.
   * @protected
   */
  _updateStickyCellOffset() {
    this.sticky.forEach(column => {
      let offsetLeft = 0;
      let offsetRight = 0;

      let colIndex = this.columns.indexOf(column);

      this.columns.forEach((col, index) => {
        if (index < colIndex) {
          offsetLeft += this.stickyCellWidth[col] || 0;
        } else if (index > colIndex) {
          offsetRight += this.stickyCellWidth[col] || 0;
        }
      });

      this.shadowRoot.host.style.setProperty(`--sticky-cell-offset-left-${column}`, `${offsetLeft}px`);
      this.shadowRoot.host.style.setProperty(`--sticky-cell-offset-right-${column}`, `${offsetRight}px`);

      this.stickyCellOffset[column] = {
        left: offsetLeft,
        right: offsetRight
      };
    });
  }

  _onSlotChange() {
    const customEvent = new CustomEvent('main-slot-change');
    this.dispatchEvent(customEvent);

    this.shadowRoot.querySelector('[role="table"]')
      .setAttribute('aria-rowcount', 
        this.querySelectorAll('data-table-item').length
      );
  }

}

window.DataTableElement = DataTableElement;
customElements.define('data-table', DataTableElement);