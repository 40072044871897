/**
 * Funktionen für die Gattungseite
 */
var genusoverview = (function () {
  var store = {};
  var exports = {};

  /**
   * Sticki Navigation
   */
  var initStickyNavigation = function() {
    var navbar   = document.querySelector('[data-genus-sticky-navbar]');
    var mainnav  = document.querySelector('[data-navbar-anchor]');

    //wenn Hauptnavi nicht existiert oder Breakpoint mobile Ansicht
    if (!navbar || !mainnav || media.breakpoint.refreshValue() === media.breakpoint.XS)
      return;

    //Sticky Navigation in Hauptnavigation kopieren
    mainnav.appendChild(navbar);
  };

  /**
   * Onepager Navigation
   */
  var initOnePageNavigation = function() {

    //Navigationselemente selektieren
    var navElements =    document.querySelectorAll('[data-genus-sticky-navbar] [data-onepage-navigation] [data-onepage-link]');

    if(!navElements.length){
      return;
    }
  
    store.currentNavLink     = navElements[0];

    //Offsetwerte, die beim autoscroll zur Zielposition dazugerechnet werden
    store.offsetScrollBottom = document.querySelector('[data-genus-headline-bar]').offsetHeight;
    store.offsetScrollTop    = store.offsetScrollBottom*2;

    var targetElements = [];

    //Für mobile Ansicht
    if (media.breakpoint.refreshValue() === media.breakpoint.XS) {

      //Hier wird leider jQuery benötigt, da der Eventlistener auf die Schaltfläche auch via jQuery zugewiesen wird.
      var toggleCollapseButton = $('[data-toggle="collapse"]');
      var toggleCollapseLabel = document.querySelector('[data-toggle-label]');

      toggleCollapseLabel.innerHTML = store.currentNavLink.innerHTML;

      for (var i=0; i < navElements.length; i++) {

        //Klick auf Navigationspunkte
        navElements[i].addEventListener('click', function(e) {
          e.preventDefault();

          //Text des angeklickten Navigationspunkt im Label anzeigen
          toggleCollapseLabel.innerHTML = e.currentTarget.innerHTML;

          //Navigation schließen
          toggleCollapseButton.trigger('click');
          
          //Zielelement selektieren
          var selector = e.currentTarget.getAttribute('href').replace('#','');
          var targetElement = document.querySelector('[data-gattung-section=' + selector + ']');
          if(targetElement){
            holy.scrollTo(targetElement, undefined, 0);
          }
        });
      }
    }

    //Für Tablet- und Desktop-Ansicht
    else {

      //Link aktiv setzen
      store.currentNavLink.classList.add('-active');

      for (var i=0; i < navElements.length; i++) {
        
        // Element zu dem gescrollt wird, bei klick auf Navipunkt

        var selector = navElements[i].getAttribute('href').replace('#','');
        var targetElement = document.querySelectorAll('[data-gattung-section=' + selector + ']');

        if(!targetElement.length)
          continue;
        
        targetElement.forEach(function(item){
          targetElements.push(item);
          //Wir horchen auf das Element, ob es in den Viewport gescrollt wird
          holy.addToObserve(item);
          $(item).on('holy:observeIn', handleObserveIn);

          //Wird gefeuert, nach dem Scrollen beendet ist
          $(item).on('holy:scrollDone', function() {

            //Wir horchen auf alle Elemente, ob sie in den Viewport gescrollt wird
            $(item).on('holy:observeIn', handleObserveIn);

            //Elemente einblenden, die sich oberhalb oder innerhalb des Viewports befinden
            productoverview.sectionAnimations();

            });
        });

        navElements[i].addEventListener('click', function(e) {
          e.preventDefault();
          store.currentNavLink.classList.remove('-active')

          //Das Element selektiren, zu dem gescrollt werden soll
          selector = '[data-gattung-section=' + e.currentTarget.getAttribute('href').replace('#','') + ']';
          targetElement = document.querySelector(selector);

          //Sind Elemente auf das geklickt wurde und das aktiv ist gleich?
          if(store.currentNavLink == e.currentTarget)
            return;

          //$(targetElements).off('holy:observeIn');

          //Element selektieren, zu dem zuletzt gescrollt wurde
          selector = '[data-gattung-section=' + store.currentNavLink.getAttribute('href').replace('#','') + ']';
          var lastElement = document.querySelector(selector);
          
          //Offset berechen, abhängig davon ob wir hoch- oder runter scrollen
					var offset = (window.toolkit.getOffsetFromTop(lastElement) < window.toolkit.getOffsetFromTop(targetElement)  ? store.offsetScrollBottom : store.offsetScrollTop);


          //Zum Zielelement scrollen + offset
          holy.scrollTo(targetElement, 300, offset);

          e.currentTarget.classList.add('-active');

          store.currentNavLink = e.currentTarget;
        });
      }

      //Wir schauen, welches Element beim Laden der Seite im Vierwport ist,
      //damit der dazugehörige Navigationspunkt in der Sticky Navi aktiviert wird.
      holy.checkObserve();
    }
  };
    
  
  /**
   * Eventhandler der darauf horcht, ob ein Element in den Viewport gescrollt wird
   */
  var handleObserveIn = function() {
    //Id von dem Element holen, dass in den Viewport gescrollt wurde
    var targetID = this.getAttribute('data-gattung-section');

    //Zu dem Element den passenen Navilink holen
    var navLink = document.querySelector('[data-onepage-navigation] [data-onepage-link][href="#'+ targetID +'"]');

    if (navLink) {
      store.currentNavLink.classList.remove('-active');
      navLink.classList.add('-active');
      store.currentNavLink = navLink;
    }
  };

	let projectSectionsInit = function () {
		let projectSlider = document.querySelectorAll('.projectsection .js-gattung-gallery');
		projectSlider.forEach(function(slider){

			let cells = slider.getElementsByClassName('gallery-cell');
			//

			let newFlickity = new Flickity(slider, {
				lazyLoad: 1
			});

			newFlickity.on('lazyLoad', function( event ) {
				let projectDotList = slider.querySelector('.flickity-page-dots');
				if(cells.length <= 1) { 
					projectDotList.classList.add('hidden'); 
				} else {
					addControlesToDotList(slider, newFlickity);
					addProjectLink(projectDotList);
				}
				
			});	

			newFlickity.on('change', function( index ) {
				let linkToProject = newFlickity.element.querySelector('.project-link');
				let prevButton = newFlickity.element.querySelector('.button-previous-item');
				let nextButton = newFlickity.element.querySelector('.button-next-item');


				if (index === 0) {
					// link to project is not visiable
					prevButton.classList.add('not-visable');
					linkToProject.classList.add('not-visable');
					nextButton.classList.remove('not-visable');
				} else {
					// link to project has to be visibale
					prevButton.classList.remove('not-visable');
					nextButton.classList.remove('not-visable');
					linkToProject.classList.remove('not-visable');
					
					if ( newFlickity.cells.length - 1  === index) {
						nextButton.classList.add('not-visable');
					}

					// set href link
					// get object gallery-cell is-selected
					let projectLinkObject = newFlickity.element.querySelector('.project-link');
					let selectedProjectCell = newFlickity.element.querySelector('.gallery-cell.is-selected');

					if (selectedProjectCell) {
						let projectJSON = JSON.parse(selectedProjectCell.getAttribute('data-project-examples'));
						projectLinkObject.setAttribute('href', projectJSON.url);
					}
				}
			});
		});
  };

  let addControlesToDotList = function (slider, flkty) {
		let cells = slider.getElementsByClassName('gallery-cell');
		if(cells.length <= 1) { return; }

		// svgs
		prevSVG = '<svg viewBox="0 0 100 100"><path d="M 10,50 L 35,75 L 35,65 L 20,50  L 35,35 L 35,25 Z" class="arrow"></path></svg>';
		nextSVG = '<svg viewBox="0 0 100 100"><path d="M 10,50 L 35,75 L 35,65 L 20,50  L 35,35 L 35,25 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>';

		// flickity Dotlist
		let dotList = slider.querySelector('.flickity-page-dots');
		if(!dotList) { return;}

		//check if dotlist has already button elements
		let buttons = slider.querySelectorAll('.button-item');
		if(buttons.length > 0) { return; }

		// create prev Button
		let liTagPrev = document.createElement('li');
		liTagPrev.setAttribute('class','button-item button-previous-item not-visable');
		let prevButton = document.createElement('button');
		prevButton.setAttribute('type', 'button');
		prevButton.setAttribute('class', 'button');
		prevButton.setAttribute('aria-label', 'previous');	
		prevButton.innerHTML = prevSVG;

		// eventhandler prev
		prevButton.addEventListener('click', function(e){
			flkty.previous();
		});

		liTagPrev.appendChild(prevButton);
		dotList.insertBefore(liTagPrev, dotList.firstChild);

		// create next Button
		let liTagNext = document.createElement('li');
		liTagNext.setAttribute('class','button-item button-next-item');
		let nextButton = document.createElement('button');
		nextButton.setAttribute('type', 'button');
		nextButton.setAttribute('class', 'button');
		nextButton.setAttribute('aria-label', 'next');	
		nextButton.innerHTML = nextSVG;

		nextButton.addEventListener('click', function(e){
			flkty.next();
		});

		liTagNext.appendChild(nextButton);
		dotList.appendChild(liTagNext);
	};

	let addProjectLink = function (projectDotList){
		let links = projectDotList.querySelectorAll('.download-items');
		if(links.length > 1) { return; }

		// get translation
		var linkText = document.querySelector('[data-link-text]');
		linkText = linkText ? JSON.parse(linkText.getAttribute('data-link-text')).trans_link : '';

		// create li element which contains the projectlink
		let liTag = document.createElement('li');
		liTag.setAttribute('class',"button-item download-items");
		let aTag = document.createElement('a');
		aTag.setAttribute('href',"#");
		aTag.setAttribute('class',"project-link not-visable");
		aTag.innerText = linkText;

		// in order to keep the dotlist centered, the same object is inserted before the dotlist again 
		let liTagNoneDisplay = document.createElement('li');
		liTagNoneDisplay.setAttribute('class',"button-item download-items");
		let aTagNoneDisplay = document.createElement('a');
		aTagNoneDisplay.setAttribute('href','#');
		aTagNoneDisplay.setAttribute('style', 'visibility: hidden;');
		aTagNoneDisplay.innerText = linkText;
		
		/*
		aTag.addEventListener('click', function(e){
			e.preventDefault();
			var slider = e.target.closest(".gallery");
			
			var selectedDot = slider.querySelector('.is-selected');
			if(selectedDot) {
				var url = selectedDot.getAttribute('data-project-examples');
				if(url){
					url = JSON.parse(url);
					window.location = "/" + url.url;
				}
			}
		});*/

		if(projectDotList) {
			// add the link to the dotlist
			liTag.appendChild(aTag);
			projectDotList.appendChild(liTag);

			// add the invisabel link to the dotlist
			liTagNoneDisplay.appendChild(aTagNoneDisplay);
			projectDotList.insertBefore(liTagNoneDisplay, projectDotList.firstChild);
		}
	};

	var globalIndex = 1;

	let initGattungsseite = function() {
		var svgElements = document.getElementsByClassName('clickable-oval');
		for(i = 0; i < svgElements.length; i++) {
			svgElements[i].addEventListener('click', function(e) {
				
				var id = e.target.getAttribute('data-ref');
				var circles = document.getElementsByClassName('clickable-oval');

				for(j = 0; j < circles.length; j++){ 
					circles[j].setAttribute('r', 15);
					if(circles[j].dataset.ref == id) {
						circles[j].setAttribute('r', 20);
					}
				}

				var objects = document.getElementsByTagName('g');
				for (j = 0; j < objects.length; j++){
					objects[j].classList.remove('active');
				}
 
				var group = document.getElementById(id);
				group.classList.add('active');

				var infos = document.querySelectorAll('[data-feature-info]');

				for(k = 0; k < infos.length; k++){
					infos[k].classList.remove('-active');
					if(id == infos[k].getAttribute('data-feature-info')) {
						infos[k].classList.add('-active');
					}
				}
			});
		}
		initGattungsGallery();
  };
  
	let showfirst = function () {
		var circles = document.getElementsByClassName('clickable-oval');
		if (circles.length != 0){
			var id = circles[0].dataset.ref;
			//circles[0].setAttribute('r', 20);

			var group = document.getElementById(id);
			group.classList.add('active');

			var circles = document.querySelectorAll("[data-ref=" + id + "]")
			circles.forEach(function(e){
				e.setAttribute('r', 20);
			})

			var infos = document.querySelectorAll('[data-feature-info]');

			for(k = 0; k < infos.length; k++){
				if(id == infos[k].getAttribute('data-feature-info')) {
					infos[k].classList.add('-active');
				}
			}
		}
  };
  
	let initGattungsGallery = function() {
		var gallery = document.getElementsByClassName("js-gallery-gattungsseite");

		for(i = 0; i < gallery.length; i++){
			galleryFactory(gallery[i]);
		}
	};
	let galleryFactory = function (element) {
		var cells = element.getElementsByClassName("gallery-cell");
		if(cells.length <= 1){
			return false;
		}
		
		var flkty = new Flickity(element);
		//flkty.options.resize = true;

		var img = element.getElementsByTagName('img');
		if(img.length > 0){
			img[0].addEventListener('load', function() {
				flkty.resize();
			});
		}

		initSvg();
		
		flkty.on('change', function( index ) {
			var index = index + 1;
			var svg = document.querySelectorAll('.feature-grafik svg');
			if(svg.length > 0){
				svg = svg[0];
				var json = svg.getAttribute("data-group-infos");
				json = JSON.parse(json);
				json.groups.forEach(element => {
					if(element.group == "group" + index) {
						animateSvgViewbox(svg, element, json);
						var objects = document.getElementsByTagName('g');
						for (j = 0; j < objects.length; j++){
							objects[j].classList.remove('active');
						}

						var group = document.getElementById(element.group);
						group.classList.add('active');

						var infos = document.querySelectorAll('[data-feature-info]');

						for(k = 0; k < infos.length; k++){
							if(element.group == infos[k].getAttribute('data-feature-info')) {
								infos[k].classList.add('-active');
							}
						}
						
					}
				});
			}
			globalIndex = index;
			
		});

  };
  
	let initSvg = function () {
		var checkWindowSize = media.breakpoint.refreshValue();
		var svg = document.querySelector('.feature-grafik svg');
		if(svg) {
			var svgFeatureGroupsJSON = {"groups": []};
			var featureGroups = svg.querySelectorAll('[id^="group"]');
			let saveDimensionOfSVG = svg.getAttribute('viewBox');
			let svgHeight = svg.getAttribute('viewBox').split(' ').pop();


			featureGroups.forEach(function(featureGroup) {
				let svgGroupBox = featureGroup.getBBox();
				let svgGroupMatrix = featureGroup.getCTM();
				let minWidth = (svgHeight / 3) * 4;
				let width = svgGroupBox.width;
				let left = svgGroupMatrix.e / svgGroupMatrix.d;

				if (width < minWidth) {
					width = minWidth;
					left = (svgGroupMatrix.e / svgGroupMatrix.d) - ((minWidth - svgGroupBox.width) / 2);
					if (left < 0) { left = 0; }

					let maxWidthSVG = saveDimensionOfSVG.split(' ')[2];
					console.log(maxWidthSVG);
					console.log(left + width);
					if (left + width > maxWidthSVG) {
						let tmpLeft = (left + width) - maxWidthSVG;
						left = left - tmpLeft;
					}
				}

				groupSvgDescription = {
					"group": 	featureGroup.getAttribute('id'),
					"height":	svgGroupBox.height,
					"width":	width,
					"left":		left,
					"top":		svgGroupMatrix.f / svgGroupMatrix.a,
					"svgheight":svgHeight,
				};

				svgFeatureGroupsJSON.groups.push(groupSvgDescription);

			});
			svg.setAttribute("data-group-infos", JSON.stringify(svgFeatureGroupsJSON));

			if (media.breakpoint.refreshValue() === media.breakpoint.XS){
				animateSvgViewbox(svg, svgFeatureGroupsJSON.groups[0], svgFeatureGroupsJSON);
			}

			window.addEventListener('holy:resizeDone', () => {
				if (media.breakpoint.refreshValue() === media.breakpoint.XS && checkWindowSize != media.breakpoint.refreshValue()) {
					showfirst();
					checkWindowSize = media.breakpoint.refreshValue();
					animateSvgViewbox(svg, svgFeatureGroupsJSON.groups[0], svgFeatureGroupsJSON);
				} else if(checkWindowSize != media.breakpoint.refreshValue()) {
					showfirst();
					checkWindowSize = media.breakpoint.refreshValue();
					svg.setAttribute('viewBox' ,saveDimensionOfSVG);
				}
			});
		}
  };
  
	let animateSvgViewbox = function (svg, el, json) {
		var previousObject;
		json.groups.forEach(element => {
			if(element.group == "group" + globalIndex) {
				previousObject = element;
			}
		});

		if(previousObject.left < el.left) {
			var startLeft = previousObject.left;
			var endLeft = el.left - previousObject.left;
		} else {
			var startLeft = previousObject.left;
			var endLeft =  el.left - previousObject.left;
		}

		if(previousObject.width < el.width) {
			var startWidth = previousObject.width;
			var endWidth = el.width - previousObject.width;
		} else {
			var startWidth = previousObject.width;
			var endWidth =  el.width - previousObject.width;
		}

		if(previousObject.height < el.height) {
			var startHeight = previousObject.height;
			var endHeight = el.height - previousObject.height;
		} else {
			var startHeight = previousObject.height;
			var endHeight =  el.height - previousObject.height;
		}
		
		let startTime = 0;
		const totalTime = 400;
		const animateStep = (timestamp) => {
			if (!startTime) startTime = timestamp;
			const progress = (timestamp - startTime) / totalTime;
			svg.setAttribute("viewBox", (startLeft + (endLeft * progress)) + " 0" + " " + (startWidth + (endWidth * progress)) + " " + el.svgheight);
			if (progress < 1) {
				window.requestAnimationFrame(animateStep);
			} else {
				svg.setAttribute("viewBox", el.left + " 0" + " " + el.width + " " + el.svgheight);
			}
		}
		window.requestAnimationFrame(animateStep);
	};

  exports.initStickyNavigation  = initStickyNavigation;
  exports.initOnePageNavigation = initOnePageNavigation;
  exports.projectSectionsInit   = projectSectionsInit;
  exports.initGattungsseite     = initGattungsseite;
  exports.showfirst             = showfirst;
  return exports;
})();