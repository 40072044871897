/**
 * Event Manager
 * 
 * @dependencies
 * * jquery
 * 
 * @dependents seite_versenden()
 * * function call used in pdb-api (dist/struktur/php/spezifikation/service/eventmanager-frontend/template_event.htm)
 * 
 * @dependents eventmanager_scrollto()
 * * $(document).ready(function() { ... }); (erco.js)
 * 
 * @dependents eventmanager_filter()
 * * #laenderfilter used in pdb-api (dist/struktur/php/spezifikation/service/eventmanager-frontend/template_event_container.htm)
 * * #stadtfilter used in pdb-api (dist/struktur/php/spezifikation/service/eventmanager-frontend/template_event_container.htm)
 * * #event_accordion used in pdb-api (dist/struktur/php/spezifikation/service/eventmanager-frontend/template_event_container.htm & dist/struktur/php/spezifikation/service/eventmanager-frontend/template_event.htm)
 * 
 * @commit
 * * Initial commit 2.0
 * * v.schaefer (2018-05-25) – Absoluter Pfad bei Funktion 'seite_versenden' von Eventmanager entfernt
 */

// Veranstaltung Teilen
function seite_versenden() {
  let loc = location.href;
  let title = document.title;

  //optinaler Anker kann übergeben werden - löscht den Anker falls einer in der URL ist
  if (arguments.length != 0) {
    const anchor = arguments[0];
    loc = loc.split('#')[0];
    loc += anchor;
  }

  // sonderfall Artikelseiten
  const articleKey = loc.match(/KEY=(.*?)&/)
  if (articleKey !== null) {
    const artNo = articleKey[1];
    const artNoParts = artNo.match(/..(.....)(...)/);
    artNo = artNoParts[1] + "." + artNoParts[2];
    loc = "/" + artNo;
  }

  loc = encodeURIComponent(loc);
  title = encodeURIComponent(title);

  location.href = "mailto:?subject=" + title + "&body=" + title + "%0A<" + loc + ">";

  return false;
}

function eventmanager_scrollto() {
  const hash = window.location.hash;
  if (!hash) {
    return;
  }

  const collapsedElements = document.querySelectorAll(`a[href="${hash}"] + .collapse`);

  if (!collapsedElements.length) {
    return;
  }

  window.scrollTo({
    top: collapsedElements[0].offsetTop - 121, // -121px wegen der Navigation,
    behavior: 'auto'
  }); 

  // animate collapse
  $(collapsedElements).collapse('show');
}

function eventmanager_filter() {

  // TODO: maybe use selection from parameter
  const country = document.querySelector('#laenderfilter').value;
  const city = document.querySelector('#stadtfilter').value;

  const eventList = document.querySelectorAll('#event_accordion > *');
  const filteredList = [];

  // case 1: country and city empty => no filter
  if (country == 0 && city == 0) {
    eventList.forEach(event => {
      $(event).fadeIn('fast');
      filteredList.push(event);
    });
  }
  // case 2: country empty, city given
  else if (country == 0 && city !== 0) {
    eventList.forEach(event => {
      if (event.dataset.city === city) {
        $(event).fadeIn('fast');
        filteredList.push(event);
      } else {
        $(event).fadeOut('fast');
      }
    });
  }
  // case 3: country given, city empty
  else if (country !== 0 && city == 0) {
    eventList.forEach(event => {
      if (event.dataset.iso === country) {
        $(event).fadeIn('fast');
        filteredList.push(event);
      } else {
        $(event).fadeOut('fast');
      }
    });
  }
  // case 4: country given, city given
  else if (country !== 0 && city !== 0) {
    eventList.forEach(event => {
      if (event.dataset.iso === country && event.dataset.city === city) {
        $(event).fadeIn('fast');
        filteredList.push(event);
      } else {
        $(event).fadeOut('fast');
      }
    });
  }

  if (results.length === 1) {
    results[0].querySelectorAll('.collapse').forEach(element => {
      $(element).collapse('show');
    });
  }
  else {
    document.querySelectorAll('#event_accordion .panel-body.in').forEach(element => {
      $(element).collapse('hide');
    });
  }
}

(() => {
  // Filter
  const filterCountries = document.querySelector('#laenderfilter');
  const filterCities = document.querySelector('#stadtfilter');


  [
    filterCountries,
    filterCities
  ].forEach((filter) => {
    if (!filter) {
      return;
    }
    
    filter.addEventListener('change', () => {
      eventmanager_filter();
    });
  });

  // Sets selected option in Dropdown with Events, when clicking on participate button
  const participateButtons = document.querySelectorAll('[data-eventid]');
  const eventSelects = document.querySelector('[data-select-events]');
  participateButtons.forEach(element => {
    element.addEventListener('click', e => {
      const eventId = e.currentTarget.getAttribute('data-eventid');

      eventSelects.querySelectorAll('[data-option-event]').forEach(element => {
        element.removeAttribute('selected');
      });

      const optionElement = eventSelects.querySelector(`[data-option-event="${eventId}"]`);
      optionElement.setAttribute('selected', 'selected');
    });
  });
})();
