/*
articledata.js
Klickmeister GmbH für ERCO GmbH
06.2013
v1.0.0
Steuerung der Artikelübersichten

*/

var artikelseite = new Object();

artikelseite.init_done = false;
artikelseite.data = {};
artikelseite.data.baseUrl = false;
artikelseite.data.lastUrl = false;
artikelseite.data.filterTags = {};
artikelseite.data.orderby = "u_artikel";
artikelseite.data.orderDirection = "asc";
artikelseite.data.paginationOffset = "0";
artikelseite.data.filter = {};
artikelseite.data.paginationMode = false;
artikelseite.data.layout = false;
artikelseite.data.AjaxTarget = "#article-list-wrap";
artikelseite.data.loadState = "empty";
artikelseite.data.context = false;

//Filter Gruppen zu denen ein Slider in der View angezeigt wird
artikelseite.data.filterSliderGroups = ['lichtstrom', 'leistung'];

/* !artikelseite.func */
artikelseite.func = (function () {

  return {

    /* !artikelseite.func.init */
    init: function () {

      // klick auf die layout-wechsler
      $(".layoutswitch li").on('click', function (e) {
        $(".layoutswitch li.active").removeClass("active");
        $(this).addClass("active");
        e.preventDefault();
        if ($(this).find("a").attr('href') == 1) {
          $('.artikel-ansicht-1').removeClass("hidden").addClass("active").show();
          $('.artikel-ansicht-2').hide().removeClass("active");
        } else if ($(this).find("a").attr('href') == 2) {
          $('.artikel-ansicht-1').hide().removeClass("active");
          $('.artikel-ansicht-2').removeClass("hidden").addClass("active").show();
        }

        // Bilder nachladen
        $(".nolazy").unveil(200, function () {
          $(this).on('load', function () {
            $(this).addClass("loaded").removeClass("nolazy");
          });
        });
      });

      // Klick auf Sprungmarken
      $("section a[href*='#']").on('click', function (e) {
        var href = $(this).attr("href");
        if (href.match(/[a-zA-Z]/)) {
          //history.pushState('','',href);
        }
      });

      /* Sind wir im PaginationMode?
      		Wenn ja, dann werden einige Aktionen anders ausgeführt. Beim Klick auf
      		einen Filter, die Sortierung, die Artikelnummernsuche oder auf
      		die Paginierungsnavi werden die Daten neu vom Server gezogen.
      */

      // Sortierung muss im paginationMode serverseitig erfolgen ...
      if (artikelseite.data.paginationMode) {

        // Wir hängen den Sortierindikator ein
        $("[data-ajax-sort]").each(function (ele) {
          var $this = $(this);
          $this.addClass("footable-sortable");
          $this.append("<span class=\"fooicon fooicon-sort\"></span>");
        });

        // Wir setzen die aktuelle Sortierspalte aktiv
        var sortierspalte = "[data-ajax-sort=" + artikelseite.data.orderby + "]";
        var sortierCSSklasse = "footable-" + artikelseite.data.orderDirection;
        $(sortierspalte).addClass(sortierCSSklasse);

        // Beim Klick triggern wir einen Ajax Call
        $("[data-ajax-sort]").on('click', function (ele) {
          var $this = $(this);

          // Sortierangaben holen und speichern
          var orderBy = $this.data("ajax-sort");
          var orderDirection = $this.data("ajax-sort-direction");

          // Soll nur die Richtung umgekehrt werden?
          if (orderBy == artikelseite.data.orderby) {
            if (artikelseite.data.orderDirection == "asc") {
              artikelseite.data.orderDirection = "desc";
            } else {
              artikelseite.data.orderDirection = "asc";
            }
          }

          // Sortierspalte speichern
          artikelseite.data.orderby = orderBy;

          // paginationOffset auf null setzen, denn wir wollen ja mit der ersten Seite starten
          artikelseite.data.paginationOffset = "0";

          // Url generieren
          var url = artikelfilter.func.generate_url(artikelseite.data.baseUrl);

          // Daten holen
          get_artikeldata.func.init(url, artikelseite.data.AjaxTarget);

          // Haupturl ändern, damit gescheite Links verschickt werden können
          artikelseite.func.edit_urlSort();

        });
      } else { // ... anderfalls via Footable
        //$("#tab-articles").attr("data-sorting", true);
      }

      // Sind Paginierungslinks vorhanden?
      var pagLinks = ($(".js-pag-trigger").length > 0) ? true : false;

      if (pagLinks) {
        $(".js-pag-trigger a").on('click', function (event) {

          event.preventDefault();
          var $this = $(this);

          // Gibt es eine URL?
          if ($this.attr("href") && $this.attr("href").match(/[a-z]/)) {
            event.preventDefault();

            // URL zerlegen
            var urlsegments = artikelfilter.func.restructure_url($this.attr("href"));

            // paginationOffset speichern
            artikelseite.data.paginationOffset = urlsegments["p"];

            // Neue AjAXURL bauen
            var ajaxUrl = artikelfilter.func.generate_url(artikelseite.data.baseUrl);

            if (urlsegments["mode"] == "querystring") {
              ajaxUrl += "?" + urlsegments["qs"];
            }

            // Content laden
            get_artikeldata.func.init(ajaxUrl, artikelseite.data.AjaxTarget);

            // Haupturl ändern, damit gescheite Links verschickt werden können
            artikelseite.func.edit_urlPagination();

            // nach oben scrollen
            //holy.scrollTo(artikelseite.data.AjaxTarget, undefined, 130);
          }
        });
      }

      // Prüfen, ob Artikelnummern an der URL hängen
      var params = {};
      params = get_params(location.href);

      // Initializing Footable for article sorting BK 24.10.2018
      artikelseite.func.initFootable();

      /* ss 20160918 - Das Initialisieren muss mehrfach durchgeführt werden können,
      1. Artikelübersicht laden, initialisieren
      2. Spotlight-Artikelnummersuche laden, initialisieren
      Ohne das Unbind werden die events mehrfach aufgesetzt!
      */

      // setting attributes
      var specUrls = document.querySelectorAll('[data-js-loadspecsheet]');
      for (var i = 0; i < specUrls.length; i++) {
        if (!specUrls[i].classList.contains('initialized')) {
          specUrls[i].classList.add('initialized');
          const separator = !!window.location.search ? '&' : '?';
          specUrls[i].href = window.location.href + separator + 'specsheet=' + specUrls[i].getAttribute('data-artno');
        }
      }

      // using event delegation to circumvent footable and jquery 3 issues
      $('[data-articles]').off('click.loadspecsheet').on('click.loadspecsheet', '[data-js-loadspecsheet]', function(event) {
        const e = event.originalEvent;
        e.preventDefault();

        const artno = e.target.getAttribute('data-artno') || e.target.parentNode.getAttribute('data-artno');
        Specsheets.init(artno);
      });

      //Wenn keine Filter existieren
      if (document.querySelectorAll('[data-filter-groups] .artikelfilter').length == 0) {

        //Werden die Sectionen mit den Filtern ausgeblendet
        if (document.querySelector('[data-filter-groups]')) {
          document.querySelector('[data-filter-groups]').classList.add('hidden');
        }

        var filterContainer = document.querySelector('[data-collapse-filter-container]');
        if (filterContainer) {
          filterContainer.classList.add('hidden');
        }
      }

      // Der Seite sagen, dass die Artikeltabellen Funktionen bereit stehen
      document.getElementsByTagName("body")[0].setAttribute("data-articletable-initiated", "true");

    },

    /* !initFootable
    	prueft ob ein Artikel geöffnet wurde ,
    	ist das nicht der fall, darf footable verwendet werden,
    	ansonsten landen alle geöffneten oben, was manchmal doof ist
    	Zudem wird geprüft, ob Footable bereits intialisiert wurde.
    */
    initFootable: function () {

      // Sortierung muss im paginationMode serverseitig erfolgen ...
      if (artikelseite.data.paginationMode) {
        $("#tab-articles").attr("data-sorting", false);
      }
      if ($("[id*='large-']").length == 0 && $('.footable.footable-initialized').length === 0) {
        $('.footable').footable()
        .on("ready.ft.table", () => {
          document.dispatchEvent(new CustomEvent('articleTableReady'));
        });

        $('.footable').addClass('footable-initialized');
      }

    },

    /* !artikelseite.func.reset */
    reset: function () {

      $("[id*='large']").each(function (index, ele) {
        ele.id.match(/large-(.*)/);
        var artno_real = RegExp.$1.substr(0, 5) + "." + RegExp.$1.substr(5, 3);
        artikelseite.func.close(artno_real, "noscroll");
      });
    },


    /* !artikelseite.func.edit_url */
    edit_url: function (artno, modus) {

      var data = edit_urlHelper.func.getUrl();

      // Parameter extrahieren
      var params = get_params(data.loc);

      // Artikeldaten aus der URL ziehen
      params = edit_urlHelper.func.cleanupArticles(params);

      // bereits übergebene Artnos ausgelesen
      var articles = new Array();
      if (params["articles"]) {
        articles = params["articles"].split(/[;|,]/);
      }

      // neuen Artikel anfuegen und in params ersetzen
      if (modus == "push") {
        articles.push(artno);
      }
      if (modus == "remove") {
        articles = $.grep(articles, function (value) {
          return value != artno;
        });
      }
      params["articles"] = articles.join(";");

      // neuen Query String bauen, URL erzeugen und anheften
      var qs = $.param(params);
      var new_url = edit_urlHelper.func.createQS(data.url, params);

      // leider müssen wir URL Fragments entfernen
      new_url = new_url.replace(/#.*?\?/, "?");

      //history.pushState('','',new_url);

      history.replaceState('', '', new_url);

    },

    /* !artikelseite.func.edit_urlPagination */
    edit_urlPagination: function () {

      if (artikelseite.data.context != "famPage") {
        return false;
      }

      var data = edit_urlHelper.func.getUrl();

      // Parameter extrahieren
      var params = get_params(data.loc);

      // Artikeldaten aus der URL ziehen
      params = edit_urlHelper.func.cleanupArticles(params);

      // Page addieren
      params["p"] = artikelseite.data.paginationOffset;

      // neuen Query String bauen, URL erzeugen und anheften
      var new_url = edit_urlHelper.func.createQS(data.url, params);

      //history.pushState('','',new_url);
      history.replaceState('', '', new_url);

    },

    /* !artikelseite.func.edit_urlSort */
    edit_urlSort: function () {

      if (artikelseite.data.context != "famPage") {
        return false;
      }

      var data = edit_urlHelper.func.getUrl();

      // Parameter extrahieren
      var params = get_params(data.loc);

      // Artikeldaten aus der URL ziehen
      params = edit_urlHelper.func.cleanupArticles(params);

      // sort addieren
      params["orderby"] = artikelseite.data.orderby;

      // sort richtung addieren
      params["orderDirection"] = artikelseite.data.orderDirection;

      // neuen Query String bauen, URL erzeugen und anheften
      var new_url = edit_urlHelper.func.createQS(data.url, params);
      //history.pushState('','',new_url);
      history.replaceState('', '', new_url);

    },

    /* !artikelseite.func.close */
    close: function (artno_real, modus) {

      var artno_real = artno_real + "";


      var artno = artno_real.replace(/\./, "");
      $("#large-" + artno).hide();
      if ($("#small-" + artno).length > 0) { // artikelansicht 1
        //if(modus != "noscroll"){ $('html, body').animate({ scrollTop: ($("#small-"+artno).offset().top-200)}, 'fast'); }
        $("#small-" + artno).slideDown("slow"); // artikel ansicht tabelle
      }
      if ($(".small-" + artno).length > 0) { // artikelansicht 1
        //if(modus != "noscroll"){ $('html, body').animate({ scrollTop: ($(".small-"+artno).offset().top-200)}, 'fast'); }
        $("#small-" + artno).slideDown("slow") // artikel ansicht tabelle
      }

      //Collector.fadecollectUIelements();
      artikelseite.func.edit_url(artno_real, "remove");
      artikelseite.func.initFootable();
    },

    /* !artikelseite.func.scoll_to_artno */
    // Zur Artikelseite scollen
    scoll_to_artno: function (kennung) {
      //$('html, body').animate({ scrollTop: ($("#"+kennung).offset().top-140)}, 'slow');

      //Offset berechen = Höhe Sticky Navigation
      //var offset = document.querySelector('[data-product-headline-bar]').offsetHeight +10;

      //Zum Zielelement scrollen + offset
      holy.scrollTo("#" + kennung, undefined, 130);
      //$.scrollTo($("#"+kennung));
      return true;
    }
  }

}());

//var artikelfilter = new Object();
artikelfilter.data = [];
artikelfilter.urlFilter = [];
artikelfilter.init_done = false;
artikelfilter.filter_fuer_excel = false;
artikelfilter.lastTooltip = null;


/* !artikelfilter.func */
artikelfilter.func = (function () {
  return {

    /* !artikelfilter.func.init */
    // Aktion auf die Filter legen
    init: function () {

      // wurde ggf schon initialisiert?
      if (artikelfilter.init_done) return false;
      artikelfilter.init_done = true;
      var filterSliderElements = document.querySelectorAll('[data-range-slider]');
      var filterMinMaxSliderElements = document.querySelectorAll('[data-min-max-slider]');

      //BO MinMax Filter Slider initialsieren
      for (let i = 0; i < filterMinMaxSliderElements.length; i++) {

        //MinMax Filter Slider initialisieren
        new FilterSliderMinMax(filterMinMaxSliderElements[i]);

        //Event wird gefeuert, wenn der Slider auf einen Wert gezogen wird, der inaktiv ist
        //also für die ausgewählten Filter nicht zur Verfügung steht
        filterMinMaxSliderElements[i].addEventListener('outOfRange', function (e) {
          let activeFilter = document.querySelectorAll('[data-filter].aktiv');

          for (let i = 0; i < activeFilter.length; i++) {
            let element = activeFilter[i];
            element.classList.remove('aktiv');
            delete artikelseite.data.filterTags[element.getAttribute('data-filter')];
          }
          window.toolkit.triggerEvent(e.currentTarget, 'changeMinMaxValue');
        });

        //Event anheften, dass gefeuert wird, wenn Range des Sliders verändert wird
        filterMinMaxSliderElements[i].addEventListener("changeMinMaxValue", function (e) {
          let dataFilterValue = e.currentTarget.getAttribute('data-filter-value');

          //Wenn Value gefüllt
          if (dataFilterValue) {

            //dann Filtertag merken
            artikelseite.data.filterTags[e.currentTarget.filterSlider.getFilterGroup()] = e.currentTarget.getAttribute('data-filter-value');
          }

          //Sonst oberste und untereste Grenze vom Slider ausgewählt => Slider inaktiv
          else {
            delete artikelseite.data.filterTags[e.currentTarget.filterSlider.getFilterGroup()];
          }
          artikelfilter.func.select(e.currentTarget);
        });

        //Event wird gefeuert, wenn Slider deaktiviert wird. Bspw. über die Filtertags.
        filterMinMaxSliderElements[i].addEventListener("deactivatefilter", function (e) {

          //Filter entfernen der zuvor ausgewählt wurde
          delete artikelseite.data.filterTags[e.currentTarget.filterSlider.getFilterGroup()];

          e.currentTarget.filterSlider.deactivateSlider();

          artikelfilter.func.select(e.currentTarget);
        });
      }
      //EO MinMax Filter Slider initialsieren

      //BO Filter Slider initialisieren
      for (var i = 0; i < filterSliderElements.length; i++) {

        //Filter Slider initialisieren
        new FilterSlider(filterSliderElements[i]);

        //Event wird gefeuert, wenn Position vom Slider verändert wird
        filterSliderElements[i].addEventListener("changevalue", function (e) {

          //Filter entfernen der zuvor ausgewählt wurde
          delete artikelseite.data.filterTags[e.currentTarget.filterSlider.getLastFilterValue()];

          //neuen Filter hinzufügen
          artikelseite.data.filterTags[e.currentTarget.filterSlider.getCurrentFilterValue()] = e.currentTarget.filterSlider.getCurrentLabelValue();
          artikelfilter.func.select(e.currentTarget);

        });

        //Event wird gefeuert, wenn Slider über das Sliderelement deaktiviert wird
        filterSliderElements[i].addEventListener("deactivateslider", function (e) {
          delete artikelseite.data.filterTags[e.currentTarget.filterSlider.getLastFilterValue()];
          artikelfilter.func.select(e.currentTarget);
        });

        // Event wird gefeuert, wenn Filter über Tagliste deaktivert werden
        filterSliderElements[i].addEventListener("deactivatefilter", function (e) {

          //Filter entfernen der zuvor ausgewählt wurde
          delete artikelseite.data.filterTags[e.currentTarget.filterSlider.getCurrentFilterValue()];
          e.currentTarget.filterSlider.deactivateSlider();
          artikelfilter.func.select(e.currentTarget);
        });
      }
      //EO Filter Slider initialisieren

      //BO Schalftlächen Filter initialisieren (exklusive Range Slider und MinMax Slider)
      var filterElements = document.querySelectorAll('[data-filter]:not([data-range-slider]):not([data-min-max-slider])');
      for (var i = 0; i < filterElements.length; i++) {

        // Event um Filter zu deaktivieren
        filterElements[i].addEventListener('deactivatefilter', function (e) {
          window.toolkit.triggerEvent(e.currentTarget, 'click');
        });
      };
      //EO Schalftlächen Filter initialisieren

      let filterGroupWithValues = [];
      let filtergroupValue = null;
      let currentFilter = null;

      //BO Filtergruppen durchgehen, die über Slider ausgewählt werden können
      for (let j = 0; j < artikelseite.data.filterSliderGroups.length; j++) {
        filtergroupValue = artikelseite.data.filterSliderGroups[j];

        // Checking if the variable exists since otherwise articlelists break BK 6.12.2018
        if (artikelseite.data.filterstring) {
          for (let k = 0; k < artikelseite.data.filterstring.length; k++) {
            currentFilter = artikelseite.data.filterstring[k];

            //Filtergruppe herausziehen
            currentFilter.match(/(.*?)_(.*)/);
            let currentGroupValue = RegExp.$1;

            //Gehört der Wert aus dem Filterstring zur Filtergruppe?
            if (currentGroupValue == filtergroupValue) {

              //Dann merken wir uns diesen
              filterGroupWithValues.push(currentFilter);

              //Filter Slider selektieren der zur Filtergruppe gehört. Entweder Slider mit einem oder zwei Stellteilen
              element = document.querySelector(`[data-range-slider][data-filter-group^="${filtergroupValue}"],[data-min-max-slider][data-filter-group^="${filtergroupValue}"]`);

              if (element) {

                //Obere und Untere Grenze auftrennen
                currentFilter = currentFilter.split(':to:', 2);

                //Filtergruppe aus Wert entfernen
                currentFilter[0] = currentFilter[0].replace(filtergroupValue + '_', '');

                //Bindestriche durch Leerzeichen ersetzen
                currentFilter[0] = currentFilter[0].replace('-', ' ');

                //Slider mit zwei Stellteilen?
                if (element.hasAttribute('data-min-max-slider')) {

                  //Wenn nur ein Wert vorhanden
                  if (currentFilter.length < 2) {


                    //Dann Wert duplizieren, da für Slider mit zwei Stellteilen, zwei Werte übergeben werden müssen
                    currentFilter.push(currentFilter[0]);
                  } else {
                    currentFilter[1] = currentFilter[1].replace(filtergroupValue + '_', '');
                    currentFilter[1] = currentFilter[1].replace('-', ' ');

                  }

                  //Filter setzen
                  element.filterSlider.setCurrentValuesByValues(currentFilter);

                  //Filter für Tagliste hinzufügen
                  artikelseite.data.filterTags[filtergroupValue] = element.getAttribute('data-filter-value');
                }

                //Slider mit einem Stellteil?
                else if (element.hasAttribute('data-range-slider')) {

                  //Filter setzen
                  element.filterSlider.setCurrentValueByValue(currentFilter[0]);

                  //Filter für Tagliste hinzufügen
                  artikelseite.data.filterTags[filtergroupValue] = element.filterSlider.getCurrentLabelValue();

                }
              }
            };
          }
        };
      }

      //artikelfilter.func.select(element);

      //EO Filtergruppen durchgehen, die über Slider ausgewählt werden können

      //Filter die zu einem Slider gehören aus dem original Filterstring entfernen
      for (let k = 0; k < filterGroupWithValues.length; k++) {
        let element = filterGroupWithValues[k];

        //Werte aus dem original Filterstring entfernen
        let index = artikelseite.data.filterstring.indexOf(element);
        artikelseite.data.filterstring.splice(index, 1);
      };

      //Wurden Filter via URL übergeben? Dann zeigen wir sie an
      if (artikelseite.data.filterstring && artikelseite.data.filterstring.length > 0) {

        jQuery.each(artikelseite.data.filterstring, function (index, ele) {

          //Element zu dem URL String slektieren
          var element = document.querySelector("[data-filter=" + ele + "]");

          artikelfilter.func.select_noAjax(element);
        });
      }

      else {
        // Funktion muss einmal ausgeführt werden, damit nicht mehr verfügbare Filter,
        // die über Schalflächen ausgewählt werden, deaktiviert werden
        artikelfilter.func.select_noAjax();
      }


      // BO Tooltips initialisieren
      if ($('[data-original-title]').length > 0) {
        $('[data-original-title]').tooltip();

        $('[data-original-title]').on('show.bs.tooltip', function(e) {

          //If a tooltip is visible and it's not the tooltip we want to show
          if (artikelfilter.lastTooltip != null && artikelfilter.lastTooltip != e.currentTarget) {

            //than we hide the tooltip
            $(artikelfilter.lastTooltip).tooltip('hide');
          };


          artikelfilter.lastTooltip = e.currentTarget;

          //Timout wird benötigt, da sonst das aria-Attribut nicht korrekt ausgelesen wird
          setTimeout(() => {

            globals.renderMustacheTemplates();

            let tooltipID      = e.currentTarget.getAttribute('aria-describedby');
            let tooltipElement = document.querySelector(`#${tooltipID}`);

            if(!tooltipElement) return;

            //Zum Schließen des Tooltips
            let closeButton    = tooltipElement.querySelector('[data-close-tooltip]');

            if (closeButton) {
              closeButton.addEventListener('click', function(e) {
                e.preventDefault();

                let currentID = e.currentTarget.closest('.tooltip').id;

                $(document.querySelector(`[aria-describedby=${currentID}]`)).tooltip('hide');
              });
            }

            //User clicks not on the info button
            document.addEventListener('click', closeTooltips);


            function closeTooltips(e) {
              if (!e.target.hasAttribute('data-tooltip')) {
                $('[data-original-title]').tooltip('hide');
              }
              document.removeEventListener('click', closeTooltips);
            };
            
          },10);
        });
      }
      //EO Tooltips initialisieren

      // beim Sortieren geladene große Artikelinhalte entfernen
      $("th.footable-sortable").on('click', function (e) {
        artikelseite.func.reset();
      });
    },
    /* !artikelfilter.func.restructure_url */
    /* Url analysieren, z.B. für Paginierung */
    restructure_url: function (url) {

      var params = [];

      // Wo sind wir?
      if (url.match(/productdata\/search/)) {
        params = artikelfilter.func.restructure_url_querystring(url);
      } else {
        params = artikelfilter.func.restructure_url_segements(url);
      }

      return params;
    },

    /* !artikelfilter.func.restructure_url_querystring */
    restructure_url_querystring: function (url) {

      var params = [];

      // QS holen
      url.match(/(.*?)\?(.*)/);
      var baseurl = RegExp.$1;
      var qs = RegExp.$2;

      // baseurl aufräumen
      params = artikelfilter.func.restructure_url_segements(baseurl);
      artikelseite.data.baseUrl = params["baseurl"];

      // QS mitnehmen
      params["qs"] = qs;

      // Methode und Sprache addieren
      if (params["search"]) {
        artikelseite.data.baseUrl += "search/" + params["search"];
        artikelseite.data.AjaxTarget = ".artikelansicht";
      }

      // Assoziatives Array bilden
      var pairs = url.split(/&/);

      while (pairs.length) {
        var temp = pairs.shift().split(/=/);
        var key = temp[0];
        var value = temp[1];
        params[key] = value;
      }

      // Neue Schreibweise (seit Artikellistentuning 2018)
      params["p"] = params["per_page"];

      // Modus speichern
      params["mode"] = "querystring";

      return params;
    },

    /* !artikelfilter.func.restructure_url_segements */
    restructure_url_segements: function (url) {

      var params = [];

      // Modus speichern
      params["mode"] = "segements";

      // Alles bis zum Controller entfernen
      url = url.replace(/(.*?\/productdata\/)/, "");

      // Basis merke
      params["baseurl"] = RegExp.$1;

      // Assoziatives Array bilden
      var segments = url.split(/\//);

      while (segments.length) {
        var key = segments.shift();
        var value = segments.shift();
        if (value) {
          params[key] = value;
        }
      }

      return params;
    },


    /* !artikelfilter.func.generate_url */
    /* Filter, Sortierung, etc. auslesen und URL bauen */
    generate_url: function (url, filter, filterstring) {

      // Filter sammeln
      if (!filterstring) {
        var filterstring = [];
      }

      // Wir klappern die Filter ab (alle Filter Buttons und Filter Slider die aktiv sind)
      $('[data-filter].aktiv, [data-range-slider]:not([data-filter=""])').each(function (index) {
        var $this = $(this);
        var filterName = $this.attr("data-filter");

        filterstring.push(filterName);
      });

      //Wir addieren die Filter welche über MinMax Slider ausgewählt wurden
      let sliderElements = document.querySelectorAll('[data-min-max-slider][data-filter-value]');
      for (let i = 0; i < sliderElements.length; i++) {
        let slider = sliderElements[i];
        let filterValue = slider.filterSlider.getURLEncodedRange();
        filterstring.push(filterValue);
      }

      //Wir addieren oder subtrahieren den aktuellen Filter, falls einer übergeben wurde
      //und falls der Filter nicht über ein Slider ausgewählt wurde
      //Für den Slider wurde der filterstring Array schon zuvor befüllt
      if (filter && !filter.hasAttribute('data-range-slider') && !filter.hasAttribute('data-min-max-slider')) {
        var this_filter = $(filter);
        var filterName = this_filter.attr("data-filter");

        if (jQuery.inArray(filterName, filterstring) === -1) {
          filterstring.push(filterName);
        } else {
          filterstring.splice($.inArray(filterName, filterstring), 1);
        }
      }

      //Sind Filter vorhanden?
      if (filterstring.length > 0) {
        var userfilter = "/userfilter/" + filterstring.join("+");
        url += userfilter;
      }


      // Filter merken
      artikelseite.data.filterstring = filterstring;

      // Sortierung einhaengen
      if (artikelseite.data.orderby.match(/[a-z]/)) {
        url += "/orderby/" + artikelseite.data.orderby;
      }

      // Sortierrichtung einhaengen
      if (artikelseite.data.orderDirection.match(/[a-z]/)) {
        url += "/orderDirection/" + artikelseite.data.orderDirection;
      }

      // Layout einfügen
      if (artikelseite.data.layout) {
        url += "/layout/" + artikelseite.data.layout;
      }

      // Artikel einhaengen
      if (artikelseite.data.article) {
        url += "/a/" + artikelseite.data.article;
      }

      // Seitenzahl einhaengen. Muss leider der letzte Parameter sein :(
      if (artikelseite.data.paginationOffset) {
        url += "/p/" + artikelseite.data.paginationOffset;
      }

      return url + "/";
    },

    /* !artikelfilter.func.count */
    count: function () {
      // Always use anzahl_artikel from artikelfilter object, cause the class does not exist in the DOM
      // var artikelanzahl = (artikelseite.data.paginationMode) ? artikelfilter.anzahl_artikel : $(".article_row:visible").length;
      $("#anzahl_artikel").html(artikelfilter.anzahl_artikel);
    },


    /* !artikelfilter.func.simulation_aendern */
    simulation_aendern: function (filter) {
      $("#simulationen img").hide();
      $("#simulationen ." + filter).show();

    },

    /* !artikelfilter.func.add_filter_to_excel_export */
    add_filter_to_excel_export: function (ele) {

      //new excel export button
      //select ajax spinner
      let spinner = ele.closest('[data-excel-export-wrap]').querySelector('[data-spinner]');

      function fertig(ele) {

        //new excel export button
        // hide ajax spinner
        spinner.classList.add('trans0');
        spinner.classList.remove('trans100');
        $(ele).parent().removeClass("active");
      }

      /* ss 20150612: im Lightfinder und in myERCO machen wir einen Postrequest, da wir ggf. große Sets an Artikelnummern mitgeben */
      if ($('#ledfinder, .myerco-articlelists-scope').length) {


        let params = {};
        params['artnos'] = "";
        params['accessories'] = "";
        let articles = null;

        //are we in the led finder?
        if ($('#ledfinder').length) {
          articles = $('[data-article-nr]')
        }

        //or in myERCO?
        else {

          //function from myerco.js
          articles = $('[data-article-nr]:checked');
        }

        articles.each(function (index, value) {

          // Check if the article is an accesory
          if (value.getAttribute('data-articletype') == "accessory") {
            params['accessories'] += "-" + value.getAttribute('data-article-nr');
          }
          // else the article is a luminaire
          else {
            params['artnos'] += "-" + value.getAttribute('data-article-nr');
          }
        });


        //remove the first character => '-'
        params['accessories'] = params['accessories'].substring(1);
        url = confEnv['pdb_api_url'] + "/productdata/articles/" + k3vars.S_Sprache + "/export/excel?api=v1.1&origin=myerco";

        if (params['artnos'].length || params['accessories']) {

          //new excel export button
          //show ajax spinner

          spinner.classList.remove('trans0');
          spinner.classList.add('trans100');
          $.fileDownload(url, {

            httpMethod: "POST",
            data: params,
            successCallback: function (url) {
              fertig(ele);
            },
            failCallback: function (responseHtml, url) {
              fertig(ele);
            }
          });
        }
      } else {

        //new excel export button
        //show ajax spinner
        spinner.classList.remove('trans0');
        spinner.classList.add('trans100');

        var f = "";
        if (artikelfilter.filter_fuer_excel) {
          f = artikelfilter.filter_fuer_excel.join("+").replace(/,/, "+");
        }

        var url = $(ele).attr("href");
        // cn: 02.2018: für die paginierten Listen nicht mehr erforderlich
        //.replace(/userfilter.*?\//, "");
        //url = url + "userfilter/" + f;

        $.fileDownload(url, {
          successCallback: function (url) {
            fertig(ele);
          },
          failCallback: function (responseHtml, url) {
            fertig(ele);
          }

        });
      }

      //ele.preventDefault();
      return false; //this is critical to stop the click event which will trigger a normal file download!
    },


    /* !artikelfilter.func.set_filter */
    /* Filter aktivieren ( nur in der GUI ) */
    set_filter: function (filter) {

      //Falls jQuery Objekt, dann ziehen wir das DOM Element raus
      var filterElement = (filter.jquery) ? filter[0] : filter;

      //Handelt es sich bei dem Filter Element nicht um einen Slider im einen Filter Slider?
      if (!filterElement.hasAttribute('data-range-slider') && !filterElement.hasAttribute('data-min-max-slider')) {

        var this_filter = $(filterElement);
        var filterId = filterElement.getAttribute('data-filter')

        if (this_filter.hasClass("aktiv")) {

          //Tooltip einblenden
          this_filter.closest('[data-filter-button-wrap]').find('[data-tooltip]').removeClass('hidden');

          filterTagList.removeTag(filter.data('filter-value'));
          delete artikelseite.data.filterTags[filterId];
          this_filter.removeClass("aktiv");
          this_filter.find("span").remove();
        } else {

          artikelseite.data.filterTags[filterId] = filterElement.getAttribute('data-filter-value')

          filterTagList.addTag(filterElement.getAttribute('data-filter-value'));
          this_filter.addClass("aktiv");

          //hide tooltip icon
          this_filter.closest('[data-filter-button-wrap]').find('[data-tooltip]').addClass('hidden');

          this_filter.append("<span class='icon-sprite white icon-close'></span>");
          $(".filtergroups .ajax_loader_button").remove();

        }
      } else {
        //artikelfilter.func.manage_filtertags();
      }
      return filter;
    },



    /* !artikelfilter.func.manage_filtertags */
    /* */
    manage_filtertags: function (filter) {
      decodeURI;
      filterTagList.init();

      for (var key in artikelseite.data.filterTags) {
        filter = artikelseite.data.filterTags[key];
        filterTagList.addTag(filter);
      };
      return true;
    },

    /* !artikelfilter.func.select_ajax */
    // Nachladen der paginierten Artikelliste
    select_ajax: function (filter, filtergruppe, filter_id, url) {

      // Wir starten wieder mit der ersten Seite (Paginierung)
      artikelseite.data.paginationOffset = "0";

      // Url holen
      url = artikelfilter.func.generate_url(url, filter);

      // Daten holen
      get_artikeldata.func.init(url, artikelseite.data.AjaxTarget, this.select_noAjax, filter, filtergruppe, filter_id);
    },

    /* !artikelfilter.func.select */
    select: function (filter, filtergruppe, filter_id) {

      // BO PEP2019: Sonderfall Artikel auf Anfrage
      if (filter && filter.getAttribute('data-filter').match(/_auf\-anfrage\-/)) {
        return false;
      }
      // EO PEP2019: Sonderfall Artikel auf Anfrage

      //Handelt es sich beim dem Filter um einen Button?
      if (filter && !filter.hasAttribute('data-range-slider') && !filter.hasAttribute('data-min-max-slider')) {
        // Ajax Loader anzeigen
        $(filter).append("<span class='ajax_loader_button'></span>");

        //hide tooltip icon
        $(filter).closest('[data-filter-button-wrap]').find('[data-tooltip]').addClass('hidden');

        //remove close button
        $(filter).closest('[data-filter-button-wrap]').find('.icon-close').remove();
      }

      // Haupturl ändern, damit gescheite Links verschickt werden können
      artikelseite.data.paginationOffset = 0;
      artikelseite.func.edit_urlPagination();

      // Soll die Liste paginiert werden?
      if (artikelseite.data.paginationMode) {
        artikelfilter.func.select_ajax(filter, filtergruppe, filter_id, artikelseite.data.baseUrl);
      } else {
        artikelfilter.func.select_noAjax(filter, filtergruppe, filter_id);
      }
    },

    /* !artikelfilter.func.select_noAjax */
    /* Filter, ohne die Daten via Ajax nach zu laden */
    select_noAjax: function (filter, filtergruppe, filter_id) {
      if (filter) {
        var this_filter = (filter.jquery) ? filter : $(filter);

        // Prüfen, ob der Filter selektiert oder deselektiert wurde und dem Filter die entsprechende Klasse geben
        artikelfilter.func.set_filter(this_filter);
      }



      // Große Artikelelemente ausblenden
      artikelseite.func.reset();
      var this_filter = $(filter);

      // Objekt für die möglichen Filter die übrig bleiben
      var moegliche_filter = new Object();
      // In welcher Filtergruppe sind wir gerade?
      // if(!filtergruppe){
      // 	if(this_filter.data("filter").match(/(.*)_(.*)/)){
      // 		filtergruppe = RegExp.$1;
      // 		filter_id = RegExp.$2;
      // 	}
      // }
      filtergruppe = null;

      // Filter sammeln
      var alle_filter = new Object();

      // Wir klappern die Filtergruppen ab. Filtergruppen können sein: Lichtwerkzeuge, Gehäusefarbe, etc.
      $(".artikelansicht .filtergroup").each(function (index) {

        // Wir wollen uns die Filtergruppen und deren Filter im Array alle_filter merken
        var id = this.id;
        alle_filter[id] = new Array();

        // Jetzt klappern wir die aktiven Filter der F-Gruppe ab ....
        $(this).find(".artikelfilter").filter(".aktiv").each(function (index) {

          //.. und speichern die Werte im assoziativen Array unter dem Key der Filtergruppe
          alle_filter[id].push($(this).data("filter"));
        });

        // und wir klappern die aktiven Filter Slider ab
        $(this).find('[data-range-slider]:not([data-filter=""])').each(function (index) {
          alle_filter[id].push($(this).attr('data-filter'));

        });

        //und wir klappern alle MinMax Slider ab
        $(this).find('[data-min-max-slider][data-filter-value]').each(function (index) {
          let filterValues = this.filterSlider.getSelectedFilters();

          for (var key in filterValues) {
            alle_filter[id].push(key);
          }
        });
      });

      // Jetzt wird es kompliziert:
      // wir bauen ein filter_array. Jeder Eintrag entspricht einer F-Gruppe und enthalten sind die aktiven Filter der Gruppe
      // als "OR" Verknüpfung, denn gesetzte Filter einer Gruppe sollen die Ergebnismenge vergrößern, z.B: Zeige alle Artikel mit der Verteilung Spot oder Narrow Spot
      // Außerdem geben wir der URL die Parameter mit, damit die gefilterte Artikelübersicht verschickt werden kann
      var filter_array = new Array();
      var filter_arrayJson = new Array();
      var url_params_filter = new Array();

      //Filterwerte die an die URL des Browsers übergeben werden
      var url_browser_params_filter = new Array();

      jQuery.each(alle_filter, function (index, ele) {
        if (ele.length > 0) {
          filter_array.push("that.filter('." + ele.join(", .") + "')");
          filter_arrayJson.push(ele.join(", ."));

          // wir geben der URL die gesetzen Filter mit
          url_params_filter.push(ele);

          // Aktuelle Filtergruppe holen
          index.match(/(.*)_(.*)/);
          let filterGroup = RegExp.$1;

          // Wird die aktuelle Filtergruppe über einen Slider ausgewählt und befinden sich innerhalb der ausgewählten Range mindestens zwei Filter?
          if (artikelseite.data.filterSliderGroups.indexOf(filterGroup) >= 0 && ele.length > 1) {

            //Dann obere und untere Grenze zusammenbauen
            let eleRange = ele[0] + ':to:' +  ele[ele.length - 1];
            url_browser_params_filter.push(eleRange);
          } else {
            //sonst alle Werte der Filtergruppe merken
            url_browser_params_filter.push(ele);
          }
        }
      });

      // Filter fuer Excel Export merken
      artikelfilter.filter_fuer_excel = url_params_filter;


      // URL ergänzen
      var loc = location.href;
      var new_url = loc;
      if (loc.match(/(.*?)\?/)) {
        new_url = RegExp.$1;
      }

      // Query Params holen ...
      var params = {};

      params = get_params(location.href);

      let urlBrowserParams = null;
      urlBrowserParams = url_browser_params_filter.join(";");

      //reset filters
      delete params["filter"];

      //if filters have been set
      if (urlBrowserParams) {
        params["filter"] = urlBrowserParams;
      }

      // neuen Query String bauen, URL erzeugen und anheften
      var qs = $.param(params);

      //If there is a query string, than append it
      if (qs) {
        new_url = new_url + "?" + decodeURIComponent(qs);
      }

      // leider müssen wir URL Fragments entfernen ...
      new_url = new_url.replace(/#.*?\?/, "?");

      // … und Kommatas durch Semikolon ersetzen
      new_url = new_url.replace(/,/, ";");

      //history.pushState('','',new_url);
      history.replaceState('', '', new_url);

      // Jetzt klappern wir die einzelnen Artikel ab.
      var artikeldaten = artikelfilter.eigenschaftsklassen;

      // Erstmal alle Artikelzeilen ausblenden, wenn die Artikelliste paginiert wird
      // und Artikeldaten übergeben wurden. Diese werden in der View der Artikelliste erzeugt.
      if (!artikelseite.data.paginationMode && artikeldaten) {
        $(".artikelansicht .article_row").hide();
      }

      for (var index in artikeldaten) {

        // Wir merken uns das Objekt und initialisieren die Checksumme
        var that = artikeldaten[index];

        var checksum = 0;


        // Jetzt prüfen wir für jede Filtergruppe mit gesetzten Filtern, ob der Artikel diese Eigenschaften erfüllt
        jQuery.each(filter_arrayJson, function (index, ele) {
          var eleOr = ele.replace(/\, \./g, "|", ele);
          var pattern = " " + eleOr + " ";
          var match = that.match(pattern);
          if (match) {
            checksum++;
          }
        });


        // Wenn der Artikel mindestens eine Eigenschaftklassen aus jeder aktivierten Filtergruppe hat, wird er angezeigt und wir merken und die Eigenschaftklassen des Artikels für später
        if (checksum == filter_array.length) {
          jQuery.each(that.split(/ /), function (index, ele) {

            ele.match(/(.*)_(.*)/);

            var fg = RegExp.$1;
            var f = RegExp.$2;

            if (f.match(/[0-9a-zA-Z]/)) {
              moegliche_filter[ele] = true;
            }
          });

          if (!artikelseite.data.paginationMode) {
            $("#" + index).show();
          }
        }
      }

      //Filterwerte aus den Objekten ziehen
      var filterValues = Object.keys(moegliche_filter);

      //Alle Filter Schaltflächen durchgehen
      $(".artikelansicht .artikelfilter").each(function (index) {

        //Filter noch verfügbar oder Filter auf Anfrage
        if (jQuery.inArray($(this).data("filter") , filterValues) >= 0 || this.getAttribute('data-filter').match(/_auf\-anfrage\-/)) {
          $(this).removeClass("na");
        } else {
          $(this).addClass("na");
        }
      });

      //Hier werden die Werte gespeichert, die über Slider gefiltert werden können.
      //Basis ist die gefilterte Artikelmenge, exklusive der Filter, die über Slider ausgewählt wurden
      var moeglicheSliderFilter = new Object();

      //Gefilterte Artikeldaten, bei denen nicht über Slider gefilter Artikeldaten berücksichtigt werden
      var artikeldatenOhneFilterSlider = null;

      //Filtergruppen, die über Slider dargestellt werden
      var sliderGroups = artikelseite.data.filterSliderGroups;

      for (var i = 0; i < sliderGroups.length; i++) {
        var sliderGroup = sliderGroups[i];

        moeglicheSliderFilter[sliderGroup] = new Object();

        //Eigenschaften zum Slider holen
        //Wurden Filter gesetzt, dann holen wir die Eigenschaften der Slider ansonsten holen wir alle Eigenschaften
        artikeldatenOhneFilterSlider = (artikelfilter.eigenschaftenklassenSlider[sliderGroup]) ? artikelfilter.eigenschaftenklassenSlider[sliderGroup] : artikelfilter.eigenschaftsklassen;

        //Iteration über Artikeldaten
        for (var index in artikeldatenOhneFilterSlider) {

          var artikeldaten = artikeldatenOhneFilterSlider[index];

          //Filterwerte splitten
          var values = artikeldaten.split(/ /);

          for (var k = 0; k < values.length; k++) {
            var value = values[k];

            //Wird der Filterwert über einen Slider gefiltert?
            if (value.indexOf(sliderGroup) >= 0) {

              //dann merken wir uns den Wert
              moeglicheSliderFilter[sliderGroup][value] = true;
            }
          }
        }
      }

      //Alle Filterslider selektieren
      var filterSliderElements = document.querySelectorAll('.artikelansicht [data-range-slider], .artikelansicht [data-min-max-slider]');

      //Alle Filter Slider durchgegen
      for (let i = 0; i < filterSliderElements.length; i++) {
        var filterSliderElement = filterSliderElements[i];
        var filterGroup = filterSliderElement.getAttribute('data-filter-group');

        //Wenn der Filter durch einen Slider ausgewählt wurde, dann sollen die Werte von diesem Slider nicht aktualisiet werden
        //es sei denn, der Filter ist inaktiv. Dies ist beispielsweise dann der Fall, wenn der Slider über die Filtertags zurückgesetzt wurde.
        if (filterGroup == this_filter.attr('data-filter-group')) {
          continue;
        }

        //Filterwerte aus Objekten ziehen
        filterValues = Object.keys(moeglicheSliderFilter[filterGroup]);

        //Alle Filterwerte holen
        var allFilterValues = filterSliderElement.filterSlider.getAllFilterValues();

        //Alle Filter durchgehen
        for (var j = 0; j < allFilterValues.length; j++) {
          var filterValue = allFilterValues[j];

          //Muss Filter Value aktiviert werden?
          if (filterValues.indexOf(filterValue) >= 0) {

            //Dann Filter Value aktivieren
            filterSliderElement.filterSlider.activateValue(filterValue);
          } else {
            //sonst Filter Value deaktiveren
            filterSliderElement.filterSlider.deactivateValue(filterValue);
          }
        }

        filterSliderElement.filterSlider.setupSliderValues();
      }

      // Miniscolling, damit die Bilder der Artikelübersichten nachgeladen werden, stichwort: lazyload
      //$('html, body').animate({ scrollTop: ($(window).scrollTop()-1)}, 'fast');
      artikelfilter.func.count();
    }
  }
}());

/* !filterTagList */
/* cn: Was macht die Funktion und warum? */
var filterTagList = (function () {
  var exports = {};
  var tagListElement = null;
  var filterGroups = null;
  var textsHasTags = null;
  var textsHasNoTags = null;

  var init = function () {
    tagListElement = document.querySelector('[data-tag-list]');
    filterGroups = document.querySelector('[data-filter-groups]');
    textsHasTags = document.querySelectorAll('[data-has-tags="true"]');
    textsHasNoTags = document.querySelectorAll('[data-has-tags="false"]');
  }

  var addTag = function (tagValue, event) {

    let triggerEvent = (typeof event !== 'undefined') ? event : "deactivatefilter";

    //Ich glaube das wird nicht mehr benötigt - vs20181115
    //isSliderValue = (typeof isSliderValue !== 'undefined') ?  isSliderValue : false;
    var element = document.createElement('li');
    var span = document.createElement('span');
    element.setAttribute('data-filter-value', tagValue)
    if (triggerEvent) {

      //Event zuordnen, welches getriggert wird, wenn Tag entfernt wird
      element.setAttribute('data-trigger-event', triggerEvent);
    }

    span.className = 'icon-delete-tag';

    if (media.device.refreshValue() === media.device.MOBILE) {
      element.addEventListener('touchstart', handleRemoveTag);
    } else {
      element.addEventListener('click', handleRemoveTag);
    }

    element.innerHTML = tagValue;
    element.appendChild(span);
    element.classList.add('tag');

    if (tagListElement) {
      tagListElement.appendChild(element);

      if (tagListElement.children.length <= 1) {
        hideElements(textsHasNoTags);
        showElements(textsHasTags);
      }
    }
  };


  var handleRemoveTag = function (e) {
    let tagValue = e.currentTarget.getAttribute('data-filter-value');
    let triggerEvent = e.currentTarget.getAttribute('data-trigger-event');
    let selector = '[data-filter-value="' + tagValue + '"]';

    //Event auf den Filtern triggern
    window.toolkit.triggerEvent(filterGroups.querySelector(selector), triggerEvent);
  }

  var removeTag = function (value) {
    var selector = '[data-filter-value="' + value + '"]';

    if (tagListElement) {
      tagElement = tagListElement.querySelector(selector);
      if (tagElement) {
        tagListElement.removeChild(tagElement);
      }
      if (tagListElement.children.length == 0) {
        showElements(textsHasNoTags);
        hideElements(textsHasTags);
      }
    }
  }

  var showElements = function (elements) {
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('hidden');
    }
  }

  var hideElements = function (elements) {
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add('hidden');
    }
  }

  exports.addTag = addTag;
  exports.init = init;
  exports.removeTag = removeTag;

  return exports;
})();


/* !initArticleList */
/* cn: Was macht die Funktion und warum? */
var initArticleList = function () {

  // Ajax Request? Dann steht jQuery schon zur Verfügung
  if (typeof jQuery != 'undefined') {
    // idrop.func.init();

    // für Produktseiten
    if ($("#articledata").length > 0 && $("[data-noload='true']").length == 0) {

      //Artikelliste nachladen //vs 20180228
      load_articles("famPage");
    }
  } else {

    // onload_functions.push(history_js.init); // pe 20210521 deprecated
    onload_functions.push("artikelseite.func.initFootable()");
  }
};

/* !get_artikeldata */
var get_artikeldata = new Object();
let firstRequest = true;
get_artikeldata.data = {};
get_artikeldata.func = (function () {
  return {
    /* !get_artikeldata.func.init */
    init: function (url, target, callback, filter, filtergruppe, filter_id) {

      // Wir merken uns die URL, damit nicht doppelt geladen wird
      if (url == artikelseite.data.lastUrl) {
        return false;
      }
      artikelseite.data.lastUrl = url;

      $(target).html('<div class="container p-b-1"><div class="row wrap"><div class="col-lg-12"><img src="/struktur/img/ajax_loader.gif" alt="please wait while loading" /></div></div></div>');
      $.get(url, function (data) {


        $(target).html(data);

        $(target + " [data-articles]").addClass('trans0');
        setTimeout(function () {
          $(target + " [data-articles]").addClass('trans');
        }, 0);


      }).done(
        function () {

          setTimeout(function () {
            $(target + " [data-articles]").removeClass('trans0');
          }, 500);

          setTimeout(function () {
            var hasfilter = window.toolkit.getQueryParameter('filter', window.location.href);
            var articleID = window.toolkit.getQueryParameter('articles', window.location.href);
            var hashValue = window.toolkit.getHashValue(window.location.href);

            // Wurden Filter via Query Parameter übergeben
            // und ist es der erste Request? Weitere Requests entstehen, wenn mit den Artikelfiltern interagiert wird
            // in dem Fall soll nicht zur Artikelliste gescrollt werden
            if (hasfilter && firstRequest) {

              //Dann wird zur Artikelliste gescrollt
              holy.scrollTo("#articles", undefined, 130);
              firstRequest = false;
            }

            // oder wurde ein Anchor (hash value) übergeben?
            else if (hashValue) {
              // dann wird dahin gescrollt
              holy.scrollTo("#" + hashValue);
            }

            //If there is an article number in the query.
            else if (articleID) {

              //than scroll to the article
              holy.scrollTo(`[data-collectable-id="${articleID}"]`);
            }
          }, 1500)
          toggleSwitch.init();

          //Mustache Templates (in Filter Tooltips)
//TODO - nach Optimierung von Range Slider wieder einkommentieren!
globals.renderMustacheTemplates();

          if (config_data.collectable == true) {
            if ($("[data-myerco-status=inside]").length <= 0) {
              Collector.injectUI(); //myerco.js
            }
          }

          if (callback) {
            callback(filter, filtergruppe, filter_id);
          }
          artikelfilter.func.manage_filtertags();

          // kt 20192404
          // trigger GA Tracking (Articlefilter Helper)
          if(typeof dataLayer === 'object' && dataLayer !== null && typeof gtmArticleFilter === 'undefined'){
            dataLayer.push({'event':'start_articlefilter'});
          }
        }
      );
    }
  }
}());


/* !editURL Helper */
/* neue Funktion in 2018 ob der paginierten Listen muss die URL auch dafür angpasst werden */
var edit_urlHelper = new Object();
edit_urlHelper.data = {};
edit_urlHelper.func = (function () {

  return {

    /* !edit_urlHelper.func.getUrl */
    // URL holen
    getUrl: function () {

      var data = {};

      // URL ergänzen
      data.loc = location.href;
      data.url = data.loc;
      if (data.loc.match(/(.*?)\?/)) {
        data.url = RegExp.$1;
      }

      return data;
    },

    /* !edit_urlHelper.func.cleanupArticles */
    // Artikeldaten aus der URL ziehen
    cleanupArticles: function (params) {

      // URL Fragment entfernen
      if (params["articles"]) params["articles"] = params["articles"].replace(/#.*/, "");

      // Artikelnummern säubern
      if (params["articles"]) params["articles"] = params["articles"].replace(/[^0-9\.;]/, "");

      // bereits übergebene Artnos ausgelesen
      var articles = new Array();
      if (params["articles"]) {
        articles = params["articles"].split(/[;|,]/);
      }

      return params;
    },

    /* !edit_urlHelper.func.createQS */
    // neuen Query String bauen, URL erzeugen und anheften
    createQS: function (new_url, params) {

      var qs = $.param(params);
      new_url = new_url + "?" + decodeURIComponent(qs);

      // leider müssen wir URL Fragments entfernen
      new_url = new_url.replace(/#.*?\?/, "?");

      return new_url;
    }
  }

}());


/* !Artikelliste */
/* neue Funktion in 2018 ob der paginierten Listen müssen der Ablauf etwas verändert werden */
var artikelliste = new Object();
artikelliste.data = {};
artikelliste.func = (function () {

  return {

    /* !artikelliste.func.init */
    init: function () {
      artikelfilter.func.init();
      artikelseite.func.init();
    }
  }

}());

var load_articles = function (context) {

  if (context) {
    artikelseite.data.context = context;
  }

  artikelfilter.ok = true;
  var ele = $("[data-fam-url]").first();
  artikelseite.data.baseUrl = ele.attr("data-fam-url");

  // Soll die Liste paginiert werden?
  artikelseite.data.paginationMode = (artikelseite.data.baseUrl.match(/\/limit\//)) ? true : false;

  // Prüfen, welche Parameter so an der URL hängen
  var params = {};
  params = get_params(location.href);


  // Hängen Filter an?
  if (params["filter"] && params["filter"].match(/[a-z]/)) {

    // URL Fragment entfernen und filterstring zerlegen
    params["filter"] = params["filter"].replace(/#.*/, "");
    var filter = params["filter"].split(/[;|,]/);

    // Filter abklappern
    jQuery.each(filter, function (i, val) {

      if (val.match(/[a-z]/)) {
        artikelfilter.urlFilter.push(val);
        // Für die paginierten Artikellisten merken wir uns die gesetzten
        // Filter und laden nachher die Seite nach ...
        // ... es sei denn, sie wurde schon nachgeladen. Darum checken wir
        // ob artikelfilter.urlFilter != loaded ist.

        // ... für unpaginierte Seiten wenden wir die Filter direkt an.
        //var $filter = $( ".filtergroups [data-filter="+val+"]" );
        //$filter.trigger( "click" );
      }
    });
  }


  // Hängt eine Seitenzahl an der URL?=
  if (params["p"]) {
    artikelseite.data.paginationOffset = params["p"];
  }

  // Hängen Sortierungsparams an der URL?
  if (params["orderby"]) {
    artikelseite.data.orderby = params["orderby"];
  }

  // Hängt ein Artikel an der URL, z.B. wegen Shortlink http://www.erco.com/de/72375.000
  if (params["articles"]) {
    artikelseite.data.article = (params["articles"].split(/;/))[0];
  }

  if (params["orderDirection"]) {
    artikelseite.data.orderDirection = params["orderDirection"];
  }

  // … holen die Artikelliste MIT gesetzten Filtern und Seite
  var url = artikelfilter.func.generate_url(artikelseite.data.baseUrl, false, artikelfilter.urlFilter);

  // console.log("try to get:"  + url);
  get_artikeldata.func.init(url, "#articledata");

  artikelfilter.urlFilter = "loaded";

  // Nach dem ersten Laden brauchen wir die Filter nicht mehr
  artikelseite.data.layout = "list-only";

  artikelseite.data.loadState = "initialized";


};


/**
 * Erzeugt einen Filter Slider mit zwei Stellteilen zur Festlegung einer unteren und oberen Grenze
 * @param  {object} element Element aus dem der Filter Slider erzeugt werden soll
 */
function FilterSliderMinMax(element) {

  let self = this;
  element.filterSlider = this;

  /**
   * Gibt die Filtergruppe zurück
   */
  this.getFilterGroup = function () {
    return element.getAttribute('data-filter');
  }

  this.setCurrentValuesByValues = function (values) {
    store.fireChangeValueEvent = false;

    //Eventhandler anhaengen, damit handleSliderChange ausgefuehrt wird
    element.noUiSlider.on('set', handleSliderChange);

    element.noUiSlider.set([values[0], values[1]]);
  }


  /**
   * Gibt die Filter innerhalb der ausgewählten Range zurück
   */
  this.getSelectedFilters = function () {
    return store.selectedFilterData;
  };

  this.getAllFilterValues = function () {
    return store.filters;
  }
  /**
   * Gibt die URL encodierte Range zurück
   */
  this.getURLEncodedRange = function () {

    let values = element.noUiSlider.get();
    let ret = `${self.getFilterGroup()}_${values[0]}:to:${self.getFilterGroup()}_${values[1]}`;
    ret = ret.split(' ').join('-');
    return ret;
  }

  this.deactivateValue = function (filter) {

    let currentIndex = store.filters.indexOf(filter);
    if (currentIndex < 0) {
      return false;
    }

    store.filterStatis[currentIndex] = false;
  }

  this.deactivateSlider = function () {
    element.removeAttribute('data-filter-value');
    store.sliderActivated = false;

    //Eventhandler anhaengen, damit handleSliderChange ausgefuehrt wird
    element.noUiSlider.on('set', handleSliderChange);

    //Sliderwerte setzen
    element.noUiSlider.set([store.lowestSelectableFilter.value, store.uppermostSelectableFilter.value]);
  }


  this.activateValue = function (filter) {

    let currentIndex = store.filters.indexOf(filter);
    let currentFilterValue = store.filterValues[currentIndex];

    //if (currentIndex < 0 || currentFilterValue < store.selectedLowerFilterValue || currentFilterValue > store.selectedUpperFilterValue) {
    if (currentIndex < 0) {
      return false;
    }

    store.filterStatis[currentIndex] = true;
  }

  this.setupSliderValues  = function() {
    setupSliderValues();
  }

  // private variables
  var store = {};

  //JSON aus data Attribut von Slider auslesen
  let dataJSON = element.getAttribute('data-min-max-slider');
  if (dataJSON) {
    dataJSON = JSON.parse(dataJSON);
  }

  //Slider Element
  store.sliderElement = element;

  //Filter Werte
  store.filterdata = dataJSON.filterdata;

  store.activeFilterdata = dataJSON.filterdata;

  //Filterwerte aggregieren
  store.filters = [];

  store.filterValues = [];

  //Filter die aktiv sind und selektiert sind
  store.selectedFilterData = [];

  store.filterStatis = [];

  store.sliderActivated = true;
  store.fireChangeValueEvent = true;

  //Anzahl der Werte
  store.numberOfValues = dataJSON.filterdata.length;

  for (let i = 0; i < store.filterdata.length; i++) {
    let value = store.filterdata[i];
    store.filters.push(value.filter);
    store.filterValues.push(value.value);
    store.filterStatis.push(true);
    store.selectedFilterData[value.filter] = value;
  }

  store.lowestSelectableFilter = store.filterdata[0];
  store.uppermostSelectableFilter = store.filterdata[store.filterdata.length - 1];

  store.firstFilterLabel = store.filterdata[0].label;
  store.lastFilterLabel = store.filterdata[store.filterdata.length - 1].label;

  store.selectedLowerFilterValue = store.filterdata[0].value;
  store.selectedUpperFilterValue = store.filterdata[store.filterdata.length - 1].value;

  store.minValue = store.filterdata[0].value;
  store.maxValue = store.filterdata[store.filterdata.length - 1].value;

  let filtergroup = element.getAttribute('data-filter-group');

  store.labelLowerValue = document.querySelector(`[data-label-container=${filtergroup}] [data-label-lower-value]`);
  store.labelRangeValue = document.querySelector(`[data-label-container=${filtergroup}] [data-label-range-value]`);
  store.labelUpperValue = document.querySelector(`[data-label-container=${filtergroup}] [data-label-upper-value]`);

  let dec = Number.isInteger(store.minValue) ? 0 : 1;

  let wnumbConfig = {
    decimals: dec
  };

  wnumbConfig.suffix = dataJSON.unit;

  let percentStep = 100 / (store.numberOfValues - 1);
  let ranges = {};
  let percentValue = percentStep;
  let positionPips = [];

  //Event wird gefeuert, wenn der Slider nach Bewegen eingerastet ist. D.h. 1 Sekunde nicht mehr bewegt wurde.
  const changeValueEvent = new CustomEvent('changeMinMaxValue');

  //Event wird gefeurt, wenn der Slider außerhalb des verfügbaren Filter bewegt wird
  const outOfRangeEvent = new CustomEvent('outOfRange');

  ranges["min"] = [store.filterdata[0].value];

  positionPips.push(0);
  positionPips.push(window.toolkit.round(percentValue));
  for (let i = 1; i < store.numberOfValues - 1; i++) {
    //percentValue = Math.round(percentValue);
    ranges[percentValue + "%"] = [store.filterdata[i].value];
    percentValue += percentStep;
    positionPips.push(percentValue);
  }

  ranges["max"] = [store.filterdata[store.numberOfValues - 1].value];

  noUiSlider.create(element, {
    connect: true,
    format: wNumb(wnumbConfig),
    start: [store.filterdata[0].value, store.filterdata[store.numberOfValues - 1].value],
    range: ranges,
    tooltips: true,
    pips: {
      mode: 'positions',
      values: positionPips,
      density: 10000
    }
  });

  element.noUiSlider.on('change', handleSliderChange);
  element.noUiSlider.on('update', handleSliderUpdate);

  //Labels, welches die Werte anzeigen füllen.
  store.labelLowerValue.innerHTML = store.firstFilterLabel;
  store.labelUpperValue.innerHTML = store.lastFilterLabel;
  store.labelRangeValue.innerHTML = `${store.firstFilterLabel} - ${store.lastFilterLabel}`

  function getFilterLabelValue() {
    let values = element.noUiSlider.get();
    let ret = values[0];
    if (values[0] !== values[1]) {
      ret += ` - ${values[1]}`;
    }
    return ret;
    //return store.selectedFilterData[0].label + " - " +  store.selectedFilterData[store.selectedFilterData.length-1].label;
  }


  function getLowerFilterIndexByValue(value, roundOff) {
    let i = null;
    if(roundOff) {
      i = store.activeFilterdata.length-1;
      while (value < store.activeFilterdata[i].value && i >0 ) {
        i--;
      }
    }
    else  {
      i = 0;
      while (value > store.activeFilterdata[i].value && i < store.activeFilterdata.length-1) {
        i++;
      }

    }
    return i;
  }

  function getUpperFilterIndexByValue(value, roundUp) {
    let i = null;
    if(roundUp) {
      i = 0;
      while (value > store.activeFilterdata[i].value && i < store.activeFilterdata.length-1) {
        i++;
      }

    }
    else {
      i = store.activeFilterdata.length-1;
      while (value < store.activeFilterdata[i].value && i >0) {
        i--;
      }
    }
    return i;
  }


  function getFilterIndexByValue(value) {
    return store.activeFilterdata.findIndex(element => element.value === value)
  }

  function getFilterstatusByValue(value) {
    let index = store.filterdata.findIndex(element => element.value === value);
    return store.filterStatis[index];
  }

  function setupSliderValues() {

    let values = [];
    let filterData = [];

    store.selectedFilterData = [];

    //Alle Filter durchgen
    for (let i = 0; i < store.filterdata.length; i++) {
      const filter = store.filterdata[i];
      //Liegt der Filterwert innerhalb der Range, die über den Slider ausgewählt wurde?
      if (filter.value >= store.selectedLowerFilterValue && filter.value <= store.selectedUpperFilterValue) {


        //dann Filter als „selekiert” merken
        store.selectedFilterData[filter.filter] = filter;
      }
    }

    let lowerIndex = 0;
    while (store.filterStatis[lowerIndex] === false) {
      let data = store.filterdata[lowerIndex];
      filterData.push(data);
      lowerIndex++;
    }

    let upperIndex = store.numberOfValues - 1;
    while (store.filterStatis[upperIndex] === false) {
      let data = store.filterdata[upperIndex];
      filterData.push(data);
      upperIndex--;
    }

    for (let i = lowerIndex; i <= upperIndex; i++) {
      if (store.filterStatis[i] === true) {
        let data = store.filterdata[i];
        filterData.push(data);
      }
    }

    store.lowestSelectableFilter = store.filterdata[lowerIndex];
    store.uppermostSelectableFilter = store.filterdata[upperIndex];

    let lowerValue = store.filterdata[lowerIndex].value;
    let upperValue = store.filterdata[upperIndex].value;


    if (element.getAttribute('data-filter-value')) {
      lowerValue = (lowerValue < store.selectedLowerFilterValue) ? store.selectedLowerFilterValue : lowerValue;
      upperValue = (upperValue > store.selectedUpperFilterValue) ? store.selectedUpperFilterValue : upperValue;
    }

    //Filterdaten aufsteigen nach Value sortieren
    filterData.sort((a, b) => {
      return a.value - b.value;
    });

    for (let i = 0; i < filterData.length; i++) {
      const filter = filterData[i];
      values.push(filter.value);

    }

    store.activeFilterdata = filterData;

    let percentStep = 100 / (values.length - 1);
    let ranges = {};
    let percentValue = percentStep;

    ranges["min"] = [values[0]];

    for (let i = 1; i < values.length - 1; i++) {
      //percentValue = Math.round(percentValue)
      ranges[percentValue + "%"] = [values[i]];
      percentValue += percentStep;
    }

    ranges["max"] = [values[values.length - 1]];
    element.noUiSlider.updateOptions({
      range: ranges
    });

    element.noUiSlider.set([lowerValue, upperValue]);

    store.labelRangeValue.innerHTML = getFilterLabelValue();
  }

  function handleSliderUpdate() {
    store.labelRangeValue.innerHTML = getFilterLabelValue();
  }

  function handleSliderChange(values, handle, unencoded, tap, positions) {

    element.noUiSlider.off('set', handleSliderChange);

    let lowerValue = unencoded[0];
    let upperValue = unencoded[1];

    store.selectedFilterData = [];
    let lowerIndex = null;
    let upperIndex = null;

    //Unters Stellteil wurde nach links bewegt
    if (store.selectedLowerFilterValue >= unencoded[0]) {
      lowerIndex = getLowerFilterIndexByValue(lowerValue, true);

    }

    //Unteres Stellteil wurde nach rechts bewegt
    else if (store.selectedLowerFilterValue < unencoded[0]) {
      lowerIndex = getLowerFilterIndexByValue(lowerValue, false);
    }

    //Oberes Stellteil wurde nach rechts bewegt
    if (store.selectedUpperFilterValue <= unencoded[1]) {
      upperIndex = getUpperFilterIndexByValue(upperValue, true);
    }

    //Oberes Stellteil wurde nach links bewegt
    else if (store.selectedUpperFilterValue > unencoded[1]) {
      upperIndex = getUpperFilterIndexByValue(upperValue, false);
    }

    element.noUiSlider.set([store.activeFilterdata[lowerIndex].label, store.activeFilterdata[upperIndex].label]);

    store.selectedLowerFilterValue = store.activeFilterdata[lowerIndex].value;
    store.selectedUpperFilterValue = store.activeFilterdata[upperIndex].value;

    //Wurden die Slider auf den ersten und letzten Wert verschoben?
    if (store.firstFilterLabel === store.activeFilterdata[lowerIndex].label && store.lastFilterLabel === store.activeFilterdata[upperIndex].value) {

      //dann wurde keine Range ausgewählt
      element.removeAttribute('data-filter-value');

    } else {

      //Slider aktiviert?
      if (store.sliderActivated) {
        element.setAttribute('data-filter-value', getFilterLabelValue());

      }

      //Selektierte Werte zwischen unterer und oberer Grenze
      for (let i = lowerIndex; i <= upperIndex; i++) {
        store.selectedFilterData[store.activeFilterdata[i].filter] = store.activeFilterdata[i];
      }
    }

    store.sliderActivated = true;

    //Wenn einer der Slider auf ein Filter außerhalb des aktiven Wertebereichs gezogen wird.
    //Also Filter, die für die aktuelle Artikelliste nicht zur Verfügung stehen
    if (getFilterstatusByValue(lowerValue) === false || getFilterstatusByValue(upperValue) === false) {
      store.sliderElement.dispatchEvent(outOfRangeEvent);
    } else if (store.fireChangeValueEvent) {
      store.sliderElement.dispatchEvent(changeValueEvent);
    } else {
      store.fireChangeValueEvent = true;
    }
  }
};

/**
 * Erzeugt einen Filter Slider
 * @param  {object} element Element aus dem der Filter Slider erzeugt werden soll
 */
function FilterSlider(element) {

  var self = this;
  //Setzt die untere Grenze des Slider an der Stelle des übergebenen Wertes
  this.setLowerLimitByValue = function (value) {
    var index = store.activeLabelValues.indexOf(value);
    if (index < 0) {
      return false;
    }
    setLowerLimit(index);
    return true;
  };
  //Setzt die obere Grenze des Slider an der Stelle des übergebenen Wertes
  this.setUpperLimitByValue = function (value) {
    var index = store.activeLabelValues.indexOf(value);
    if (index < 0) {
      return false;
    }
    setUpperLimit(index);
    return true;
  };
  this.setCurrentValueByValue = function (value) {
    var index = store.activeFilterValues.indexOf(value);
    if (index < 0) {
      return false;

    }
    store.currentIndex = index;
    setCurrentValueByIndex(index, true);
  };

  this.getAllFilterValues = function () {
    return store.allFilterValues;
  }

  //Gibt alle Filter Values zurück die deaktiviert sind
  this.getDeactiveFilterValues = function () {
    return store.allFilterValues.filter(function (element) {
      return (store.activeFilterValues.indexOf(element) < 0);
    });
  };
  //Deaktiviert den Slider an der Stelle des übergebenen Wertes
  this.deactivateValue = function (value) {
    return repaintSlider(value, false);
  };
  //Activiert den Slider an der Stelle des übergebenen Wertes
  this.activateValue = function (value) {
    return repaintSlider(value, true);
  };
  this.getLastLabelValue = function () {
    return store.lastLabelValue;
  };
  this.getCurrentLabelValue = function () {
    return store.currentLabelValue;
  };
  this.getLastFilterValue = function () {
    return store.lastFilterValue;
  };
  this.getCurrentFilterValue = function () {
    return store.currentFilterValue;
  };

  this.isSliderActive = function () {
    return store.sliderElement.classList.contains('active');
  }

  this.deactivateSlider = function () {
    //Slider mittig positionieren
    //setCurrentValueByIndex(Math.floor(getNumberOfSteps() / 2), true);

    //Label ausblenden
    store.activeLabels[store.currentIndex].classList.remove('-selected');

    //active Klasse von Slider Element entfernen
    store.sliderElement.classList.remove('active');

    //max Attribut setzen
    setMaxAttributeValue(false);

    //Werte alle zurücksetzen
    store.currentLabelValue = '';
    store.currentFilterValue = '';
    store.sliderElement.setAttribute('data-filter', '');
    store.sliderElement.setAttribute('data-filter-value', '');
    store.lastLabelValue = store.activeLabelValues[store.lastSettledIndex];
    store.lastFilterValue = store.activeFilterValues[store.lastSettledIndex];
    store.lastSettledIndex = store.currentIndex;

    //Aktuelle Position des Sliders merken
    store.currentIndex = Math.floor(getNumberOfSteps() / 2);
    store.sliderElement.value = store.currentIndex;

    //Custom Event feuern
    var deactivateSliderEvent = new CustomEvent("deactivateslider");
    store.sliderElement.dispatchEvent(deactivateSliderEvent);
  }

  //Wurde ein Input Range Element übergeben?
  if (!(element instanceof Object) || element.tagName !== 'INPUT' || element.type !== 'range') {
    throw new TypeError('element has the wrong type');
    return false;
  }

  //JSON aus data Attribut von Slider auslesen
  var dataJSON = element.getAttribute('data-range-slider');
  if (dataJSON) {
    dataJSON = JSON.parse(dataJSON);
  }

  element.filterSlider = this;

  //Min und Max für Slider setzen
  element.min = 0;
  element.max = (dataJSON.length) ? dataJSON.length - 1 : 10;

  // private variables
  var store = {};
  store.slideTimer;

  //Container (ul-Element) in dem sich alle Labels befinden
  store.labelContainer = null;

  //alle Labels
  store.allLabels = [];

  //Labels von den Elementen, die active sind
  store.activeLabels = [];

  //Alle Werte
  store.allLabelValues = [];

  //Werte von den Elementen, die active sind
  store.activeLabelValues = [];

  //Alle Filterwerte
  store.allFilterValues = [];

  //Filterwerte von den Elementen die active sind
  store.activeFilterValues = [];

  //Wert der mit dem Slider ausgewählt ist
  store.currentLabelValue = 'initial';

  //Wert der als Letztes mit dem Slider ausgewählt wurde
  store.lastLabelValue = null;

  //Filter der mit dem Slider ausgewählt ist
  store.currentFilterValue = 'initial';

  //Filter der als Letztes mit dem Slider ausgewählt wurde
  store.lastFilterValue = null;

  //Index des Wertes der ausgewählt ist
  store.currentIndex = 0;

  //Werd den der Slier zulezt im Ruhezustand angenommen hat
  store.lastSettledIndex = 0;

  //Slider Element
  store.sliderElement = element;

  //Element in das die Styles generiert werden
  store.styleElement = null;

  //untere Grenze
  store.lowerLimit = null;

  //obere Grenze
  store.upperLimit = null;

  //Wert der unteren Grenze
  store.lowerLimitValue = null;

  //Wert der oberen Grenze
  store.upperLimitValue = null;

  //UniqueID für das Element generieren und zuweisen
  do {
    var uniqueID = getUniqID(5);
  } while (document.querySelector('[data-slider-id="' + uniqueID + '"]'));
  store.sliderElement.setAttribute('data-slider-id', uniqueID);

  //Style Element für das Füllen des Slides erzeugen
  store.styleElement = document.createElement('style');
  store.styleElement.id = 'slider-style-' + uniqueID;
  document.head.appendChild(store.styleElement);

  //Container für Lables erzeugen
  store.labelContainer = document.createElement('ul');
  store.labelContainer.classList.add('range-labels');
  store.sliderElement.insertAfter(store.labelContainer);

  //Wurden values im data Attribute definiert und ausreichend Values übergeben?
  if (Array.isArray(dataJSON) && dataJSON.length >= getNumberOfSteps()) {
    for (var i = 0; i < dataJSON.length; i++) {
      store.activeLabelValues.push(dataJSON[i].label);
      store.allLabelValues.push(dataJSON[i].label);
      store.activeFilterValues.push(dataJSON[i].filter);
      store.allFilterValues.push(dataJSON[i].filter);
    }
  }

  //Sonst werden Standardwerte 0 bis n für values gewählt
  else {
    //Dann werden Standard values 0 bis n gewählt
    for (var i = 0; i < getNumberOfSteps(); i++) {
      store.activeLabelValues.push(i);
      store.allLabelValues.push(i);
      store.activeFilterValues.push(i);
      store.allFilterValues.push(i);
    }
  }

  //Labels erzeugen
  for (var i = 0; i < getNumberOfSteps(); i++) {

    //li Element erzeugen
    var labelElement = document.createElement('li');
    labelElement.setAttribute('data-value', store.activeLabelValues[i]);
    labelElement.innerHTML = ('<span>' + store.activeLabelValues[i] + '<i class="icon-delete-tag" data-reset-slider></i></span>');
    labelElement.classList.add('label');

    //Position berechnen
    labelElement.style.left = ((100 / (getNumberOfSteps() - 1)) * i) + '%';
    store.labelContainer.appendChild(labelElement);
    labelElement.addEventListener('click', self.deactivateSlider);
    labelElement.classList.add('-active');
    store.activeLabels.push(labelElement);
    store.allLabels.push(labelElement);
  }

  //Wurde ein Wert für die untere Grenze in data Attribut definiert?
  store.lowerLimitValue = (dataJSON.lowerLimitValue) ? dataJSON.lowerLimitValue : store.activeLabelValues[0];

  //Wurde ein Wert für die obere Grenze in data Attribut definiert?
  store.upperLimitValue = (dataJSON.upperLimitValue) ? dataJSON.upperLimitValue : store.activeLabelValues[store.activeLabelValues.length - 1];

  //Grenzen des Sliders definieren und damit wie der Track vom Slider gefüllt wird
  (dataJSON.lowerLimitValue) ? this.setLowerLimitByValue(dataJSON.lowerLimitValue): setLowerLimit(0);
  (dataJSON.upperLimitValue) ? this.setUpperLimitByValue(dataJSON.upperLimitValue): setUpperLimit(getMaxIndex());

  //Event wird gefeuert, wenn der Slider nach Bewegen eingerastet ist. D.h. 1 Sekunde nicht mehr bewegt wurde.
  var changeValueEvent = new CustomEvent("changevalue");

  //Eventlistener werden gefeuert, wenn Position des Sliders geändert wird
  element.addEventListener('input', handleOnSliderInput);
  element.addEventListener('change', handleOnSliderInput);
  element.addEventListener('mousedown', handleOnSliderInput);
  element.addEventListener('mousedown', activateSlider);


  //Slider deaktivieren
  self.deactivateSlider();

  // Event wird gefeuert, wenn Sliderposition geändert wird
  function handleOnSliderInput(e) {

    //Slider darf nicht über die obere Grenze...
    e.currentTarget.value = (Math.max(store.lowerLimit, e.currentTarget.value));

    //... und untere die Untere Grenze bewegt werden.
    e.currentTarget.value = (Math.min(store.upperLimit, e.currentTarget.value));
    var i = e.currentTarget.value;

    //Filterwert setzen.
    setCurrentValueByIndex(i);

    //Verzögern, bevor das Event gefeuert wird, dass der Slider eingerastet ist.
    if (store.slideTimer) {
      clearTimeout(store.slideTimer);
    }
    store.slideTimer = window.setTimeout(function () {

      //Filterwert setzen
      setCurrentValueByIndex(i, true);
      store.sliderElement.dispatchEvent(changeValueEvent);
    }, 1000);
  }

  function getNumberOfSteps() {
    return store.sliderElement.max - store.sliderElement.min + 1;
  }

  function getMaxIndex() {
    return store.activeLabels.length - 1;
  }

  function getMinIndex() {
    return Number(store.sliderElement.min);
  }


  /**
   * Setzt das Max Attribut für den Slider
   * @param  {boolean} forActiveSlider für aktiven oder inaktiven Slider
   */
  function setMaxAttributeValue(forActiveSlider) {
    store.sliderElement.max = store.activeLabels.length - 1;

    if (!forActiveSlider) {

      //Bei einer geraden Anzahl an Schritten
      if (getNumberOfSteps() % 2 == 0) {

        //Wird ein Schritt dazu addiert, damit Slider mittig positioniert werden kann
        store.sliderElement.max = Number(store.sliderElement.max) + 1;
      }

      //Slider mittig positionieren
      store.sliderElement.value = Math.floor(getNumberOfSteps() / 2);
    }
  }
  /**
   * De- oder aktivert den übergebenen Wert und rendert den Slider neu
   * @param  {string} value Wert der aktivert oder deaktivert werden soll
   * @param  {boolean} setToActive true: Wert soll aktivert werden; false: Wert soll deaktiviert werden
   */
  function repaintSlider(value, setToActive) {
    //Indexposition von Value holen
    var index = store.allFilterValues.indexOf(value);
    if (index < 0) {
      return false;
    }

    var labelValue = store.allLabelValues[index];

    //label zum übergebenen value selektieren
    var labelElement = store.labelContainer.querySelector('[data-value="' + labelValue + '"]');

    //Muss label eingeblendet werden?
    if (setToActive) {

      // labelElement.classList.remove('-hidden');
      labelElement.classList.add('-active');
      labelElement.classList.remove('-selected');
      labelElement.classList.remove('-hidden');
    }

    //Muss label ausgeblendet werden?
    else {
      labelElement.classList.remove('-active');
      labelElement.classList.add('-hidden');
    }

    var lower = 0;

    //Arrays löschen
    store.activeLabelValues = [];
    store.activeFilterValues = [];
    store.activeLabels = [];

    //Untere Grenze des Sliders berechnen
    while (!store.allLabels[lower].classList.contains('-active') && lower < store.allLabels.length - 1) {
      // store.allLabels[lower].classList.remove('-hidden');
      lower++;
    }

    //Obere Grenze des Sliders berechnen
    var upper = store.allLabels.length - 1;

    //Obere Grenze berechnen
    while (!store.allLabels[upper].classList.contains('-active') && upper > lower) {
      //store.allLabels[upper].classList.remove('-hidden');
      upper--;
    }

    var i = 0;

    //Werte unterhalt der unteren Grenze
    for (; i <= lower; i++) {
      label = store.allLabels[i];
      store.activeLabels.push(label);
      store.activeFilterValues.push(store.allFilterValues[i]);
      store.activeLabelValues.push(label.getAttribute('data-value'));

    }

    //Alle Werte zwischen unterer und oberer Grenze
    for (; i <= upper; i++) {
      if (store.allLabels[i].classList.contains('-active')) {
        label = store.allLabels[i];
        store.activeLabels.push(label);
        store.activeFilterValues.push(store.allFilterValues[i]);
        store.activeLabelValues.push(label.getAttribute('data-value'));
      }
    }

    //Werte oberhalb oberer Grenze
    for (; i < store.allLabels.length; i++) {
      label = store.allLabels[i];
      store.activeLabels.push(label);
      store.activeFilterValues.push(store.allFilterValues[i]);
      store.activeLabelValues.push(label.getAttribute('data-value'));

    }

    //Aktive Labels positionieren
    for (var j = 0; j < store.activeLabels.length; j++) {
      store.activeLabels[j].style.left = ((100 / (store.activeLabels.length - 1)) * j) + '%';
    }

    //Max Attribut setzen
    setMaxAttributeValue(self.isSliderActive());

    //Untere Grenze setzen
    setLowerLimit(lower);

    //Obere Grenze setzen
    setUpperLimit(upper);

    //Slider neu positionieren, da Werte hinzugekommen oder entfernt wurden
    var newIndex = store.activeLabelValues.indexOf(store.currentLabelValue);
    setCurrentValueByIndex(newIndex, true);

    return true;

  }
  /**
   * Setzt den Filterwert des Sliders
   * @param  {number} index Index von dem Filterwert der gesetzt werden soll
   * @param  {boolean} sliderSettled true: Slider eingerastet (ist der Fall, wenn der Slider eine Sekunden nicht mehr gewegt wurde)
   */
  function setCurrentValueByIndex(index, sliderSettled) {
    sliderSettled = (typeof sliderSettled !== 'undefined') ? sliderSettled : false;

    if (index < 0 || index > getMaxIndex()) {
      return;
    }

    // Slider ist eingerastet?
    if (sliderSettled) {

      //Die zuletzt ausgewählten Werte merken, bevor der Slider verschoben wurde
      store.lastLabelValue = store.activeLabelValues[store.lastSettledIndex];
      store.lastFilterValue = store.activeFilterValues[store.lastSettledIndex];
      store.lastSettledIndex = parseInt(store.currentIndex);

      //Aktuellen Werte merken
      store.currentLabelValue = store.activeLabelValues[index];
      store.currentFilterValue = store.activeFilterValues[index];
    }


    store.activeLabels[store.currentIndex].classList.remove('-selected');
    store.activeLabels[index].classList.add('-selected');

    //Aktuelle Position des Sliders merken
    store.currentIndex = parseInt(index);
    store.sliderElement.value = index;

    store.sliderElement.setAttribute('data-filter', store.activeFilterValues[store.currentIndex]);
    store.sliderElement.setAttribute('data-filter-value', store.activeLabelValues[index]);

  }

  function getUniqID(idlength) {
    var charstoformid = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
    if (!idlength) {
      idlength = Math.floor(Math.random() * charstoformid.length);
    }
    var uniqid = '';
    for (var i = 0; i < idlength; i++) {
      uniqid += charstoformid[Math.floor(Math.random() * charstoformid.length)];
    }
    return uniqid;
  }


  /**
   * Untere Grenze definieren
   * @param  {index} index
   */
  function setLowerLimit(index) {

    //Wurde ein Wert übergeben, der den min Wert unterschreitet?
    if (index < getMinIndex()) {
      index = 0;
    }
    store.lowerLimitValue = store.allLabelValues[index];
    store.lowerLimit = index;

    //Wenn Slider sich unterhalb der Grenze befindet, die neu gesetzt wird und Slider aktiv ist
    if (store.currentIndex < index && self.isSliderActive()) {

      //Dann Slider auf Grenze setzen
      self.setCurrentValueByValue(store.currentFilterValue);
      //setCurrentValueByIndex(index);
    }
    fillSliderTrack();
  }
  /**
   * Obere Grenze definieren
   * @param  {number} index
   */
  function setUpperLimit(index) {

    //Wurde ein Wert übergeben, der den max Wert überschreitet?
    if (index > getMaxIndex()) {
      index = getMaxIndex();
    }

    //Wenn Slider sich oberhalb der Grenze befindet, die neu gesetzt wird und Slider aktiv ist
    if (store.currentIndex > index && self.isSliderActive()) {

      self.setCurrentValueByValue(store.currentFilterValue);
    }

    store.upperLimitValue = store.activeLabelValues[index];
    store.upperLimit = index;
    fillSliderTrack();
  }
  /**
   * Berechnet die prozentuale horizontale Position für ein Label anhand dessen Indexwert
   * @param  {number} index Indexwert des Labels
   */
  function caculatePercentValue(index) {
    return Math.round(((index - getMinIndex()) / (getMaxIndex() - getMinIndex())) * 100, 3);
  }
  /**
   * Aktiviert den Slider
   */
  function activateSlider() {
    element.removeEventListener('mousedown', handleOnSliderInput);

    store.sliderElement.classList.add('active');
    setMaxAttributeValue(true);
    setCurrentValueByIndex(store.currentIndex);
  }
  /**
   * Setzt die Füllung für den Track vom Slider
   */
  function fillSliderTrack() {
    var lowerPercentLimit = caculatePercentValue(store.lowerLimit);
    var upperPercentLimit = caculatePercentValue(store.upperLimit);
    store.styleElement.innerHTML = 'input[data-slider-id=' + uniqueID + ']::-moz-range-track { background: -webkit-linear-gradient(left, #eaeaea 0%, #eaeaea ' + lowerPercentLimit + '% , #999999 ' + lowerPercentLimit + '%, #999999 ' + upperPercentLimit + '%, #eaeaea ' + upperPercentLimit + '%) }';
    store.styleElement.innerHTML += 'input[data-slider-id=' + uniqueID + ']::-webkit-slider-runnable-track { background: -webkit-linear-gradient(left, #eaeaea 0%, #eaeaea ' + lowerPercentLimit + '% , #999999 ' + lowerPercentLimit + '%, #999999 ' + upperPercentLimit + '%, #eaeaea ' + upperPercentLimit + '%) }';
    store.styleElement.innerHTML += 'input[data-slider-id=' + uniqueID + ']::-ms-track { background: linear-gradient(to right, #eaeaea 0%, #eaeaea ' + lowerPercentLimit + '% , #999999 ' + lowerPercentLimit + '%, #999999 ' + upperPercentLimit + '%, #eaeaea ' + upperPercentLimit + '%) }';
  }
};
