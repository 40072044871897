/**
 * Back Link Referrer
 * ==================
 * 
 * Zum Erzeugen eines Close-Button mit einem Link der auf die Referrer-Seite zeigt
 * Auf der Seite, auf der der Button angezeigt werden soll, muss ein Element mit
 * folgendem Data-Attribut existieren:
 * data-show-back-link='{"referrer": [{"url": "url-path-1"}, {"url": "url-path-2"}]}'
 * und folgendes Element:
 * <div data-container-last-page-link style="display:none;"><a href="#" data-last-page-link>TEST</a></div>
 * Des Close-Button wird dann angezeigt, wenn der Wert des Attributs 'url'
 * im Referrer enthalten ist
 *
 * Beim Klick auf auf den Closebutton, wird zurück auf die Referer-Seite gewechselt.
 * Dort kann ein Element mit folgendem Data-Attribut erstellt werden:
 * data-scroll-after-close='{"referrer": [{"url": "url-path-1"}, {"url": "url-path-2"}]}'
 * Die Seite scrollt dann zu dem Element, welches das Data-Attribut enthält, wenn der Wert des Attributs 'url'
 * im Referrer enthalten ist
 * 
 * 
 * @dependencies
 * * jQuery -> Nur für die Verwendung von jQuery-Selektore
 * * holy
 * 
 * @dependents
 * * $(document).ready(function() { ... })
 * * data attribute [data-show-back-link] -> Wird benötigt, um den Backlink zu initialisieren
 *   -> used in several modules in cms-backend
 * * data attribute [data-scroll-after-close] -> Wird benötigt, um nach dem Schließen des Backlinks zu einem Element zu scrollen
 *   -> used in several modules in pdb-api
 * 
 * @commit Initial Commit 2.0
 */
var backLinkReferrer = (function () {
  var exports = {};


  /**
   * Intialisiert einen Backlink auf den Referrer
   */
  var initBacklink = function () {
    var referringURL = document.referrer;
    var showBackLinkElement = document.querySelector('[data-show-back-link]');
    if (!showBackLinkElement || !referringURL) {
      return;
    }

    var data = JSON.parse(showBackLinkElement.getAttribute('data-show-back-link'));


    //Iteration über alle referrer im JSON-Array
    for (var i = 0; i < data.referrer.length; i++) {

      //Ist der Referrer identisch mit dem Inhalt des Array-Elements
      if (referringURL.indexOf(data.referrer[i].url) > 0) {

        //Event mit Close Button verknüpfen
        side_menu_animate.assignClose($('[data-container-last-page-link]'));

        //Close Button in Sidebar einblenden
        side_menu_animate.showClose();

        //Eventlistener fuer den Close-Button hinzufuegen
        var $lastPageLink = $('[data-last-page-link]');
        $lastPageLink.on('click', function () {

          if (data.referrer[i].scrollToAfterClosing) {

            //qurySelector auslesen, der auf das Element zeigt zu dem gescrollt werden soll
            sessionStorage.setItem('referrerURL', window.location);
          }

          window.location = referringURL;
        });
        break;
      }
    }
  };
  /**
   * Führt Scrolling zu dem Element aus, welches zuvor über Funktion initBacklink gesetzt wurde
   */
  var scrollToAfterClosing = function () {
    var storageReferrerURL = sessionStorage.getItem('referrerURL');

    var referringURL = document.referrer;
    var scrollToElements = document.querySelectorAll('[data-scroll-after-close]');

    if (!storageReferrerURL || !scrollToElements) {
      return;
    }
    for (var j = 0; j < scrollToElements.length; j++) {

      //Enthält das Attribut einen Attributwert?
      if (scrollToElements[j].getAttribute('data-scroll-after-close') === "")
        continue;

      var data = JSON.parse(scrollToElements[j].getAttribute('data-scroll-after-close'));

      for (var i = 0; i < data.referrer.length; i++) {

        // Ist der Referrer identisch mit dem Inhalt des Array-Elements

        if (storageReferrerURL.indexOf(data.referrer[i].url) > 0) {


          setTimeout(function () {
            holy.scrollTo($(scrollToElements[j]), 500);
          }, 300);

          sessionStorage.removeItem('referrerURL');
          return;

        }
      }
    }
  };

  var init = function () {
    initBacklink();
    scrollToAfterClosing();
  }

  exports.init = init;
  return exports;
})();