const eventsHandler = (function() {
  const baseUrl = confEnv.pdb_api_url + '/events';
  const lang = k3vars.S_Sprache;

  const getEvents = function (params) {
    // prepare url
    let url = baseUrl + '/list';

    // collect query parameters
    queryParams = [];
    for (const [key, value] of Object.entries(params)) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    };

    // add query parameters to url
    url += '?' + queryParams.join('&');

    // fetch url
    return fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
      },
    });
  };

  const reloadList = function (list, filtersSet, type) {
    // language param should always be used
    let params = {
      'lang': lang,
      'type': type,
    };

    // iterate over set filters to collect the query parameters
    for (let name in filtersSet) {
      let value = filtersSet[name];
      // only add filled or boolean filters
      if (typeof value === 'string' && value.length > 0 || typeof value === 'boolean') {
        params[name] = value;
      }
    }

    // get new list of events
    getEvents(params)
    .then(function (response) {
      return response.text()
    })
    .then(function (bodyText) {
      // replace old list with new one
      let listContainer = list.parentNode;
      let newList = list.cloneNode(false);
      newList.innerHTML = bodyText;
      listContainer.replaceChild(newList, list);

      // enable big checkboxes, if present
      listContainer.querySelectorAll('[data-js-check-checkboxes]').forEach((element) => {
        new CheckboxGroup(element);
      });

      // init contact form and render mustache temlates, if a form is present
      if (listContainer.querySelector('[data-submit-form]') !== null
        && listContainer.querySelector('[data-submit-form]').length > 0) {
        productoverview.initContactForms();
        globals.renderMustacheTemplates();
      }
    });
  };

  const start = function (container) {
    // collect relevant elements
    let type = container.querySelector('[data-events-list').dataset.eventsType;

    // get all filter elements
    let countryFilterSelect = document.getElementById('filter_country_select-'+type);
    let localFilterCheck = document.getElementById('filter_local_check-'+type);
    let onlineFilterCheck = document.getElementById('filter_online_check-'+type);
    let filtersSet = [];

    const filterChange = function () {
      let eventList = container.querySelector('[data-events-list');
      // only consider filter elements
      if (!this.id.includes('filter')) return;
      // check if element is checkbox
      if (this.type && this.type === 'checkbox') {
        // checkbox -> value = element.checked
        filtersSet[this.name] = this.checked;
      } else {
        // no checkbox -> value = element.value
        filtersSet[this.name] = this.value;
      }
      // reload the list of events
      reloadList(eventList, filtersSet, type);
    };

    // init set filters
    filtersSet[countryFilterSelect.name] = countryFilterSelect.value;
    filtersSet[localFilterCheck.name] = localFilterCheck.checked;
    filtersSet[onlineFilterCheck.name] = onlineFilterCheck.checked;

    // add eventlistener to filter elements
    countryFilterSelect.addEventListener('change', filterChange);
    localFilterCheck.addEventListener('change', filterChange);
    onlineFilterCheck.addEventListener('change', filterChange);
  };

  const init = function() {
    document.querySelectorAll('.events-list').forEach( function (container) {
      start(container);
    });
  };

  return {
    init
  };
})();

// Start the initialization by pushing it to the onload functions
onload_functions.push(eventsHandler.init);
