/**
 * Header-Gallery
 * 
 * @dependencies
 * * blueimp
 * * jquery
 *
 * @dependents
 * class .js-blueimp-link and data attribute [data-blueimp-link] used in
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/products_teaser_fullscreen.htm
 * * not in pdb-api
 * class .blueimp-gallery-carousel used in
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/products_teaser_fullscreen.htm
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/report_teaser_fullscreen_20171206.htm
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/report_teaser_fullscreen.htm
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/kampagne_2016/video_head copy.htm
 * * cms-backend/dist/kunden/ls2014/vorlagen/html/layout/kampagne_2016/video_head.htm
 *
 * @commit
 * Initial commit 2.0
 */
header_gallery = {
  init: function () {

    // Extxend blueimp handleClick Event
    if (!blueimp.Gallery.prototype.handleClick_prev) {

      // backup original prototype
      blueimp.Gallery.prototype.handleClick_prev = blueimp.Gallery.prototype.handleClick;

      // overwrite prototype
      blueimp.Gallery.prototype.handleClick = function (event) {

        // select slides wrapper
        var $slides = $(event.target).closest('.js-blueimp-link');
        var slidesLink = $slides.attr('data-blueimp-link');

        // set link
        if (slidesLink) {
          location.href = slidesLink;
        }

        // trigger original event
        this.handleClick_prev(event);
      }
    }



    // normale Initialisierung wie bisher
    $('.blueimp-gallery-carousel').not('[data-gallery-carousel-init]').each(function () {
      var self = $(this);

      var head_img_options = {
        container: self,
        slidesContainer: ".slides",
        carousel: true,
        stretchImages: "cover",
        urlProperty: 'src',
      }

      var imgs = self.find('.images img, .slides img')
      if (!imgs.length) return;

      var blu = blueimp.Gallery(imgs, head_img_options);
      if (blu.num > 1) { self.addClass('multi'); }
      self.attr('data-gallery-carousel-init', '');
    });
  }
}


/**
 * Header init function
 * 
 * @dependencies
 * * jquery
 * * holy
 * 
 * @dependents
 * data attribute [data-nav-container] used in
 * * cms-backend/dist/kunden/ls2014/plugins/guide.pm
 * * pdb-api/dist/struktur/php/error-docs/erco-404.php
 * 
 * @note
 * pe(2023-02-14) probably deprecated, may need a stub 
 */
header = {
  scroll: '',

  start: function () {
    header.scroll = { ele: $('[data-nav-container]') }
    header.prevent();

    header.scroll.ele.on('holy:scrollUp', header.show)
    
    header.scroll.ele.on('holy:scrollDown', header.hide)
  },

  show: function () {
    header.scroll.ele.removeClass('hideHead');
    return false // stop bubbling
  },
  hide: function () {
    header.scroll.ele.addClass('hideHead');
    return false // stop bubbling
  },
  prevent: function () {
    header.scroll.ele.on('DOMMouseScroll mousewheel', function (ev) {
      var $this = $(this),
        scrollTop = this.scrollTop,
        scrollHeight = this.scrollHeight,
        height = $this.height(),
        delta = ev.originalEvent.wheelDelta,
        up = delta > 0;

      var prevent = function () {
        ev.stopPropagation();
        ev.preventDefault();
        ev.returnValue = false;
        return false;
      }

      if (!$('[data-nav-toggle="ls-navbar"]').is(':visible'))
        if (!up && -delta > scrollHeight - height - scrollTop) {
          // Scrolling down, but this will take us past the bottom.
          $this.scrollTop(scrollHeight);
          return prevent();
        } else if (up && delta > scrollTop) {
          // Scrolling up, but this will take us past the top.
          $this.scrollTop(0);
          return prevent();
        }
    });
  }
}