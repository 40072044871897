class AnimationRepTextControl {

  /**
   * Introductory animation for the text control
   * @param {HTMLElement} rootElement with <video> and <button> to replay the video
   */
  constructor (rootElement) {
    /** @type {HTMLElement} */
    this.rootElement = rootElement;
    /** @type {HTMLVideoElement|null} */
    this.video = this.rootElement.querySelector('video');
    /** @type {HTMLButtonElement|null} */
    this.btnReplay = this.rootElement.querySelector('button');

    if (this.video && this.btnReplay) {
      this.init();
    }
  }

  init() {
    this.btnReplay.addEventListener('click', this.replay.bind(this), false);
    this.video.addEventListener('ended', this.onVideoEnded.bind(this), false);

    this.intersectionObserverOptions = { threshold: 1.0 };
    this.intersectionObserver = new IntersectionObserver((elements, observer) => {
      elements.forEach((element) => {
        if (element.intersectionRatio >= 0.95) {
          this.play();
          observer.unobserve(element.target);
        }
      });
    }, this.intersectionObserverOptions);

    this.intersectionObserver.observe(this.video);
  }

  play() {
    this.video.play();
    this.btnReplay.setAttribute('disabled', '');
  }

  replay() {
    this.video.currentTime = 0;
    this.play();
  }

  onVideoEnded() {
    this.btnReplay.removeAttribute('disabled');
  }
}