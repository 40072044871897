/**
 * Videos
 * 
 * @dependencies
 * * jquery
 * * video.js
 * * @vimeo/player
 * * /struktur/php/global/youtube_oembed_proxy.php
 * 
 * 
 * @commit Initial commit 2.0
 */



/**
 * Check if an embedded youtube video is available.
 * Remove it from the DOM if so. ^schi
 * 
 * @dependents
 * * $(document).ready() dist/struktur/js/global/erco.js
 * 
 * @note
 * neither data-attribute data-movid or function name is
 * referenced/called in lightscout/pdb-api/cms-backend
 */
function filter_unavailable_youtube_videos() {

  $('iframe').each(function (i, el) {
    // deprecated: see "Note:"
    /*var src = el.src; // iframe src (enthält youtube embed link)
    var srcurlparts = src.split("/"); // url zerlegen
    var videoID = srcurlparts[srcurlparts.length-1]; // letztes element holen und als ID verwenden*/

    // Note: since we got the movid-data-attribute in the dom, we can use this to get the video id
    var videoID = $(el).parent().attr('data-movid');
    var apiurl = "/struktur/php/global/youtube_oembed_proxy.php?yid=" + videoID;

    // request the php proxy script to see if the video is available
    $.getJSON(apiurl, function (res) {
      if (!res.success) {
        // loop all our movid-nodes and remove them from the dom
        $("*[data-movid='" + videoID + "']").each(function (i, el) {
          $(el).remove();
        });
      }
    })
  });
}



/**
 * Video.js initialisieren
 * 
 * @dependents
 * * $(document).ready() dist/struktur/js/global/erco.js
 * * several templates in cms-backend
 * 
 * @dependencies
 * * dynamicallyLoadJSFile()
 * * spezial_js_url (Konfig Objekt)
 */
video_js = {
  start: function () {
    if ($("video.video-js").length) {
      $("video.video-js").removeAttr('data-setup');
      dynamicallyLoadJSFile(spezial_js_url.videojs, video_js.callback);
    }
  },
  callback: function () {
    videojs.options.flash.swf = "//" + location.hostname + "/struktur/lib/video-js/video-js.swf";
    $("video.video-js").each(function () {
      videojs(this);
    });
  }
}



/**
 * Video Subtitles aktivieren
 *
 * Achtung: die Video-URL darf kein »api=1« beinhalten.
 * »api=1« wird nur von der früheren API benötigt.
 *
 * @dependents
 * * $(document).ready() dist/struktur/js/global/erco.js
 * * after_ajax dist/struktur/js/global/erco.js
 * 
 * @dependencies
 * * k3vars (Config Object)
 * 
 * @note
 * neither css-class .js-video-subtitles or function name is
 * referenced/called in lightscout/pdb-api/cms-backend
 */
var videosWithSubtitles = (function () {

  // local vars
  var exports = {};

  // local funcs
  var init = function () {
    var elements = $('iframe.js-video-subtitles');
    var language = k3vars.S_Sprache.replace('_us', '');

    // if players exist
    if (elements.length) $.getScript('https://player.vimeo.com/api/player.js', function () {

      // each Player
      elements.each(function () {

        // create player
        var player = new Vimeo.Player(this);

        player.getTextTracks().then(function (tracks) {
          var enableTextLanguage = (tracks.map(function (item) { return item.language; }).indexOf(language) >= 0) ? language : 'en';

          // set player text track
          player.enableTextTrack(enableTextLanguage);
        });
      });
    });
  };

  // global funcs
  exports.init = init;

  // publish
  return exports;
})();