/**
 * @typedef {Object} JwtPayload
 *
 * @property {string} iss - Issuer
 * @property {string} aud - Audience
 * @property {string} sub - Subject (users email)
 * @property {number} iat - Issued At (Unix timestamp in **seconds**)
 * @property {number} exp - Expiration Time (Unix timestamp in **seconds**)
 * @property {boolean} logged_in - Wether or not the user is logged in
 * @property {boolean} is_staff - Wether or not current user is part of staff
 * @property {string[]} groups - Groups current users belongs to
 */

/**
 * @typedef {Object} ErcoUser - User data as originally returned by original user status endpoint
 * @property {string} email - Users email
 * @property {string[]} groups - Groups current users belongs to
 * @property {boolean} is_staff - Wether or not current user is part of staff
 * @property {boolean} logged_in - Wether or not the user is logged in
 */

/**
 * Helper to get jwt tokens and handle login status.
 */
class AuthHelper {
  /** Url to get jet tokens */
  _jwtTokenUrl = `${confEnv.pdb_api_url}/myerco/auth/get_jwt_token`;

  /**
   * Promise of ongoing update method
   * @type {?Promise<void>}
   */
  _ongoingUpdate = null;

  /** Wether or not helper was initialized and thus the values can be seen as valid/usable */
  _helperInitialized = false;

  /** Current timeout for refreshing the token */
  _refreshTimeout = null;

  /**
   * Current jwt tokens data, if logged in
   * @type {?JwtPayload}
   */
  currentTokenData;

  /**
   * Current users data, if logged in
   * @returns {Promise<ErcoUser>}
   */
  get user() {
    return async function () {
      await this.verifyUserDataAvailability.bind(this)();

      return {
        email: this.currentTokenData?.sub || '',
        groups: this.currentTokenData?.groups || [],
        is_staff: !!this.currentTokenData?.is_staff,
        logged_in: !!this.currentTokenData?.logged_in,
      };
    }.bind(this)();
  }

  /**
   * Current users email adress, if logged in
   * @returns {Promise<string>}
   * */
  get email() {
    return async function () {
      await this.verifyUserDataAvailability.bind(this)();

      return this.currentTokenData?.sub;
    }.bind(this)();
  }

  /**
   * Wether or not the current user is staff
   * @returns {Promise<boolean>}
   */
  get isStaff() {
    return async function () {
      await this.verifyUserDataAvailability.bind(this)();

      return !!this.currentTokenData?.is_staff;
    }.bind(this)();
  }

  /**
   * Wether or not the current user is logged in
   * @returns {Promise<boolean>}
   */
  get isLoggedIn() {
    return async function () {
      await this.verifyUserDataAvailability.bind(this)();

      return !!this.currentTokenData?.logged_in;
    }.bind(this)();
  }

  /**
   * Verify integrity of current user data.
   * @returns {Promise<void>}
   */
  async verifyUserDataAvailability() {
    if (this._ongoingUpdate) {
      // Return ongoing update as this means, that init is going on / we have a
      // valid state after update
      return this._ongoingUpdate;
    }

    if (!this.currentTokenData && !this._helperInitialized) {
      return this.initJwtAuth.bind(this)();
    }
  }

  /**
   * Get current jwt token from cookie
   * @returns {string}
   * @throws {Error}
   */
  static _getJwtToken() {
    const token = document.cookie.split(';').find((cookie) => {
      if (cookie.includes('erco_auth_token')) {
        return true;
      }
      return false;
    });

    if (!token) throw new Error('No JWT token found');

    return token.split('=')[1];
  }

  /**
   * Get payload from jwt token
   * @param {string} token - Token to parse
   * @returns {object}
   * @throws {Error}
   */
  static _parseJwtPayload(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  /**
   * Fetch a (new) jwt token.\
   * return current token if still valid.
   * @returns {Promise<void>}
   */
  async _fetchJwtToken() {
    const response = await fetch(this._jwtTokenUrl, {
      credentials: 'include',
    });

    if (response.status === 401) throw new Error('Unauthorized');
    if (response.status >= 400) {
      throw new Error('Error while fetching JWT token');
    }

    // Current token is still valid and fresh enough
    if (response.status === 204) {
      return AuthHelper._getJwtToken();
    }

    const newToken = await response.text();
    return newToken;
  }

  /**
   * Try to update the helpers data by fetching and handling a (new) token.\
   * @returns {Promise<void>}
   */
  async updateAuthData() {
    if (this._ongoingUpdate) {
      return this._ongoingUpdate;
    }

    const updatePromise = this._fetchJwtToken()
      .then((token) => {
        this.currentTokenData = AuthHelper._parseJwtPayload(token);

        const leftValidMilliseconds =
          this.currentTokenData.exp * 1000 - Date.now();

        // Refresh token 10 seconds before it expires
        let timeout = leftValidMilliseconds - 10e3;

        // If we somehow have a negative timeout (e.g. someones time is wrong),
        // set it to 60 seconds by default
        if (timeout <= 0) {
          timeout = 60e3;
        }

        if (this._refreshTimeout) clearTimeout(this._refreshTimeout);

        this._refreshTimeout = setTimeout(
          this.updateAuthData.bind(this),
          timeout,
        );
      })
      .catch((err) => {
        this.currentTokenData = null;

        if (err.message !== 'Unauthorized') {
          console.error(err);
        }
      })
      .finally(() => {
        this._ongoingUpdate = null;
      });

    this._ongoingUpdate = updatePromise;
    return updatePromise;
  }

  /**
   * Init helper with current cookie.\
   * Always fetch a token to see if user is still logged in.
   * @returns {Promise<void>}
   */
  async initJwtAuth() {
    if (this._helperInitialized) return;

    await this.updateAuthData().then(() => {
      this._helperInitialized = true;
    });
  }
}

const authHelper = new AuthHelper();
