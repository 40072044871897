/**
 * Mustache renderer addition to globals object
 * 
 * @dependencies
 * * (Mustache)
 * * globals
 * * dynamicallyLoadJSFile
 * 
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * artikelfilter.func, get_artikeldata.func - articletable-km-v2.1.js
 * * artikelfilter.func, get_artikeldata.func - articletable-km.js
 * * lightfinder.func - lightfinder-km.js
 * * reloadList - events-list.js
 * * overwriteTransition - lichtbericht.js
 * * Specsheets - specsheets.js
 * 
 * @note
 * dynamically load mustache engine and remove jquery - pe 20230228
 * 
 * @commit
 * * Sven Schäfermeier vor 4 Jahren
 */


/**
 * Funktion zum rendern von Mustache Templates. Es werden Elemente berücksicht, die wie folgt aufgebaut sind:
 * <div data-render-mustache={"url":"<URL zu den JSON Daten>"</div>}
 */
globals.renderMustacheTemplates = function() {
  var dataValues = {}

  return function() {

    var elements = document.querySelectorAll('[data-render-mustache]');
    if(elements.length == 0) {
      return;
    }

    // load the mustache framework only when needed
    if (!window.Mustache) {
      dynamicallyLoadJSFile('/struktur/lib/mustache.js-2.3.0/mustache.min.js', () => { iterate(0) });
    } else {
      iterate(0);
    }

    function iterate(i) {
      if(i >= elements.length) {
        return;
      }

      //JSON aus Data Attribut holen und parsen
      var dataJSON = JSON.parse(elements[i].getAttribute('data-render-mustache'));

      //URL ziehen
      var dataURL = dataJSON.url;
      var index = dataURL.replace(/\//g, "");

      //Wenn JSON keine URL enthält
      if(!dataURL || elements[i].getAttribute('data-mustache-inited')) {
        iterate(i+1);
        return;
      }

      elements[i].getAttribute('data-original-title');

      //Zum Testen auskommentieren, um folgenden AJAX Request zu umgehen (Der braucht lokal seeeeeeehr lange)
      // var data = {"iso":"'DE'","lat":"51","lng":"9","zoom":"5","email":"info.de@erco.com","strasse1":"Brockhauser Weg 80-82","strasse2":"","ort":"58507 L\u00fcdenscheid","staat":"Germany","fon":"+49 2351 551 100","mailtext_artikel_auf_anfrage":"Hallo liebes ERCO Team,\\r\\n\\r\\nich habe eine Anfrage zu folgender Konfiguration:\\r\\n%s\\r\\n\\r\\nBitte setzen Sie sich mit mir dazu kurzfristig in Verbindung.","betreff_artikel_auf_anfrage":"Produktfrage zu \u201e%s\u201c \u00fcber erco.com","prefix":"+49"}
      // dataValues[index] = data;

      //Wenn JSON zu der URL bereits gezogen wurde
      if(dataValues[index]) {
        elements[i].innerHTML = Mustache.render(elements[i].innerHTML, dataValues[index]);
        elements[i].classList.add('trans100');
        elements[i].setAttribute('data-mustache-inited', 'data-mustache-inited');

        //Mustache Templates für Tooltips rendern
        let dataOriginalTitle = elements[i].getAttribute('data-original-title');
        if  (dataOriginalTitle) {
          elements[i].setAttribute('data-original-title', Mustache.render(dataOriginalTitle, dataValues[index] ));
        }

        iterate(i+1);
        return;
      }

      //Daten für Mustache Templates ziehen
      fetch(dataURL, { cache: 'no-cache' })
        .then((response) => response.json())
        .then((data) => {
          //Daten merken, damit diese nicht noch einmal geladen werden müssen,
          //wenn sie in einem anderen Template benötigt werden
          dataValues[index] = data;
      
          //Mustache Templates rendern
          elements[i].innerHTML = Mustache.render(elements[i].innerHTML, data );
      
          //Mustache Templates für Tooltips rendern
          let dataOriginalTitle = elements[i].getAttribute('data-original-title');
          if  (dataOriginalTitle) {
            elements[i].setAttribute('data-original-title', Mustache.render(dataOriginalTitle, data ));
          }
      
          //Elemente einblenden
          elements[i].classList.add('trans100');
          elements[i].setAttribute('data-mustache-inited', 'data-mustache-inited');
          iterate(i+1);
        }).catch((err) => {
          elements[i].innerHTML = 'error';
          console.error(err);
          iterate(i+1);
        });
    }
  }
}();

globals.renderSingleMustacheTemplate = async function(element, data) { 
  if (!window.Mustache) {
    await new Promise((resolve, reject) => {
      dynamicallyLoadJSFile('/struktur/lib/mustache.js-2.3.0/mustache.min.js', () => {
        resolve();
      });
    });
  }
  return Mustache.render(element.innerHTML, data);
};
