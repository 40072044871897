/**
 * Funktionen für die Produktübersicht
 */
var productoverview = (function () {
  var store = {};
  var exports = {};

  /**
   * Sticki Navigation
   */
  var initStickyNavigation = function () {
    var navbar = document.querySelector('[data-sticky-navbar]');
    var mainnav = document.querySelector('[data-navbar-anchor]');

    //wenn Hauptnavi nicht existiert oder Breakpoint mobile Ansicht
    if (!navbar || !mainnav || media.breakpoint.refreshValue() === media.breakpoint.XS)
      return;

    //Sticky Navigation in Hauptnavigation kopieren
    mainnav.appendChild(navbar);
  };

  var generateOnePageNavigation = function () {
    // get navbar container
    const navContainer = document.querySelector('[data-product-headline-bar]');

    if (!navContainer) {
      return;
    }

    // get all elements with data attribute
    const navElements = document.querySelectorAll('[data-js-sticky-navbar-item]');

    if (!navElements.length) {
      return;
    }

    const navListElement = document.createElement('ul');
    navListElement.id = 'collapseMenu';
    navListElement.className = 'nav-bar -inline pull-right collapse';
    navListElement.setAttribute('data-onepage-navigation', '');

    navContainer.appendChild(navListElement);

    // loop through elements
    navElements.forEach(el => {

      const config = JSON.parse(el.getAttribute('data-js-sticky-navbar-item'));
      const configID = config.anchor;
      const configName = config.name;

      // create nav element
      const navElement = document.createElement('li');
      navElement.setAttribute('class', 'nav-item -lightfont js-switchbutton js-buttoncell js-cell cell');
      navElement.innerHTML = `<a class="navlink" href="#${configID}" data-onepage-link>${configName}</a>`;

      // append nav element to navbar list
      navListElement.appendChild(navElement);
    });
  };

  /**
   * Onepager Navigation
   */
  var initOnePageNavigation = function () {

    //Navigationselemente selektieren
    var navElements = document.querySelectorAll('[data-sticky-navbar] [data-onepage-navigation] [data-onepage-link]');

    if (!navElements.length)
      return;

    setTimeout(() => {
      if (location.hash) {
        const hash = location.hash;
        location.hash = "";
        location.hash = hash;

        const targetElement = document.querySelector(hash);
        if (targetElement) {
          var offset = (window.toolkit.getOffsetFromTop(targetElement) > window.pageYOffset ? store.offsetScrollBottom : store.offsetScrollTop);
          holy.scrollTo(targetElement, undefined, offset);
        }
      }
    }, 800);
    setTimeout(function () {

      store.currentNavLink = navElements[0];

      //Offsetwerte, die beim autoscroll zur Zielposition dazugerechnet werden
      store.offsetScrollBottom = document.querySelector('[data-product-headline-bar]').offsetHeight;
      store.offsetScrollTop = store.offsetScrollBottom * 2;

      //Funktioniert leider nicht immer, da das Hauptmenü hin und wieder während der Initialisierung eine größere Höhe hat.
      //Es wäre aber der zuverlässigere Weg
      //store.offsetScrollTop    = store.offsetScrollBottom + document.querySelector('.new-menu_._bar').offsetHeight;

      var targetElements = [];


      //Für mobile Ansicht
      if (media.breakpoint.refreshValue() === media.breakpoint.XS) {

        //Hier wird leider jQuery benötigt, da der Eventlistener auf die Schaltfläche auch via jQuery zugewiesen wird.
        var toggleCollapseButton = $('[data-toggle="collapse"]');
        var toggleCollapseLabel = document.querySelector('[data-toggle-label]');

        toggleCollapseLabel.innerHTML = store.currentNavLink.innerHTML;

        for (var i = 0; i < navElements.length; i++) {

          //Klick auf Navigationspunkte
          navElements[i].addEventListener('click', function (e) {
            e.preventDefault();

            //Text des angeklickten Navigationspunkt im Label anzeigen
            toggleCollapseLabel.innerHTML = e.currentTarget.innerHTML;

            //Navigation schließen
            toggleCollapseButton.trigger('click');

            //Zielelement selektieren
            targetElement = document.querySelector(e.currentTarget.getAttribute('href'));
            if (targetElement) {
              //Zum Zielelement scrollen + offset
              holy.scrollTo(targetElement, undefined, 0);

            }
          });
        }
      }

      //Für Tablet- und Desktop-Ansicht
      else {

        //Link aktiv setzen
        store.currentNavLink.classList.add('-active');

        for (var i = 0; i < navElements.length; i++) {

          // Element zu dem gescrollt wird, bei klick auf Navipunkt
          var targetElement = document.querySelector(navElements[i].getAttribute('href'));
          if (!targetElement)
            continue;

          targetElements.push(targetElement);

          //Wir horchen auf das Element, ob es in den Viewport gescrollt wird
          holy.addToObserve(targetElement);
          $(targetElement).on('holy:observeIn', handleObserveIn);

          //Wird gefeuert, nach dem Scrollen beendet ist
          $(targetElement).on('holy:scrollDone', function () {

            //Wir horchen auf alle Elemente, ob sie in den Viewport gescrollt wird
            $(targetElements).on('holy:observeIn', handleObserveIn);

            //Elemente einblenden, die sich oberhalb oder innerhalb des Viewports befinden
            sectionAnimations();

          });

          navElements[i].addEventListener('click', function (e) {
            e.preventDefault();
            store.currentNavLink.classList.remove('-active')

            //Das Element selektiren, zu dem gescrollt werden soll
            targetElement = document.querySelector(e.currentTarget.getAttribute('href'));

            //Sind Elemente auf das geklickt wurde und das aktiv ist gleich?
            if (store.currentNavLink == e.currentTarget)
              return;

            // Wir entfernen die observeIn-Listener temporär für alle target elements,
            //  damit diese nicht ausgelöst werden, wenn zum aktuell betrachteten target element gesprungen bzw. gescrollt wird;
            //  die Listener-Funktion wird innerhalb des scrollDone-Events den target elements wieder hinzugefügt
            $(targetElements).off('holy:observeIn', handleObserveIn);

            //Element selektieren, zu dem zuletzt gescrollt wurde
            var lastElement = document.querySelector(store.currentNavLink.getAttribute('href'));

            //Offset berechen, abhängig davon ob wir hoch- oder runter scrollen
            var offset = (window.toolkit.getOffsetFromTop(lastElement) < window.toolkit.getOffsetFromTop(targetElement) ? store.offsetScrollBottom : store.offsetScrollTop);


            //Zum Zielelement scrollen + offset
            holy.scrollTo(targetElement, 300, offset);

            e.currentTarget.classList.add('-active');

            store.currentNavLink = e.currentTarget;
          });
        }

        //Wir schauen, welches Element beim Laden der Seite im Vierwport ist,
        //damit der dazugehörige Navigationspunkt in der Sticky Navi aktiviert wird.
        holy.checkObserve();
      }
    }, 0);
  };

  /**
   * Eventhandler der darauf horcht, ob ein Element in den Viewport gescrollt wird
   */
  var handleObserveIn = function () {
    //Id von dem Element holen, dass in den Viewport gescrollt wurde
    var targetID = this.getAttribute('id');

    //history.pushState(null, null, '#' + targetID);

    //Zu dem Element den passenen Navilink holen
    var navLink = document.querySelector('[data-onepage-navigation] [data-onepage-link][href="#' + targetID + '"]');
    //var navLink = document.querySelector('[data-onepage-navigation] [data-onepage-link][href="#'+ targetID +'"]');

    if (navLink) {
      store.currentNavLink.classList.remove('-active');
      navLink.classList.add('-active');
      store.currentNavLink = navLink;
    }
  };


  /**
   * Akkordeons
   */
  var initCollapseContent = function () {
    var collapseSection = document.querySelectorAll('[data-collapse-section]');
    if (!collapseSection.length)
      return;

    var collapseContent = document.querySelectorAll('[data-collapse-content]');
    var collapseItems = document.querySelectorAll('[data-collapse-icon]');
    var collapseContainer = document.querySelectorAll('[data-collapse-container]');
    var collapseLinks = document.querySelectorAll('[data-collapse-link]');

    dataCollapseSection = collapseSection[0].getAttribute('data-collapse-section');

    //if JSON in data-collapse-section is Empty or the attribute closed is false
    if (dataCollapseSection === '' || JSON.parse(dataCollapseSection).closed === false) {
      //then open the Section
      collapseItems[0].classList.add('rotate');
      collapseContainer[0].classList.add('-open');
    }

    var handleCollapseLinkClick = function (event) {
      event.preventDefault();
      var collapseRef = event.currentTarget.getAttribute('href');

      for (var i = 0; i < collapseContent.length; i++) (function (i) {
        if (collapseRef == "#" + (collapseContent[i].getAttribute('id'))) {
          if (window.toolkit.getAbsoluteHeight(collapseContainer[i]) == 0) {
            collapseContainer[i].style.height = window.toolkit.getAbsoluteHeight(collapseContent[i]) + 'px';
            collapseItems[i].classList.add('rotate');

          }
          else {
            collapseContainer[i].style.height = window.toolkit.getAbsoluteHeight(collapseContent[i]) + 'px';
            setTimeout(function () {
              collapseContainer[i].style.height = 0;
              collapseItems[i].classList.remove('rotate');
            }, 0);
          }
        }
        else {

          if (window.toolkit.getAbsoluteHeight(collapseContainer[i]) == 0)
            return;
          collapseContainer[i].style.height = window.toolkit.getAbsoluteHeight(collapseContent[i]) + 'px';

          setTimeout(function () {
            collapseContainer[i].style.height = 0;
            collapseItems[i].classList.remove('rotate');
            if (collapseRef != '#collapseOne') {
              //collapseTU.classList.remove('-active');
            }
          }, 0);
        }
      })(i);
    }

    var handleTransitionEnd = function (event) {
      var element = event.currentTarget;
      element.style.height = (element.offsetHeight > 0) ? 'auto' : element.style.height;
    }


    for (var i = 0; i < collapseContainer.length; i++) {
      collapseContainer[i].addEventListener('transitionend', handleTransitionEnd);
    }

    for (var j = 0; j < collapseLinks.length; j++) {
      collapseLinks[j].addEventListener('click', handleCollapseLinkClick)
    }
  };

  /**
   * Flickity Carousels
   */
  var initCarousels = function () {
    //Box mit Infos zur Szene
    //var infoBox = document.querySelector('.js-info-Carousel');

    var flickityOptions = {
      adaptiveHeight: true,
      cellAlign: 'left',
      cellSelector: '[data-cell]',
      imagesLoaded: true,
      initialIndex: 0,
      setGallerySize: true,
      watchCSS: true,
      percentPosition: true
    }


    //Flickity wird nur initialisiert, wenn mehr als eine Zelle existiert
    if (document.querySelectorAll('[data-content-carousel] ' + flickityOptions.cellSelector).length > 1) {
      store.contentCarousel = $('[data-content-carousel]').flickity(flickityOptions);
    }

    //Flickity wird nur initialisiert, wenn mehr als eine Zelle existiert
    if (document.querySelectorAll('[data-roentgenbilder-carousel] ' + flickityOptions.cellSelector).length > 1) {
      store.contentRoentgenbilderCarousel = $('[data-roentgenbilder-carousel]').flickity(flickityOptions);
    }



    //Flickity wird nur initialisiert, wenn mehr als eine Zelle existiert
    if (document.querySelectorAll('[data-systemoverview-carousel="0"] ' + flickityOptions.cellSelector).length > 1) {

      //Wir selektieren nur das Element, welches den Attributwert 0 hat,
      //da es mehrere Sektionen mit dem Datenattribut existieren,
      //diese aber ausgeblendet werden, und sonst Flickity Fehler wirft
      store.systemoverviewCarousel = $('[data-systemoverview-carousel="0"]').flickity(flickityOptions);

    }

    flickityOptions.pageDots = false;
    flickityOptions.draggable = false;

    //Wurde das Content Carousel initialisiert?
    if (store.contentCarousel) {
      //Carousel mit Buttons
      store.buttonsCarousel = $('[data-buttons-carousel]').flickity(flickityOptions);

      //Wir müssen an dieser Stelle die Carousels (Navi-Buttons und Content) manuell synchronisieren,
      //da Flickity fehler wirft, bei der Verwendung von 'asNavFor' und 'watchCSS' in Kombination
      store.contentCarousel.on('select.flickity', function () {
        var index = store.contentCarousel.data('flickity').selectedIndex;
        store.buttonsCarousel.flickity('select', index);
      });

      setTimeout(function () {
        store.buttonsCarousel.flickity('resize');
        store.contentCarousel.flickity('resize');
      }, 800);

    }

    //Wurde das Roentgenbilder Carousel initialisiert?
    if (store.contentRoentgenbilderCarousel) {
      //Carousel mit Buttons
      store.buttonsRoentgenbilderCarousel = $('[data-roentgenbilder-buttons-carousel]').flickity(flickityOptions);

      //Wir müssen an dieser Stelle die Carousels (Navi-Buttons und Content) manuell synchronisieren,
      //da Flickity fehler wirft, bei der Verwendung von 'asNavFor' und 'watchCSS' in Kombination
      store.contentRoentgenbilderCarousel.on('select.flickity', function () {
        var index = store.contentRoentgenbilderCarousel.data('flickity').selectedIndex;
        store.buttonsRoentgenbilderCarousel.flickity('select', index);
      });

      setTimeout(function () {
        store.buttonsRoentgenbilderCarousel.flickity('resize');
        store.contentRoentgenbilderCarousel.flickity('resize');
      }, 800);

    }


    //Wurde das Systemoverview Carousel initialisiert?
    if (store.systemoverviewCarousel) {
      flickityOptions.contain = true;

      //Wir selektieren nur das Element, welches den Attributwert 0 hat,
      //da es mehrere Sektionen mit dem Datenattribut existieren,
      //diese aber ausgeblendet werden, und sonst Flickity Fehler wirft
      store.systemoverviewButtonsCarousel = $('[data-systemoverview-buttons-carousel="0"]').flickity(flickityOptions);

      //Wir müssen an dieser Stelle die Carousels (Navi-Buttons und Content) manuell synchronisieren,
      //da Flickity fehler wirft, bei der Verwendung von 'asNavFor' und 'watchCSS' in Kombination
      store.systemoverviewCarousel.on('select.flickity', function () {
        var index = store.systemoverviewCarousel.data('flickity').selectedIndex;
        store.systemoverviewButtonsCarousel.flickity('select', index);
      });

      setTimeout(function () {
        store.systemoverviewCarousel.flickity('resize');
        store.systemoverviewButtonsCarousel.flickity('resize');
      }, 800);

    }
  };


  /**
   * Kontaktformulare
   */
  var initContactForms = function () {

    var forms = document.querySelectorAll('[data-submit-form]');

    //Alle Formulare durchgehen
    for (var i = 0; i < forms.length; i++)(function (i) {

      // Checking if the form is already initialized and preventing multiple event listener bk 24.2.18
      if (forms[i].getAttribute('data-send-init')) {
        return;

      }

      var buttonsShowMessageField = forms[i].querySelectorAll('[data-show-element]');
      for (var j = 0; j < buttonsShowMessageField.length; j++) {
        buttonsShowMessageField[j].addEventListener('click', (e) => {
          const showElements = JSON.parse(e.currentTarget.getAttribute('data-show-element'));

          showElements.forEach(showElement => {
            const targetElement = document.querySelector(showElement.targetelement);
            if (!targetElement) {
              return;
            }
            const inputFields = targetElement.querySelectorAll('[data-input-field]');

            if (showElement.visible == 'true') {
              targetElement.classList.remove('hidden');
              inputFields.forEach((inputField) => {
                inputField.classList.remove('ignoreformdata');
              });
            }

            else {
              targetElement.classList.add('hidden');
              inputFields.forEach((inputField) => {
                inputField.classList.add('ignoreformdata');
              });
            }
          });
        });
      }

      var inputLocation = forms[i].querySelector('[data-input-location]');

      if (inputLocation) {

        forms[i].querySelector('[data-change-location]').addEventListener('click', (e) => {

          //set location
          contact_map.check_input_position();
        });

        // get JSON with all countries
        contact_map.get_all_countries();

        // Init autocompletion Dropdown with countries
        Dropdown.init();

        // locate user and set country in inputfield
        contact_map.wo_bin_ich();

      }

      forms[i].setAttribute('data-send-init', 'true');
      forms[i].addEventListener('submit', function (e) {
        e.preventDefault();

        //Spamcheck => Wenn Variablen leer sind,
        //handelt es sich sehr wahrscheinlich um einen Bot, der die Formulare antriggert
        if (k3vars.S_Gattung.trim() === "" || k3vars.S_Objekt.trim() === "") {
          return;
        }

        var currentTarget = e.currentTarget;
        var messageBoxContainer = currentTarget.querySelector('[data-message-box-container]');
        var messageBox = messageBoxContainer.querySelector('[data-message-box]');
        var errMsgBoxContainer = currentTarget.querySelector('[data-err-message-box-container]');
        var errMsgBox = errMsgBoxContainer && errMsgBoxContainer.querySelector('[data-err-message-box]');
        var data = {};
        var inputFields = currentTarget.querySelectorAll('[data-input-field]:not(.ignoreformdata)');
        var requiredInputs = currentTarget.querySelectorAll('[data-required-field]');
        var allValid = true;
        //      var ajaxLoader          = currentTarget.querySelector('[data-ajax-loader]');

        //Daten von Eingabefeldern aggregieren
        for (var i = 0; i < inputFields.length; i++) {
          let currentInputField = inputFields[i];


          //is the input field a checkbox?
          if (currentInputField.type == 'checkbox') {

            // If Array Element for saving the value is not empty
            if (!data[currentInputField.getAttribute('name')]) {
              data[currentInputField.getAttribute('name')] = '';
            }

            // If value exists
            let dataValue = currentInputField.getAttribute('data-input-field');
            if (dataValue !== '') {
              if (currentInputField.checked === true) {
                data[currentInputField.getAttribute('name')] += `${dataValue}, `;
              }
            }
            else {
              data[currentInputField.getAttribute('name')] = (currentInputField.checked === true ? 'ja' : 'nein');
            }
          }

          //is the input field a radiobox?
          else if (currentInputField.type == 'radio') {
            if (currentInputField.checked) {

              data[currentInputField.getAttribute('name')] = currentInputField.value;
            }
          }
          else {
            data[currentInputField.getAttribute('name')] = currentInputField.value;
          }
        }

        // Remove commas at the end
        Object.keys(data).map(function (key, index) {
          data[key] = data[key].replace(/\, $/, '')
        });

        for (var j = 0; j < requiredInputs.length; j++) {
          var currentField = requiredInputs[j];


          //Markierung vom Label entfernen
          let label = currentTarget.querySelector('[data-label-field][for="' + currentField.id + '"]');
          if (label) {
            label.classList.remove('has-error');
          }
          else {
            currentField.classList.remove('-error');
          }

          //Daten im Eingabefeld valide
          if ((currentField.value === "" ||
            (requiredInputs[j].type == 'email' && !isValidEmail(currentField.value)) ||
            (requiredInputs[j].type == 'checkbox' && requiredInputs[j].checked === false)
          ) &&
            (window.getComputedStyle(currentField).getPropertyValue('visibility') == 'visible')) {

            //Zum Inputfeld zugehöriges Label markieren
            if (label) {
              label.classList.add('has-error');
            }
            else {
              currentField.classList.add('-error')
            }
            allValid = false;
          }
        }

        if (!allValid) {
          if (errMsgBoxContainer) {
            errMsgBoxContainer.classList.add('hidden');
          }

          messageBox.classList.add('alert-danger');
          messageBox.classList.remove('alert-info');
          messageBoxContainer.classList.remove('hidden');
          messageBoxContainer.classList.remove('d-n');
        } else {

          //Wird für Google Anlytics Tracking benötigt
          data.form_type = currentTarget.getAttribute('data-form-tag');

          //Gattung und Produktfamile aus k3 Variablen holen
          data.gattung = k3vars.S_Gattung;
          data.produktfamilie = k3vars.S_Objekt;
          data.suchweg_entscheidungen = currentTarget.getAttribute('data-suchweg-entscheidungen');
          data.suchweg = document.location.href;
          data.site_url = document.location.href;
          data.client_id = (window.Tracking && window.Tracking.isEnabled() && window.Tracking.getClientId()) || '';

          var form_origin = currentTarget.getAttribute('data-form-origin');
          var form_external_url = currentTarget.getAttribute('data-form-external-url');

          data.form_origin = (form_origin) ? form_origin : 'internal';

          if (data.form_origin == 'external' && form_external_url) {
            data.site_url = form_external_url;
          }

          var forced_url = currentTarget.getAttribute('data-forced-url');

          if (forced_url) {
            data.site_url = forced_url;
          }

          //if location is setted by user
          if (contact_map.currentISO !== null) {
            data.iso = contact_map.currentISO;
          }

          // append UTM parameters to form data
          const utmParams = Tracking.EmarsysDataLayer?.UTMApiParams;
          if (utmParams) {
            for (const [key, value] of Object.entries(utmParams)) {
              if (value) {
                data[key] = value;
              }
            }
          }

          formSpinner.show(e.target);

          // Formular abschicken
          $.ajax({

            //die Ziel-URL holen wir uns aus dem action-Attribut des Formulars
            url: e.currentTarget.getAttribute('action'),
            type: 'POST',
            data: data,
            success: function (response) {

              if (typeof response === 'string') {
                var data = JSON.parse(response);
              } else {
                var data = response;
              }

              // Markierungen von Labels entfernen
              for (var i = 0; i < requiredInputs.length; i++) {

                let label = currentTarget.querySelector('[data-label-field][for="' + currentField.id + '"]');
                if (label) {
                  label.classList.remove('has-error');
                }
                else {
                  currentField.classList.remove('-error');
                }
              }

              // Showing the success message
              formSpinner.showSuccess(data.response_message, e.target);

              Tracking.adform.addConversionPixel(Tracking.adform.pixelType.LEAD);

              // Hiding the missing input message box
              messageBoxContainer.classList.add('hidden');
              messageBoxContainer.classList.add('d-n');
              messageBox.classList.remove('alert-info');

              if (errMsgBoxContainer) {
                errMsgBoxContainer.classList.add('hidden');
              }

            },

            error: function (response) {
              var data = JSON.parse(response.responseText);

              messageBoxContainer.classList.add('hidden');

              //Labels markieren, wenn in den zugehordneten Eingabefeldern ein Fehler auftritt
              for (var prop in data) {

                var label = currentTarget.querySelector('[for="' + prop + '"]');

                if (label && !data[prop]) {
                  label.classList.add('has-error');
                }
              }

              // Input-felder markieren, die Fehler aufweisen
              for (var prop in data) {
                var label = currentTarget.querySelector('[name="' + prop + '"]');

                if (label && !data[prop]) {
                  label.classList.add('-error');
                }
              }

              formSpinner.remove(e.target);

              if (errMsgBox && errMsgBoxContainer) {
                errMsgBox.innerHTML = data.response_message;
                errMsgBoxContainer.classList.remove('hidden');
              }
            }

          });
        }
      });
    })(i);
  };

  /**
   * Überprüft ob die übergebene E-Mail-Adresse valide ist
   * @param   {string}  email E-Mail-Adresse die überprüft werden soll
   * @returns {boolean} true: E-Mail-Adresse ist valide; false: E-Mail Adresse ist nicht valide
   */
  var isValidEmail = function (email) {
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    return re.test(email);
  }

  /**
   * Zum Einblenden der Content-Streifen
   */
  var sectionAnimations = function () {
    var elements = document.querySelectorAll('[data-animation="fade-in"]');
    for (var i = 0; i < elements.length; i++) (function (i) {
      setTimeout(function () {
        elements[i].classList.add('trans');
        elements[i].classList.add('transduration08');
      }, 300);

    })(i);

    setTimeout(function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      for (var i = 0; i < elements.length; i++) {

        //Ist das Element oberhalb oder im Viewport
        if (scrollTop > window.toolkit.getOffsetFromTop(elements[i]) || window.toolkit.isElementTopInViewport(elements[i])) {

          //Dann wird es direkt eingeblendet
          elements[i].classList.add('trans100');
        }
        else {
          elements[i].classList.add('trans0');
          holy.addToObserve(elements[i]);
          //Ansonsten, wird Eventlistener angehangen, der das Objekt einblendet, sobald es sich im Viewport befindet
          $(elements[i]).off('holy:observeIn', handleFadeIn).one('holy:observeIn', handleFadeIn);

        }
      }
    }, 100);
  }

  // Event listener function used to handle a fade in effect for a element to which the listener is bound to
  var handleFadeIn = function () {
    var self = this;
    setTimeout(function () {
      self.classList.remove('trans0');
      self.classList.add('trans100');
    }, 0)
  };

  // Injects superscript for product brands
  var injectsSuperScript = function () {

    const pageSpecificationName = document.querySelector('body').getAttribute('data-specification');
    const specifications = [
      {
        name: 'innenraum',
        pageType: 'default'
      },
      {
        name: 'aussenraum',
        pageType: 'default'
      },
      {
        name: 'projekte',
        pageType: 'default'
      },
      {
        name: 'microsites',
        pageType: 'microsite'
      },
    ];

    specificationName = specifications.find(specification => specification.name === pageSpecificationName);
    if (!specificationName) {
      return;
    }

    const querySelectorStrings = {
      'default': 'span.headline, h1, h2, h3, h4:not(.header-title)',
      'microsite': 'p.ms-text, p.ms-text-small, h2.ms-headline'
    }

    querySelectorString = querySelectorStrings[specificationName.pageType];
    if (!querySelectorString) {
      return;
    }

    const productBrands = [
      {
        searchValue: 'Parscan New',
        replaceValue: 'Parscan&nbsp;<sup>New</sup>',
      },
      {
        searchValue: 'Parscan New 48V',
        replaceValue: 'Parscan&nbsp;<sup>New</sup>&nbsp;48V',
      },
      {
        searchValue: 'Parscan New InTrack',
        replaceValue: 'Parscan&nbsp;<sup>New</sup>&nbsp;InTrack',
      },
      {
        searchValue: 'Parscan New OnTrack',
        replaceValue: 'Parscan&nbsp;<sup>New</sup>&nbsp;OnTrack',
      },
      {
        searchValue: 'Optec New',
        replaceValue: 'Optec&nbsp;<sup>New</sup>',
      },
      {
        searchValue: 'Optec New 48V',
        replaceValue: 'Optec&nbsp;<sup>New</sup>&nbsp;48V',
      },
    ];

    const elements = document.querySelectorAll(querySelectorString);
    elements.forEach((element) => {
      productBrands.forEach(productBrand => {
        if (element.innerHTML.includes(productBrand.searchValue)) {
          element.innerHTML = element.innerHTML.replace(productBrand.searchValue, productBrand.replaceValue);
          element.classList.add('product-brand');
          return;
        }
      })
    });
  };

  exports.initContactForms = initContactForms;
  exports.initCarousels = initCarousels;
  exports.initStickyNavigation = initStickyNavigation;
  exports.generateOnePageNavigation = generateOnePageNavigation;
  exports.initOnePageNavigation = initOnePageNavigation;
  exports.initCollapseContent = initCollapseContent;
  exports.sectionAnimations = sectionAnimations;
  exports.injectsSuperScript = injectsSuperScript;
  return exports;
})()

var formSpinner = {
  // Showing the loading box
  show: function (elm) {
    // Hiding all form elements
    for (var i = 0; i < elm.children.length; i++) {
      elm.children[i].classList.remove('trans100');
      elm.children[i].classList.add('trans0');
      // elm.children[i].classList.add('hidden');
    }

    var spinContainer = document.createElement('div'),
      spinner = document.createElement('div');

    spinner.classList.add('spinner');
    spinContainer.classList.add('spin-container');
    if (elm.classList.contains('-dark')) {
      spinContainer.classList.add('-dark');
    }
    spinContainer.appendChild(spinner);

    elm.appendChild(spinContainer);

    spinContainer.classList.add('trans100');
  },

  showSuccess: function (data, form) {
    // Getting the translations
    var lang = k3vars.S_Sprache || 'en';
    $.ajax({
      url: confEnv['pdb_api_url'] + '/productdata/translate/' + lang + '/context/service/layout/json',
      type: 'GET',
      dataType: 'json',
      success: function (translations) {
        // Removing the spin container
        const spinContainer = form.querySelector('.spin-container');
        spinContainer.removeChild(spinContainer.querySelector('.spinner'));

        const successContainer = document.createElement('div');
        const msgContainer = document.createElement('div');
        const textContainer = document.createElement('div');
        const msg = document.createElement('p');
        const close = document.createElement('a');
        const iconDiv = document.createElement('div');
        const icon = document.createElement('span');

        msgContainer.classList.add('spin-message__content');

        msg.innerHTML = data;
        textContainer.appendChild(msg);

        close.appendChild(document.createTextNode(translations.label_schliessen));
        close.addEventListener('click', function (e) {
          e.preventDefault();
          formSpinner.remove(form);
        });

        icon.classList.add('icon--checkmark-success');
        iconDiv.classList.add('spin-message__icon');
        iconDiv.appendChild(icon);

        msgContainer.appendChild(textContainer);
        msgContainer.appendChild(close);

        successContainer.classList.add('spin-message');

        successContainer.appendChild(iconDiv);
        successContainer.appendChild(msgContainer);

        spinContainer.appendChild(successContainer);
      },
      error: function (err) {
        console.error(err);
      },
    });
  },

  showError: function (data, form, showButtons = []) {
    // Getting the translations
    const lang = k3vars.S_Sprache || 'en';
    $.ajax({
      url: confEnv['pdb_api_url'] + '/productdata/translate/' + lang + '/context/service/layout/json',
      type: 'GET',
      dataType: 'json',
      success: function (translations) {
        // Removing the spin container
        const spinContainer = form.querySelector('.spin-container');
        spinContainer.removeChild(spinContainer.querySelector('.spinner'));

        const errorContainer = document.createElement('div');
        const msgContainer = document.createElement('div');
        const textContainer = document.createElement('div');
        const iconDiv = document.createElement('div');
        const icon = document.createElement('span');

        errorContainer.classList.add('spin-message');

        iconDiv.classList.add('spin-message__icon');
        icon.classList.add('icon--error');
        iconDiv.appendChild(icon);

        msgContainer.classList.add('spin-message__content');

        const msg = document.createElement('p');
        // replace line breaks with one <br> tag
        msg.innerHTML = data.replace(/\n+/g, '<br>');
        textContainer.appendChild(msg);

        msgContainer.appendChild(textContainer);

        if (showButtons && showButtons.includes('edit')) {
          const edit = document.createElement('button');
          edit.classList.add('btn', 'btn-sm', 'invers', 'btn-primary', 'xs-btn-block', 'bottom-1');
          edit.appendChild(document.createTextNode(translations.label_eingaben_korrigieren));
          edit.addEventListener('click', function (e) {
            e.preventDefault();
            formSpinner.remove(form);
          });
          msgContainer.appendChild(edit);
        }

        if (showButtons && showButtons.includes('resetPassword')) {
          const resetPassword = document.createElement('a');
          resetPassword.href = `${confEnv['pdb_api_url']}/myerco/auth/forgot_password/${lang}`;
          resetPassword.appendChild(document.createTextNode(translations.label_passwort_zuruecksetzen));
          msgContainer.appendChild(resetPassword);
        }

        errorContainer.appendChild(iconDiv);
        errorContainer.appendChild(msgContainer);

        spinContainer.appendChild(errorContainer);
      },
      error: function (err) {
        console.error(err);
      },
    });
  },

  remove: function (form) {
    var spinContainer = form.querySelector('.spin-container');

    if (form.hasAttribute('data-success-reload')) { // Optionally reloading the form
      location.reload();
    } else { // Otherwise removing the spinner
      form.removeChild(spinContainer);
      for (var i = 0; i < form.children.length; i++) {
        form.children[i].classList.remove('trans0');
        form.children[i].classList.add('trans100');
      }
    }
  },
};

const specialFeatures = {
  init: function () {
    const mediaBlocks = document.querySelectorAll('.media-container .media-block.animated');
    if (mediaBlocks.length > 0) {
      specialFeatures.addClickEvents(mediaBlocks);
    }
  },
  addClickEvents: function (mediaBlocks) {
    mediaBlocks.forEach((mediaBlock) => {
      mediaBlock.addEventListener("click", (event) => {
        if (event.srcElement.classList.contains('activated')) {
          event.srcElement.classList.remove('activated');
        } else {
          event.srcElement.classList.add('activated');
        }
      })
    })
  }
}
