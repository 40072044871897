/**
 * Röntgenbilder & Hotspots
 * 
 * @dependencies
 * * jquery
 * * holy
 * * ls_module -> not used anywhere else (lightscout, cms-backend, pdb-api)
 * @dependents
 * * function roentgen.start is called in cms-backend (dist/kunden/ls2014/cleaner/0011_products_roentgen.pl)
 * * data attribute [data-click-roentgen-tab] used in a file in pdb-api (dist/application/v1/views/productdata/structure_and_characteristics.php)
 * * id [id*="hotspots"] used in a file in pdb-api (dist/application/v1/views/productdata/structure_and_characteristics.php)
 * 
 * @note
 * Could not find any use of initHotspots in lightscout or cms-backend or pdb-api
 * 
 * @commit Initial commit 2.0
 */
roentgen = {
  start: function () {

    //Tabs zum Umschalten den Roentgenbilder
    var roentgenTabs = document.querySelectorAll('[data-click-roentgen-tab]');
    for (var i = 0; i < roentgenTabs.length; i++) {

      //Eventlistener hinzufügen
      roentgenTabs[i].addEventListener('click', handleRoentgenTabClick);
    }

    function handleRoentgenTabClick(e) {
      //Eventlistener von geklickten Tab entfernen
      e.currentTarget.removeEventListener('click', handleRoentgenTabClick);

      //Href auslesen => Das ist die ID von dem Container, den wir einblenden wollen
      var href = e.currentTarget.getAttribute('href');

      //Röntgenbild einblenden
      $(document.querySelector(href + ' .translucent')).delay(500).animate({
        'opacity': '1'
      }, 1000);
    }


    if (typeof holy === 'undefined') {
      $(window).on('scroll', function () {
        $('[id*="hotspots"].addListener').each(function () {
          if (($(window).scrollTop() + $(window).height()) > ($(this).offset().top + $(this).outerHeight())) {
            $(this).removeClass('addListener').find('.translucent').delay(1000).animate({ 'opacity': '1' }, 1000);
          }
        });
      });

    } else {
      //var elements = $('[id*="hotspots"]');
      var elements = $('[id*="hotspots"]');

      holy.addToObserve(elements);

      elements.on('holy:observeIn', function () {
        holy.rmObserve(this)
        $(this).find('.translucent').delay(1000).animate({ 'opacity': '1' }, 1000)
        return false // stop bubbling
      });

    }

    // ^sschi 06112016
    //
    var duplicates = 0;
    $('[id*="hotspots"]').each(function (i, el) {
      $(el).find('.less').each(function (i, el) {
        duplicates = $(el).data('duplicates');

        // Check if data-duplicates is set
        if (duplicates !== undefined && duplicates > 0) {
          var clone;

          var classes = $(el).attr('class');
          var spotclass = classes.match(/spot\d/);
          var spotnumber = spotclass[0].replace("spot", "");

          // Loop the amount of duplicates
          for (var i = 1; i <= duplicates; i++) {
            clone = $(el).clone(); 				// Clone the element
            clone.addClass('spot' + spotnumber + '-' + i); 		// Add the secondary spot class (for new arrangement)
            clone.removeAttr('data-duplicates');// remove the duplicatge attribute. Just to be sure.
            clone.insertAfter($(el)); 			// Insert it after the parent spot
          }
        }
      });
    });
  }
}

/* Module: Hotspots
============================================================*/

if (typeof (ls_module) === 'undefined') ls_module = {};

ls_module.hotspots = {
  start: initHotspots
};

function initHotspots() {

  if (typeof holy === 'undefined') {
    $(window).on('scroll', function () {
      $('[id*="hotspots"].addListener').each(function () {
        if (($(window).scrollTop() + $(window).height()) > ($(this).offset().top + $(this).outerHeight())) {
          $(this).removeClass('addListener').find('.less').animate({ 'opacity': '1' }, 1000);
        }
      });
    });

  } else {
    var elements = $('[id*="hotspots"]')
    holy.addToObserve(elements);

    elements.on('holy:observeIn', function () {
      holy.rmObserve(this)
      $(this).removeClass('addListener').find('.less').animate({ 'opacity': '1' }, 1000);
      return false // stop bubbling
    })
  }


  // Close open CSS-Overlays via ESC

  $(document).keydown(function (e) {

    if (e.keyCode == 27) {
      window.location.hash = '#hotspots-PKM-Objekt';
    }

  });
}