/**
 * Newsletter Bottombar
 * 
 * @dependencies
 * * jquery
 * * toolkit.getLocalStorageObject (toolkit.js)
 * * toolkit.setLocalStorageObject (toolkit.js)
 * 
 * @dependents
 * * $(document).ready(function()
 * 
 * @commit 
 * * s.schaefermeier (2018-03-16): update from gw2.erco.org
 * * h.bode (2020-03)
 */


NewsletterBottom = {

  // Checking the cookies if the bottombar should be displayed or not
  init: function () {

    // Appending the newsletter bottombar to the end of the body so it does not break on the compar microsites

    // Reading visitCount object from localStorage
    let visitCountStorageObject = window.toolkit.getLocalStorageObject('visitCount');

    // Reading the newsletterSubmitted object from localStorage
    let newsletterSubmittedStorageObject = window.toolkit.getLocalStorageObject('newsletterSubmitted');

    // Setting the visitCount object in localStorage if it does not exist, otherwise incrementing it
    if (visitCountStorageObject === null) {
      // Create the object
      visitCountStorageObject = {
        counter: 1,
      };
      // Set object in localStorage with expiration
      window.toolkit.setLocalStorageObject('visitCount', visitCountStorageObject, 24 * 60); // expires in 24 hours
    } else {
      // Increment visitCount counter and set localStorage object with expiration
      visitCountStorageObject.counter++;
      window.toolkit.setLocalStorageObject('visitCount', visitCountStorageObject, 24 * 60); // expires in 24 hours
    }

    // Setting the newsletterSubmitted object in localStorage if it does not exist
    if (newsletterSubmittedStorageObject === null) {
      // Create the object
      newsletterSubmittedStorageObject = {
        submitted: false,
      }
      // Set object in localStorage with expiration
      window.toolkit.setLocalStorageObject('newsletterSubmitted', newsletterSubmittedStorageObject, 7 * 24 * 60); // expires in 1 week
    }

    // Showing the container after x ms during the first three visits or if the corresponding data tag is present
    // prevent showing the bottom-bar if data-newsletter-bottom-bar-disable is present
    if (
      (visitCountStorageObject.counter <= 3
        && !newsletterSubmittedStorageObject.submitted
        || document.querySelector('[data-newsletter-active]'))
      && !document.querySelector('[data-newsletter-bottom-bar-disable]')
    ) {
      setTimeout(function () {
        $('#newsletter-bottom-bar').addClass('active');
        // Closing the bar if we are at the bottom of the website as to not overlay the footer
        NewsletterBottom.checkScrollPosition();
      }, 5000);
    }

    // Extending the newsletterbar when its active
    $('#newsletter-bottom-bar').on('click', function () {
      this.classList.add('extended');
    });

    $('form[name="submit_newsletter"]').on('submit', function (e) {
      e.preventDefault();
      NewsletterBottom.submit();
    });
  },

  // Closing the bottombar and hiding it for a week
  close: function () {

    // Creating the newsletterSubmitted object
    const newsletterSubmittedStorageObject = {
      submitted: true,
    };

    // Setting the newsletterSubmitted object in localStorage with expiration of 1 week
    window.toolkit.setLocalStorageObject('newsletterSubmitted', newsletterSubmittedStorageObject, 7 * 24 * 60); // expires in 1 week

    $('#newsletter-bottom-bar').removeClass('active');
    // Removing the scroll event listener
    window.removeEventListener('scroll', NewsletterBottom.handleScroll);
  },

  validEmail: function (email) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(email);
  },

  // Validating the bottombar input before sending
  valid: function () {

    var valid = true;

    // Looping through all form elements and checking if they are empty
    $('form[name="submit_newsletter"] input').each(function () {
      if ($(this).attr('name') === 'email') { // Checking if the email is valid
        if (!NewsletterBottom.validEmail($(this).val())) {
          valid = false;
          $(this).addClass('empty');
        }
      }
    });

    return valid;

  },

  // Submitting the newsletter subscriber
  submit: function () {
    // Checking if the input is valid
    if (NewsletterBottom.valid()) {

      // Showing the spinner
      $('#submit_newsletter').addClass('sending');

      var payload = {};

      // Grabbing the userinput
      $('form[name="submit_newsletter"] input').each(function () {
        payload[$(this).attr('name')] = $(this).val();
      });

      const langSelectElement = $('form[name="submit_newsletter"] select');
      payload.lang = langSelectElement && langSelectElement.length ? langSelectElement[0].value : undefined;
      payload.type = 'newsletterbottombar';

      newsletter.subscribeNewsletter(payload, function (res) {
        if (res) {
          // Removing the spinner
          $('#submit_newsletter').removeClass('sending');
          if (res.success) { // Successfully subscribed
            // Fading the content out and the message in
            $('#newsletter-bottom-bar > .container').addClass('trans');

            setTimeout(function () {
              $('#newsletter-bottom-bar > .container').html('<p class="user_message">' + res.message + '</p><a href="#" class="js-close btn invers">' + res.close + '</a>');
              $('#newsletter-bottom-bar > .container').removeClass('trans');
              // Event listener for closing the newsletter bottombar
              $('#newsletter-bottom-bar .js-close').on('click', function (e) {
                e.preventDefault();
                NewsletterBottom.close();
              });

              // Creating the newsletterSubmitted object
              const newsletterSubmittedStorageObject = {
                submitted: 1,
              };

              // Setting the newsletterSubmitted object in localStorage, therefore hiding the newsletter bottombar for a week
              window.toolkit.setLocalStorageObject('newsletterSubmitted', newsletterSubmittedStorageObject, 7 * 24 * 60); // expires in 1 week

              // Hiding the newsletter bar after x Seconds
              setTimeout(function () {
                $('#newsletter-bottom-bar').removeClass('active');
              }, 10000);

            }, 500);
          }
        }
      });
    }
  },

  // Checking if we are scrolled to the bottom of the page
  checkScrollPosition: function () {
    window.addEventListener('scroll', NewsletterBottom.handleScroll);
  },

  handleScroll: function (e) {
    if ($(window).scrollTop() + $(window).height() >= $(document).height() - 100) {
      NewsletterBottom.close();
    }
  }
}
