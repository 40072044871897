/**
 * Check if an secondary element is longer then it partners(siblings?)
 *
 * @dependencies
 * * jQuery
 *
 * @dependents
 * * /js/global/lichtbericht.js (cannot get called)
 * * /js/global/modules/transition.js (cannot get called)
 *
 * @note
 * probably deprecated, pe 20230214
 *
 * @commit
 * * Initial Commit 2.0
 */
var secondaryReportAdjust = (function () {

	// lokale Funktionen
	var check = function () {

		// abstände
		var msr = {
			off: 16, // gridGutter (15px) + minHeight   (1px)
			hgt: 35  // gridGutter (15px) + lineHeight (20px)
		};

		// Selektor und Länge
		var lst = document.querySelectorAll ('[data-category="report"] .content[class*="report_"], [data-category="products"] .content[class*="report_"]');

		// Alle durchlaufen
		for (var itm = 1, len = lst.length; itm < len; itm++) {

			// Aktuelles und vorheriges Element
			var pre = lst[itm - 1];
			var cur = lst[itm];

			// Reset style & min-height
			cur.classList.remove('undo-secondary');
			if (cur.style.minHeight) cur.style.minHeight = '';

			// Falls auf ein Info-Template zwei Bilder folgen
			if (pre.classList.contains('report_info') && cur.classList.contains('report_2_images')) pre.classList.add('undo-secondary');

			// Falls auf ein Bilder-Template zwei Bilder folgen
			if (pre.classList.contains('report_2_images') && cur.classList.contains('report_2_images')) pre.classList.add('undo-secondary');

			// Wenn Elemente nicht benachbart sind -> abbrechen
			if (cur.offsetTop - pre.offsetTop > msr.off) continue;

			// Wenn prev Element zu geringe Höhe besitzt -> abbrechen
			if (pre.scrollHeight <= msr.hgt) continue;

			// Wenn Element kleiner als Secondary Element -> minHeight setzen
			if (cur.offsetHeight < pre.scrollHeight) cur.style.minHeight = pre.scrollHeight+'px';
		}
	}

	// Check ob wir bereits einen fertigen dom haben
	if (document.readyState === "complete") check();

	// Event Listener für load und resize
	window.addEventListener('load'   , check);
	window.addEventListener('resize' , check);

	//window.addEventListener('km:ajax', check);
	$(window).on('km:ajax', check);
})();
