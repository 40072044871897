class VideoAnimation {
  constructor(videoContainer, options = {}) {
    this.video = videoContainer.querySelector('video');

    if (!this.video) {
      return
    }

    this.videoContainer = videoContainer;

    if (this.videoContainer.dataset.videoAnimationInitialized) {
      return
    }

    this.config = videoContainer.dataset.jsVideoAnimation ? JSON.parse(videoContainer.dataset.jsVideoAnimation) : {};

    if( this.config.deferedLoading && !options.loadImmediately) {
      return;
    }

    this.isVideoPlaying = false;
    this.video.addEventListener('ended', this.handleVideoEnd.bind(this));
    this.controlButton = document.createElement('button');

    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('theme-light', 'theme-light--10', 'wrapper-animation__control-button');
    this.controlContainer.appendChild(this.controlButton);
    this.videoContainer.appendChild(this.controlContainer);

    this.controlButton.classList.add('button', 'button--secondary', 'button--rounded', 'button--medium', 'button--with-icon-only');
    this.controlButtonIcon = document.createElement('span');
    this.controlButtonIcon.classList.add('icon', 'icon--play');
    this.controlButton.appendChild(this.controlButtonIcon);
    this.controlButton.addEventListener('click', this.togglePlay.bind(this));
    this.videoContainer.setAttribute('data-video-animation-initialized', 'true');
    this.destroy = this.destroy.bind(this);

    if (this.config.autoplay) {
      this.isVideoPlaying = true;
      this.play();
    }
  }

  static init(options = {}) {
    document.querySelectorAll('[data-js-video-animation]').forEach((videoContainer) => {
      new VideoAnimation(videoContainer, options);
    });
  };

  play() {
    this.video.play();
    this.controlButtonIcon.classList.remove('icon--replay');
    this.controlButtonIcon.classList.remove('icon--play');
    this.controlButtonIcon.classList.add('icon--pause');

  }

  pause() {
    this.video.pause();
    this.controlButtonIcon.classList.remove('icon--pause');
    this.controlButtonIcon.classList.add('icon--play');
  }

  togglePlay() {
    if (this.isVideoPlaying) {
      this.pause();
    } else {
      this.play()
    }
    this.isVideoPlaying = !this.isVideoPlaying;
  }

  handleVideoEnd() {
    this.controlButtonIcon.classList.remove('icon--pause');
    this.controlButtonIcon.classList.add('icon--replay');
    this.isVideoPlaying = false;
  }

  destroy() {
    this.videoContainer.removeAttribute('data-video-animation-initialized');
    this.video.removeEventListener('ended', this.handleVideoEnd.bind(this));
    this.controlButton.removeEventListener('click', this.togglePlay.bind(this));
    this.videoContainer.removeChild(this.controlContainer);
  }
}

document.addEventListener('DOMContentLoaded', VideoAnimation.init);