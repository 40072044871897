/**
 * Nav-toggle
 * 
 * @dependencies
 * * jquery
 * * holy
 * 
 * @dependents
 * * $(document).ready(function()
 * * data attribute [data-nav-toggle] used in several views in pdp-api
 * * data attribute [data-nav-btn] used in several templates in cms-backend
 * * function `sprachen_einblenden` in pdp-api -> dist/struktur/php/global/sprachen_templates/vorschlag.php
 * 
 * @commit Initial commit 2.0
 */
var navigation = {
  start: function () {
    /* ss 20150611 - bei clicks auf die TU in breadcrumbs oder neben headlines wie Systemübersicht, etc. */
    $('[data-nav-toggle="inpage_nav_lang"]').off('click').on('click', function (event) {
      /* going up, when beeing down - eg. breadcrumb */
      holy.scrollTo();
      $('[data-nav-btn="nav_lang"]:not(._active)').trigger('click');
    });
  },
  ausblenden: function ($this) { /* deprecated */ },
  einblenden: function ($this) { /* deprecated */ },
  moveIn: function ($this) { /* deprecated */ },
  moveOut: function ($this) { /* deprecated */ },

  // Sonderfall für das Triggern des Sprachmenüs, wenn wir eine Sprache vorgeschlagen haben
  sprachen_einblenden: function () {
    $(document).ready(function () {
      $('[data-nav-btn="nav_lang"]:not(._active)').trigger('click');
    });
  }
}