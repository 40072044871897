/**
 * handles clicks on a radiobutton-group
 * @dependencies
 * *
 * @dependents
 * * document.ready for all [data-js-check-radioboxes]
 * @note
 * cause we call it only for data-js-check-radioboxes which doesnt exist this is propably deprecated
 * @commit
 * * v.schaefer, (September 1st, 2020 3:58 PM): Changed color for button_color_invers. Reduced transition speed for button color. Reduced padding for big buttons.
 */
class RadioboxGroup {
  constructor(checkboxGroup) {
    this.checkboxGroup = checkboxGroup;
    this.activeCheckbox = checkboxGroup.querySelector('input[type=radio]:checked').closest('[data-js-radio-box]');
    this.activeCheckbox.classList.add('-active');
    this.activeCheckbox.setAttribute('data-state', 'active');

    checkboxGroup.querySelectorAll('[data-js-radio-box]').forEach((checkbox) => {
      checkbox.addEventListener('click', e => this.handleCheckboxClick(e));
    })
  }

  handleCheckboxClick (e) {
    let currentTarget = e.currentTarget;
    if (currentTarget === this.activeCheckbox) {
      return;
    }

    this.activeCheckbox.classList.remove('-active');
    this.activeCheckbox.removeAttribute('data-state');
    this.activeCheckbox.querySelector('input[type=radio]').checked = false;

    currentTarget.classList.add('-active');
    currentTarget.setAttribute('data-state', 'active');
    currentTarget.querySelector('input[type=radio]').checked = true;

    this.activeCheckbox = e.currentTarget;
  }
}

/**
 * handles clicks on a Checkbox-Group
 * @dependencies
 * *
 * @dependents
 * * document.ready for all [data-js-check-checkboxes]
 * * js/events/events-list.js for all [data-js-check-checkboxes] in list
 * * js/global/modules/specsheets.js for all [data-js-check-checkboxes] in contactform of specsheets
 * * multiple templates where data-js-check-checkboxes exists
 * @note
 *
 * @commit
 * * v.schaefer, (September 1st, 2020 3:58 PM): Changed color for button_color_invers. Reduced transition speed for button color. Reduced padding for big buttons.
 * * v.schaefer,(June 22nd, 2022 12:06 PM) feat(css): do not use new CSS in lightscout and prepare integration of new CSS
 * * p.erasmus, (June 27th, 2022 3:57 PM): Apply 8 suggestion(s) to 2 file(s)
 */
class CheckboxGroup {
  constructor(checkboxGroup) {
    this.checkboxGroup = checkboxGroup;

    let activeCheckboxes = checkboxGroup.querySelectorAll('input[type=checkbox]:checked');
    activeCheckboxes.forEach(
      (element) => {
        element.closest('.checkbox-item').classList.add('-active')
        element.closest('.checkbox-item').setAttribute('data-state', 'active');
      }
    );

    checkboxGroup.querySelectorAll('li').forEach((checkbox) => {
      checkbox.addEventListener('click', e => this.handleCheckboxClick(e));
    })
  }

  handleCheckboxClick (e) {
    let currentTarget = e.currentTarget;
    if (!currentTarget.classList.contains('checkbox-item')) {
      currentTarget = currentTarget.querySelector('.checkbox-item');
    }
    if (currentTarget.classList.contains('-active')) {
      currentTarget.classList.remove('-active');
      currentTarget.removeAttribute('data-state');
      currentTarget.querySelector('input[type=checkbox]').checked = false;

    }
    else {
      currentTarget.classList.add('-active');
      currentTarget.setAttribute('data-state', 'active');
      currentTarget.querySelector('input[type=checkbox]').checked = true;

    }
  }
}


/**
 * handle click and additionlcontent for hotspots
 * @dependencies
 * *
 * @dependents
 * * document.ready() for all [data-js-hotspot-group]
 * * some microsite-tempaltes where data-js-hotspot-group is used
 * @note
 *
 * @commit
 * * v.schaefer,(May 18th, 2021 3:35 PM): Some tweaks in HotspotGroup class
 * * v.schaefer,(May 18th, 2021 2:17 PM): Adds functions to append hotspot contentbox to the body element
 * * v.schaefer,(December 9th, 2022 2:52 PM): feat: hides hotspots initially
 * * v.schaefer (May 18th, 2021 4:32 PM): Removes -active class if detaching content container of body and fire detachContentboxFromBody only once
 * * v.schaefer,(September 8th, 2022 4:36 PM): feat(js): toggle data-attribute when interact with a hotspot
 */
class HotspotGroup {
  constructor(hotspotGroup, config) {
    this.hotspots = hotspotGroup.querySelectorAll('[data-js-hotspot]');
    this.lastHotspot = this.hotspots[0];
    this.currentParentContainer = null;
    this.hotspots.forEach(element => {
      element.addEventListener('click', (e) => this.handleHotspotClick(e));

      // Element is not visible by default
      element.removeAttribute('data-state');
    });
    if (config && config.contentContainer) {

      // Container which should be appended to the body
      this.contentContainer = config.contentContainer;
    }
  }

  detachContentboxFromBody() {
    if (this.currentContentContainer) {
      this.lastHotspot.classList.remove('-active');
      this.lastHotspot.removeAttribute('data-state');
      this.currentParentContainer.appendChild(this.currentContentContainer);
    }
  }

  appendContentboxToBody(element) {
    this.currentContentContainer = element.querySelector(this.contentContainer);
    if (this.currentContentContainer) {
      this.currentParentContainer = this.currentContentContainer.parentNode;
      document.body.appendChild(this.currentContentContainer);
      this.currentContentContainer.addEventListener('click', e => this.detachContentboxFromBody(e), {
        once: true,
      });
    }
  }

  handleHotspotClick (e) {
    if (this.lastHotspot === e.currentTarget) {
      e.currentTarget.classList.toggle('-active');
      if(e.currentTarget.hasAttribute('data-state')) {
        this.lastHotspot.removeAttribute('data-state');
      } else {
        e.currentTarget.setAttribute('data-state', 'active');
      }
    } else {
      this.lastHotspot.classList.remove('-active');
      this.lastHotspot.removeAttribute('data-state');
      this.lastHotspot = e.currentTarget
      e.currentTarget.classList.add('-active');
      e.currentTarget.setAttribute('data-state', 'active');
    }

    if(media.breakpoint.refreshValue() === media.breakpoint.XS) {
      // When the modal is shown, we want a fixed body
      if(e.currentTarget.classList.contains('-active')) {
        fixedBody.enable();

        // if currentContentContainer is setted than append container to body
        if (this.contentContainer) {
          this.appendContentboxToBody(e.currentTarget);
        }
      }
    }
    // When the modal is hidden...
    if(!e.currentTarget.classList.contains('-active')) {
      fixedBody.disable();
    }
  };
}
