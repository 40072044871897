/**
 * After Ajax
 * Functions and actions which are called after ajax-requests
 *
 * @dependencies
 * * jQuery
 * * unveil (jquery plugin)
 * * layout
 * * header_gallery
 * * fullscreen_slideshow
 * * dotscroll
 * * videosWithSubtitles
 *
 * @dependents
 * * js/global/contact.js
 * * js/global/deferred-load-km.js
 * * js/global/lichtbericht.js
 * * js/global/spotlight-km.js
 * * js/global/modules/microsite.js
 * * js/global/modules/transition.js
 * * js/lightfinder/lightfinder-km.js
 * * PDB-API (v1): /views/myerco/combined_articlelist.php
 * * PDB-API (v1): /views/productdata/articles.php
 * * PDB-API (v1.1): /views/myerco/combined_articlelist.php
 * * PDB-API (v1.1): /views/productdata/articles.php
 * * PDB-API (v2): /views/productdata/articles.php
 *
 * @commit
 * * Initial Commit 2.0
 */
after_ajax = {
  start: (scope) => {
    const selector = 'img[data-src]:not(.loaded)';
    const scopedSelector = scope ? `${scope} ${selector}` : selector;
    const images = document.querySelectorAll(scopedSelector);

    images.forEach((image) => {
      const src = image.getAttribute('data-src');
      if (!src.match(/spacer/) && src.match(/\.gif|\.jpg|\.png/)) {
        image.setAttribute('src', src);
        image.classList.add('loaded');
      }
    });

    layout.init();
    header_gallery.init();

    // bilder für die fullscreen slideshow klarmachen
    fullscreen_slideshow.init_links();

    // ss 20161020 - init fading animations
    dotscroll.init();

    videosWithSubtitles.init();
  },
  addLoadedClass: () => {
    // Nachladen der Bilder
    const postLoadImages = document.querySelectorAll('img[data-src]:not(.hidden .nolazy, .tab-pane .lazynav, .isotope)');

    $(postLoadImages).unveil(200, function () {
      this.addEventListener('load', function () {
        this.classList.add('loaded');
      });
    });

    // Jetzt die Bilder in aktiven tab-panels
    const activeTabImages = document.querySelectorAll('.tab-pane.active img[data-src]');
    $(activeTabImages).unveil(100, function () {
      this.addEventListener('load', function () {
        this.classList.add('loaded');
      });
    });

    // Jetzt die Bilder beim Wechsel eins tab-panels
    const tabLinks = document.querySelectorAll('a[data-toggle="tab"]');
    tabLinks.forEach(function (link) {
      $(link).on('shown.bs.tab', function (e) {
        const target = e.target;
        const tabTargetId = target.getAttribute('href');
        const imagesInTabTarget = document.querySelectorAll(tabTargetId + ' img:not(.loaded)');
        $(imagesInTabTarget).unveil(100, function () {
          this.addEventListener('load', function () {
            this.classList.add('loaded');
          });
        });
      });
    });
  },
};
