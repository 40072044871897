/**
 * @typedef {Object} SelectedDownload
 * @property {string} id - Downloads defined ID (might be number or string)
 * @property {string} type - Downloads type (e.g. for tracking)
 */

/**
 * @typedef {Object} DownloadGroup
 * @property {boolean} allChecked - Whether all checkboxes are checked or not
 * @property {HTMLElement} groupCheckbox - The groups "check-all" checkbox
 * @property {HTMLInputElement[]} checkboxes - All checkboxes of the group
 * @property {Set<string>} selectedDownloads - All selected downloads of the group
 */

/**
 * @typedef {Object} ActiveMessageBox
 * @property {'info'|'danger'} level - Level of the message box, determines color
 * @property {HTMLDivElement} element - The message box element
 * @property {number} timeout - Id for auto-removal timeout
 */

/**
 * @callback singleDownload
 * @param {SelectedDownload} download - Selected download
 */

/**
 * @callback multiDownload
 * @param {SelectedDownload[]} downloads - Selected downloads
 */

/**
 * Manage the checkboxes and download buttons of a (specsheet) download section
 */
class SpecsheetDownloadSection {
  /**
   * All groups with their respective items and states
   * @type {Object.<string, DownloadGroup>}
   */
  dlGroups = {};

  /**
   * All currently selected downloads
   * @type {Map<string, SelectedDownload>}
   */
  selectedDownloads = new Map();

  /**
   * Button for downloading all selected items
   * @type {HTMLButtonElement}
   */
  downloadSelectedButton;

  /**
   * Area where message boxes are attached to
   * @type {HTMLDivElement}
   */
  messageArea;

  /** Counter to generate message box ids */
  messageBoxIdCounter = 0;

  /**
   * All currently active message boxes
   * @type {Map<string, ActiveMessageBox>}
   */
  activeMessageBoxes = new Map();

  /** Autom removal time for messageboxes in this section */
  messageBoxTimeout = 10e3; // 10s

  /**
   * @param {HTMLElement} downloadSection - Download section wrapper containing the section
   * @param {multiDownload} handleMultipleDownload - Callback for multiple downloads
   * @param {singleDownload} handleSingleDownload - Callback for single download
   * @param {number?} messageBoxTimeout - Timeout for auto-removal of message boxes
   */
  constructor(
    downloadSection,
    handleMultipleDownload,
    handleSingleDownload,
    messageBoxTimeout,
  ) {
    if (messageBoxTimeout) {
      this.messageBoxTimeout = messageBoxTimeout;
    }

    this.downloadSelectedButton = downloadSection.querySelector(
      '[data-js-dl-selected]',
    );

    this.spinner = this.downloadSelectedButton.querySelector('.specsheetSpinner');
    this.arrow = this.downloadSelectedButton.querySelector('.specsheet-download-arrow');


    this.downloadSelectedButton.addEventListener('click', (e) => {
      e.preventDefault();
      const downloads = Array.from(this.selectedDownloads.values());

      handleMultipleDownload(downloads);
    });

    this.messageArea = downloadSection.querySelector(
      '[data-js-dl-message-area]',
    );

    const selectGroupCheckboxes = downloadSection.querySelectorAll(
      '[data-js-group-check]',
    );

    // Init groups based on group checkboxes
    selectGroupCheckboxes.forEach((checkbox) => {
      const group = checkbox.dataset.jsGroupCheck;
      if (!this.dlGroups[group]) {
        this.dlGroups[group] = {
          allChecked: false,
          groupCheckbox: checkbox,
          checkboxes: [],
          selectedDownloads: new Set(),
        };
      }

      checkbox.addEventListener('change', (e) => {
        this._handleToggleGroup(group, e.currentTarget.checked);
      });
    });

    const downloadItems = downloadSection.querySelectorAll('[data-js-dl-item]');

    // Init individual downloads and their checkboxes
    downloadItems.forEach((downloadItem) => {
      const id = downloadItem.dataset.jsDlId;
      const type = downloadItem.dataset.jsDlType;
      const groupAttribute = downloadItem.dataset.jsDlGroup;
      const group = this.dlGroups[groupAttribute];
      const checkbox = downloadItem.querySelector('[data-js-dl-checkbox]');
      const singleDownloadAnchor = downloadItem.querySelector(
        '[data-js-single-dl]',
      );

      /**
       * Items download data for callbacks/handling
       * @type {SelectedDownload}
       */
      const download = {
        id,
        type,
      };

      if (group && checkbox) {
        group.checkboxes.push(checkbox);
      }

      singleDownloadAnchor?.addEventListener('click', (e) => {
        e.preventDefault();
        handleSingleDownload(download);
      });

      checkbox?.addEventListener('change', (event) => {
        const checked = event.target.checked;

        this._handleItemCheckboxChange(id, type, groupAttribute, checked);
      });
    });
  }

  /**
   * Check if the download button should be enabled
   */
  _checkDowloadSelectedButton() {
    if (this.selectedDownloads.size > 0) {
      this.downloadSelectedButton.removeAttribute('disabled');
    } else {
      this.downloadSelectedButton.setAttribute('disabled', '');
    }
  }

  /**
   * Handle the change of a single checkbox
   * @param {string} id
   * @param {string} type
   * @param {string} groupName
   * @param {boolean} checked
   */
  _handleItemCheckboxChange(id, type, groupName, checked) {
    const group = this.dlGroups[groupName];

    /**
     * Items download data for callbacks/handling
     * @type {SelectedDownload}
     */
    const download = {
      id,
      type,
    };

    if (checked) {
      group.selectedDownloads.add(`${id}-${type}`);
      this.selectedDownloads.set(`${id}-${type}`, download);

      if (group.selectedDownloads.size === group.checkboxes.length) {
        group.allChecked = true;
        group.groupCheckbox.checked = true;
      }
    } else {
      group.allChecked = false;
      group.groupCheckbox.checked = false;
      group.selectedDownloads.delete(`${id}-${type}`);
      this.selectedDownloads.delete(`${id}-${type}`);
    }

    // Check if download selected button should be enabled
    this._checkDowloadSelectedButton();
  }

  /**
   * Toggle the state of a group
   * @param {string} group - Groups unique identifier
   * @param {boolean} checked - State of checkboxes to set
   */
  _handleToggleGroup(group, checked) {
    this.dlGroups[group].allChecked = checked;
    this.dlGroups[group].checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
      checkbox.dispatchEvent(new Event('change'));
    });
  }

  /**
   * Display a new message box in the download section (e.g. for errors)\
   * Will be displayed above the download button
   * @param {string} message - Message to display
   * @param {'info'|'danger'} level - Level of the message box, determines color
   * @returns {number} Identifier for the message box
   */
  newMessageBox(message, level = 'info') {
    const id = this.messageBoxIdCounter++;
    const messageBox = document.createElement('div');
    messageBox.classList.add('message-box', `message-box--${level}`);
    messageBox.innerHTML = message;

    const timeout = setTimeout(() => {
      this.removeMessageBox(id);
    }, this.messageBoxTimeout);

    this.activeMessageBoxes.set(id, {
      level,
      element: messageBox,
      timeout,
    });

    this.messageArea.appendChild(messageBox);
    this.messageArea.classList.remove('d-n');
    return id;
  }

  /**
   * Update existing message box with new message/level
   * @param {string} id - Identifier for the message box
   * @param {string} message - Message to display
   * @param {'info'|'danger'} level - Level of the message box, determines color
   * @throws {Error} If no message box with this id exists
   */
  updateMessageBox(id, message, level = 'info') {
    const messageBox = this.activeMessageBoxes.get(id);

    if (!messageBox) {
      throw new Error('No message box with this id');
    }

    messageBox.element.innerHTML = message;

    if (messageBox.level !== level) {
      messageBox.element.classList.remove(`message-box--${messageBox.level}`);
      messageBox.element.classList.add(`message-box--${level}`);
      messageBox.level = level;
    }

    clearTimeout(messageBox.timeout);

    messageBox.timeout = setTimeout(() => {
      this.removeMessageBox(id);
    }, this.messageBoxTimeout);
  }

  /**
   * Remove a message box from the download section
   * @param {string} id - Previously set unique identifier
   */
  removeMessageBox(id) {
    const messageBox = this.activeMessageBoxes.get(id);

    if (messageBox) {
      messageBox.element.remove();
      this.activeMessageBoxes.delete(id);
    }

    // Compare with message area children, cause we can also have a message box
    // that was set by the backend
    if (this.messageArea.children.length === 0) {
      this.messageArea.classList.add('d-n');
    }
  }

  /**
   * Remove all message boxes from the download section
   */
  clearMessageBoxes() {
    this.activeMessageBoxes = new Map();
    this.messageArea.innerHTML = '';
    this.messageArea.classList.add('d-n');
  }

  /**
   * Hide the spinner of the download button
   */
  hideSpinner() {
    if (this.spinner === null || this.arrow === null)  return;
    this.spinner.classList.add('d-n');
    this.arrow.classList.remove('d-n');
  }
  
  /**
   * Show the spinner of the download button
   */
  showSpinner() {
    if (this.spinner === null || this.arrow === null)  return;
    this.arrow.classList.add('d-n');
    this.spinner.classList.remove('d-n');
  }

  /**
   * Disable the download button
   */
  disableDownloadButton() {
    this.downloadSelectedButton.setAttribute('disabled', '');
  }

  /**
   * Enable the download button
   */
  enableDownloadButton() {
    this.downloadSelectedButton.removeAttribute('disabled');
  }
}
