/**
 * Onepager
 * Neues One-Pager Konstrukt (nicht) nur für »Dussmann«
 * 
 * @dependencies
 * * jquery
 * * holy
 * 
 * @dependents
 * * side_menu_animate (dist/struktur/js/global/modules/side_menu_animate.js)
 * * detect (dist/struktur/js/global/erco.js)
 * * several onepage templates in cms-backend
 * * $document.ready (dist/struktur/js/global/erco.js)
 * * transition.ausklappen (dist/struktur/js/global/lichtbericht.js)
 * 
 * @commit Initial commit 2.0
 */

var onepager = (function () {

  // Lokale Variablen
  var exports = {};

  // Helferfunktion, um scrollToTop aktiv zu schalten
  var overwriteSideMenu = function ($onepager) {
    if (!$onepager) return;

    // overrwite side menu button up funcion
    side_menu_animate.assignButton(function () {

      if (detect.breakpoint() === 'lg') {
        $onepager.moveTo(1);
      } else {
        holy.scrollTo();
      }

    });

    return $onepager;
  }

  // Helferfunktion; bereitet Dom knoten vor
  var rearrangeItems = function ($root) {
    $root = $root || $('body');

    // Element, um den der OnePager gebastelt wird
    var onepageContainer = $root.find('.onepage-container_');

    // Falls Element nicht vorhanden > abbruch
    if (!onepageContainer.length) return;

    // Einzug entfernen
    $root.addClass('undoPadding');

    // Vorbereiten
    var onepageInner = onepageContainer.children('._inner').eq(0);
    var onepageItems = $root.find('.onepage-item_');

    onepageItems.appendTo(onepageInner);

    // Klassen initialisieren
    setClasses(onepageItems);

    setTimeout(function () {
      $root.find('.onepage-header_').addClass('_transition');
    }, 0);

    return onepageInner;
  }

  // Helferfunktion; verschiebt den Footer ins letzte Element
  var rearrangeFooter = function ($root) {
    $root = $root || $('body');

    // fixes for legacy onepager - pe 04.2021
    $root.find('.sitemap').remove();
    $root.find('.er-footer').appendTo($root.find('.onepage-item_').last());

    return $root;
  }

  // Helferfunktion; erstellt den onepager
  var createOnepager = function ($onepager) {
    if (!$onepager) return;

    $('body').addClass('with-onepager_');
    rearrangeFooter();

    // OnePage-Konstruktor aufrufen
    $onepager.onepage_scroll({
      sectionContainer: '.onepage-item_',   // sectionContainer accepts any kind of selector in case you don't want to use section
      easing: "ease-out",              // Easing options accepts the CSS3 easing animation such "ease", "linear", "ease-in",
      // "ease-out", "ease-in-out", or even cubic bezier value such as "cubic-bezier(0.175, 0.885, 0.420, 1.310)"
      animationTime: 1000,             // AnimationTime let you define how long each section takes to animate
      pagination: true,                // You can either show or hide the pagination. Toggle true for show, false for hide.
      updateURL: true,                // Toggle this true if you want the URL to be updated automatically when the user scroll to each page.
      beforeMove: function (index) {
        this.previousIndex = this.previousIndex || 0;
        var direction = index - this.previousIndex;

        if (direction > 0) window.dispatchEvent(new Event('holy:scrollDown'));
        if (direction < 0) window.dispatchEvent(new Event('holy:scrollUp'));
        if (direction < 0 && index <= 1) window.dispatchEvent(new Event('holy:toTop'));
        if (direction > 0 && this.previousIndex <= 1) window.dispatchEvent(new Event('holy:fromTop'));

        this.previousIndex = index;
        setClasses($onepager.children(), index);
      },  // This option accepts a callback function. The function will be called before the page moves.
      afterMove: function (index) { },   // This option accepts a callback function. The function will be called after the page moves.
      loop: false,                     // You can have the page loop back to the top/bottom when the user navigates at up/down on the first/last page.
      keyboard: true,                  // You can activate the keyboard controls
      responsiveFallback: 1024,        // You can fallback to normal page scroll by defining the width of the browser in which
      // you want the responsive fallback to be triggered. For example, set this to 600 and whenever
      // the browser's width is less than 600, the fallback will kick in.
      direction: "vertical"            // You can now define the direction of the One Page Scroll animation. Options available are "vertical" and "horizontal". The default value is "vertical".
    });
    return $onepager;
  };

  // Helferfunktion; initialisiert die Tabbar
  var nextOnepager = function () {
    var buttonsSelector = '.onepage-tabbar_ .nav a';
    var buttons = $(buttonsSelector);
    var preload = {};

    // Einschub erhöhen
    if (buttons.length) {
      $('body').addClass('with-onepage-tabbar_');
      rearrangeFooter();
    }

    // cache aufbauen
    buttons.each(function () {
      var url = $(this).attr('href');

      $.get(url, function (data) {
        preload[url] = data;
      });
    });


    // Event delegation
    $(document).on('click', buttonsSelector, function (e) {

      if (detect.breakpoint() !== 'lg') return;

      if ($(this).is('#pkm_objekt_6456')) return;  // damn sonderfall ss 20170423 wegen LEDs in ERCO-Leuchten (pdf)")


      // regulären Click hijaxen
      e.preventDefault();

      // falls bereits aktive: abbrechen
      if ($(this).parent().hasClass('active')) return;

      // cache auslesen
      var url = $(this).attr('href');
      var newthing = $('<main>').html(preload[url]);
      var title = newthing.find('title').html();

      // Footer verschieben
      rearrangeFooter(newthing);

      // unnötiges entfernen
      newthing = newthing.children().not('title, meta, link, script, .new-menu_, .side_menu_outer, #myerco-footer, footer.highlight');

      // dom vorbereiten
      $('title').html(title);
      $('.onepage-header_, .onepage-container_').remove();
      $('body').prepend(newthing);

      // reinitialisieren
      var $newpager = rearrangeItems();
      createOnepager($newpager);
      overwriteSideMenu($newpager);

      // url und scrollposition aufräumen
      // lustig auf die 1 zu springen - da hätten wir uns den ganzen sums sparen können :)
      $newpager.moveTo(1);
      history.pushState(null, title, url + '#1');
    });
  };


  // Helferfunktion: Klassen anpassen
  var setClasses = function (itms, idx) {
    idx = idx || 1;
    if (!itms.length) return;

    itms.removeClass('_below-active _above-active').each(function (i) {
      if (i + 1 < idx) $(this).addClass('_above-active');
      if (i + 1 > idx) $(this).addClass('_below-active');
    });
  };


  // Lokale Funktionen
  var init = function () {
    var $onepager = rearrangeItems();
    createOnepager($onepager);
    overwriteSideMenu($onepager);
    nextOnepager();

  };


  var destroy = function () {

    // remove onepage eventListener
    $(window).off('resize.onepage-scroll');
    $(document).off('mousewheel DOMMouseScroll MozMousePixelScroll keydown');

    // remove classes
    $('body').removeClass('undoPadding with-onepager_');
    document.body.className = document.body.className.replace(/viewing-page-\d+/g, '');

    // assign scrollTo button
    side_menu_animate.assignButton(function () {
      holy.scrollTo();
    });

    // remove Pagination
    $('.onepage-pagination').remove();
  };

  // Exports-Objekt mit lokalen Funktionen verknüpfen
  exports.init = init;
  exports.destroy = destroy;
  exports.nextOnepager = nextOnepager;

  // Exports-Objekt global veröffentlichen
  return exports;
})();