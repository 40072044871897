// Transition ausklappen überschreiben
var overwriteTransition = function () {

	var $overlay = $('.lichtbericht-container_ > .lichtbericht-overlay');
	if (!$overlay.length) return;





	transition.ausklappen = function(me){
		var self	= $(me).closest('.expander_outer'),
			url		= $(me).attr('href');




		// mobil doch lieber die seite wechseln
		if (detect.breakpoint() !== 'lg' || self.attr('data-scroll-after-close')) window.location = url;


		holy.scrollTo( 500 );


		header.scroll.ele.addClass('spezial_hideHead')
		self.one('holy:scrollDone',function(){
			$(document).one('holy:scrollStart',function(){
				header.scroll.ele.removeClass('spezial_hideHead')
				return false;
			})
		})


		$.get( url, function( data ) {

			// Ajax reinigen
			var $new = $( '<div>'+data.replace(/<body/g , "<div data-ajax-wrap")+'</div>' )	// body-tag ersetzen und selektierbar machen
				.find('[data-ajax-wrap]').empty().removeAttr('data-ajax-wrap')				// selektieren, leeren und data-attribut entfernen
				.addClass("ajax bottom-1")													// notwendige Klassen anheften
				.append( data.split('<!-- EO header.php -->')[1] )							// Content von menü befreien
				.find('#copyright').remove().end()											// copyright entfernen
				.find('script[src]').remove().end()												// scripte entfernen


			// Seitenmenü-Button bei Bedarf anheften;
			$new.on('holy:observeIn',function(){
				side_menu_animate.assignClose( $new )
				side_menu_animate.showClose()
				return false; // stop bubbling
			})

			$new.on('holy:observeOut',function(){
				side_menu_animate.assignClose()
				side_menu_animate.hideClose()
				return false; // stop bubbling
			})



			// Schließen Event anheften
			$new.find('.expander_close').attr('data-transition-url',url).on('click',function(){
				var index = transition.in_view.indexOf( url );
				transition.in_view.splice(index, 1); // url aus dem aktuellen State entfernen

				transition.einklappen(this);
				history.pushState({'data':transition.og_title,'in_view':transition.in_view},transition.og_title,transition.og_url);
				side_menu_animate.hideClose();
			});


			// observe
			holy.addToObserve( $new )


			// an den DOM anheften
			$overlay.append($new);

			// FadeIn
			$overlay.parent().addClass('_active-overlay');

			// irgendwas
			window.dispatchEvent(new Event('km:ajax'));

			// browser url und title anpassen
			var h2 = $new.find('h2').text();

			// Anpassungen
			transition.in_view.push(url); // url zum aktuellen State hinzufügen
			history.pushState({'data': h2,'in_view':transition.in_view},h2 ,url );

			listener.start();
			after_ajax.start();
			deferred.start();
			
			// Initializing the contact forms bk 22.2.18
			productoverview.initContactForms();
			
			//Mustache Templates rendern //vs 20180226
			globals.renderMustacheTemplates();

			//Sticky Footer für Microsites initialisieren
			parallaxContentStickyFooter();


			// onepager initialisieren
			onepager.init();


			//Wird verwendet umd die Module 2.1. zu initialisieren
			document.dispatchEvent(new Event('ajaxTransitionHomepageReady'));

			if (typeof ga == 'function'){
				ga('send', 'event', 'story-overlay-open', 'click', url);
			}

			// check if in view
			setTimeout(holy.checkObserve, 500);
		});
	};

	transition.einklappen = function(me){
		var $this = $(me).closest('.ajax');
		var self = $( '.' + $this.data('id-objekt'));

		if (typeof ga == 'function'){
			ga('send', 'event', 'story-overlay-close', 'click', $this.data('id-objekt'));
		}

		$overlay.parent().removeClass('_active-overlay');

		setTimeout(function () {
			$overlay.empty();

			// onepager zerstören
			onepager.destroy();

			side_menu_animate.hideClose();
		}, 500);
		setTimeout(function () {
			holy.scrollTo(self, 500);
		}, 0);





		header.scroll.ele.addClass('spezial_hideHead')
		self.one('holy:scrollDone',function(){
			$(document).one('holy:scrollStart',function(){
				header.scroll.ele.removeClass('spezial_hideHead')
				return false;
			})
		})

		holy.rmObserve( $this );
	}

};

onload_functions.push('overwriteTransition();');
