/* Selbständige Juxtapose Slider Animation
============================================================*/

var juxtaposeInitialAnimation = (function () {
  var exports = {};
  var elements = null;
  var init = function () {
    elements = document.querySelectorAll('[data-juxtapose-initial-animation]');
    for (var i = 0; i < elements.length; i++) (function (i) {
      var element = elements[i];

      //Wenn sich das Element im Viewport befindet
      if (window.toolkit.isElementTopInViewport(element)) {
        setTimeout(function () {

          //wird Slider bewegt
          updateSlider(element);
        }, 2000);
      }

      holy.addToObserve(element);
      $(element).one('holy:observeIn', function (e) {
        setTimeout(function () {
          updateSlider(e.target);
        }, 1000);
      });
    })(i)
  };

  //Bewegt den Slider von einem Juxtapose Element in die Mitte
  function updateSlider(target) {
    var found = juxtapose.sliders.find(function (element) {
      return (element.wrapper == target)
    });
    if (found) {
      found.updateSlider(50, true);
    }
  }

  exports.init = init;
  return exports;

})();

/* Overlay Tab Navigation
============================================================*/
overlayTabNavigation = function (element) {
  let exports = {};
  let store = {};

  let init = function () {

    //Select nav elements
    let links = element.querySelectorAll('.navlink');

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', loadOverlay);
    }
  };

  let loadOverlay = function (e) {
    e.preventDefault();
    let url = e.target.href;

    //Load Content
    $.get(url, function (data) {

      let overlayBoxContainer = document.querySelector('.overlay-box');

      // Ajax reinigen
      let newBody = data.split('<!-- EO header.php -->')[1];

      // Fill overlaybox with Content
      overlayBoxContainer.innerHTML = newBody;

      //Select Tab Navi in Career Site
      let tabNaviElement = document.querySelector('.overlay-box[data-specification=karriere] .nav-bar');

      // Broserhistory
      //Get the title
      let currentTitle = data.slice(data.indexOf('<title>') + 7, data.indexOf('</title>'));

      history.pushState({ url }, currentTitle, url);

      //If there is a Tab Navigation
      if (tabNaviElement) {

        overlayTabNavigation(tabNaviElement).init();
      }

      //Wird verwendet umd die Module 2.1. zu initialisieren
      document.dispatchEvent(new Event('ajaxTransitionHomepageReady'));

      //Lazy Loading initialisieren
      $("img[data-src]").not(".hidden .nolazy, .tab-pane .lazynav, .isotope").unveil(200, function () {
        $(this).on('load', function () {
          $(this).addClass("loaded");
        });
      });

      // Schließen Event anheften
      $(overlayBoxContainer).find('.expander_close').on('click', function () {
        overlayBox.closeOverlay($('.overlay-box'));
      });

      //Event mit Close Buttons verknüpfen
      side_menu_animate.assignClose($(overlayBoxContainer));
    });


  }

  store.element = element;
  exports.init = init;
  return exports;
};

/* Overlaybox for career site // vs 20190531
============================================================*/
var overlayBox = (function () {
  var exports = {};

  var scrollTop = null;
  var cssClasses = 'overlay-box';
  var lastURL = null;
  // var naviWrapper = null;

  //Classes for the overlay-box can be appended via an array
  var init = function (classes) {
    var showTriggerOverlayButtons = document.querySelectorAll('[data-trigger-show-overlay]');

    if (classes) {
      cssClasses += ' ' + classes.join(' ');
    }

    //Clickevents, zum Anzeigen der Overlays, initialisieren
    for (var i = 0; i < showTriggerOverlayButtons.length; i++) {
      showTriggerOverlayButtons[i].addEventListener('click', function (e) {

        //if we are on desktop devices
        //and the target is not the button to show the overlay, because we don't want to load the overlay twice
        if (media.breakpoint.refreshValue() !== media.breakpoint.XS && media.device.refreshValue() === media.device.DESKTOP && !e.target.hasAttribute('data-show-overlay')) {

          var url = e.currentTarget.querySelector('[data-show-overlay]').getAttribute('href');
          loadOverlay(url);
        }
      });
    }

    var showOverlayButtons = document.querySelectorAll('[data-show-overlay]');
    for (var i = 0; i < showOverlayButtons.length; i++) {
      showOverlayButtons[i].addEventListener('click', function (e) {
        if (media.breakpoint.refreshValue() !== media.breakpoint.XS || media.device.refreshValue() === media.device.DESKTOP) {
          e.preventDefault();
          var url = e.currentTarget.getAttribute('href');
          loadOverlay(url);
        }
      });
    }

    if (showTriggerOverlayButtons.length > 0 || showOverlayButtons.length > 0) {
      return;
    }

    //Overlay via Event Delegation laden und anzeigen
    var showOverlayEventDelegates = document.querySelectorAll('[data-event-delegate-show-overlay]');
    for (let j = 0; j < showOverlayEventDelegates.length; j++) {
      showOverlayEventDelegates[j].addEventListener('click', function (e) {
        if (e.target.hasAttribute('data-show-overlay')) {
          e.preventDefault();
          var url = e.target.getAttribute('href');
          loadOverlay(url);
        }
      });
    }

    if (showOverlayEventDelegates.length > 0) {
      return;
    }
  };

  var closeOverlay = function ($new, pushState = true) {

    //Verknüfung von Event mit Close Buttons auflösen
    side_menu_animate.assignClose();

    //Close Button in Sidebar ausblenden
    side_menu_animate.hideClose();

    //Button, um an den Anfang der Seite zu scrollen, einblenden
    side_menu_animate.forceHideUpRemove();

    // Mute button einblenden
    side_menu_animate.forceHideMuteRemove();

    $new.removeClass('-visible');
    document.documentElement.classList.remove('_no-scroll');
    document.body.classList.remove('_no-scroll');

    $('.js-li-gallery').flickity('reposition');

    setTimeout(function () {
      $('html, body').stop().animate({
        scrollTop: overlayBox.scrollTop
      }, 1);
    }, 0);


    $new.one('transitionend', function () {
      $new.remove();
      // naviWrapper.classList.remove('-forceHide');

    });


    // Broserhistory
    var currentTitle = document.querySelector('title').innerHTML;
    var currentURL = window.location.href;

    if (pushState) {
      history.pushState(undefined, currentTitle, lastURL);
    }
  };

  var loadOverlay = function (url, pushState = true) {

    $.get(url, function (data) {

      lastURL = window.location.href;

      // Ajax reinigen
      var $new = $('<div>' + data.replace(/<body/g, "<div data-ajax-wrap") + '</div>')   // body-tag ersetzen und selektierbar machen
        .find('[data-ajax-wrap]').empty().removeAttr('data-ajax-wrap')                   // selektieren, leeren und data-attribut entfernen
        .addClass(cssClasses)
        .append(data.split('<!-- EO header.php -->')[1])                                 // Content von menü befreien
        .find('#newsletter-bottom-bar').remove().end()                                   // Newsletter bottom bar entfernen
        .find('#copyright').remove().end()                                               // copyright entfernen
        .find('script[src]').remove().end()                                              // scripte entfernen
        .find('#myModal').remove().end();                                                // remove modal (one that exists is enough)

      side_menu_animate.assignClose($new);
      side_menu_animate.showClose();

      //Minheight setzen, damit Footer nicht in der Seite steht, falls im Overlay nur ein Element existiert.
      setMinHeight.init($new[0])

      //Scroll-Position merken (Mit Fallback für IE10, da dieser window.scrollY nicht kennt)
      overlayBox.scrollTop = typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY;

      var $element = $new.find('.container').first();
      // naviWrapper = document.querySelector('.new-menu_._wrap');
      // naviWrapper.classList.add('-forceHide');

      // browser url und title anpassen
      var h2 = $new.find('h2').text();

      // Broserhistory
      if (pushState) {
        history.pushState({ url }, h2, url);
      }

      setTimeout(function () {
        $new.addClass('-visible');

        fullscreen_slideshow.init_links();

        //Slider initialisieren
        li_gallery.init();

        var fadeElements = $new.get(0).querySelectorAll('[data-fade-slide-show]');


        if (fadeElements) {
          for (var i = 0; i < fadeElements.length; i++) {
            var fadeSlideShowWithNav = new FadeSlideShowWithNavi(fadeElements[i]);
            fadeSlideShowWithNav.init();
          }
        }

        //Wird verwendet umd die Module 2.1. zu initialisieren
        document.dispatchEvent(new Event('ajaxTransitionHomepageReady'));

        //Event mit Close Buttons verknüpfen
        side_menu_animate.assignClose($('.overlay-box'));

        //Close Button in Sidebar einblenden
        side_menu_animate.showClose();

        //Button, um an den Anfang der Seite zu scrollen, ausblenden
        side_menu_animate.hideUp();

        //Event mit Close Buttons verknüpfen
        side_menu_animate.assignClose($('.overlay-box'));

        //Close Button in Sidebar einblenden
        side_menu_animate.showClose();

        //Button, um an den Anfang der Seite zu scrollen, ausblenden
        side_menu_animate.hideUp();

        //Event mit Close Buttons verknüpfen
        side_menu_animate.assignClose($('.overlay-box'));

        //Close Button in Sidebar einblenden
        side_menu_animate.showClose();

        //Button, um an den Anfang der Seite zu scrollen, ausblenden
        side_menu_animate.hideUp();

        //Button, um an den Anfang der Seite zu scrollen, ausblenden
        side_menu_animate.forceHideUp();

        //	Mute button ausblenden (falls er nicht benötigt wird)
        side_menu_animate.forceHideMute();


        $new.one('transitionend', function () {
          handleTransitionEnd();

        });

      }, 0);


      // an den DOM anheften
      $('body').append($new);

      // Schließen Event anheften
      $('.overlay-box').find('.expander_close').on('click', function () {
        closeOverlay($('.overlay-box'));
      });

      // init modal links for ajax content, if given
      modaldata.func.init();

      document.documentElement.classList.add('_no-scroll');

      let tabNaviElement = document.querySelector('.overlay-box[data-specification=karriere] .nav-bar');
      if (tabNaviElement) {
        overlayTabNavigation(tabNaviElement).init();

        //Hide Navbar
        menuContainer.barHide();
      }
    });
  };

  var handleTransitionEnd = function () {

    document.body.classList.add('_no-scroll');

    //Scroll-Position setzen
    window.scroll(0, overlayBox.scrollTop);

  };


  // handle pop state
  window.addEventListener('popstate', (event) => {
    if (event.state?.url) {
      loadOverlay(event.state.url, false);
    } else if (lastURL) {
      closeOverlay($('.overlay-box'), false);
    }
  });

  exports.closeOverlay = closeOverlay;
  exports.init = init;

  return exports;
})();


/* Landingpage Tabs - Wechsel der Hintergrundfarbe
============================================================*/
var dynamicBackgroundChange = (function () {
  var exports = {};
  var tabs = null;
  var self = this;
  var targetElement = null;
  var mapsInited = false;

  var init = function () {
    tabs = document.querySelectorAll('[data-specification="licht_planen"] [data-toggle="tab"]');

    if (tabs.length === 0)
      return;

    targetElement = tabs[0].closest('section').children[1];


    for (var i = 0; i < tabs.length; i++) {
      if (tabs[i].parentNode.classList.contains('active')) {
        changeBackgroundcolor(i);
      }

      tabs[i].addEventListener('click', function (e) {
        var index = getIndex(e.currentTarget);
        changeBackgroundcolor(index);
      });
    }
  };

  var changeBackgroundcolor = function (elementIndex) {
    if (elementIndex === 0) {
      targetElement.classList.add('_bg-lichtwissen');
      targetElement.classList.remove('white');
    }

    else {
      targetElement.classList.add('white');
      targetElement.classList.remove('_bg-lichtwissen');
    }

    if (elementIndex === 1 && !mapsInited) {
      maps.init();
      mapsInited = true
    }

  };

  var getIndex = function (el) {
    var children = el.parentNode.childNodes;
    for (var i = 0; i < tabs.length; i++) {
      if (tabs[i] == el) {
        return i;
      }
    }
    return -1;
  }
  exports.init = init;
  return exports;
})();

/* Definiert eine min-height für einen Container, wenn dieser das einzige Content-Element
   auf der Seite ist, damit der Footer nicht mitten in der Seite steht
============================================================*/
var setMinHeight = (function () {
  var exports = {};
  var init = function (element) {
    if (!element)
      return;

    var articles = element.querySelectorAll('article[data-id-seite], .li-container_, section.container, .container');
    if (articles && articles.length === 1)
      articles[0].classList.add('_min-height');

  };
  exports.init = init;
  return exports;
})();



onload_functions.push(function () {
  juxtaposeInitialAnimation.init();
  if (document.querySelector('[data-specification="licht_planen"]') || document.querySelector('[data-specification="service"]')) {
    overlayBox.init();
    dynamicBackgroundChange.init();
  }

  if (document.querySelector('[data-specification="karriere"]')) {
    let classes = ['white'];
    overlayBox.init(classes);
  }
  var body = document.querySelector('body[data-specification="licht_planen"], body[data-specification="mediassetpool"]');
  if (body)
    setMinHeight.init(body);

});