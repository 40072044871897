class Overlay {
  constructor(triggerElement) {
    this.overlayElement = document.querySelector(
      `[data-ms-overlay="${triggerElement.dataset.msShowAsOverlay}"]`
    );

    if (!this.overlayElement) {
      // exit if no overlay element is found
      return;
    }

    // prepare variables
    const { msOverlayVisibleClass, msOverlayScrollLockClass } = this.overlayElement.dataset;
    this.overlayVisibleClass = msOverlayVisibleClass;
    this.scrollLockClass = msOverlayScrollLockClass;
    this.closeElement = this.overlayElement.querySelector('[data-ms-close-overlay]');

    // init event listener
    triggerElement.addEventListener('click', this.handleShowOverlay);
  }

  static init() {
    document.querySelectorAll('[data-ms-show-as-overlay]').forEach((triggerElement) => {
      new Overlay(triggerElement);
    });
  }

  handleShowOverlay = (e) => {
    // set classes
    this.overlayElement.classList.add(this.overlayVisibleClass);
    document.body.classList.add(this.scrollLockClass);

    // set event listeners
    document.addEventListener('keydown', this.handlePressEscape);

    setTimeout(() => {
      document.body.addEventListener('click', this.handleBodyClick);
    }, 0);

    if (this.closeElement) {
      this.closeElement.addEventListener('click', this.handleCloseElementClick);
    }
  };

  hideOverlay() {
    // remove classes
    this.overlayElement.classList.remove(this.overlayVisibleClass);
    document.body.classList.remove(this.scrollLockClass);

    // remove event listeners
    document.removeEventListener('keydown', this.handlePressEscape);
    document.body.removeEventListener('click', this.handleBodyClick);
    if (this.closeElement) {
      this.closeElement.removeEventListener('click', this.handleCloseElementClick);
    }
  }

  handleCloseElementClick = (e) => {
    this.hideOverlay();
  };

  handleBodyClick = (e) => {
    if (!e.target.closest('[data-overlay-inner]')) {
      this.hideOverlay();
    }
  };

  handlePressEscape = (e) => {
    if (e.key === 'Escape') {
      this.hideOverlay();
    }
  };
}
