
const showMoreData = {

  layoutVariants: {
    'oldSchoolTiles' : `<div data-tile=1 class="col-6 col-sm-4 col-lg-3  {{{lc_beleuchtungsart}}}  {{{lc_land}}} {{{lc_gattungsname1}}}  {{{lc_gattungsname2}}}  {{{lc_gattungsname3}}} {{{lc_gattungsname4}}} {{{lc_gattungsname5}}} {{{lc_gattungsname6}}}">
  <a href="{{{link text:none}}}" class="row block highlight itContainerBlock" data-creation-date="{{{text U_Erstellungsdatum:1}}}">
    <div class="col-lg-6 flush"><img src="/struktur/php/timthumb/images.php/src=images/representative/{{regionskuerzel}}-{{pkm_objekt}}.jpg;w=260;h=255;q=75" alt="{{{text objektname_nolink:1;}}}" /></div>
    <div class="col-lg-6 itContainerText white"><h5><strong>{{{text objektname_nolink:1;}}}</strong><br/><small>{{{text nummer:gattungsname;}}} - {{{text nummer:30;thema:objektdaten;}}}</small></h5>
      <ul class="top-1 list-unstyled">
        <li class="flush km-block metadata-clean">Architekt: {{{text nummer:10;thema:objektdaten;no_hrefbuild:1}}}</li>
        <li class="flush km-block metadata-clean">Lichtplaner: {{{text nummer:40;thema:objektdaten;no_hrefbuild:1}}}</li>
        <li class="flush km-block metadata-clean">Fotograf: {{{text nummer:20;thema:objektdaten;no_hrefbuild:1}}}</li>
      </ul>
    </div>
  </a>
</div>`
    
    

  
  },
};




