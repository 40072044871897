/**
 * Definition of actions:
 * ======================
 *
 * General
 * -------
 * - tag (string)
 *    The tag to be used for the emarsys event
 * - once (boolean)
 *    If true, the action will only be triggered once on a pageload. After a reload, the action
 *    can be triggered again. If false or undefined, the action will be triggered every time.
 * - name (string)
 *    The name of the action. If not provided, the action name will be the key of the action object.
 *    This is useful if you want to trigger the same action multiple times with different conditions
 *    or triggers. If once is true, the action name will be used to check if the action has already
 *    been triggered, independent of the condition or trigger element.
 *
 *
 * Static actions
 * --------------
 * Actions that are triggered on page load.
 *
 * - condition (object or function)
 *    The condition to be met. If a function is provided, it will be called with the EmarsysDataLayer
 *    class instance as context argument (ctx) to access class variables. If it is true, the action
 *    will be triggered.
 * - valueObject (object or function)
 *    The value object to be used for the emarsys event. If a function is provided, it will be
 *    called with the EmarsysDataLayer class instance as context argument (ctx) to access class
 *    variables. The concrete value of the value object depends on the emarsys event.
 *
 *
 * Event actions
 * -------------
 * Actions that are triggered by events.
 *
 * - event (object)
 *    Describes the event that triggers the action.
 *    - selector (string)
 *       The selector to be used to find the elements that trigger the event.
 *    - type (string)
 *       The type of the event. Can be any valid event type, e.g. 'click', 'keyup', 'keydown', etc.
 *    - keyCode (number)
 *       The keyCode of the event. Only needed if the event type is 'keyup' or 'keydown'.
 *    - domMutation (boolean)
 *       If true, the event listener will automatically readded after dom mutations have been taken
 *       place. Uses the DOMObserver class to observe dom mutations.
 *
 *
 * External actions
 * ----------------
 * Actions that are triggered from outside the EmarsysDataLayer class. They are triggered by calling
 * the triggerAction method, which accepts an action name (see general > name) and an event object
 * instance. They are configured like event actions.
 */

const emarsysConfig = {
  referrerOptions: {
    ignore: ['erco.com'],
    searchEngines: ['google', 'bing', 'yahoo', 'duckduckgo', 'ecosia'],
  },
  staticActions: {
    // funnel website visit
    funnel_website_visit: {
      tag: 'DataLayer',
      condition: (ctx) => ctx.websiteLabeling !== null && ctx.websiteLabeling.funnel !== null,
      valueObject: function (ctx) {
        // fallback, if condition was not met (just to prevent errors)
        if (!emarsysConfig.staticActions.funnel_website_visit.condition(ctx)) {
          return { Funnel: '' };
        }
        const { funnel } = ctx.websiteLabeling;
        return { Funnel: funnel.charAt(0).toUpperCase() + funnel.slice(1).toLowerCase() };
      },
    },

    // MyERCO: registration
    myerco_registration: {
      tag: 'Interaction',
      condition: document.referrer.indexOf('myerco/auth/activate') > -1,
      valueObject: {
        Event: 'Registration',
        Channel: 'myERCO',
      },
    },
  },
  eventActions: {
    // shared content
    shared_content_facebook: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="content_shared"][title="Facebook"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'content_shared',
        Channel: 'facebook',
      },
    },

    shared_content_twitter: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="content_shared"][title="Twitter"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'content_shared',
        Channel: 'twitter',
      },
    },

    shared_content_pinterest: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="content_shared"][title="Pinterest"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'content_shared',
        Channel: 'pinterest',
      },
    },

    shared_content_linkedin: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="content_shared"][title="LinkedIn"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'content_shared',
        Channel: 'linkedin',
      },
    },

    shared_content_email: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="content_shared"][title="E-Mail"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'content_shared',
        Channel: 'email',
      },
    },

    // used product finder
    used_product_finder: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="product_finder_used"]',
        type: 'click',
      },
      valueObject: {
        Event: 'Product_Finder',
        Use: 'general',
      },
    },

    // downloaded marketing content
    downloaded_marketing_content: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="marketing_content_downloaded"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: (event) => {
        const currentURL = window.location.href;
        const valueObject = {
          Event: 'Download',
          Content: 'Marketing',
          URL: currentURL,
        };

        // check if user is currently on a content access page
        if (!/\/contentaccess\//.test(currentURL)) {
          const targetURL = event.target.closest('a').getAttribute('href').split('?').shift();
          const lastUrlPath = targetURL.split('/').pop();

          // check if target url is a file
          if (/\.\w+$/.test(lastUrlPath)) {
            valueObject.URL = targetURL;
          }
        }
        return valueObject;
      },
    },

    // downloaded product data
    downloaded_product_data: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="product_data_downloaded"]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'Download',
        Content: 'Product_Data',
      },
    },

    // filled in a communication form
    filled_in_communication_form: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="communication_form"]',
        type: 'submit',
      },
      valueObject: {
        Event: 'Form_Request',
        Relation: 'general',
      },
    },

    // filled in a product/project related form
    filled_in_project_form: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="project_form"]',
        type: 'submit',
      },
      valueObject: {
        Event: 'Form_Request',
        Relation: 'specific',
      },
    },

    // Webinar/Event registration
    webinar_registration: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="webinar_registration"]',
        type: 'click',
      },
      valueObject: {
        Event: 'Registration',
        Channel: 'Webinar',
      },
    },

    // subscribed for Lichtbericht
    order_lichtbericht: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="order_lichtbericht"]',
        type: 'submit',
      },
      valueObject: {
        Event: 'Registration',
        Channel: 'Lichtbericht',
      },
    },

    // MyERCO: add product to watchlist
    myerco_collect_item: {
      tag: 'Interaction',
      event: {
        selector: '[data-collect-item]',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'AddProduct',
        Target: 'Watchlist',
      },
    },

    // Newsletter registration
    newsletter_registration: {
      tag: 'Interaction',
      event: {
        selector: '[data-user-interaction="newsletter_registration"]',
        type: 'submit',
      },
      valueObject: {
        Event: 'Registration',
        Channel: 'Newsletter',
      },
    },

    // MyERCO: create collection via click
    myerco_create_collection_click: {
      name: 'myerco_create_collection',
      tag: 'Interaction',
      event: {
        selector: '.myerco-articlelists-scope .new-collection > .action',
        type: 'click',
        domMutation: true,
      },
      valueObject: {
        Event: 'Creation',
        Target: 'Collection',
      },
    },

    // MyERCO: create collection via enter
    myerco_create_collection_enter: {
      name: 'myerco_create_collection',
      tag: 'Interaction',
      event: {
        selector: '.myerco-articlelists-scope .new-collection > .input',
        type: 'keyup',
        domMutation: true,
        keyCode: 13,
      },
      valueObject: {
        Event: 'Creation',
        Target: 'Collection',
      },
    },
  },
  externalActions: {
    // used configurator
    configurator_used: {
      tag: 'Interaction',
      valueObject: {
        Event: 'Used_Configurator',
      },
    },
  },
};
