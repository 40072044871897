/**
 * @name Dialog
 * @description Creates a dialog overlay from a trigger element
 */

class Dialog {
  /**
   * @param {HTMLElement} triggerElement - The element that triggers the overlay
   */
  constructor(triggerElement) {
    this.triggerElement = triggerElement;
    this.overlayConfig = JSON.parse(triggerElement.getAttribute('data-js-show-as-dialog'));
    this.overlaySourceId = this.overlayConfig.source;
    this.overlayElements = document.querySelectorAll(`[data-js-show-as-dialog-element="${this.overlaySourceId}"]`);
    this.overlayContainer = null;
    this.videoAnimations = [];
    this.init();
  }

  init() {
    this.initOverlay();

    this.triggerElement.addEventListener('click', (event) => {
      event.preventDefault();
      this.openOverlay();
    });
  }

  initOverlay() {
    // Check if the overlay already exists
    const existingOverlay = document.querySelector(`dialog[data-dialog-id="${this.overlaySourceId}"]`);
    if (existingOverlay) {
      this.overlayBase = existingOverlay;
      this.overlayContainer = this.overlayBase.querySelector('.dialog__inner');
      return;
    }

    // Create the overlay
    this.overlayBase = document.createElement('dialog');
    this.overlayBase.classList.add('dialog');
    this.overlayBase.dataset.dialogId = this.overlaySourceId;
    this.overlayContainer = document.createElement('div');
    this.overlayContainer.classList.add('dialog__inner', 'theme-light', 'theme-light--10');
    this.overlayBase.appendChild(this.overlayContainer);

    const closeButton = document.createElement('button');
    closeButton.setAttribute('data-close-overlay', '');
    closeButton.setAttribute('aria-label', 'close');
    closeButton.classList.add('button', 'button--unstyled', 'dialog__close-button');
    closeButton.innerHTML = '<span class="icon icon--close"></span>';
    closeButton.addEventListener('click', this.closeOverlay.bind(this));
    this.overlayContainer.appendChild(closeButton);
    this.overlayElements.forEach((element) => {
      this.overlayContainer.appendChild(element);
    });
    document.body.appendChild(this.overlayBase);
  }

  openOverlay() {
    this.overlayBase.showModal();
    this.overlayBase.classList.add('dialog--open');
    document.body.classList.add('er-scroll--lock');
    this.initVideoAnimations();
    document.body.addEventListener('click', this.handleBodyClick.bind(this));
    document.addEventListener('keydown', this.handlePressKey.bind(this));
  }

  closeOverlay() {
    const closeOverlayAfterTransitioned = () => {
      this.overlayBase.close();
      this.destroyVideoAnimations();

      document.body.classList.remove('er-scroll--lock');
      document.body.removeEventListener('click', this.handleBodyClick);
      document.removeEventListener('keydown', this.handlePressKey);
      this.overlayBase.removeEventListener('transitionend', closeOverlayAfterTransitioned);
    };

    this.overlayBase.addEventListener('transitionend', closeOverlayAfterTransitioned);
    this.overlayBase.classList.remove('dialog--open');
  }

  initVideoAnimations() {
    this.overlayContainer.querySelectorAll('[data-js-video-animation]').forEach((videoContainer) => {
      this.videoAnimations.push(
        new VideoAnimation(videoContainer, { loadImmediately: true }),
      );
    });
  }

  destroyVideoAnimations() {
    this.videoAnimations.forEach((video) => {
      video.destroy();
    });
    this.videoAnimations = [];
  }

  handlePressKey(event) {
    if (event.key === 'Escape') {
      // Prevent default <dialog> behavior
      event.preventDefault();

      // Close the dialog
      this.closeOverlay();
    }
  }

  handleBodyClick(event) {
    if (event.target === this.overlayBase) {
      this.closeOverlay();
    }
  }

  /**
   * @description Initializes all elements with the data-js-show-as-dialog attribute
   */
  static initOverlays() {
    const triggerOverlayElements = document.querySelectorAll('[data-js-show-as-dialog]');
    triggerOverlayElements.forEach((element) => {
      new Dialog(element);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  Dialog.initOverlays();
});
