/**
 * Fade Slide Show with Navigation
 * Erstellt eine FadeSlideshow, die über eine Navigation gesteuert werden kann.
 * 
 * @dependencies
 * * jquery
 * * holy
 * 
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * overlaybox – (lichtwissen.js)
 * * data attribute [data-fade-slide-show] and [data-fade-index] used in several modules in cms-backend
 * 
 * @commit
 * * Initial commit 2.0
 * * v.schafer (2020-10-20) – Optimized fade-in and fade-out of navigation elements in fadeslideshow
 * * v.schaefer (2022-04-28) – Adds toggle modus to fade slide show
 * 
 * 
 * @note make subclass of FadeSlideshow if functionality is overlapping (or use only one class)
 */


function FadeSlideShowWithNavi(element) {
  var self = this;
  this.fadeSlideShowElement = element;

  //JSON aus data-Attribut holen und parsen
  this.data = JSON.parse(element.getAttribute('data-fade-slide-show'));

  this.modus = (this.data.modus) ? this.data.modus : 'switch';

  //Navigationselemente, um ein Slide anzusteuern, holen
  if (this.data.navigationItem) {
    this.fadeSlideNavItems = element.querySelectorAll(this.data.navigationItem);

  }

  //Navigationselement selektieren, um ein Slide weiter zu schalten, selektieren
  if (this.data.navigationNext) {
    this.fadeSlideNavNext = element.querySelector(this.data.navigationNext);
  }

  //Navigationselement, um ein Slide zurück zu schalten, selektieren
  if (this.data.navigationPrevious) {
    this.fadeSlideNavPrevious = element.querySelector(this.data.navigationPrevious);
  }

  //Slides selektieren
  if (this.data.slides) {
    this.dataSlides = this.data.slides;

  }

  this.fadeSlideShows = [];
  this.watchCss = (this.data.watchCss == "true" ? true : false);
  this.fadeSlideShowElement.fadeSlideNav = false;

  if (this.watchCss) {
    window.addEventListener('holy:resizeDone', () => {
      self.refreshView();
    });
  }



  /**
   * Initilisiert Slideshow
   */
  this.init = function () {

    if (this.watchCss && getComputedStyle(self.fadeSlideShowElement, ':after').getPropertyValue('content').replace(/\"/g, '') !== "fadeslideshow")
      return;


    //Existieren Slideshow-Elemente und wurde die Slideshow noch nicht initialisiert
    if (self.fadeSlideShowElement.fadeSlideShowWithNavInited === true)
      return;

    //Merken, dass die Slideshow initislisiert wurde
    self.fadeSlideShowElement.fadeSlideShowWithNavInited = true;

    //Soll die Slideshow ein expliziten Anfang und ein explizites Ende haben
    if (self.data.wrapAround === "false") {
      //Navigationselement zum zurück schalten ausblenden
      self.fadeSlideNavPrevious.classList.add('hidden');
    }

    //Iteration durch alle Slide Container
    for (var i = 0; i < self.dataSlides.length; i++) {

      var dataSlide = self.dataSlides[i];

      var fadeElement = self.fadeSlideShowElement.querySelector(dataSlide.container);

      if (self.data.fadeInNextOnclick === "true") {
        fadeElement.addEventListener('click', self.fadeInNext);

      }

      //FadeSlideShow erzeugen und dabei Optionsparameter übergeben
      var fadeSlideShow = new FadeSlideshow(fadeElement, { "fadeSpeed": dataSlide.fadeSpeed, "wrapAround": this.data.wrapAround });

      //FadeSlideShow merken
      self.fadeSlideShows.push(fadeSlideShow);

      fadeSlideShow.init();
    }


    //Click-EventListener für Navigationselemente hinzufügen
    for (var j = 0; j < self.fadeSlideNavItems.length; j++) {
      self.fadeSlideNavItems[j].addEventListener('click', self.handleClick);
    }

    self.setNavigation(0);

    //Click-Eventlistener für Navigationselement zum weiter schalten der Slides
    if (self.fadeSlideNavNext) {
      self.fadeSlideNavNext.addEventListener('click', function () {
        self.fadeInNext();
      });
    }

    //Click-Eventlistener für Navigationselement zum zurück schalten der Slides
    if (self.fadeSlideNavPrevious) {
      self.fadeSlideNavPrevious.addEventListener('click', function () {
        //Iteration über alle Slide
        for (var k = 0; k < self.fadeSlideShows.length; k++) {

          //Slide einblendne
          self.fadeSlideShows[k].fadeInPrev();
        }

        //Navigationspunkt aktiv schalten
        self.setNavigation(self.fadeSlideShows[0].getCurrentItem());
      });
    }
  }

  this.handleClick = function (e) {
    //Data-Attribut holen, damit wir wissen, welcher Slide eingeblendet werden soll
    var index = e.currentTarget.getAttribute('data-fade-index');

    //Iteration über alle Slideshows
    for (var k = 0; k < self.fadeSlideShows.length; k++) {

      if (self.modus === 'toggle' && self.fadeSlideShows[k].getCurrentItem() == index) {
        self.fadeSlideShows[k].fadeIn(0);

        //Navigationspunkte inaktiv schalten
        self.setNavigation(-1);
      } else {
        //Slide einblenden
        self.fadeSlideShows[k].fadeIn(index);

        //Navigationspunkt aktiv schalten
        self.setNavigation(index);
      }

    }
  }


  this.fadeInNext = function () {
    //Iteration über alle Slides
    for (var k = 0; k < self.fadeSlideShows.length; k++) {
      self.fadeSlideShows[k].fadeInNext();
    }

    //Slide einblenden
    self.setNavigation(self.fadeSlideShows[0].getCurrentItem());
  }

  this.destroy = function () {
    //Existieren keine Slideshow-Elemente und wurde die Slideshow initialisiert
    if (self.fadeSlideShowElement.fadeSlideShowWithNavInited === false)
      return;

    //Merken, dass die Slideshow destroyed wurde
    self.fadeSlideShowElement.fadeSlideShowWithNavInited = false;

    //Soll die Slideshow ein expliziten Anfang und ein explizites Ende haben
    if (self.data.wrapAround === "false") {

      //Navigationselement zum zurück schalten ausblenden
      self.hidePreviousNav();
    }

    //Click-EventListener für Navigationselemente hinzufügen
    for (var j = 0; j < self.fadeSlideNavItems.length; j++) {
      self.fadeSlideNavItems[j].removeEventListener('click', self.handleClick);
    }



    //Click-Eventlistener für Navigationselement zum weiter schalten der Slides
    if (self.fadeSlideNavNext) {
      self.fadeSlideNavNext.removeEventListener('click');
    }

    //Iteration durch alle Slide Container
    for (var i = 0; i < self.fadeSlideShows.length; i++) {

      if (self.data.fadeInNextOnclick === "true") {
        var fadeElement = self.fadeSlideShowElement.querySelector(self.dataSlides[i].container);
        fadeElement.removeEventListener('click', self.fadeInNext);
      }

      //FadeSlideShow destroyen
      self.fadeSlideShows[i].destroy();
    }

    self.fadeSlideShows = [];

    //Click-Eventlistener für Navigationselement zum zurück schalten der Slides
    if (self.fadeSlideNavPrevious) {
      self.fadeSlideNavPrevious.removeEventListener('click');
    }

    self.setNavigation(-1);
  }

  /**
   * Aktualisiert die Navigations auf Basis des übergebenen Index
   * @param {number} index Index des Navigationspunktes, der aktiv geschaltet werden soll
   */
  this.setNavigation = function (index) {
    //Alle Navigationselemente durchiterieren setzen
    for (var i = 0; i < self.fadeSlideNavItems.length; i++) {
      if (self.fadeSlideNavItems[i].dataset.fadeIndex == index) {
        self.fadeSlideNavItems[i].classList.add('-active');
        self.fadeSlideNavItems[i].setAttribute('data-state', 'active');
      } else {
        self.fadeSlideNavItems[i].classList.remove('-active');
        self.fadeSlideNavItems[i].removeAttribute('data-state');
      }
    }

    if (index < 0)
      return;

    //Navigationselement an Stelle des index aktiv setzen
    //self.fadeSlideNavItems[index].classList.add('-active');

    if (self.data.wrapAround === "false") {

      //Wird der lezte Slide angezeigt
      if (self.fadeSlideShows[0].getCurrentItem() === self.fadeSlideShows[0].numberOfItems - 1) {

        //NavigationsElement zum weiter schalten ausblenden
        self.hideNextNav();

        //NavigationsElement zum zurück schalten einblenden
        self.showPreviousNav();
      }

      //Wird der erste Slide angezeigt
      else if (self.fadeSlideShows[0].getCurrentItem() === 0) {
        self.showNextNav();
        self.hidePreviousNav();
      }

      //Wird ein Slide "aus der Mitte" angezeigt
      else {
        self.showNextNav();
        self.showPreviousNav();
      }
    }
    // wenn nur ein Element vorhanden ist, wird die Navbar nicht angezeigt
    if (self.fadeSlideNavItems.length == 1) {
      self.hideNextNav();
      self.hidePreviousNav();
      self.fadeSlideNavItems[0].classList.add('hidden');
    }
  }

  this.showNextNav = function () {
    if (self.fadeSlideNavNext) {
      self.fadeSlideNavNext.classList.remove('hidden');
    }
  }

  this.hideNextNav = function () {
    if (self.fadeSlideNavNext) {
      self.fadeSlideNavNext.classList.add('hidden');
    }
  }

  this.showPreviousNav = function () {
    if (self.fadeSlideNavPrevious) {
      self.fadeSlideNavPrevious.classList.remove('hidden');
    }
  }

  this.hidePreviousNav = function () {
    if (self.fadeSlideNavPrevious) {
      self.fadeSlideNavPrevious.classList.add('hidden');
    }
  }

  this.refreshView = function () {
    if (getComputedStyle(self.fadeSlideShowElement, ':after').getPropertyValue('content').replace(/\"/g, '') !== "fadeslideshow") {
      self.destroy();
    }
    else {
      self.init();
    }
  }
};