/**
 * reads and writes cookies
 * @dependencies
 * *
 * @dependents
 * * pdb-Api: /sprachen_templates/js-setcookie.php
 * * struktur/php/global/sprachen_templates/js-setcookie.php
 * * /js/myerco/myerco.js
 * * /js/lightfinder/lightfinder-km.js
 * * /js/global/modules/view-switch.js
 * * /js/global/modules/language-switch.js
 * * /js/global/ab-test.js
 * * CMS-Backand: dist/assets/struktur/js/modul_js/editieren.js
 * * CMS-Backand: dist/assets/struktur/js/k3.js
 * @note
 *
 * @commit
 * * Initial Commit 2.0
 * * v.schaefer, (July 6th, 2018 3:00 PM): Bugfix - Schreiben der Cookies hat nicht funktioniert
 */
kmcookie = {
	setzen:function( name, wert, dauer_in_min ){

		// Neues Date Objekt erzeugen
		datum = new Date();

		// Ablaufdatum erzeugen
		ablauf = datum.getTime() + (31 * 24 * 60 * 60 * 1000);

		// Falls Ablaufdatum übergeben wurde, dieses nehmen
		if(dauer_in_min){ ablauf = datum.getTime() + (dauer_in_min * 60 * 1000); }
		datum.setTime(ablauf);

		// cookie setzen
		var cookie_inhalt = name+"="+wert+"; path=/; expires="+datum.toGMTString()+ ";domain=" + config_data.cookie_domain;
		document.cookie = cookie_inhalt;
	},

	lesen:function(name) {
	   var i=0;
	   var suche = name+"=";
	   while (i<document.cookie.length){
	      if (document.cookie.substring(i, i+suche.length)==suche){
	         var ende = document.cookie.indexOf(";", i+suche.length);
	         ende = (ende>-1) ? ende : document.cookie.length;
	         var cook = document.cookie.substring(i+suche.length, ende);
	         return unescape(cook);
	      }
	      i++;
	   }
	   return null;
	}
}
