/**
 * Newsletter
 *
 * @dependencies
 * * jquery
 * * k3vars (erco.js)
 *
 * @dependents
 * * modaldata (erco.js)
 * * data attribute [data-js-init-newsletter-subscription] used in several files in pdb-api
 * * data attribute [data-js-newsletter-subscription] used in order_lichtbericht.pdh in pdb-api
 * * $(document).ready(function() { ... }) (erco.js)
 *
 * @commit
 * * Initial commit 2.0
 * * c.unger (2018-10-16) – print styling for modal
 * * s.schiffer (2019-03-12) – implemented service item in tab list depending on the form positoin on the page (low/high)
 * * k.tennie (2019-07-18) – add querybuild and redirect for glossar- and vacancie-cards
 * * c.unger (2021-04-27) – fix(JS->modal): separate modal creation, init eventlisteners after ajax call in loadOverlay
 */

var newsletter = {

  EXISTING_FORMS: {
    FORM_SELECTOR: 'form[data-js-init-newsletter-subscription]',
    CHECKBOX_SELECTOR: 'input[data-js-newsletter-subscription]',
  },

  // Testing for page conditions to append to specific forms
  init: function () {

    // Appending the checkbox to the media order form if it exists
    if ($('form#submit-userdata').length || document.querySelectorAll('[data-init-newsletter]').length) {
      newsletter.initCheckbox();
    }

    newsletter.initSubscribers();
  },

  /*
  * Handle newsletter subscription for forms, which already contain a checkbox for newsletter subscription
  * cu 20211001
  */
  initSubscribers: function () {
    let getPayload = function (form) {
      const firstnameInput = form.querySelector('input[name="firstname"]');
      const lastnameInput = form.querySelector('input[name="surname"],input[name="lastname"]');
      const emailInput = form.querySelector('input[name="email"');
      const lang = form.querySelector('select[name="language"');

      if (firstnameInput == null || lastnameInput == null || emailInput == null) {
        return null;
      }

      const payload = {
        firstname: firstnameInput.value,
        lastname: lastnameInput.value,
        email: emailInput.value,
        lang: lang.value,
        type: form.querySelector(newsletter.EXISTING_FORMS.CHECKBOX_SELECTOR).dataset.jsNewsletterSubscription
      };
      return payload;
    }

    const forms = document.querySelectorAll(newsletter.EXISTING_FORMS.FORM_SELECTOR);
    forms.forEach((form) => {
      const checkbox = form.querySelector(newsletter.EXISTING_FORMS.CHECKBOX_SELECTOR);
      if (checkbox !== null) {
        form.addEventListener('submit', function (e) {
          if (checkbox.checked) {
            const payload = getPayload(form);
            if (payload !== null) {
              newsletter.subscribeNewsletter(payload, function (res) { });
            }
          }
        });
      }
    });
  },


  /* this injects a checbox with translated textlabel to formulars in order to have a newsletterregistration checkbox
     ss 20171016
   */
  initCheckbox: function () {
    var lang = k3vars.S_Sprache || 'en';

    $.get(confEnv['pdb_api_url'] + '/productdata/translate/' + lang + '/context/service/layout/json', function (data) {

      globals.translationNewsletter = data || JSON.parse(data);

      /* media request, seminar registration + lightfinder, buyERCO */
      // var elements = document.querySelectorAll("input[type=submit]:not(.btnheader):not(#mc-embedded-subscribe):not([data-prevent-inject-newsletter]), #submit, #submit-userdata > button[type=submit]");
      var elements = document.querySelectorAll('[data-js-newsletter]');

      for (var i = 0; i < elements.length; i++) {

        //Checkbox erzeugen
        var checkboxElement = document.createElement('div');
        checkboxElement.setAttribute('data-scoped-css-v3', '');
        checkboxElement.innerHTML = `<p id="newsletter_check" class="control-check | m-b-5">
          <input type="checkbox" name="mc_newsletter_registration" value="j" id="subscribe_newsletter_box_${i}">
          <label for="subscribe_newsletter_box_${i}" class="check-box__label">${globals.translationNewsletter['get_newsletter']}
          </label></p>`;
        var element = elements[i];

        //Checkbox hinzufügen
        element.parentNode.insertBefore(checkboxElement, element);

        var form = element.closest('form');

        form.addEventListener('submit', function (e) {
          e.preventDefault();
          var currentTarget = $(e.currentTarget);

          //Newsletter Checkbox markiert?
          if (currentTarget.get(0).querySelector('input[name="mc_newsletter_registration"]').checked) {
            var payload = {};
            payload.firstname = $('input[name="FIRSTNAME"]', currentTarget).val() || $('input[name="S_Vorname"]', currentTarget).val();
            payload.lastname = $('input[name="LASTNAME"]', currentTarget).val() || $('input[name="S_Nachname"]', currentTarget).val();
            payload.email = $('input[name="EMAIL"]', currentTarget).val() || $('input[name="S_Email"]', currentTarget).val() || $('input[name="email"]', currentTarget).val();
            payload.lang = $('input[name="language"]', currentTarget).val() || $('input[name="lang"]', currentTarget).val();
            payload.type = element.getAttribute('data-js-newsletter');

            // Sending the userdata to the Mailchimp route
            newsletter.subscribeNewsletter(payload, function (res) { });
          }

        });
      }

    }).done(function () {
      return globals.translationNewsletter;
    });
  },

  // Sending the request to the server to be redirected to mailchimp
  subscribeNewsletter: function (data, cb) {
    const lsLang = k3vars.S_Sprache || 'en';
    data.site_url = window.location.href;
    const utmParams = Tracking.EmarsysDataLayer?.UTMApiParams;
    if (utmParams) {
      for (const [key, value] of Object.entries(utmParams)) {
        if (value) {
          data[key] = value;
        }
      }
    }
    $.ajax({
      url: confEnv['pdb_api_url'] + '/newsletter/opt_in/' + lsLang,
      method: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      success: function (res) {
        // NOTE: currently deactivated
        // Reason: the scarab script cannot handle multiple go commands in a short time
        // Tracking.EmarsysDataLayer.triggerAction('newsletter');
        cb(res);
      },
      error: function (err) {
        console.error(err);
      }
    });
  }
}