/**
 * Clean Layout on some pages
 * @dependencies
 *
 * @dependents
 * * after_ajax (global/erco.js)
 * * $(document).ready(function()
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
// Leider müssen wir das Layout an manchen stellen etwas bereinigen
var layout = {
  init: function () {
    // cn: Related Products (unten) ist nicht immer gefüllt :(
    // Darum nehmen wir es raus
    var check = $('.raster-container .border-top-grey');
    if ($(check).find('img').length == 0) {
      $(check).remove();
    }

    // cn: Einige Features gibt es in US nicht
    $('.media .media-body .media-heading').each(function (ele) {
      if (!$(this).html().match(/[a-z]/)) {
        $(this).closest('.media').remove();
      }
    });
  },
};

/**
 * Get the current URL
 * @dependencies
 *
 * @dependents
 * * isotope_tools (global/isotope_tools.js)
 * * articletable-km-v2.1 (products/articletable-km-v2.1.js)
 * * articletable-km (products/articletable-km.js)
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
// Liest aktuelle URL aus und gibt diese zurück
function get_url() {
  if (window.location != window.parent.location) {
    return document.referrer;
  } else {
    return document.location.href;
  }
}

/**
 * Get the params of a given url
 * @dependencies
 *
 * @dependents
 * * isotope_tools (global/isotope_tools.js)
 * * articletable-km-v2.1 (products/articletable-km-v2.1.js)
 * * articletable-km (products/articletable-km.js)
 * @note
 *
 * @commit
 * * Initial commit 2.0
 * * s.schäfermeier (March 16th, 2018 2:40 PM) Update von gw2.erco.org
 */
// Querystring auslesen und in Objekt zurück geben
function get_params(url) {
  var params = {};

  if (url.match(/(.*?)\?(.*)/)) {
    var data = RegExp.$2.split(/&/);
    for (i = 0; i < data.length; i++) {
      var item = data[i].split(/=/);
      params[item[0]] = item[1];
    }
  }

  return params;
}

/**
 * Insert a new element after an existing one in the DOM tree
 * @dependencies
 *
 * @dependents
 * * articletable-km-v2.1 (products/articletable-km-v2.1.js)
 * * articletable-km (products/articletable-km.js)
 * @note
 * FG: Not needed anymore since Element.after() function is supported by all browsers @see https://caniuse.com/mdn-api_element_after
 * @commit
 * * v.schaefer(October 4th, 2018 8:49 AM) Slider für Artikelfilter implementiert (Überführung von gw2.erco.org)
 */
Node.prototype.insertAfter = function (newNode) {
  this.parentNode.insertBefore(newNode, this.nextSibling);
};

/**
 * Load special JS-Files which are not needed on every page
 * @dependencies
 * * jQuery
 * * dynamicallyLoadJSFile()
 * @dependents
 * * $(document).ready(function()
 * * a lot of templates in CMS-Backend and some views in the pdb-api (where 'data-js-km' exists)
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
var spezial_js_laden = (function () {
  return {
    check: function () {
      // Wir prüfen ob Contentblöcke nachgeladen werden müssen
      // data-js-km = [kennung des Scripts, z.B. articletable]
      // [kennung des Scripts, z.B. articletable] muss in spezial_js_url vorhabenden sein

      $('[data-js-km]').each(function (obj, index) {
        var spezial_js = $(this).attr('data-js-km');

        // gibt es dazu eine URL, die noch nicht nachgeladen wurde?
        var url = spezial_js_url[spezial_js];

        if (url) {
          //for(var js in url){
          for (var js = 0, len = url.length; js < len; js++) {
            // laden ...
            dynamicallyLoadJSFile(url[js]);
          }
          // ... und sagen, dass wir es schon geladen haben
          spezial_js_url[spezial_js] = false;
        }
      });
    },
  };
})();

/**
 * Load map if thirdparty is enabeld
 * @dependencies
 * * jQuery
 * @dependents
 * * global/lichtwissen.js
 * * global/third-party-js
 * @note
 *
 * @commit
 * * Initial commit 2.0
 * * j.pereira,(September 9th, 2021 11:30 AM) feat(third-party): add default google maps init and initMap functions
 */
var maps = {
  init: function () {
    if (!ThirdParty.isEnabled()) return;

    $.when(
      $.getScript('/cdn/maps/js/map_functions.js'),
      $.getScript('/cdn/maps/js/infobubble.js')
    ).done(function () {
      initialize($('[data-map]').attr('data-map'));
    });
  },
};

/**
 * Storyswitch
 * @dependencies
 * * jQuery
 * @dependents
 * * CMS-Backend Modul: /layout/microsites_sonder_storify_switcher.htm
 * @note
 * Propably deprecated, cause for L+B 2014 and url doesnt exist anymore.
 * @commit
 * * Initial commit 2.0
 */
function storifyswitch(urlid) {
  // storify-iframe:
  var iframecode =
    '<iframe src="//storify.com/ERCO/{{}}/embed?header=false&template=grid" width="100%" height=750 frameborder=no allowtransparency=true></iframe>';
  streamurl = new Object();
  // urls zu den storys:
  //streamurl.one = "erco-making-of-light-building";
  streamurl.two = 'light-building-party';
  streamurl.three = 'erco-live-at-light-building';
  iframecode = iframecode.replace(/\{\{\}\}/, streamurl[urlid]);
  // story wechseln
  $('#storify').empty().append(iframecode);
}

/**
 * Send Link
 * @dependencies
 * * jQuery
 * @dependents
 * * $(document).ready(function()
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
var overwrite_send_mail = new (function () {
  var ele;

  function init() {
    ele = $('footer a[href*="mailto:?"]');
    if (!ele.length || k3vars.PKM_Bereich !== '1') return;
    ele.on('click', handleClick);
  }

  function handleClick(e) {
    e.preventDefault();
    var splitter = '&body=';
    var currentRef = location.href.replace('index.php', '');
    var currentAttr = ele.attr('href').split(splitter);

    // Kürzen der URL durch bitly
    $.getJSON('/struktur/json/bitly.json', function (res) {
      $.get(
        'https://api-ssl.bitly.com/v3/shorten?access_token=' + res.key + '&longUrl=' + currentRef,
        function (response) {
          if (response.status_code == 200) {
            currentRef = response.data.url;
          }
        }
      ).always(function () {
        if (currentRef !== currentAttr[1]) ele.attr('href', currentAttr[0] + splitter + currentRef);

        location.href = ele.attr('href');
      });
    });
  }

  // return
  this.init = init;
})();

/**
 * Scroll to anchor via holy
 * @dependencies
 * * jQuery
 * * holyscroll
 * @dependents
 * * $(document).ready(function()
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
var anchors_scroll_to = new (function () {
  function init() {
    var ele = $('.anchors a[href^="#"]');
    if (!ele.length) return;

    ele.on('click', function (e) {
      var ref = $(this).attr('href');
      var target = $(ref);

      if (target.length) {
        e.preventDefault();
        holy.scrollTo(target);
      }
    });
  }

  this.init = init;
})();

/**
 * add Loader-image while loading
 * @dependencies
 * * jQuery
 * @dependents
 * * Download-Container: /images/download/dist/assets/js/download.js
 * * /js/products/product-search-km.js
 * @note
 *
 * @commit
 * * Initial commit 2.0
 */
var ajax_loader = new Object();
ajax_loader.html =
  '<img id="ajax_spinner" src="/struktur/img/ajax_loader.gif" id="ajax-loader" alt="please wait while loading" />';
ajax_loader.func = {
  // gallery code in den body injecten. und links initialisieren
  insert: function (target, modus) {
    if (modus == 'append') {
      $(target).append(ajax_loader.html);
    }
    if (modus == 'prepend') {
      $(target).prepend(ajax_loader.html);
    } else {
      $(target).html(ajax_loader.html);
    }
  },

  // links erstellen
  remove: function (dauer) {
    $('#ajax_spinner').slideUp(dauer, function () {
      $(this).remove();
    });
  },
};

/**
 * enable the search
 * @dependencies
 * * jQuery
 * @dependents
 * * $(document).ready(function()
 * @note
 * probably deprecated, pe 20230214
 * @commit
 * * Initial commit 2.0
 * * p.erasmus(July 28th, 2022 6:33 PM): prop not props
 */
search_unlock = {
  start: function () {
    $('[data-nav-search]').prop('disabled', false);
  },
};

/**
 * Listen to trigger click events
 * @dependencies
 * * toolkit.js
 * @dependents
 * * $(document).ready(function()
 * * /struktur/php/global/sprachen-km.php (data-attribute)
 * @note
 * probably deprecated, pe 20230214
 * @commit
 * * c.unger, (September 18th, 2020 11:44 PM) readd lost code during merge from integration
 */
var listenToTriggerClickEvents = function () {
  var clickTriggers = document.querySelectorAll('[data-trigger-click]');
  clickTriggers.forEach(function (trigger) {
    trigger.addEventListener('click', function (e) {
      var clickTarget = document.querySelector(e.currentTarget.dataset.triggerClick);
      if (clickTarget) {
        window.toolkit.triggerEvent(clickTarget, 'click');
      }
      e.preventDefault();
      return false;
    });
  });
};

/**
 * Checking for direct download data attributes on pageload
 * @dependencies
 * * toolkit.js
 * @dependents
 * * $(document).ready(function()
 * * lightscout/dist/home-page-2021/de/intro-4.php (data-attribute)
 * * locallandingpages for download block (data-attribute)
 * * pdb-api: /v1.1/views/productdata/downloaddata-pakete-mashup.php (data-attribute)
 * @note
 * same function exists also in download-container: images/download/dist/assets/js/download.js (Line 323)
 * @commit
 * * b.kelm, (February 8th, 2019 3:29 PM): Added JS code to check for direct download links on pageload in the global scope. This now allows for a directDownload from the global ERCO Footer. I also removed the initial check for download links in the download section of the website since this is now handeld by the global JS
 */
DirectDownloads = {
  checkParams() {
    var directDlLinks = document.querySelectorAll('[data-js-directdownload]');

    for (var i = 0; i < directDlLinks.length; i++) {
      if (!directDlLinks[i].classList.contains('downloadInit')) {
        directDlLinks[i].classList.add('downloadInit');

        directDlLinks[i].addEventListener('click', function (e) {
          e.preventDefault();

          var url = e.currentTarget.href;
          window.toolkit.downloadFile(url, true);
        });
      }
    }
  },
};

/**
 * get Translations from PDB-Api
 * @dependencies
 * * jQuery
 * * PDB_API ;)
 * @dependents
 * * /js/products/product-header-tooltip.js
 * * /js/global/modules/specsheets.js
 * @note
 *
 * @commit
 * * Initial commit 2.0
 * * b.kelm, (October 1st, 2019 11:56 AM): Added a switch in text for the accessory expansion button on the specsheets
 * * h.bode, (June 26th, 2020 6:00 PM): add phaseout js
 */
jsTranslation = {
  getContext: function (context, callback = null) {
    var lang = k3vars.S_Sprache || 'en';
    if (!globals.trans) {
      globals.trans = {};
    }
    $.get(
      `${confEnv['translation_service_url']}/translation/context/${context}/${lang}`,
      function (data) {
        globals.trans[context] = data;
      }
    ).done(function () {
      if (callback) callback();
      return globals.trans[context];
    });
  },
};

/**
 * Run other startfunctions
 * @dependencies
 * * responsive_table (also in here)
 * * view_switch (global/modules/view_switch)
 * @dependents
 * * /global/modules/transition.js
 * * /global/lichtbericht.js
 * * /global/erco.js
 * * /global/deferred-load-km.js
 * @note
 *
 * @commit
 * * Initial commit 2.0
 * * s.schäfermeier,(March 16th, 2018 2:40 PM): Update von gw2.erco.org
 */ listener = {
  start: function () {
    responsive_table.start();
    view_switch.start();
  },
};

/**
 * Run another init-function
 * @dependencies
 * * artikelseite.func (articletable-km-v2.1.js)
 * @dependents
 * * listener.start() (also in here)
 * @note
 * could be added to the function which calls it
 * @commit
 * * Initial commit 2.0
 * * s.schäfermeier,(March 16th, 2018 2:40 PM): Update von gw2.erco.org
 */
responsive_table = {
  start: function () {
    //artikelseite.func.initFootable()
    artikelseite.func.initFootable();
  },
};


/**
 * register scripts for document.ready
 * @dependencies
 * * onload_functions
 * @dependents
 * * /global/lichtbericht.js
 * * document.ready
 * @note
 * could be added to the function which calls it
 * @commit
 * * Initial commit 2.0
 */
deferred = {
  start: function () {
    // sind Funktionen vorhanden?
    while (onload_functions.length) {
      // nächsten Kandidaten festlegen
      var nextFunction = onload_functions.shift();

      // ist der Kandidat im alten Format? - dann »konvertieren« wir ihn
      if (typeof nextFunction === 'string') nextFunction = new Function(nextFunction);

      // Funktion aufrufen
      nextFunction();
    }
  },
};
