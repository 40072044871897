Tracking = {
  started: false,
  deferred: [],

  /**
   * Defer the 'addition' and 'running' of tracking code
   *
   * @param  {string}   name Tracking name with support for additonal data transportation (colon separated)
   * @param  {Function} cb   Function to be called when tracking is started / was already started
   * @return {void}
   */
  defer: function (name, cb) {
    this.deferred.push({
      tracker: name,
      cb: cb,
      done: false,
    });

    if (this.started) {
      this.processDeferred();
    }
  },

  /**
   * Process all deferred and non-processed (pending) trackings
   *
   * @return {void}
   */
  processDeferred: function () {
    const pendingTrackings = this.deferred.filter((deferredTracking) => !deferredTracking.done);

    pendingTrackings.forEach(function (pendingTracking) {
      /* Here would be the best place to evaluate the pendingTracker-Name */

      pendingTracking.cb();

      // Mark as done
      pendingTracking.done = true;
    });
  },

  /**
   * Get the current GA client id
   *
   * @return {string|null} The current client id or null if GA was not loaded / initialized
   */
  getClientId() {
    /** @type {string | null} */
    let clientId = null;

    if (ga && ga.getAll) {
      const all = ga.getAll();

      if (all && all.length > 0) {
        const foundClientId = all[0].get('clientId');

        if (foundClientId) {
          clientId = foundClientId;
        }
      }
    }

    return clientId;
  },

  isEnabled: function () {
    // Check localStorage for the cookieMessage object
    const cookieMessageStorageObject = window.toolkit.getLocalStorageObject('cookieMessage');
    return cookieMessageStorageObject ? true : false;
  },
  start: function () {
    Tracking.enableAnalytics();
    Tracking.enableGTM();
    Tracking.enableHotjar();
    Tracking.enableMicrosoftAdvertising();
    Tracking.enableAdform();
    Tracking.enableEmarsysDataLayer();
    Tracking.enablePosthog();

    Tracking.processDeferred();
    this.started = true;
  },
  enablePosthog: function () {
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);

    window.posthog.init('phc_45eFCOAIl1YiGBDMyQdaIbi7bnXFbBbYuPfpJUa1Yx6', {
      api_host:'https://eu.posthog.com',
      persistence: 'memory',
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
    })
  },
  enableGTM: function () {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(['consent', 'default', {
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'wait_for_update': 500,
    }]);
    window['dataLayer'].push(['consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
    }]);
    window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const gtmscript = document.createElement('script');
    gtmscript.async = true;
    gtmscript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-PLXNMD';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(gtmscript, head);
  },
  enableAnalytics: function () {
    window.ga =
      window.ga ||
      function () {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();
    ga('create', 'UA-32863609-1', 'www.erco.com');
    ga('require', 'linkid', 'linkid.js');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
    const gascript = document.createElement('script');
    gascript.async = true;
    gascript.src = 'https://www.google-analytics.com/analytics.js';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(gascript, head);
  },
  enableHotjar: function () {
    window.hj =
      window.hj ||
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    window._hjSettings = {
      hjid: 2240968,
      hjsv: 6,
    };
    head = document.getElementsByTagName('head')[0];
    htscript = document.createElement('script');
    htscript.async = 1;
    htscript.src =
      'https://static.hotjar.com/c/hotjar-' +
      window._hjSettings.hjid +
      '.js?sv=' +
      window._hjSettings.hjsv;
    head.appendChild(htscript);
  },
  enableAdform: function () {
    const adformscript = document.createElement('script');
    adformscript.async = true;
    adformscript.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-sync.js';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(adformscript, head);
  },
  enableFacebook: function () {
    // facebook pixel is loading by gtm, so we cannot start this seperatly at the moment.
  },
  enableMicrosoftAdvertising: function () {
    window['uetq'] = window['uetq'] || [];
    const f = function () {
      const o = { ti: '17555736' };
      o.q = window['uetq'];
      window['uetq'] = new UET(o);
      window['uetq'].push('pageLoad');
    };
    const msscript = document.createElement('script');
    msscript.src = '//bat.bing.com/bat.js';
    msscript.async = 1;
    msscript.onload = msscript.onreadystatechange = function () {
      var s = this.readyState;
      (s && s !== 'loaded' && s !== 'complete') ||
        (f(), (msscript.onload = msscript.onreadystatechange = null));
    };
    const i = document.getElementsByTagName('script')[0];
    i.parentNode.insertBefore(msscript, i);
  },
  enableEmarsysDataLayer: function () {
    Tracking.EmarsysDataLayer = new EmarsysDataLayer();
  },

  /* AdForm - Tracking; code previously written by vs 20210331 and moved here by jp 20210721 */
  adform: {
    pixelType: {
      LEAD: 'leadpixel',
      PAGE: 'pagePixel',
      DOWNLOAD: 'trackDownloadButton',
    },
    config: {
      objects: [
        {
          objectId: '7216',
          language: 'de',
          pagePixel:
            '<img style="disply:none"  src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7CService%7Clicht-fuer-moderne-bueros-7216&ADFdivider=|" width="1" height="1" alt="" />',
        },
        {
          objectId: '7216',
          language: 'it',
          pagePixel:
            '<img style="display:none" src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7Clight-for-modern-offices_it&ADFdivider=|" width="1" height="1" alt="" />',
          leadPixel:
            '<img style="disply:none" src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7Clight-for-modern-offices_contact_it&ADFdivider=|" width="1" height="1" alt="" />',
        },
        {
          objectId: '7216',
          language: 'es',
          pagePixel:
            '<img style="display:none" src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7Clight-for-modern-offices_es&ADFdivider=|" width="1" height="1" alt="" />',
          leadPixel:
            '<img style="disply:none" src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7Clight-for-modern-offices_contact_es&ADFdivider=|" width="1" height="1" alt="" />',
        },
        {
          objectId: '7320',
          language: 'de',
          trackDownloadButton: {
            trackingId: 213642,
            trackingCode: 'erco|Service|human-centric-lighting-7320-whitepaper',
          },
        },
        {
          objectId: '7326',
          language: 'de',
          pagePixel:
            '<img src="https://server.adform.net/Serving/TrackPoint/?pm=213642&ADFPageName=erco%7Cprojekte%7Cwork%7Cbeleuchtung_fuer_co_working-7316&ADFdivider=|" width="1" height="1" alt="" />',
        },
      ],
    },
    add: function (pixel) {
      const element = document.createElement('p');
      element.style.height = '0';
      element.style.width = '0';
      element.innerHTML = pixel;
      document.body.append(element);
    },

    addConversionPixel: function (pixelType, target = null) {
      if (!k3vars.PKM_Objekt || !k3vars.S_Sprache) {
        return false;
      }
      const conversionPixelItem = this.config.objects.find((element) => {
        return element.objectId == k3vars.PKM_Objekt && element.language == k3vars.S_Sprache;
      });

      if (!conversionPixelItem) {
        return false;
      }

      if (pixelType === this.pixelType.DOWNLOAD && Tracking.started) {
        window.adf &&
          adf.Track(
            conversionPixelItem.trackDownloadButton.trackingId,
            conversionPixelItem.trackDownloadButton.trackingCode,
            {}
          );
      }
      const self = this;

      Tracking.defer('adform:conversionPixel:' + pixelType, function () {
        switch (pixelType) {
          case self.pixelType.LEAD:
            self.add(conversionPixelItem.leadPixel);
            break;
          case self.pixelType.PAGE:
            self.add(conversionPixelItem.pagePixel);
            break;
        }
      });
    },
  },
};
