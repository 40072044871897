/**
 * Common DataTable Class to be extended by other DataTable Elements.
 * Combines common functionality of all DataTable Elements.
 * @class DataTableCommon
 */
class DataTableCommon extends HTMLElement {

  /**
   * Common CSS for all DataTable Elements.
   * @protected
   */
  static styles = /*css*/ `
    :host {
      --cell-padding-x: var(--space-4);
      --cell-padding-y: var(--space-3);
    }

    :host * {
      box-sizing: border-box;
    }

    .table__row {
      display: table-row;
    }

    .table__cell {
      display: table-cell;
      height: 100%; /* CSS Bugfix for Firefox */
      padding: 0;
      background-color: var(--base-color-background);
      vertical-align: top;
    }


    .table__cell--sticky {
      position: sticky;
      z-index: 1;
      right: var(--sticky-cell-offset-right, 0);
      left: var(--sticky-cell-offset-left, 0);
    }

    .table__cell--sticky[data-sticky-active="true"] {
      box-shadow: 0 0 5px 1px var(--base-color-border);
      clip-path: inset(0 -6px 0 -6px);
    }

    .table__cell--sticky[data-sticky-active="true"][data-sticky-side="left"] {
      clip-path: inset(0 -6px 0 0);
    }

    .table__cell--sticky[data-sticky-active="true"][data-sticky-side="right"] {
      clip-path: inset(0 0 0 -6px);
    }

    .table__cell .inner {
      height: 100%;
      padding: var(--cell-padding-y) var(--cell-padding-x) var(--cell-padding-y) 0;
      border-top: 1px solid var(--base-color-border);
    }

    .table__cell:last-child .inner {
      padding-right: 0;
    }

    .table__cell--sticky .inner {
      padding-left: var(--cell-padding-x);
    }

    .table__cell--sticky:first-of-type .inner,
    .table__cell--sticky + .table__cell--sticky .inner {
      padding-left: 0;
    }

    .table__cell:not(.table__cell--sticky):has(+ .table__cell--sticky) .inner {
      padding-right: 0;
    }

    .table__cell:has(.table__sort-icon) {
      cursor: pointer;
    }

    ::slotted(a) {
      border-bottom: none !important;
    }

    ::slotted(a:hover) {
      text-decoration: underline !important;
    }
  `;

  constructor() {
    if (new.target === DataTableCommon) {
      throw new TypeError("Cannot construct DataTableCommon instances directly");
    }

    super();

    this.attachShadow({ mode: 'open' });
  }

  /** @type DataTableElement|null */
  dataTableElement = null;

  /**
   * Life-Cycle Callback.
   * Run when the element is added to the DOM.
   */
  connectedCallback() {
    this.dataTableElement = this.shadowRoot.host.closest('data-table');
    this.render();
    this._initStickyCellsObserver();
  }

  /**
   * Initializes the observer for the sticky cells.
   * @protected
   */
  _initStickyCellsObserver() {
    const stickyCells = this.shadowRoot.querySelectorAll('.table__cell--sticky');

    const stickyCellsStateUpdater = () => {
      stickyCells.forEach(cell => {
        if (!this.dataTableElement) {
          return;
        }

        const column = cell.dataset.col;
        const additionalOffsetLeft = this.dataTableElement.stickyCellOffset[column]?.left ?? 0;
        const additionalOffsetRight = this.dataTableElement.stickyCellOffset[column]?.right ?? 0;
        
        this.constructor._updateStickyCellState(
          cell,
          this.dataTableElement,
          additionalOffsetLeft,
          additionalOffsetRight,
        );
      });
    }
    
    window.requestAnimationFrame(stickyCellsStateUpdater);
    this.dataTableElement.addEventListener('scroll', stickyCellsStateUpdater);
    window.addEventListener('resize', stickyCellsStateUpdater);
  }

  /**
   * Checks if the given cell is actively sticking to the left or right side of the root.
   * @param {HTMLTableCellElement} cell Sticky Cell
   * @param {DataTableElement} root Table Root Element
   * @param {number} additionalOffsetLeft Additional offset due to other sticky cells for the left side
   * @param {number} additionalOffsetRight Additional offset due to other sticky cells for the left side
   * @protected
   * @static
   */
  static _updateStickyCellState(cell, root, additionalOffsetLeft = 0, additionalOffsetRight = 0) {
    const positionLeft = cell.offsetLeft;
    const positionRight = root.scrollWidth - positionLeft - cell.offsetWidth;

    const scrollLeft = Math.round(root.scrollLeft);
    const scrollRight = root.scrollWidth - root.clientWidth - scrollLeft;

    // check if cell is at the beginning of the scroll position
    if (additionalOffsetLeft === 0 && positionLeft === 0 && root.scrollLeft === 0) {
      cell.dataset.stickyActive = false;
      return;
    }

    // check if cell is at the end of the scroll position
    if (additionalOffsetRight === 0 && positionRight === 0 && scrollRight === 0) {
      cell.dataset.stickyActive = false;
      return;
    }

    // check if cell is sticking to the left with offset
    if (scrollLeft === positionLeft - additionalOffsetLeft) {
      cell.dataset.stickyActive = true;
      cell.dataset.stickySide = 'left';
      return;
    }

    // check if cell is sticking to the right with offset
    if (scrollRight === positionRight - additionalOffsetRight) {
      cell.dataset.stickyActive = true;
      cell.dataset.stickySide = 'right';
      return;
    }

    // cell is not sticking and not at the beginning or end of the scroll position
    cell.dataset.stickyActive = false;
  }
}