//Cache für jQuery-AJAX-Requests abschalten, da der Internet Explorer AJAX-Requests zwischenspeichert,
//und das beim Abrufen des Sammelkorbs für den myERCO-Footer zu Fehlern geführt hat.
//Dieser hat häufig die falsche Anzahl an gesammelten Artikeln angezeigt.
//Wird jetzt innerhalb der einzelnen Request festgelegt, da es sonst bei anderen Requests, die nicht zu
//myERCO gehörten zu Fehlern kam
//$.ajaxSetup({ cache: false });

var settings = {
	home											: confEnv['pdb_api_url']+'/myerco/',
	searchroutesUrl						: confEnv['pdb_api_url']+'/myerco/searchroutes/',
	basketUrl									: confEnv['pdb_api_url']+'/myerco/basket/',
	collectionCheckboxListUrl	: confEnv['pdb_api_url']+'/myerco/collections/checkbox_list',
	basketCheckboxesOnlyUrl		: confEnv['pdb_api_url']+'/myerco/basket/checkboxes_only/',
	basketBatchdeleteUrl			: confEnv['pdb_api_url']+'/myerco/basket/batchdelete',
	downloadFilesUrl					: confEnv['download_service_url']+'/download',
	downloadTypesUrl					: confEnv['pdb_api_url']+'/myerco/downloadfiles/downloadtypes/',
	articlelistsUrl						: confEnv['pdb_api_url']+'/myerco/articlelists/',
	collectionsUrl						: confEnv['pdb_api_url']+'/myerco/collections',
	newCollectionRowUrl				: confEnv['pdb_api_url']+'/myerco/tools/create_new_collection_row/de',
	loginStatusUrl						: confEnv['pdb_api_url']+'/myerco/auth/get_login_status',
	offerRequest							: confEnv['pdb_api_url']+'/myerco/offers/',
	offersArticles						: confEnv['pdb_api_url']+'/myerco/offers_articles/',
	articleCount							: confEnv['pdb_api_url']+'/myerco/offers/articlecount/',
	openRequests							: confEnv['pdb_api_url']+'/myerco/offers/openrequests/',
	formLoginUrl							: confEnv['pdb_api_url']+'/myerco/auth/form_login',
	createUserUrl							: confEnv['pdb_api_url']+'/myerco/auth/create_user',
	forgotPasswordJsonUrl			: confEnv['pdb_api_url']+'/myerco/auth/forgot_password_json',
};

var translations = {};

var ssoEmailPattern = /.+@(erco\.com)$/;

globals.isStaff = false;

/* myERCO dynamischer Footer
============================================================*/
var DynamicFooter = {
	init: function () {
		this.element = document.querySelector('.js-myerco-dynamic-footer');

		if(this.element) {
			DynamicFooter.refreshButtonDistributeArticles();
		}
	},
	visible : false,
	show: function () {
		if(this.visible) {
			return;
		}
		this.visible = true;
		//Animation muss via JavaScript 'zu Fuß' erstellt werden, da man height: auto via CSS nicht animieren kann

		var prevHeight = this.element.style.height || '0px';
		this.element.style.transition = 'height .3s ease-in-out';
		this.element.style.height = 'auto';

		var endHeight = getComputedStyle(this.element).height;
		this.element.style.height = prevHeight;
		this.element.offsetHeight // force repaint
		this.element.style.height = endHeight;
		this.element.addEventListener('transitionend', DynamicFooter.handleTransitionFooterEnd);
	},
	hide: function () {
		if(!this.visible) {
			return;
		}
		this.visible = false;

		this.element.style.transition = 'height .3s ease-in-out';

		//Animation muss via JavaScript 'zu Fuß' erstellt werden, da man height: auto via CSS nicht animieren kann
		this.element.style.height = getComputedStyle(this.element).height
		this.element.offsetHeight // force repaint
		this.element.style.height = '0px';
		this.element.removeEventListener('transitionend', DynamicFooter.handleTransitionFooterEnd, false);
		this.element.style.overflowY = 'initial';
	},

	handleTransitionFooterEnd: function (e) {
		var element = e.target;
		if (e.propertyName == 'height') {
			element.style.transition = ''
			element.style.height = 'auto'
			element.style.overflowY = 'auto';
			element.removeEventListener('transitionend', DynamicFooter.handleTransitionFooterEnd, false);
		}
	},
	refreshCollectionCheckboxesList: function () {
		var show_download_collection_button = '';
		//Downloadbutton soll nicht  angezeigt werden, wenn eine collection_ID in der URL übergeben wurden
		//und demnach in der View die Inhalte einer Sammlung angezeigt werden
		if (!get_url_segment_collection_id())
			show_download_collection_button = 'show_download_collection_button';

		$.ajax({
			url: settings.collectionCheckboxListUrl,
			type: 'GET',
			cache: false,
			success: function (data) {

				//Aus String mit HTML-Content ein DOM-Element bauen und neu generierte Checkboxen daraus ziehen
				var newNode = $(data).get(0);

				//Alte Checkboxen von Artikelliste holen
				var oldNode = DynamicFooter.element.querySelector('#checkboxes-collections');

				//Checkboxen existieren nur dann, wenn auch Artikel gespeichert sind
				if (oldNode) {

					//Neue Artikelliste durch alte Artikelliste ersetzen
					oldNode.parentNode.replaceChild(newNode, oldNode);
				}

				DynamicFooter.refreshButtonDistributeArticles();

				//Data-navtoggle neu initialisieren, damit dieser auch bei neu hinzugefügten Tabellenzeilen funktioniert.
				toggle.start();
			}
		});
	},
	//Aktiviert oder deaktiviert die Schaltfläche zum Verteilen der Artikel
	refreshButtonDistributeArticles: function () {

		var toggleDistributeArticles = DynamicFooter.element.querySelector('[data-nav-toggle="distribute-articles"]');

		//Schaltfläche selektieren
		var buttonDistributeArticles = DynamicFooter.element.querySelector('#distribute-articles');

		//Existiert die Schaltlfäche?
		if (buttonDistributeArticles) {

			//Existieren Sammlungen?
			if (DynamicFooter.element.querySelectorAll('#checkboxes-collections .checkbox-item').length > 0) {

				//Schaltfläche aktivieren
				buttonDistributeArticles.classList.remove('-disabled');

				if (toggleDistributeArticles.classList.contains('active')) {
					toggle.einblenden($(toggleDistributeArticles));
				}

			} else {

				//Schaltfläche deaktivieren
				buttonDistributeArticles.classList.add('-disabled');

				toggle.ausblenden($(toggleDistributeArticles));

			}
		}
	}
};
/* Objekt zum ein- und ausblenden von Elementen
============================================================*/
toggle = {
	start: function () {
		$('[data-nav-toggle]>a').off('click').on('click', function (event) {
			event.preventDefault();
			$this = $(this).parent();

			if ($this.hasClass('active')) {
				toggle.ausblenden($this);
			} else {
				toggle.einblenden($this);

				if ($this.attr('data-nav-toggle') === 'nav_suche') {
					$('[data-nav-search]').focus();
				}
			}
		});

		$('[data-nav-search]').off('click').on('click', function (event) {
			event.preventDefault();
			event.stopPropagation();
			$this = $(this).closest('[data-nav-toggle]');
			($this.hasClass('active')) ? '' : toggle.einblenden($this);
		});

		$('body').off('click').on('click', function (event) {
			var $self = $('nav.aktiv');
			if ($self.length && !($(event.target).closest('[data-nav-container]').length)) {
				$this = $self.find('.nav-toggle [data-nav-toggle].active').first();
				toggle.ausblenden($this);
			}
		});
	},
	ausblenden: function ($this) {
		// einfaches ausblenden
		this.moveOut($this);

		// externes ausblenden
		if ($this.attr('data-nav-toggle')) this.moveOut($('[data-nav-target="' + $this.attr('data-nav-toggle') + '"]'));
		if ($this.attr('data-nav-target')) this.moveOut($('[data-nav-toggle="' + $this.attr('data-nav-target') + '"]'));

		// Umschließendem nav neuen Zustand mitteilen
		$this.closest("nav").removeClass("aktiv");
	},
	einblenden: function ($this) {
		// geschwister ausblenden
		toggle.ausblenden($this.siblings('.active'));

		// einfaches einbenden
		this.moveIn($this);

		// externes einblenden
		if ($this.attr('data-nav-toggle')) this.moveIn($('[data-nav-target="' + $this.attr('data-nav-toggle') + '"]'));
		if ($this.attr('data-nav-target')) this.moveIn($('[data-nav-toggle="' + $this.attr('data-nav-target') + '"]'));

		// Umschließendem nav neuen Zustabd mitteilen
		$this.closest("nav").addClass("aktiv");
	},
	moveIn: function ($this) {
		$this.children(':not("a")').stop().slideDown('fast').addBack().addClass('active');
	},
	moveOut: function ($this) {
		$this.children(':not("a")').stop().slideUp('fast').addBack().removeClass('active');
	},
};

DownloadSection = {
	checkBoxes: null,

	/**
	 * Aktiviert und deaktivert die Auswahlboxen in der Downloadsektion.
	 * @param jQuery-Object section Section mit den Auswahlboxen
	 * @param Array download_type_ids Downloadtypen zu denen die Auswahlboxen aktiviert werden sollen
	 */
	refreshSection: function (section, download_type_ids) {
		section.find('li').off('click');

		//Checkboxen selektieren
		this.checkBoxes = section.find('li.checkbox-item[data-dl-type-id]');

		//Iteration ueber jede Checkbox in Download-Section
		this.checkBoxes.each(function () {

			var checkBox = $(this);
			var inputCheckbox = checkBox.find('.check-box > input');

			//Downloadtype-Id der Checkbox holen
			var dl_type_id = parseInt($(this).attr('data-dl-type-id'), 10);

			//Gibt es einen Artikel der den Downloadtyp der Checkbox unterstuetzt?
			if (download_type_ids.indexOf(dl_type_id) >= 0) {

				//Checkbox wird aktiviert
				checkBox.removeClass('-disabled');

				//Eventhandler an Checkbox anheften
				inputCheckbox.on('click', DownloadSection.handleCheckboxClick);

				//Attribut hinzufuegen
				inputCheckbox.prop('disabled', false);

			} else {
				//Checkbox wird deaktivert
				checkBox.addClass('-disabled');

				//Eventhandler von Checkbox entfernen
				inputCheckbox.off('click');

				//Attribut hinzufuegen
				inputCheckbox.attr('disabled', '');

				//Markierung von Checkbox entfernen
				$(this).find('input').prop('checked', false);
			}
		});

		var check_box_all = section.find('input.check-alle-daten');
		var download_button = section.find('.download-article-data');

		//Wurde ein Artikel ausgewählt
		if (download_type_ids.length) {
			//Checkbox aktivieren
			check_box_all.parent().parent().removeClass('-disabled');
		} else {
			//Checkbox deaktivieren
			check_box_all.parent().parent().addClass('-disabled');
			check_box_all.prop('checked', false);

			//Downloadbutton deaktivieren
			download_button.addClass('-disabled');
		}

		//Einzelne Kategorien der Artikeltypen durchgehen
		section.find('ul').each(function () {
			//Auswahlbox zur Auswahl aller Artikeltypen einer Kategorie
			var categoryBox = $(this).find('span.-category:not(.check-alle-daten)').parent();

			//Sind alle Auswahlboxen einer Kategorie deaktivert
			if ($(this).children('li[data-dl-type-id]').length === $(this).children('li[data-dl-type-id].-disabled').length) {

				//Auswahlbox zur Auswahl aller Artikeltypen einer Kategorie deaktieren
				categoryBox.find('input').prop('checked', false);
				categoryBox.off('click');
				categoryBox.addClass('-disabled');
			} else {
				//Auswahlbox zur Auswahl aller Artikeltypen einer Kategorie aktivieren
				categoryBox.find('input').on('click', DownloadSection.handleCategoryCheckboxClick);
				categoryBox.removeClass('-disabled');
			}
		});

		// Check if there are checkable checkboxes
		var checkableCheckboxes = section.find('[data-dl-type-id]').filter(function(index, el) {
			return !el.classList.contains('-disabled');
		});

		var articleDataDownloadToggle = $('[data-nav-toggle="download-collector"]');

		if(checkableCheckboxes.length <= 0) {
			articleDataDownloadToggle.find('#download-collector').addClass('-disabled');
			toggle.ausblenden(articleDataDownloadToggle);
		}
		else {
			articleDataDownloadToggle.find('#download-collector').removeClass('-disabled');
		}
	},

	handleCheckboxClick: function () {
		var donload_section = $(this).closest('.download-section');

		//Schaltflaeche der Download-Section
		var btn_download_article_data = donload_section.find('.download-article-data');
		var count_selected_checkboxes = donload_section.find('input:checked').not('.-category').length;

		//Gibt es markierte Auswahlboxen
		if (count_selected_checkboxes > 0) {
			btn_download_article_data.removeClass('-disabled');
		} else {
			btn_download_article_data.addClass('-disabled');
		}

		if ($(this).prop('checked') === false) {

			//Checkbox der Oberkategorie zu der Checkbox die gerade angeklickt wurde unchecken
			//$(this).closest('').children('.-category').children('input').prop('checked', false);
			$(this).parent().parent().siblings().children('.-category').children('input').not('.-disabled').prop('checked', false);

			//Checkbox für alle Daten unchecken
			$(this).closest('.download-section').find('input.check-alle-daten').prop('checked', false);
		}
	},

	handleCategoryCheckboxClick: function () {
		var checkboxen = $(this).closest('ul').find('li').not('.-disabled').find('input');
		if ($(this).prop('checked')) {
			checkboxen.prop('checked', true);
		} else {
			checkboxen.prop('checked', false);

			//Checkbox für alle Daten unchecken
			$(this).closest('.download-section').find('input.check-alle-daten').prop('checked', false);
		}
	},

	init: function() {

			/* Alle Daten die in der Artikelliste ausgewählt wurden via ZIP herunterladen
			 ============================================================*/
		var downloadActive = false;
		$('.js-download-section-articllist').off('click', '.download-article-data').on('click', '.download-article-data', function (event) {
			event.preventDefault();

			//Download erst nachdem die Daten im AJAX-Request verarbeitet wurden, ein weiteres mal starten.
			if (!downloadActive) {
				downloadActive = true;
				var self = $(this);
				var pathParamArtikelIds = "";
				var articleIds = [];

				/* Wurde der Download der Artikel im Sammelkorb oder über eine Artikelliste innerhalb einer Sammlung ausgeführt?
				 * Nur dann dürfen die ausgewählten Artikel aus der Artikelliste geholt werden
				 * Sonst kommt es zu Fehlern beim AJAX-Request zum Download der Artikeldaten
				 */
				if (self.attr('href').indexOf('/collections/') < 0 || get_url_segment_collection_id() ) {

					//Artikelnummern holen
					articleIds = Articlelist.getCheckedArticleIDs(true);

					//ArtikelIds von markierten Artikeln konkatenieren
					for (var j = 0; j < articleIds.length; j++) {
						pathParamArtikelIds += articleIds[j] + '-';
					}
					//Letztes Zeichen, dass ein Bindestrich ist, entfernen
					pathParamArtikelIds = pathParamArtikelIds.substr(0, pathParamArtikelIds.length - 1);

					if (pathParamArtikelIds)
					pathParamArtikelIds += '/';
				}

				if (articleIds.length === 0) {
					//Artikelnummern holen
					var articles = self.attr('data-articles');
					if (articles !== '') {
						articleIds = articles.split(', ');
					}
				}

				//Alle Checkboxen selektieren, die gechecked sind und NICHT eine Gruppe von Checkboxen auswählen.
				var checkBoxes = $(this).closest('.download-section').find('input:checked').not('.-category');
				//var checkBoxes = $('.checkbox-group .check-box > input:checked').not('.-category');

				const downloadTypesIds = [];
				const downloadTypesLiterals = [];

				//Alle Checkboxen durchgehen
				Array.from(checkBoxes).forEach((checkbox) => {
					const parentLi = checkbox.closest('li');
					const downloadTypeId = parentLi.getAttribute('data-dl-type-id');
					const downloadTypeLiteral = checkbox.getAttribute('data-type');

					downloadTypesIds.push(downloadTypeId);
					downloadTypesLiterals.push(downloadTypeLiteral);
				})

				//Rows in der Tabelle mit den Artikeln selektieren
				//var tableRows = $('.artikel-ansicht-1 tbody tr');

				//Wenn Artikel vorhanden und mindestens eine Checkbox ausgewählt
				if (downloadTypesIds.length) {

					// send download data to google analytics
					DownloadSection.sendAnalyticsData(articleIds, downloadTypesLiterals);

					const requestUrl = `${settings.downloadFilesUrl}/${k3vars.S_Sprache}?articleIds=${articleIds.join(',')}&types=${downloadTypesIds.join(',')}&formattedId`;

					//Ajax-Spinner aus DOM holen
					var ajax_loader = self.siblings('.ajax-loader');

					//Ajax-Spinner einblenden
					ajax_loader.fadeIn();

					$.ajax({
						url: requestUrl,
						type: "GET",
						cache: false,
						startTime: new Date().getTime(),
						success: function (data) {
							//Iframe anhängen, innerhalb welchem die Daten heruntergeladen werden
							var _tmp = $('<iframe />')
								.attr('src', requestUrl)
								.attr('class', 'download-frame')
								.hide()
								.appendTo(self);
							//Wartezeit berechnen: Downloadzeit + 1,5s
							waitTime = new Date().getTime() - this.startTime + 1500;
							setTimeout(function () {
								_tmp.remove();
								self.prop('disabled', false);
								downloadActive = false;
								//Ajax-Spinner ausblenden
								ajax_loader.fadeOut();
							}, waitTime);

						},
						error: function (data) {
							console.error(data);
							downloadActive = false;
							//Ajax-Spinner ausblenden
							ajax_loader.fadeOut();
						}
					});
				}
			}
		});

	},
	// Sending analytic data to Google / KT - 25.04.2019
	sendAnalyticsData(articleIds, dataTypes) {
		if(typeof trackDownload == "function"){
			articleIds.forEach(function(articleID){
				dataTypes.forEach(function(dataType){
					var analyticString = 'download/' + articleID + '/' + k3vars.S_Sprache + '/' + new Date().getFullYear() + '/' + dataType;
					trackDownload(analyticString);
				});
			});
		}
	}
};

ViewTable = {
	/**
	 * Loescht eine Zeile in einer Artikelliste
	 * @param html target DOM-Element welches sich innerhalb der Zeile befindet die gelöscht werden soll.
	 */
	deleteRow: function (target) {
		var tableRow = $(target).closest('tr');

		// @since web component update
		if (tableRow === undefined || tableRow.length === 0) {
			tableRow = $(target).closest('data-table-item');

			if (tableRow === undefined || tableRow.length === 0) {
				return;
			}

			tableRow.remove();
			return;
		}

		//Detailansicht einer Tabellenzeiele löschen.
		//footable-row-detail = Detailansicht in einer Artikellliste
		ViewTable.slideUpRow(tableRow.next('.footable-row-detail'));

		//basics = Basisansicht in einer Sammlungsliste
		ViewTable.slideUpRow(tableRow.prev('.basics'));

		ViewTable.slideUpRow(tableRow);
	},

	/**
	 * Laedt die Artikelliste neu
	 */
	refreshView: function () {
		var show_download_collection_button = '';
		//Downloadbutton soll nicht  angezeigt werden, wenn eine collection_ID in der URL übergeben wurden
		//und demnach in der View die Inhalte einer Sammlung angezeigt werden
		if (!get_url_segment_collection_id())
			show_download_collection_button = 'show_download_collection_button';



		$.ajax({
			url: settings.basketCheckboxesOnlyUrl + '/' + show_download_collection_button,
			type: 'GET',
			cache: false,
			success: function (data) {

				//Aus String mit HTML-Content ein DOM-Element bauen und neu generierte Checkboxen daraus ziehen
				var newNode = $(data).find('#js-checkbox-ares').get(0);
				//Alte Checkboxen von Artikelliste holen
				var oldNode = document.getElementById('js-checkbox-ares');

				//Checkboxen existieren nur dann, wenn auch Artikel gespeichert sind
				if (oldNode) {

					//Neue Artikelliste durch alte Artikelliste ersetzen
					oldNode.parentNode.replaceChild(newNode, oldNode);
				}

				$('.artikel-ansicht-1 tbody input[data-article-nr]').prop('checked', false);

				//Data-navtoggle neu initialisieren, damit dieser auch bei neu hinzugefügten Tabellenzeilen funktioniert.
				toggle.start();


			}
		});
	},
	//Animation zum Loeschen einer Tabellenzeile
	slideUpRow: function (target) {
		target.children('td, th')
			.animate({
				padding: 0
			})
			.wrapInner('<div />')
			.children()
			.slideUp(function () {

				//Wenn letzte Tabellenzeile geloescht wird
				if ($(this).closest('tbody').children('tr').length === 1) {

					//dann Seite neu laden
					location.reload();
				}
				$(this).closest('tr').remove();
			});
	}
};

Collections = {

	//Neue Sammlung hinzufuegen
	addCollection: function (event, collection_name, url_ressource, project, callback) {
		event.preventDefault();

		if (collection_name.length > 0) {
			var jsonData = {
				'collection_name': collection_name,
				'project': project,
			};

			$.ajax({
				url: url_ressource,
				type: 'POST',
				cache: false,
				data: {
					data: JSON.stringify(jsonData)
				},
				dataType: "html",
				success: function (data) {
					callback(data);
				},
				error: function (data) {
					console.error(data);
				}
			});
		}
	},

	//Sammlung loeschen
	deleteCollection: function (target, url_ressource, callback) {

		//Sicherheitsabfrage bevor Sammlung geloescht werden soll
		var result = confirm(globals.trans.myerco.frage_sammlung_loeschen);
		if (result) {

			$.ajax({
				url: url_ressource,
				cache: false,
				type: 'DELETE',
				success: function (data) {
					callback();
				},
				error: function (data) {
					console.error(data);
				}
			});
		}
	},

	//Sammlung umbenennen
	renameCollection: function (event, url_ressource, collection_name, callback) {
		event.preventDefault();
		if (collection_name.length > 0) {
			var jsonData = {
				'collection_name': collection_name,
			};
			$.ajax({
				data: {
					data: JSON.stringify(jsonData)
				},
				type: 'PUT',
				url: url_ressource,
				cache: false,
				success: function (data) {
					callback(data);

				}
			});
		}
	},

	hideRenameCollectionBox: function (collection_edit_box, collection_rename_button, nav_toggle) {

		//Schaltflaechen und Eingabefeld selektieren
		var input_collection = collection_edit_box.children('.input');
		var action_okay = collection_edit_box.children('.rename-collection-okay');
		var action_cancel = collection_edit_box.children('.rename-collection-cancel');

		//Eventlistener entfernen
		input_collection.off('keyup');
		action_cancel.off('click');
		action_okay.off('click');

		//Schaltflaechen zum Editieren der Sammlung ausblenden
		collection_rename_button.siblings().removeClass('-disabled');
		collection_rename_button.removeClass('-disabled');


		//Box zum Umbenennen ausblenden
		collection_edit_box.fadeOut(300, function () {
			nav_toggle.fadeIn(300);
		});
	},

	shareCollection: function (target, url_ressource, collection_id, callback) {

		//AJAX-Request muss asynchron ausgefuehrt werden, da 'window.open' sonst nicht funktioniert
		var response = false;
		var jsonData = {
			'collection_id': collection_id
		};

		$.ajax({
			data: {
				data: JSON.stringify(jsonData)
			},
			async: false,
			cache: false,
			type: 'POST',
			url: url_ressource,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				response = data;
			},
			error: function (data) {
				console.error(data);
			}
		});
		if (response) {

			//Zeilenumbrüche encodieren
			var lineFeed = encodeURIComponent('\r\n')

			//Zeilenumbrüche mit encodierten Zeilenumbrüchen ersetzen
			response.email_text = (response.email_text.replace(/(?:\n)/g, lineFeed));

			window.location.href = 'mailto:?subject=' + response.email_subject + '&body=' + response.email_text + '%0D%0A' + response.share_link;
			callback(response);
		}
	},


	addCollectionToProject: function(url_ressource, external_project_id, callback) {
		var jsonData = {
			'external_project_id': external_project_id,
		};

		$.ajax({
			url: url_ressource,
			type: 'PUT',
			data: {
				data: JSON.stringify(jsonData)
			},
			dataType: 'html',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				callback(data);
			},
			error: function (data) {
				console.error(data);
			}
		});
	},

	copyCollection: function(url_resource, destination, project, callback) {
		var jsonData = {
			'copy_destination': destination,
			'project': project,
		};

		$.ajax({
			url: url_resource,
			type: 'POST',
			data: {
				data: JSON.stringify(jsonData)
			},
			dataType: 'html',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				callback(null, data);
			},
			error: function (err) {
				console.error(data);
				callback(err);
			}
		});
	},

	removeCollectionFromProject: function(url_resource, callback) {
		$.ajax({
			url: url_resource,
			type: 'DELETE',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				callback(null);
			},
			error: function (data) {
				callback(err);
				console.error(data);
			}
		});
	},


	/**
	 * Aktualsiert die DownloadSection zu jeder Sammlung
	 * Downloadtypen die zu den Artikeln in einer Sammlung nicht verfügbar sind
	 * werden deaktiviert.
	 */
	refreshDownloadSection: function () {
		$('#collections-table [data-collection-id]').each(function () {
			var tr_collection = $(this);

			//Verfügbare Downloadtypen der Artikel innerhalb der Sammlung abrufen
			var dl_types = tr_collection.attr('data-dl-types').split(', ').map( Number );

			//Colleciton ID der Sammlung holen
			var collection_id = tr_collection.attr('data-collection-id');


			//Zur Sammlung dazugehoerige Download-Section holen
			var download_section = $('[data-nav-target="download-' + collection_id + '"] .download-section');

			//TODO Hier müssen noch die dynamischen Downloaddaten gezogen werden
			DownloadSection.refreshSection(download_section, dl_types);
		});
	},


	/**
	 * Lädt die Tabelle mit den Sammlungen bzw. Unterordnern neu
	 */
	refreshView: function (callback) {
		var table_type;
		//Befinden wir uns in einer Sammlung?
		if (get_url_segment_collection_id()) {

			//Dann wird die Tabelle mit Unterordner neue geladen
			table_type = "table_folders";
		} else {
			//Sonst wird die Tabelle mit den Sammlungen neu geladen
			table_type = "table_collections";

		}
		$.ajax({
			url: settings.collectionsUrl + '/' + get_url_segment_collection_id() + '/' + table_type + '/' + k3vars.S_Sprache,
			type: 'GET',
			dataType: "html",
			cache: false,


			success: function (data) {

				// Tabelle mit Sammlungen refreshen
				//Tabelle mit Sammlungen selektieren
				var collections_table = $('#collections-table tbody');
				//Tabellenzeilen aus Sammlungen loeschen, ausser die mit den LightFinder-Suchwegen
				//und Elementen zum Anlegen neuer Sammlungen
				collections_table.children().not('.tr-searchroutes, .tr-new-collection').remove();

				//Tabellenzeilen wieder hinzufuegen
				collections_table.children().last().before(data);

				//Data-navtoggle neu initialisieren, damit dieser auch bei neu hinzugefügten Tabellenzeilen funktioniert.
				toggle.start();
			},
			error: function (data) {
				location.reload();
				console.error(data);
			},

			complete: function (data) {
				callback();
			}
		});
	},

	updateArticleQuantity: function(url_resource, quantity, callback) {
		$.ajax({
			method: 'PUT',
			url: url_resource,
			cache: false,
			data: {
				data: JSON.stringify({
					quantity: quantity,
				}),
			},
			success: function (data) {
				if (typeof callback !== 'undefined') {
					callback(null, data);
				}
			},
			error: function (err) {
				console.error(err);
				if (typeof callback !== 'undefined') {
					callback(err);
				}
			}
		});
	},

	updateArticleIndividual: function(url_resource, individual, callback) {
		$.ajax({
			method: 'PUT',
			url: url_resource,
			cache: false,
			data: {
				data: JSON.stringify({
					individual: individual,
				}),
			},
			success: function (data) {
				if (typeof callback !== 'undefined') {
					callback(null, data);
				}
			},
			error: function (err) {
				console.error(err);
				if (typeof callback !== 'undefined') {
					callback(err);
				}
			}
		});
	},
};

ControlCollector = {
	links: null,

	//Initialisiert das Object
	init: function (isVisible) {

		//Elemente selektieren
		this.links = $('.js-toggle-myerco-footer');
		if (this.links) {

			//Eventlistener hinzufuegen
			this.links.off('click').on('click', this.toggleCollector);
			Collector.isVisible = isVisible;
			//Ist der myERCO-Footer eingeblendet
			if (isVisible) {

				//Footer einblenden
				Collector.showhideFooter();

				//Linktexte aendern
				this.links.html(translations.linktext_myerco_footer_ausblenden);
			} else {

				//Footer einblenden
				Collector.hideFooter();

				//Linktexte aendern
				this.links.html(translations.linktext_myerco_footer_einblenden);
			}
		}
	},

	showCollector: function () {
		//Footer einblenden
		Collector.showhideFooter();

		//Text in Schalter ändern
		//ControlCollector.links.html(translations.linktext_myerco_footer_ausblenden);

	},
	hideCollector: function () {
		//Footer ausblenden
		Collector.hideFooter();

		//Text in Schalter ändern
		ControlCollector.links.html(translations.linktext_myerco_footer_einblenden);

	},

	//myERCO Footer ein- und ausblenden
	toggleCollector: function (e) {

		e.preventDefault();

		//Wird der Footer angezeigt?
		if (Collector.isVisible) {

			//Footer ausblenden
			Collector.hideFooter();

			//Text in Schalter ändern
			ControlCollector.links.html(translations.linktext_myerco_footer_einblenden);
		} else {
			//Footer einblenden
			Collector.showhideFooter();

			//Text in Schalter ändern
			ControlCollector.links.html(translations.linktext_myerco_footer_ausblenden);
		}

	}
};

//Objekt zur Verwaltung der Artikel im Sammelkorb
Collector = {

	isVisible: false,
	numberOfArticles: 0,
	articles: {},
	eventListenerAdded: false,

	/* injizieren des Sammelkorbicons */
	injectUI: function (obj) {

		// Die Logik ob wir uns im
		//Wenn config Variable auf false gesetzt oder wir uns innerhalb von myeRCO befinden und nicht die Spotlightsuche benutzt wird
		// if (config_data.collectable == false || ($("[data-myerco-status=inside]").length > 0 && obj.selector && obj.selector !== "[data-target=gui_suchergebnis]")) {
		if (config_data.collectable == false) {
			//Dann wird das Sammkorb Icon nicht injiziert
			return;
		}

		obj = obj || {};

		var selector = "";
		if (obj.artno) {
			selector = '#data_large-' + obj.artno;
		}

		// Man kann den Bereich angeben indem injected wird, zBsp in der Artikelnummersuchliste
		if (obj.selector) {
			selector = obj.selector;
		}

		if (selector === undefined) {
			selector = "";
		}

		// Paketicon injizieren, wenn es noch keins gibt
		var elements = document.querySelectorAll(`${selector} [data-collectable-ui-element]`);
		if (elements.length) {
			for (var i=0; i < elements.length; i++) {
				var td = elements[i].closest('[data-artno]');
				
				//Check if element is within td element and interaction items are not injected
				if(td && elements[i].childNodes.length === 0) {
					var articleId = td.getAttribute('data-artno');
					elements[i].innerHTML = '<div class="check-box__box"><input type="checkbox" name="check-articles" id="'+articleId+'" data-collect-item> <label class="check-box__mark -star" for="'+articleId+'"></label></div>';
				}
			}
		}

		for (var item in Collector.articles) {
			//Checkboxen von den Artikeln anwählen, die sich bereits im Sammelkorb befinden
			var checkboxWrapper = $('[data-collectable-id="' + item + '"] span[data-collectable-ui-element] .check-box');
			checkboxWrapper.addClass('-checked');
			checkboxWrapper.find('[data-collect-item]').attr('checked', 'checked');
		}

		//Fügt einen Artikel zum Sammelkorb hinzu
		$('#articledata, #ledfinder, #specsheet, [data-target=gui_suchergebnis]')
			.off('click', '[data-collect-item]', Collector.collectItemHasBeenClicked) // unbind the click listener first to avoid duplicated event-calls
			.on('click', '[data-collect-item]', Collector.collectItemHasBeenClicked); // rebind the click listener
	},

	collectItemHasBeenClicked: function(event) {

		ControlCollector.showCollector();

		//ArtikelID aus dem Element holen und Punkte entfernen
		var article_id = $(this).closest('[data-collectable-id]').attr('data-collectable-id');
		var cleanID = article_id.replace('.', '');

		// Artikeltyp spezifizieren
		var articleType = $(this).closest('[data-articletype]').attr('data-articletype'); // might be undefined, which is ok because Collector.addArticle's default type is 'article'. ^schi

		var packageItems = document.querySelectorAll('tr#small-' + cleanID + ' [data-collect-item], tr#large-' + cleanID + ' [data-collect-item]');

		for (var i = 0; i < packageItems.length; i++) {
			if (this.checked) {
				packageItems[i].parentNode.classList.add('-checked');
				packageItems[i].checked = true;
			} else {
				packageItems[i].parentNode.classList.remove('-checked');
				packageItems[i].checked = false;
			}
		}

		if (this.checked) {
			Collector.addArticle(article_id, articleType);
		} else {
			Collector.deleteArticles([article_id]);
		}
	},

	showhideFooter: function () {
		//myERCO-Footer anzeigen
		//if (!$('[data-myerco-status="inside"]').length) {
		var pageArea = window.location.href.replace(/^https?:\/\//, '').split('/')[2];
		if(pageArea === 'myerco') {
			$('#myerco-footer').addClass('visible');
			this.isVisible = true;
			kmcookie.setzen('myerco-footer-isvisible', 'true', 600);
		}
		//}

	},

	hideFooter: function () {
		//myERCO-Footer anzeigen
		$('#myerco-footer').removeClass('visible');
		this.isVisible = false;

		kmcookie.setzen('myerco-footer-isvisible', 'false', 600);

	},

	refresh: function () {
		$('#myerco-collector a > .counter').html(this.numberOfArticles);

		// Showing the accent
		if($('#sidebar_article_count').closest('.side_menu_section').hasClass('js-init')) {
			$('#sidebar_article_count').closest('.side_menu_section').removeClass('js-init');
		} else {
			$('#sidebar_article_count').closest('.side_menu_section').addClass('dropAccent');
			setTimeout(function() {
				$('#sidebar_article_count').closest('.side_menu_section').removeClass('dropAccent');
			}, 150);
		}

		// Adding the count also to the sidebar element and showing the accent corner and animation if neccesary
		$('#sidebar_article_count, .sidebar_article_count').html(this.numberOfArticles);
		if(this.numberOfArticles > 0) {
			// dispatch same event to change myerco menu content
			const myErcoEvent = new CustomEvent('myercoLoggedIn');
			document.dispatchEvent(myErcoEvent);

				$('#sidebar_article_count').closest('.side_menu_section').addClass('showAccent');

			if (!globals.isStaff) {
				$('.get-offer-dynamic').removeClass('hidden');
			}
		} else {
			$('#sidebar_article_count').closest('.side_menu_section').removeClass('showAccent');
			$('.get-offer-dynamic').addClass('hidden');
		}

		if (typeof article_singular != 'undefined' && typeof article_plural != 'undefined') {
			var word_article = $('#myerco-collector > a > #word-article');

			//Befindet sich nur ein Artikel im Sammelkorb
			if (this.numberOfArticles === 1) {


				//Dann wird fuer das Wort "Artikel" die Einzahl eingefuegt
				word_article.html(article_singular);
			}
			//Sonst wird die Mehrzahl eingefuegt
			else {
				word_article.html(article_plural);
			}
		}

	},

	toggleArticle: function (articleID) {
		if (!this.articles[articleID]) {
			this.addArticle(articleID);
		} else {
			this.deleteArticles([articleID]);
		}
	},

	addArticle: function (articleID, type = 'article') {
		var articles = [{
			'article_id': articleID,
			'type': type,
		}];

		$.ajax({
			method: 'POST',
			url: settings.basketUrl,
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			data: {
				data: JSON.stringify(articles),
			},
			success: function (data) {
				Collector.articles[articleID] = articleID;
				Collector.numberOfArticles++;
				Collector.refresh();
			},
			error: function (data) {
				console.error(data);
			}
		});
	},

	deleteArticles: function (articleIDs, callback) {
		$.ajax({
			method: 'POST',
			url: settings.basketBatchdeleteUrl,
			cache: false,
			data: {
				data: JSON.stringify(articleIDs),
			},
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				for (var i = 0; i < articleIDs.length; i++) {
					delete Collector.articles[articleIDs[i]];
				}

				Collector.numberOfArticles -= articleIDs.length;
				Collector.refresh();

				if (typeof callback !== 'undefined')
					callback(data);
			},
			error: function (data) {
				console.error(data);
			}
		});
	},

	init: function () {

		// Artikel die im Sammelkorb sind abrufen
		$.ajax({
			url: settings.basketUrl,
			method: 'GET',
			cache: false,
			dataType: "json",
			xhrFields: {
				withCredentials: true
			},

			success: function (data) {

				Collector.articles = {};

				//Für alle Artikel im Sammelkorb
				for (var i in data) {
					//Artikel in lokaler Variable speichern
					Collector.articles[data[i].PKM_Article_nr] = data[i].PKM_Article_nr;
				}

				//$('tr[data-collectable-id] span[data-collectable-ui-element] a').fadeTo('fast', 0.3);

				//Paketicons von den Artikeln faden, die sich bereits im Sammelkorb befinden
				for (var item in Collector.articles) {
					var checkboxWrapper = $('[data-collectable-id="' + item + '"] span[data-collectable-ui-element] .check-box');
					checkboxWrapper.addClass('-checked');
					checkboxWrapper.find('[data-collect-item]').attr('checked', 'checked');
				}

				Collector.numberOfArticles = data.length;
				Collector.refresh();

				/*
				 * Befinden sich Artikel im Sammelkorb
				 * und ist im Cookie gespeichert, dass Sammelkorb einbeblendet werden soll
				 * und befinden wir uns außerhalb von myerco
				 */
				if (Collector.numberOfArticles && (kmcookie.lesen('myerco-footer-isvisible') === 'true') && $('[data-myerco-status="inside"]').length === 0) {
					ControlCollector.init(true);
				}
				else {
					ControlCollector.init(false);
				}
			},
			error: function (data) {
				console.error(data);
			}
		});

	}
};

Articlelist = {

	/**
	 * Verteilt mehrere Artikel auf mehrere Ordner
	 * @param array folderIDs  Array mit den IDs der Ordner,
	 *                auf denen die Artikel verteilt werden sollen [{"folder_id": <folderID>}]
	 * @param array articles Array mit den Artikelm ([{"article_id", "type"}],
	 *                die auf die Ordner verteilt werden sollen. [{"article_id": <articleID>}]
	 * @param int Quellordner aus dem die Artikel verteilt werden
	 */
	distributeArticles: function (folderIDs, articles, originCollectionId, callback) {
		//********** JSON-Datei aus Array mit articleIds bauen
		// var jsonData = [];
		// for (var i in articleIDs) {
		// 	jsonData.push({
		// 		"article_id": articleIDs[i]
		// 	});

		// }
		//**********

		//Speichert die promises der AJAX-Aufrufe
		var promises = [];

		//Collections die sich geaendert haben
		//Werden dem Callback uebergeben, wenn das Verteilen der Artikel abgeschlossen ist

		var count_ajax_requests = 0;

		for (var j in folderIDs) {
			$.ajax({
				method: 'POST',
				url: settings.articlelistsUrl + folderIDs[j],
				cache: false,
				data: {
					data: JSON.stringify(articles)
				},
				success: function () {
					//Abgeschlossene Ajax-Requests zaehlen
					count_ajax_requests++;

					//Wenn Ajax-Requests fuer alle Ordner erfolgreich abgeschlossen sind
					if (count_ajax_requests == folderIDs.length)
						onAjaxSuccess();
				},
				error: function (data) {
					console.error(data);

				}
			});
		}

		//Synchronisation der AJAX-Requests. Wird ausgeführt wenn alle AJAX-Request erfolgreich abgeschlossen sind.
		function onAjaxSuccess() {
			var articleids = articles.map(function(article) {
				return article.article_id;
			});

			//Wenn originCollectionId nicht gesetzt ist, dann ist der Quellordner der Sammelkorb und die Artikel sollen aus dem Sammelkorb geloescht werden
			if (originCollectionId === 0) {
				Collector.deleteArticles(articleids, callback);
			}

			//Artikel die verteilt wurden löschen. Aber nur dann wenn NICHT in den Quellordner verteilt werden soll.
			else if (folderIDs.indexOf(originCollectionId) < 0) {
				Articlelist.deleteArticles(articleids, settings.articlelistsUrl + originCollectionId + '/batchdelete', callback());
			} else {
				callback();
			}

		}
	},

	deleteArticles: function (articleIDs, url_ressource, callback) {
		var articles = [];
		for (var i = 0; i < articleIDs.length; i++) {
			articles.push({
				"article_id": articleIDs[i]
			});
		}

		$.ajax({
			method: 'POST',
			url: url_ressource,
			cache: false,
			data: {
				data: JSON.stringify(articleIDs),
			},
			success: function (data) {
				Collector.numberOfArticles -= articleIDs.length;
				Collector.refresh();

				if (typeof callback !== 'undefined')
					callback(data);
			},
			error: function (data) {
				console.error(data);
			}
		});
	},

	getCheckedArticles: function (articlesOnly = false) {
		var selector = articlesOnly ? '[data-article-nr][data-articletype="article"]:checked' : '[data-article-nr]:checked';

		//Artikelnummer der Artikel die markiert sind
		var articles = [];

		//Artikelnummer der ausgewaehlten Artikel selektieren
		$(selector).each(function (index, value) {
			var article = {
				'article_id': value.getAttribute('data-article-nr'),
				'type': value.getAttribute('data-articletype')
			};

			//Artikelnummer holen und dem Array hinzufuegen
			articles.push(article);
		});

		return articles;
	},

	getCheckedArticleIDs: function(articlesOnly = false) {
		var articles = Articlelist.getCheckedArticles(articlesOnly);

		var ids = articles.map(function(article) {
			return article.article_id;
		});

		return ids;
	},

	//Gibt die IDs der Downloadtypen von den markierten Artikeln zurueck
	getDownloadTypeIds: function (article_list, callback) {
		var download_type_ids = [];
		let articleIds = '';

		//Downloadtypen der markierten Artikel aggregieren
		article_list.each(function (index, value) {
			articleIds += `${value.closest('tr').getAttribute('data-collectable-id')}-`
		});


		articleIds = articleIds.slice(0, -1);

		const requestUrl = `${settings.downloadTypesUrl}${articleIds}/${k3vars.S_Sprache}`;
		let downloadIds = [];
		
		// Fetch available download types of articles
		$.ajax({
			url: requestUrl,
			type: 'GET',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function(res) {
				if (res) {
					downloadIds = res.map((element) => element.id);
				}
				callback(downloadIds);
			},
			error: function(err) {
				callback(false);
				console.error(err);
			}
		});
	},
};

/**Holt die CollectionID aus der URL und gibt sie zurueck
 * @returns String CollectionID. Wenn keine CollectionID gefunden, dann ist der String leer.
 */
function get_url_segment_collection_id() {
	//CollectionID aus URL holen
	var pathArray = window.location.pathname.split('/');
	var url_segment_collection_id = 0;
	if (pathArray[2] === 'collections' || pathArray[2] === 'articlelists' && parseInt(pathArray[3])) {
		url_segment_collection_id = pathArray[3];
	}
	return url_segment_collection_id;
}


$(function () {

	toggle.start();
	DynamicFooter.init();

	/* Uebersetzungen holen
	============================================================*/
	var lang = k3vars.S_Sprache || 'en';


	$.ajax({
		url: confEnv['pdb_api_url']+'/productdata/translate/' + lang + '/context/myerco/layout/json',
		type: 'GET',
		dataType: "json",
		success:  function (data) {

			if (!globals.trans) {
				globals.trans = {};
			}
			globals.trans['myerco'] = data


			Collector.init();


			/* Link, zum Wechseln nach myERCO, initialisieren
			============================================================*/
			var link_my_erco = $('.js-myerco-login');

			//Status abrufen, ob der User bei myERCO angemeldet ist
      authHelper.isLoggedIn.then((isLoggedIn) => {
        if (isLoggedIn) {
          link_my_erco.html(globals.trans.myerco.linktext_myerco_angemeldet);
          const myErcoEvent = new CustomEvent('myercoLoggedIn');
          document.dispatchEvent(myErcoEvent);
        } else {
          link_my_erco.html(globals.trans.myerco.linktext_myerco_abgemeldet);
        }
      });
		}

		/*
		============================================================*/

	});

	/* Angebotsanfrage-Buttons ausblenden, wenn User Staff-Mitglied */
  authHelper.isStaff.then((isStaff) => {
    globals.isStaff = isStaff;
    if (isStaff) {
      $('.get-offer-dynamic, #get-offer, #continue-offer').addClass('hidden');
    }
  });

	// SessionID aus dem Cookie ci_session auslesen, dieses Cookie wird vom CI gesetzt.
	var cookie = kmcookie.lesen('ci_session');
	var sessionID = cookie ? cookie.match(/s:32:"([a-z0-9]{32})"/)[1] : null;

	if ($('[data-myerco-status="inside"]').length || $('[data-buyerco-status="inside"]').length) {
		$('footer.highlight').css({
			"display": "none"
		});
		$('body').css({
			"background-color": "#ffffff"
		});

		$('#collections-table tr').addClass('footable-detail-show');

		/* Funktionalitäten für die Artikellisten intitialisieren, falls noch nicht geschehen
		============================================================*/

		if (typeof artikelseite === "undefined") {
			artikelfilter.func.init();
			artikelseite.func.init();
			artikelliste.func.init();
			// idrop.func.init();
		}


	}
	//else {
	$('#myerco-footer .js-close-footer').on('click', function (e) {
		e.preventDefault();
		ControlCollector.hideCollector();
	});
	//}


	$('#myerco-dynamic-footer .js-close-dynamic-footer').on('click', function (e) {
		e.preventDefault();
		DynamicFooter.hide();
		$('#articlelist input').prop('checked', false);
	});

	if (sessionID) {
		$(document).on('holy:edgeBottom', function () {
			//$('#myerco-footer').css({'transform' : 'translate(0px, -'+$('footer').height()+'px)'});
			$('footer.highlight').css({
				'padding-bottom': $('#myerco-footer').height() + 'px'
			});
		});

		$(document).on('holy:fromBottom', function () {
			$('footer.highlight').css({
				'padding-bottom': 0
			});
		});
	}

	//Befinden wir uns innerhalb einer bereitgestellten Sammlung?
	if ($('#shared_collection').length > 0) {

		//DownloadSection holen
		var dl_section = $('.download-section');

		//Downloadtypen von Artikeln der Sammlung und den Unterordnern holen
		Articlelist.getDownloadTypeIds($('.artikel-ansicht-1 tbody tr > td:first-child'), function(dl_type_ids) {
			//DownloadSection aktualisieren. Verfügbare DL-Typen werden aktiviert, nicht verfügbare deaktiviert.
			DownloadSection.refreshSection(dl_section, dl_type_ids);
		});
	}
	
	//Befinden wir uns im Browser innerhalb einer Sammlung?
	if (get_url_segment_collection_id()) {
		//var dl_type_ids = $('#collections-table').attr('data-dl-types');
		var dl_section = $('[data-nav-target="download-' + get_url_segment_collection_id() + '"] .download-section');
		if (dl_section) {
			DownloadSection.refreshSection($('[data-nav-target="download-' + get_url_segment_collection_id() + '"] .download-section'), []);
		}
	}

	//Footable für Suchwege initialisieren
	//Event wird gefeuert, wenn Footable initialisiert ist
	$('[data-footable-searchrouts]').footable()
		.on('ready.ft.table', function (e) {
			toggle.start();

			/* Löscht einen gespeicherten Suchweg
			 ============================================================*/
			$('button.delete-route').on('click', function () {
				var self = $(this);
				$.ajax({
					url: confEnv['pdb_api_url']+'/myerco/searchroutes/' + $(this).attr('data-id'),
					method: 'DELETE',
					cache: false,
					success: function (result) {
						self.parent().parent().parent()
							.children('td, th')
							.animate({
								padding: 0
							})
							.wrapInner('<div />')
							.children()
							.slideUp(function () {
								$(this).closest('tr')[0].remove();

								if ($('#searchroutes > tbody > tr').length === 0) {
									location.reload();
									//$('#searchroutes').parent().append("<div class='top-1'><?php echo $translate['searchroutes_nachricht_keine_suchwege'];?></div>");
									//$('#searchroutes').remove();
								}
							});
					},
					error: function (result) {
						console.error(result);
					}
				});
			});

			/* Zeigt einen gespeicherten Suchweg im Lightfinder an
			 ============================================================*/
			$('button.show-route').on('click', function () {
				var sprache = k3vars.S_Sprache;
				if ($(this).attr('data-route-tu') == "2") {
					sprache = "en_us";
				}
				if ($(this).attr('data-route-tu') == "6") {
					sprache = "en_gb";
				}
				window.open(confEnv['pdb_api_url'] + '/lightfinder/index/' + sprache + '?frage=&sprachkuerzel=' + sprache + '&qanda=' + $(this).attr('data-route-token'));
			});
		});


	//Footable für Sammlungen und Artikellisten innerhalb von myERCO initialisieren
	$('[data-footable-collections]').footable()
		//Event wird gefeuert, nachdem Footable Tabelle sortiert hat
		.on('after.ft.sorting', function (e) {

			/* Nach Sortierung der Tabelle mit den Sammlungen, müssen die Detailansichten zu den Zeilen
			 * immer unter die dazugehörige Zeile eingefügt werden,
			 ============================================================*/

			//Row mit den Suchwegen immer an den Anfang der Tabelle
			var table_rows_basics = $(this).find('tbody tr.basics');

			//Row zum Anlegen einer neuen Sammlung immer ans Ende
			var table_rows_details = $(this).find('tbody tr.details');

			$(this).find('tbody').prepend($(this).find('.tr-searchroutes'));
			$(this).find('tbody').append($(this).find('.tr-new-collection'));

			//Für jede Row mit den Basis-Daten
			table_rows_basics.each(function () {

				//Collection-Id holen
				var current_id = $(this).attr('data-collection-id');

				//Row mit den Detail-Daten holen die zur aktuellen Row mit den Basisdaten gehoert holen
				var detail_row = table_rows_details.find('[data-nav-target="' + current_id + '"]').parent();

				//Row mit den Detail-Daten nach Row mit den Basis-Daten anfuegen
				$(detail_row).insertAfter($(this));
			});

			/* ============================================================*/
		})
		//Event wird gefeuert, wenn Footable initialisiert ist
		.on('ready.ft.table', function (e) {
	
			/* Bugfix Footable 3
			 * Footable 3 ignoriert td-Elemente mit colspan-Attributen
			 * Bsp: Wenn eine tr nur ein td-Element mit colspan='3' enthält, dann ignotiert
			 * das Footable das colspan und füllt generiert in de tr-Element drei zusätzliche td-Elemente
			 * Diese werden hier wieder entfernt
			============================================================*/
			$(e.currentTarget).find('[data-tr-details] > :not([data-td-details])').remove();
	
			toggle.start();
	
			/* Sammlung hinzufuegen
			 ============================================================*/
			var inputCollection = $('.new-collection > .input');
			var actionCollection = $('.new-collection > .action');
	
			var project = {
				id: actionCollection.closest('[data-project-id]').attr('data-project-id') || null,
				currency: actionCollection.closest('[data-project-currency]').attr('data-project-currency') || null,
			};
	
			actionCollection.off('click.myerco');
			actionCollection.on('click.myerco', function (event) {
				Collections.addCollection(event, inputCollection.val(), $(this).attr('href'), project, function (data) {
	
					//Sammlung der Tabelle hinzufuegen
					$('#collections-table > tbody > tr:last').before(data);
	
					//Inhalt vom Eingabefeld loeschen
					inputCollection.val('');
	
					//Schaltflaeche deaktivieren
					actionCollection.addClass('-disabled');
	
					//Tabelle mit Artikeln aktualisieren
					ViewTable.refreshView();
	
					DynamicFooter.refreshCollectionCheckboxesList();
	
	
				});
			});
	
			inputCollection.off('focusin.myerco');
			inputCollection.on('focusin.myerco', function (e) {
				inputCollection.on('keyup', function (e) {
	
					if ($(this).val().length > 0)
						actionCollection.removeClass('-disabled');
					else
						actionCollection.addClass('-disabled');
					//Wenn <enter> gedrueckt wird, dann click-event ausloesen.
					if (e.keyCode == 13) {
	
						actionCollection.trigger('click');
					}
				});
			});
	
			inputCollection.off('focusout.myerco');
			inputCollection.on('focusout.myerco', function (e) {
				inputCollection.off('keyup');
			});
	
			/* Auswahlboxen der Downloadtypen zu den Sammlungen aktivieren
			 ============================================================*/
				//Jede Sammlung durchgehen
				Collections.refreshDownloadSection();
	
	
			/* Download Section initialisieren, so dass Daten zu markierten
			 * Artikeln in der Artikelliste heruntergeladen werden können
			 ============================================================*/
			DownloadSection.init();
		});

	//Footable für Projekte innerhalb von myERCO initialisieren
	$('[data-footable-projects]').footable()
		//Event wird gefeuert, nachdem Footable Tabelle sortiert hat
		.on('after.ft.sorting', function (e) {
	
				/* Nach Sortierung der Tabelle mit den Sammlungen, müssen die Detailansichten zu den Zeilen
			 	* immer unter die dazugehörige Zeile eingefügt werden,
			 	============================================================*/
	
				//Row zum Anlegen einer neuen Sammlung immer ans Ende
				var table_rows_details = $(this).find('tbody tr.details');
	
				$(this).find('tbody').append($(this).find('.tr-project-management'));
	
				//Für jede Row mit den Basis-Daten
				table_rows_basics.each(function () {
	
					//Collection-Id holen
					var current_id = $(this).attr('data-external-project-id');
	
					//Row mit den Detail-Daten holen die zur aktuellen Row mit den Basisdaten gehoert holen
					var detail_row = table_rows_details.find('[data-nav-target="' + current_id + '"]').parent();
	
					//Row mit den Detail-Daten nach Row mit den Basis-Daten anfuegen
					$(detail_row).insertAfter($(this));
				});
	
				/* ============================================================*/
			})
		//Event wird gefeuert, wenn Footable initialisiert ist
		.on('ready.ft.table', function (e) {
		
				/* Bugfix Footable 3
			 	* Footable 3 ignoriert td-Elemente mit colspan-Attributen
			 	* Bsp: Wenn eine tr nur ein td-Element mit colspan='3' enthält, dann ignotiert
			 	* das Footable das colspan und füllt generiert in de tr-Element drei zusätzliche td-Elemente
			 	* Diese werden hier wieder entfernt
				============================================================*/
				$(e.currentTarget).find('[data-tr-details] > :not([data-td-details])').remove();
		
				toggle.start();
			});

	/*
	============================================================*/

	//Schaltflachen und Checkboxen zum Löschen von Artikeln der Artikelliste hinzufuegen
	$('[data-myerco-status="inside"] .artikel-ansicht-1 [data-collectable-ui-element]').each(function (index) {
		var self = $(this);
		var artikelnummer = self.parent().attr('data-artno');
		var articletype = self.parent().attr('data-articletype');
		var content = '<span class="check-box check-all pull-right">';

		if(artikelnummer) {
			content += '<input type="checkbox" data-article-nr="' + artikelnummer + '" data-articletype="' + articletype + '" name="check-articles' + '" value="check-' + artikelnummer + '" id="check-' + artikelnummer + '">';
			content += '<label for="check-' + artikelnummer + '" class="check-box-mark"></label>';
		}

		content += '</span>';
		$(this).html(content);
	});


	/* ss logging out */
	$('#myErcoLogout').on('click', function (event) {
		event.preventDefault();

		$.ajax({
			method: "POST",
			url: $(this).attr('href'),
			cache: false,
			data: {
				"session_id": $(this).attr('data-session_id')
			},
			success: function (data) {
				location.reload();
			}
		});

	});

	//Alle Checkboxen des Sammelkorbs auswaehlen
	$('.js-check-articles-basket').on('click', function () {
		if ($(this).prop('checked')) {
			$('.artikel-ansicht-1 input').prop('checked', true);
		} else {
			$('.artikel-ansicht-1 input').prop('checked', false);
		}

		//Blendet die Schaltflächen zum Herunterladen, Verteilen und Löschen von Artikeln ein bzw. aus
		handle_article_checkboxes();

		//Downloadtype-IDs der markierten Artikel holen
		Articlelist.getDownloadTypeIds($('[data-article-nr]:checked'), function(download_type_ids) {
			DownloadSection.refreshSection($('.js-download-section-articllist'), download_type_ids);
		});
	});

	/* Alle Checkboxen in der Artikelliste auswählen
	============================================================*/

	let articleTables = document.querySelectorAll('.artikel-ansicht-1');
	for (let i = 0; i < articleTables.length; i++) {
		articleTables[i].addEventListener('click', function(e){

			if (e.target && e.target.classList.contains('js-check-all-articles')) {
				let currentCheckbox = e.target;
				let checkArticles = currentCheckbox.closest('.artikel-ansicht-1').querySelectorAll('input');

				for (let j = 0; j < checkArticles.length; j++) {
					checkArticles[j].checked = currentCheckbox.checked;
				}

				if (!currentCheckbox.checked) {
					//Checkbox 'alle Artikel markieren' über alle Artikel
					document.querySelector('.js-check-articles-basket').checked = false;
				}

				// Blendet die Schaltflächen zum Herunterladen, Verteilen und Löschen von Artikeln ein bzw. aus
				handle_article_checkboxes();
			}
		});
	}



	/* Verhalten um alle Datentypen in der Dowonload-Section auszuwaehlen
	 * Wird über Event-Delegation gelöst, da die Tabelle mit den Sammlungen aktualisiert wird, nachdem Artikel verteilt wurden.
	 * Da der DOM dadurch neu aufgebaut wird, greifen die Events nicht mehr, wenn ohne Event-Delegation gearbeitet wird.
	 * Selektoren: #collections-table für Tabelle mit Sammlungen und [data-nav-target="download-collector"] für Artikelliste, .download-container für Collectionsansicht
	 */

	$('.js-download-section-articllist, #collections-table').on('click', '.check-alle-daten', function () {

		var donload_section = $(this).closest('.download-section');

		//Schaltflaeche der Download-Section
		var btn_download_article_data = donload_section.find('.download-article-data');

		//Alle Checkboxen in der Download-Section die nicht deaktiviert sind
		var checkBoxes = donload_section.find('li').not('.-disabled').find('input');
		if ($(this).prop('checked')) {
			checkBoxes.prop('checked', true);
			btn_download_article_data.removeClass('-disabled');
		} else {
			checkBoxes.prop('checked', false);
			btn_download_article_data.addClass('-disabled');
		}
	});


	//Einzelne Checkbox der Downloadtypen, außer die Checkboxen für die Oberkategorien
	$('.download-section .check-box > input:not(.-category)').on('click', DownloadSection.handleCheckboxClick);


	/* Download Section initialisieren, so dass Daten zu markierten
	 * Artikeln in der Artikelliste heruntergeladen werden können
	 ============================================================*/
	DownloadSection.init();

	//Löscht die in der Artikelliste markierten Artikel
	$('.js-myerco-dynamic-footer').on('click', '#delete-articles', function (event) {
		var self = $(this);
		event.preventDefault();

		//Artikelnummern der markierten Artikel holen
		var articlesIDs = Articlelist.getCheckedArticleIDs();

		//Checkboxen der Artikel die markiert sind
		var checkboxes = $('[data-article-nr]:checked');

		//Wurden Artikel markiert?
		if (checkboxes.length > 0) {
			var question = "";
			if (checkboxes.length === 1)
				question = globals.trans.myerco.frage_artikel_loeschen_einzahl;
			else
				question = globals.trans.myerco.frage_artikel_loeschen_mehrzahl;


			//Sicherheitsabfrage bevor Sammlung geloescht werden soll
			var result = confirm(question);
			if (result) {
				var originCollectionId = get_url_segment_collection_id();

				//Wenn originCollectionId nicht gesetzt ist, dann ist der Quellordner der Sammelkorb und die Artikel sollen aus dem Sammelkorb geloescht werden
				if (originCollectionId === 0) {

					Collector.deleteArticles(articlesIDs, callback);
				}

				//sonst sollen die Artikel aus der angezeigten Sammlung geloescht werden
				else {
					Articlelist.deleteArticles(articlesIDs, settings.articlelistsUrl + originCollectionId + '/batchdelete', callback);
				}
			}
		}

		function callback() {

			//Geloeschte Artikel in Artikelliste ausblenden
			ViewTable.deleteRow(checkboxes);

			//Auswahlboxen in Artikelliste unchecken
			checkboxes.prop('checked', false);

			//blendet Elemente zum Herunterladen und Verteilen von Artikeln aus
			handle_article_checkboxes();
		}
	});


	//Sammlung loeschen
	$('#collections-table').on('click', '.delete-collection', function (event) {
		var url_ressource = ($(this).attr('href'));
		event.preventDefault();

		Collections.deleteCollection(event.target, url_ressource, function () {

			//Tabellenzeile der Sammlung loeschen
			ViewTable.deleteRow(event.target);

			//Tabelle mit Artikeln aktualisieren
			ViewTable.refreshView();

			DynamicFooter.refreshCollectionCheckboxesList();
		});
	});

	//Sammlung umbenennen
	$('#collections-table').on('click', '.rename-collection', function (event) {

		event.preventDefault();
		var collection_rename_button = $(this);
		var collection_id            = $(this).closest('[data-collection]').attr('data-collection');
		var ahref_collectionname     = collection_rename_button.closest('tr').prev('tr').find('.ahref-collectionname');
		var collection_name          = ahref_collectionname.attr('title');

		ahref_collectionname.fadeOut(300, function () {

			//Box zum Umbenennen selektieren
			var collection_edit_box = collection_rename_button.closest('tr').prev('tr').find('.edit-collection-box');

			//Box zum Umbenennen einblenden
			collection_edit_box.fadeIn(300);

			//Schaltflaechen und Eingabefeld selektieren
			var input_collection = collection_edit_box.children('.input');
			var action_okay = collection_edit_box.children('.rename-collection-okay');
			var action_cancel = collection_edit_box.children('.rename-collection-cancel');


			//Value des Eingabefeldes auf aktuellen Namen der Sammlungen setzen
			input_collection.attr('value', collection_name);

			//Schaltflaechen zum Editieren der Sammlung ausblenden
			collection_rename_button.siblings().addClass('-disabled');
			collection_rename_button.addClass('-disabled');


			//Verhalten bei Aenderung des Inhaltes vom Eingabefeld
			input_collection.on('keyup', function () {

				if ($(this).val().length > 0) {
					action_okay.removeClass('-disabled');
				} else {
					action_okay.addClass('-disabled');
				}
			});

			//Umbenennen bestaetigen
			action_okay.on('click', function (e) {
				var url_ressource = ($(this).attr('href'));

				//Letzter Parameter ist Callback-Funktion die aufgerufen wird,
				//nach dem der AJAX-Request zum Umbenennen der Sammlung erfolgreich war.
				Collections.renameCollection(e, url_ressource, input_collection.val(), function (data) {

					//Box zum Aendern des Sammlungsnamen auslbenden und Schaltflaechen zum Editieren der Sammlungen einblenden
					Collections.hideRenameCollectionBox(collection_edit_box, collection_rename_button, ahref_collectionname);


					/* Anzeige Sammlungsnamen aktualisieren
					============================================================*/

					//Inhalt von Hyperlink holen
					var ahref_html = ahref_collectionname.html();

					//Posistion von geöffneter Klammer
					var k = ahref_html.lastIndexOf('(');


					//Substring von geöffneter Klammer bis Ende => (8)
					var number_of_items = ahref_html.substring(k);
					ahref_collectionname.html(input_collection.val() + ' ' + number_of_items);


					ahref_collectionname.attr('title', input_collection.val());
					/*
					============================================================*/

					//Anzeige 'zuletzt geaendert' von der Sammlung aktualisieren
					ahref_collectionname.parent().siblings('.last-updated').html(data.TS_Last_updated);

					input_collection.off('keyup');

					ViewTable.refreshView();

					DynamicFooter.refreshCollectionCheckboxesList();
				});
			});

			//Umbenennen abbrechen
			action_cancel.on('click', function (e) {
				e.preventDefault();

				input_collection.off('keyup');


				//Box zum Aendern des Sammlungsnamen auslbenden und Schaltflaechen zum Editieren der Sammlungen einblenden
				Collections.hideRenameCollectionBox(collection_edit_box, collection_rename_button, ahref_collectionname);

			});


		});

	});

	// Sammlung mit Projekt verknüpfen
	$('#collections-table, #collection-view').on('click', '.add-collection-to-project', function (event) {
		event.preventDefault();

		var url_ressource = $(this).attr('href');

		var projects_selection_modal = $('#projects-selection-modal');
		var add_btn = projects_selection_modal.find('.js-modal-add-to-project');
		var projects_list = projects_selection_modal.find('.projects-list');
		var project_inputs = projects_selection_modal.find('input[name="selected_project"]');

		var myerco_list = projects_selection_modal.find('.projects-list-myerco');
		var external_list = projects_selection_modal.find('.projects-list-external');

		project_inputs.on('click', function() {
			add_btn.removeClass('-disabled');
		});

		projects_selection_modal.modal('show');

		add_btn.on('click.myerco', function(add_btn_event) {
			add_btn_event.preventDefault();

			var selected_project_el = project_inputs.filter(':checked');

			if ($(this).hasClass('-disabled') || !selected_project_el) {
				return;
			}

			add_btn.off('click.myerco');

			var project_external_id = selected_project_el.val();

			Collections.addCollectionToProject(
				url_ressource,
				project_external_id,
				function(data) {
					projects_list.scrollTop(0);
					projects_selection_modal.modal('hide');
					selected_project_el.prop('checked', false);

					/* if an external project is selected, we have to move it to the myerco block
						because of the newly created connection */
					var selected_project_item = external_list.find(
						'[data-project-external-id="' + project_external_id + '"]',
					);

					if (selected_project_item.length === 1) {
						selected_project_item.appendTo(myerco_list);
					}

					if ($(event.delegateTarget).is('#collection-view')) {
						window.location.reload();
					} else {
						//Tabellenzeile der Sammlung loeschen
						ViewTable.deleteRow(event.target);

						Projects.upsertProjectInTable(project_external_id, data);

						//Tabelle mit Artikeln aktualisieren
						ViewTable.refreshView();

						DynamicFooter.refreshCollectionCheckboxesList();
					}
				},
			);
		});
	});


	// Projektsammlung kopieren
	$('#collections-table, #collection-view').on('click', '.copy-project-collection', function (event) {
		event.preventDefault();

		var self = $(this);
		var url_resource = self.attr('href');

		var modal = $('#project-collection-copy-modal');
		var copy_inside_btn = modal.find('.js-modal-copy-inside');
		var copy_outside_btn = modal.find('.js-modal-copy-outside');

		var all_btns = copy_inside_btn.add(copy_outside_btn);

		var project = {
			id: self.closest('[data-project-id]').attr('data-project-id') || null,
			currency: self.closest('[data-project-currency]').attr('data-project-currency') || null,
		};

		modal.modal('show');

		function click_listener_generator(direction, callback) {
			return function(click_event) {
				click_event.preventDefault();

				if ($(this).hasClass('-disabled')) {
					return;
				}

				$(this).off('click.myerco');

				all_btns.addClass('-disabled');

				Collections.copyCollection(url_resource, direction, project, function(err, data) {
					if (!err) {
						modal.modal('hide');
					}

					all_btns.removeClass('-disabled');

					if (typeof(callback) == 'function') {
						callback(err, data);
					}
				});
			};
		};


		copy_inside_btn.on('click.myerco', click_listener_generator('inside', function(err, data) {
			if ($('#collections-table').length === 0) {
				return;
			}

			//Sammlung der Tabelle hinzufuegen
			$('#collections-table > tbody > tr:last').before(data);

			ViewTable.refreshView();

			DynamicFooter.refreshCollectionCheckboxesList();
		}));

		copy_outside_btn.on('click.myerco', click_listener_generator('outside'));
	});

	// Projekt aus myERCO entfernen
	$('#projects-table, #project-view').on('click', '.remove-project-from-myerco', function(event) {
		event.preventDefault();

		var result = confirm(globals.trans.myerco.frage_projekt_loeschen);
		if (!result) {
			return;
		}

		var self = $(this);
		var project_external_id = self.closest('[data-external-project-id]').attr('data-external-project-id');
		var redirect_path = self.attr('data-redirect');
		var url_resource = self.attr('href');

		Projects.removeProject(url_resource, function(err) {
			if(!err) {
				if (redirect_path) {
					window.location.pathname = redirect_path;
				} else {
					Projects.removeProjectFromTable(project_external_id);
				}
			}
		});
	});


	/*Schaltflaeche zur Bereitstellung einer Sammlung
	  .edit-collection-box muss mit in Event-Listener uebernoemmen werden,
	  da auch in der der Collections-View eine Sammlung bereitgestellt werden kann und es dort
	  keine Tabelle mit #collections-table existiert.
	  */
	$('#collections-table, #edit-collection').on('click', '.share-collection', function (e) {
		e.preventDefault();
		var self = $(this);
		var collection_id = $(this).closest('.edit-collection-box').attr('data-collection');
		Collections.shareCollection(e, $(this).attr('href'), collection_id, function (data) {

			//View aktualisieren
			$(self).parent().parent().parent().siblings('.last-shared').html(data.TS_Last_shared);

		});
	});

	$('#collections-table, #edit-collection').on('click', '.remove-collection-from-project', function(e) {
		e.preventDefault();

		var self = $(this);
		var url_resource = self.attr('href');

		var result = confirm(globals.trans.myerco.frage_sammlung_von_projekt_loesen);
		if (!result) {
			return;
		}

		Collections.removeCollectionFromProject(url_resource, function(err) {
			if (!err) {
				if ($(e.delegateTarget).is('#edit-collection')) {
					window.location.reload();
				} else {
					//Tabellenzeile der Sammlung loeschen
					ViewTable.deleteRow(e.target);

					//Tabelle mit Artikeln aktualisieren
					ViewTable.refreshView();

					DynamicFooter.refreshCollectionCheckboxesList();
				}
			}
		});
	});

	/* Eingabe von neuem Anzahlswert für Artikel einer mit einem Projekt verknüpften Sammlung */
	$('#articles').on('focus', '.article-quantity', function(e) {
		var self = $(this);
		var current_value = self.val();
		var parsed_current_value = parseInt(current_value);
		var url_resource = self.attr('data-update-url');

		function updateQuantity(el) {
			var new_value = el.val();
			var parsed_new_value = parseInt(new_value);

			/* Gate value to be at least bigger or equal as 1 */
			if (Number.isNaN(parsed_new_value) || parsed_new_value < 1 || parsed_current_value === parsed_new_value) {
				el.val(parsed_current_value);
			} else {
				Collections.updateArticleQuantity(url_resource, parsed_new_value, function(err) {
					if (!err) {
						el.val(parsed_new_value);
					} else {
						el.val(parsed_current_value);
					}
				});
			}
		}

		self.one('blur', function(e) {
			updateQuantity(self);
		});

		self.on('keyup', function(e) {
			if (e.keyCode == 13) {
				self.off('keyup');
				self.blur();
			}
		});
	});

	/* Ein Artikel als individuell setzen / zurücksetzen */
	$('#articles').on('click', '.article-individual', function(e) {
		var self = $(this);
		var url_resource = self.attr('data-update-url');
		var is_checked = self.is(':checked');

		self.prop('disabled', true);

		Collections.updateArticleIndividual(url_resource, is_checked, function(err) {
			if (!err) {
				self.prop('checked', is_checked);
			} else {
				self.prop('checked', !is_checked);
			}

			self.prop('disabled', false);
		});
	});

	/* Notizen für Sammlungen und Artikel */
	$('#articles, #collection-notes').on('click', '.js-add-note', function(e) {
		e.preventDefault();

		var self = $(this);
		var box = self.closest('.js-add-notes');
		var notes_editor = box.find('.js-notes-editor');
		var read_only_notes = box.find('.js-read-only-notes');

		box.addClass('in-edit-mode');
		self.addClass('-disabled');
		read_only_notes.hide();
		notes_editor.show();

		var current_value = notes_editor.val();
		var url_resource = box.attr('data-update-url');

		notes_editor.focus();

		/* Etwas tricksen, um den Textcursor an das Ende des Textes zu kriegen */
		notes_editor.val('');
		notes_editor.val(current_value);

		notes_editor.on('blur', function() {
			var new_value = notes_editor.val();

			if (new_value !== current_value) {
				General.setNote(url_resource, new_value, function(err) {
					var final_value = current_value;
					
					if (!err) {
						final_value = new_value;
					}

					read_only_notes.html(final_value.replace(/\n/g, '<br>'));
					notes_editor.val(final_value);

					if (final_value.length > 0) {
						box.addClass('has-note');
					} else {
						box.removeClass('has-note');
					}

					self.removeClass('-disabled');
					box.removeClass('in-edit-mode');
				});
			} else {
				self.removeClass('-disabled');
				box.removeClass('in-edit-mode');
			}

			read_only_notes.show();
			notes_editor.hide();

			notes_editor.off('blur');
			notes_editor.off('keyup');
		});

		if (notes_editor.is(function() { return this instanceof HTMLInputElement; })) {
			notes_editor.on('keyup', (e) => {
				if(e.keyCode === 13) {
					notes_editor.blur();
				}
			});
		}
	});


	// Event for the article destribution and download collector
	$('.js-myerco-dynamic-footer').on('click', '#distribute-articles', handle_distribute_articles_click);
	$('.js-myerco-dynamic-footer').on('click', '#download-collector', handle_download_collector_click);


	// Handling the download collector
	function handle_download_collector_click(e) {

		//Checkboxen zum Herunterladen von Artikeldaten werden agezeigt
		if ($(this).parent().hasClass('active')) {

			//            $('html, body').animate({
			//                scrollTop: $('#download-collector').offset().top
			//            }, 500);
		}
	}


	function handle_distribute_articles_click(e) {
		e.preventDefault();

		if ($(e.target).parent().hasClass('active')) {

			var current_collection = get_url_segment_collection_id();

			//Befinden wir uns in einer Sammlung oder Unterordner
			if (current_collection) {

				$('#checkboxes-collections input[data-collection-id="' + current_collection + '"]').prop('checked', true);
			}

			//Checkboxen zum Markieren einblenden
			$('[data-collectable-ui-element] span').stop().delay(300).fadeIn(300);
		} else {


			//Checkbox, die alle Artikel markiert ausblenden
			//$('#checkbox-item-all').stop().fadeOut(300);

			//Schaltflaechen zum loeschen einblenden
			$('.delete-article a').stop().delay(300).fadeIn(300);

			//Checkboxen zum Markieren ausblenden
			//$('.delete-article span').stop().fadeOut(300);

			//Schaltflaeche 'Artikel loeschen' aktiveren
			$('.js-myerco-dynamic-footer').on('click', '#delete-articles', handle_download_collector_click);
			$('#delete-articles').removeClass('-disabled');

			//Schaltflaeche 'Sammlung herunterladen' aktiveren
			$('.js-myerco-dynamic-footer').on('click', '#download-collector', handle_download_collector_click);
			$('#download-collector').removeClass('-disabled');
		}
	}


	/**Verhalten fuer Schaltflaeche 'Artikel verteilen'
	 * Eventlistener muss via Eventdelegation hinzugefuegt werden.
	 * Da das Element z.B. beim Hinzufuegen neuer Sammlungen neu hinzugefuegt wird
	 * aber die Eventlistener dann nicht mehr greifen
	 * siehe (http://wiki.selfhtml.org/wiki/SELFHTML:Forum/FAQ/Eventhandler_für_eingefügte_Elemente)
	 * Artikel verteilen
	 */
	$('.js-myerco-dynamic-footer').on('click', '#distribute-articles-okay', function (e) {

		e.preventDefault();

		//CollectionIds der Collections die markiert sind
		var collection_ids = [];

		//IDs der ausgewaehlten Sammlungen selektieren
		$('[data-collection-id]:checked').each(function (index, value) {
			//Collectionid holen und dem Array hinzufuegen
			collection_ids.push(value.getAttribute('data-collection-id'));
		});

		//Checkboxen der Artikel die markiert sind
		var checkboxes = $('[data-article-nr]:checked');

		//Artikelnummern der markierten ARtikel holen
		var articles = Articlelist.getCheckedArticles();

		//Artikel koennen nur dann verteilt werden, wenn Artikel und Sammlungen markiert wurden
		if (articles.length > 0 && collection_ids.length > 0) {

			Articlelist.distributeArticles(collection_ids, articles, get_url_segment_collection_id(), function () {

				//Feedback-Icon wird eingeblendet, dass Verteilen erfolgreich war.
				var distribution_successful = $('.distribution-successful');
				distribution_successful.fadeIn();
				setTimeout(function () {
					distribution_successful.fadeOut();
				}, 2000);

				/*
				 Falls Aritkel nach dem Verteilen in der aktuellen Sammlung geloescht werden.
				 Sollen die Artikel nicht gleichzeit aus der Tabelle gelöscht werden und die Animation zum Ausblenden der Auswahlboxen
				 und Einblenden der Schalftlaechen zum Loeschen gestartet werden.
				 Variable bestimmt das Intervall zwischen den beiden Animationen.

				*/
				var delay = 0;

				//Tabelle mit Sammlungen neu laden, da sich Daten für die letzte Aenderung an der Sammlung geandert haben
				Collections.refreshView(function () {
					/*Wenn die Artikel in die Collection verteilt werden sollen die gerade angezeigt wird,
					 dann sollen die Artikel nicht aus der Artikelliste entfernt werden
					 collection_ids = Ids der Markierten Collections und Unterordner
					 url_segment_collection_id = ID der Collection die gerade angezeigt wird. Wird aus der URL des Browsers gezogen
					*/
					if (collection_ids.indexOf(get_url_segment_collection_id()) < 0) {
						ViewTable.deleteRow(checkboxes);

					}
					//Schaltflaeche zum Verteilen deaktiveren
					$('#distribute-articles-okay').addClass('-disabled');

					//Downloadsection zu jeder Sammlung aktualisieren
					Collections.refreshDownloadSection();
				});
			});
		}
	});

	//Verhalten fuer Checkboxen zur Auswahl von Sammlungen zum Verteilen von Artikeln
	$('#js-checkbox-area').parent().on('click', 'input', function () {
		handleDistributeArticlesOkay();
	});


	//Verhalten fuer Checkboxen zur Auswahl von Artikeln zum Verteilen von Artikeln
	$('.artikel-ansicht-1').on('click', 'input[data-article-nr]', function () {

		if ($(this).prop('checked') === false) {

			//Checkbox 'alle Artikel markieren' innerhalb einer Tabelle' unchecken
			$(this).closest('.artikel-ansicht-1').find('input.js-check-all-articles').prop('checked', false);

			//Checkbox 'alle Artikel markieren' über alle Artikel
			$('.js-check-articles-basket').prop('checked', false);
		}
		handleDistributeArticlesOkay();
		handle_article_checkboxes();
	});


	//Blendet die Download-Section und Schaltflächen, zum Bearbeiten von Artikeln, ein oder aus
	function handle_article_checkboxes() {

		//Wurden Sammlungen bzw. Ordner und Artikel ausgewaehlt
		if (($('.artikel-ansicht-1 [data-article-nr]:checked').length) > 0) {
			DynamicFooter.show();
		} else {
			DynamicFooter.hide();
		}

		//Downloadtype-IDs der markierten Artikel holen
		Articlelist.getDownloadTypeIds($('[data-article-nr]:checked'), function(download_type_ids) {
			DownloadSection.refreshSection($('.js-download-section-articllist'), download_type_ids);
		});
	}

	function handleDistributeArticlesOkay() {
		var btn_distribute_articles_okay = $('#distribute-articles-okay');

		//Wurden Sammlungen bzw. Ordner und Artikel ausgewaehlt
		if (($('#checkboxes-collections').find('input:checked').length) > 0 && $('.artikel-ansicht-1 [data-article-nr]:checked').length > 0) {

			//Schaltflaeche zum Verteilen aktiveren
			btn_distribute_articles_okay.removeClass('-disabled');
		} else {
			//Schaltflaeche zum Verteilen deaktiveren
			btn_distribute_articles_okay.addClass('-disabled');
		}
	}
});


/*==========================================================
  =Offer requests                                          =
  ==========================================================*/

// Getting the request id
// Getting the request id
function get_url_segment_request_id() {
	var pathArray = window.location.pathname.split('/');

	for(var i = 0; i < pathArray.length; i++) {
		if(pathArray[i] === 'offers' && pathArray[i + 1]) {
			return pathArray[i + 1];
		}
	}
}


// Offers object
Offers = {

	// Starting a new offer request
	addOffer: function(e, articlesIDs, callback) {

		var json = {
			'items': articlesIDs
		}

		$.ajax({
			url: settings.offerRequest,
			type: 'POST',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			data: {
				data: JSON.stringify(json)
			},
			//dataType: 'json',
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Continuing an offer request
	continue: function(e, articlesIDs, callback) {

		var data = {
			'articles': articlesIDs
		}

		$.ajax({
			url: settings.offerRequest + '/' + $('#continue-offer').data('request') + '/addArticles',
			type: 'PUT',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			data: {
				data: JSON.stringify(data)
			},
			dataType: 'json',
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});

	},


	// Changing the product count
	changeArticleCount: function(callback) {
		var elms = document.getElementsByClassName('amount-articles'),
				data = {
					'articles': []
				};

		for(var i = 0; i < elms.length; i++) {
			var article = {
				'article_id': elms[i].getAttribute('data-request-article-nr'),
				'count': elms[i].value
			};

			data.articles.push(article);
		}

		$.ajax({
			url: settings.offerRequest + get_url_segment_request_id() + '/articleCount',
			type: 'PUT',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			data: {
				data: JSON.stringify(data)
			},
			dataType: 'json',
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Getting the counts for all articles
	getAllArticlesCount: function() {
		$.ajax({
			url: settings.articleCount + get_url_segment_request_id() + '/' + k3vars.S_Sprache,
			type: 'GET',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function(res) {
				if($('.slim-articlelist').data('view') === 'full') { // Load the full view including count and delete operations
					$('[data-buyerco-status="inside"] .artikel-ansicht-1 [data-collectable-ui-element]').each(function(index) {
						var self = $(this);
						var artikelnummer = self.parent().attr('data-artno');
						var content = '<span class="amount">';
						content += '<input type="number" class="amount-articles" data-request-article-nr="' + artikelnummer + '" min="0" step="1" name="amount-articles' + '" placeholder="0" value="' + res.articles[index].I_Count + '" id="amount-' + artikelnummer + '">';
						content += '<a href="#" class="delete-offer-item" data-request-article-nr="' + artikelnummer + '" class="pull-right"><span pull-right">' + res.text + '</span></a>';
						content += '</span>';
						$(this).html(content);
					});
				} else if($('.slim-articlelist').data('view') === 'static') {
					$('[data-buyerco-status="inside"] .artikel-ansicht-1 [data-collectable-ui-element]').each(function(index) { // Only show the article count
						var self = $(this);
						var artikelnummer = self.parent().attr('data-artno');
						var content = '<span class="amount">' + res.articles[index].I_Count + '</span>';
						$(this).html(content);
					});
				}
				var count_inputs = document.getElementsByClassName('amount-articles');
				for(var i = 0; i < count_inputs.length; i++) {
					count_inputs[i].addEventListener('input', function() {
						this.value = this.value.replace(/[^0-9]|^0+(?!$)/g,'');
						Offers.changeArticleCount(() => {});
					});
				}
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Checking if all articles counts are set
	allArticlesCountsSet: function(cb) {
		var elms = document.getElementsByClassName('amount-articles'),
				allSet = true;

		if(elms.length > 0) {
			for(var i = 0; i < elms.length; i++) {
				if(!elms[i].value || elms[i].value <= 0) {
					allSet = false;
					elms[i].classList.add('empty');
				}
			}
		} else {
			allSet = true;
		}

		if(allSet) { // All items counts are set
			cb(true);
		} else { // Not all articlecounts are set
			// Showing the info container
			$('.alert-count').removeClass('hidden');
			// Switching the view back to the articlelist if neccesary
			if($('.slim-articlelist').data('view') === 'full') {
				$('[data-ft-btn="articles"], [data-ft-itm="articles"]').addClass('_active');
				$('[data-ft-btn="contact"], [data-ft-itm="contact"]').removeClass('_active');
			} else {
				$('[data-ft-btn="articles"], [data-ft-itm="articles"]').addClass('_active');
				$('[data-ft-btn="contact"], [data-ft-itm="contact"]').removeClass('_active');
			}
			// Sending the callback
			cb(false);
		}
	},


	// Deleting an article from the offer request
	deleteArticle: function(e, callback) {
		var article_id = e.target.parentNode.getAttribute('data-request-article-nr');
		$.ajax({
			url: settings.offerRequest + get_url_segment_request_id() + '/' + article_id,
			type: 'DELETE',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Getting all checked collections and their type from the dropdown
	getCheckedCollections: function () {

		var collections = [];

		//Artikelnummer der ausgewaehlten Artikel selektieren
		$('[data-collection-id]:checked').each(function(index, value) {

			// Adding the collection id to the array
			var collection = {
				'type': value.getAttribute('data-collection-type'),
				'collection_id': value.getAttribute('data-collection-id'),
				'collection_time': value.getAttribute('data-collection-time')
			}

			collections.push(collection);

		});

		return collections;
	},


	// Adding products to an offer request
	addOfferArticles: function(data, callback) {
		$.ajax({
			url: settings.offerRequest + get_url_segment_request_id() + '/addCollection',
			type: 'PUT',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			data: {
				data: JSON.stringify(data)
			},
			dataType: 'json',
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Canceling the entire request
	deleteRequest: function(callback) {
		$.ajax({
			url: settings.offerRequest + get_url_segment_request_id(),
			type: 'DELETE',
			cache: false,
			xhrFields: {
				withCredentials: true
			},
			success: function(res) {
				callback(res);
			},
			error: function(err) {
				console.error(err);
			}
		});
	},


	// Submitting the offer reuqust with the userdata
	submitRequest: function() {
		// Getting the formdata
		var formData = {};
		$.each($('#submit-userdata').serializeArray(), function(_, kv) {
	  		formData[kv.name] = kv.value;
		});
		formData['message'] = $('#message').val();

		// Getting the url from the form submit button
		var reqUrl = $('#submit-request').attr('href');

		// Checking if all articles counts are set
		Offers.allArticlesCountsSet(function(allSet) {
			if(allSet) { // All articles have a set amount
				var formEl = document.getElementById('submit-userdata');

				// Adding the spinner overlay
				formSpinner.show(formEl);

				$.ajax({
					url: reqUrl + '/submit/' + k3vars.S_Sprache,
					type: 'POST',
					cache: false,
					data: {
						data: JSON.stringify(formData)
					},
					dataType: 'json',
					success: function(res) {
						// Removing the spinner
						formSpinner.showSuccess(res.data, formEl);
						// Hiding the continue button in the sidebar
						document.querySelector('.get-offer-dynamic').parentNode.removeChild(document.querySelector('.get-offer-dynamic'));
					},
					error: function(err) {
						console.error(err);
					}
				});
			}
		});
	}
}

var Projects = {
	upsertProjectInTable: function(project_external_id, data) {
		var projectsTableBodyEl = $('#projects-table .body');
		var existingProjectRowEls = projectsTableBodyEl.find(
			'[data-external-project-id="' + project_external_id + '"]',
		);

		var existingProjectBasicsRowEl = existingProjectRowEls.filter('.basics');

		if (existingProjectBasicsRowEl.length === 1) {
			existingProjectBasicsRowEl.replaceWith(data);
			existingProjectRowEls.remove();
		} else {
			var detailsRowEl = projectsTableBodyEl.find('.tr-project-management');
			detailsRowEl.before(data);
		}
	},

	removeProjectFromTable: function(project_external_id) {
		var projectsTableBodyEl = $('#projects-table .body');
		var existingProjectRowEl = projectsTableBodyEl.find(
			'[data-external-project-id="' + project_external_id + '"]',
		);

		existingProjectRowEl.remove();
	},

	removeProject: function(url_resource, callback) {
		$.ajax({
			url: url_resource,
			type: 'DELETE',
			cache: false,
			success: function() {
				if (callback !== 'undefined') {
					callback(null);
				}
			},
			error: function(err) {
				console.error(err);

				if (callback !== 'undefined') {
					callback(err);
				}
			}
		});
	},
}


General = {
	/* Used for articles and collection notes */
	setNote: function(url_resource, notes, callback) {
		var data = {
			notes,
		};

		$.ajax({
			url: url_resource,
			type: 'PUT',
			cache: false,
			data: {
				data: JSON.stringify(data),
			},
			dataType: 'json',
			success: function() {
				if (callback !== 'undefined') {
					callback(null);
				}
			},
			error: function(err) {
				console.error(err);

				if (callback !== 'undefined') {
					callback(err);
				}
			},
		});
	},
}


// Event handler for offer button
$('.js-myerco-dynamic-footer').on('click', '#get-offer', handleOfferRequest);

// Event handler for continuing an offer request
$('.js-myerco-dynamic-footer').on('click', '#continue-offer', continueOfferRequest);


// If the dynamic footer exists
if($('.get-offer-dynamic').length) {

	// Checking if the user has open offer requests
	$.ajax({
		url: settings.openRequests + k3vars.S_Sprache,
		type: 'GET',
		cache: false,
		xhrFields: {
			withCredentials: true
		},

		success: function(res) {

			if(res.offer_id) { // User has open offer requests

				// Changing the link to direct to the open offer request
				$('.get-offer-dynamic').html(res.text);
				$('.get-offer-dynamic').attr('href', settings.offerRequest + res.offer_id + '/' + k3vars.S_Sprache);

			} else { // User has no open offer requests, adding the event handler to start a new one

				$('.get-offer-dynamic').on('click', handleOfferRequest);

			}
		},
		error: function(err) {
			console.error(err);
		}
	});

}


// Handling an offer request
function handleOfferRequest(e) {
	e.preventDefault();

	// Getting all checked articles and posting them to the server
	var articlesIDs = Articlelist.getCheckedArticleIDs();

	Offers.addOffer(e, articlesIDs, function(res) {

		// Grabbing the language for the redirect
		var href = $('#get-offer').attr('href') || $('.get-offer-dynamic').attr('href'),
				splitUrl = href.split('/'),
				lng = splitUrl.pop(),
				shortUrl = '/' + splitUrl[splitUrl.length - 2] + '/' + splitUrl[splitUrl.length - 1];

		window.location.href = confEnv['pdb_api_url'] + shortUrl + '/' + res.S_UUID+ '/' + lng;

	});
}


// Continuing an offer request
function continueOfferRequest(e) {
	e.preventDefault();

	// Getting all checked articles and posting them to the server
	var articlesIDs = Articlelist.getCheckedArticleIDs();

	if(articlesIDs.length <= 0) {
		window.location.href = e.target.getAttribute('href');
	} else {
		Offers.continue(e, articlesIDs, function(res) {
			if(res) window.location.href = e.target.getAttribute('href');
		});
	}
}


// Adding the count and delete function elements, getting the article count via ajax
if($('button[data-switch-btn]').length) {
	document.addEventListener('articleTableReady', () => {
    Offers.getAllArticlesCount();
  });

	// Checking if a query exists on pageload
	var segment = getQueryParameterByName('segment', window.location.href);

	if(segment) {
		switchUserView(segment);
	}
}

// Button for switching to the contact view
$('button[data-switch-btn], .switch-segment').on('click', function(e) {
	e.preventDefault();

	switchUserView($(this).data('switch-btn'));
});

// Listenning for the back button to go back to the contact view
window.addEventListener('popstate', function(e) {
	var segment = getQueryParameterByName('segment', window.location.href);

	if(segment === 'contact') {
		switchUserView('articles');
	}
});

// Switching the view
function switchUserView(segment) {
	// Checking if all articles counts are set
	Offers.allArticlesCountsSet(function (allSet) {
		if (allSet) { // All articles have a set amount
			if ($('.slim-articlelist[data-view="full"]').length) {
				Offers.changeArticleCount(function (res) {});
			}

			$('button[data-switch-btn]').removeClass('_active');
			$('button[data-switch-btn="' + segment + '"]').addClass('_active');

			$('div[data-ft-itm]').removeClass('_active');
			$('div[data-ft-itm="' + segment + '"]').addClass('_active');

			// Changing the query to reflect the view change
			// Getting the url without the querystring
			var baseUrl = window.location.href.split('?')[0];
			// Removing the trailing slash
			baseUrl = baseUrl.replace(/\/$/, "");

			var query = '?segment=' + segment;

			// Replacing the url with the query
			history.pushState({}, '', baseUrl + query)
		}
	});
}


// Event listener for delete elements
$(document).on('click', '.delete-offer-item', function(e) {
	e.preventDefault();
	Offers.deleteArticle(e, function(data) {
		// If delete was successfull
		$('tr[data-collectable-id="' + data + '"]').remove();
	});
});


// Event listener for the add items dropdown
$('.toggle-offer-items').on('click', function(e) {
	e.preventDefault();
	Offers.changeArticleCount(function(res) {});
	document.getElementById('add-offer-items').classList.toggle('active');
	$('.check-box > input[type="checkbox"]').prop('checked', false);
});


// Event listener for list headings
var listHeadings = document.getElementsByClassName('list-heading');

for(var i = 0; i < listHeadings.length; i++) {
	listHeadings[i].addEventListener('click', function(e) {
		e.preventDefault();
		e.target.closest('ul').classList.toggle('collapsed');
	});
}

// Event handler for adding selected lists to the offer request
$('#add-selected-articles').on('click', function(e) {
	e.preventDefault();

	// Getting all checked collections
	var collections = Offers.getCheckedCollections();

	// Adding the articles to the db, response is the new articlelist
	Offers.addOfferArticles(collections, function(data) {

		// Removing the warning text if it exists
		$('.alert-count').addClass('hidden');

		// Closing the dropdown and unchecking all checkboxes
		document.getElementById('add-offer-items').classList.toggle('active');
		$('.check-box > input[type="checkbox"]').prop('checked', false);

		if($('#articles').length) {
			$('#articles').replaceWith(data[0].articles);
		} else {
			$('#articlelist > .container').append(data[0].articles);
		}
		// Getting the new article count
		Offers.getAllArticlesCount();
	});
});


// Event handler for canceling the requets
$('.cancel-request').on('click', function(e) {
	e.preventDefault();
	Offers.deleteRequest(function(data) {
		window.location.href = settings.home + k3vars.S_Sprache;
	});
});


// Event handler for submitting the offer request
$('#submit-request').on('click', function(e) {
	e.preventDefault();
	// Triggering the hidden submit button to force the html5 form validation
	$('#submit-userdata').find('[type="submit"]').trigger('click');
});


// Listenning for the form submit triggered by jquery
$('#submit-userdata').on('submit', function(e) {
	e.preventDefault();
	// Actually submitting the request over ajax
	Offers.submitRequest();
});

// Checks if an url query exists and returns its value
function getQueryParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}


// Event listener for removing the warning color from empty count elements
$(document).on('focus', 'input.empty', function(e) {
	e.target.value = "";
	e.target.classList.remove('empty');
	// Removing the warning label if all count boxes are filled
	if(!$('input.empty').length) {
		$('.alert-count').addClass('hidden');
	}
});


// Login form SSO behavior
$('.js-form-login').each(function() {
	var $self = $(this);
	var $emailBlockEl = $self.find('.js-form-login-email-block');
	var $passwordBlockEl = $self.find('.js-form-login-password-block');

	var $emailInputEl = $emailBlockEl.find('[type="email"]');
	var $passwordInputEl = $passwordBlockEl.find('[type="password"]');
	var $forgotPasswordEls = $self.find('.js-forgot-password');

	function updatePasswordFieldState($emailInputEl) {
		var value = $emailInputEl.val();

		if (ssoEmailPattern.exec(value)) {
			$passwordInputEl.prop('required', false);
			$passwordBlockEl.addClass('invisible');
			$forgotPasswordEls.addClass('invisible');
		} else {
			$passwordInputEl.prop('required', true);
			$passwordBlockEl.removeClass('invisible');
			$forgotPasswordEls.removeClass('invisible');
		}
	}

	$emailInputEl.on('blur input', function() {
		updatePasswordFieldState($emailInputEl);
	});


	$(document).ready(function() {
		updatePasswordFieldState($emailInputEl);
	});
});

/*==========================================================
ajax auth                              
==========================================================*/

// forgot password
const sendForgotPasswordEmail = async (event) => {
  event.preventDefault();

  const passwordForm = document.querySelector('#password-form');
  formSpinner.show(passwordForm);

  const email = document.querySelector('#email').value;

  try {
    const res = await fetch(`${settings.forgotPasswordJsonUrl}/${k3vars.S_Sprache}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await res.json();

		if (!data.success) {
			throw new Error(data.message);
		}

    formSpinner.showSuccess(data.message, passwordForm);
  } catch (error) {
		formSpinner.showSuccess(error.message, passwordForm);
  }
};


// create user
const sendCreateUser = async (event) => {
  event.preventDefault();

  const createUserForm = document.querySelector('#create-user-form');
  formSpinner.show(createUserForm);

  const fullname = document.querySelector('#fullname').value;
  const job = document.querySelector('#job').value;
  const email = document.querySelector('#email').value;
  const password = document.querySelector('#password').value;
  const password_confirm = document.querySelector('#password_confirm').value;

  try {
    const res = await fetch(`${settings.createUserUrl}/${k3vars.S_Sprache}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fullname, job, email, password, password_confirm }),
    });

    const data = await res.json();

    if (!data.validation.success) {
      throw new Error(data.validation.message);
    }

    formSpinner.showSuccess(data.validation.message, createUserForm);
  } catch (error) {
    formSpinner.showError(error.message, createUserForm, ['edit', 'resetPassword']);
  }
};

// login
const sendLogin = async (event) => {
	event.preventDefault();

	const loginForm = document.querySelector('#login-form');

	formSpinner.show(loginForm);

	const email = document.querySelector('#email').value;
	const password = document.querySelector('#password').value;

	try {
		const res = await fetch(`${settings.formLoginUrl}/${k3vars.S_Sprache}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, password }),
		});
		
		const data = await res.json();

		if (!data.success) {
			throw new Error(data.message);
		}

		// Check if URL is myERCO or SSO
		if (data.url.startsWith('myerco')) {
			window.location.href =`${confEnv.pdb_api_url}/${data.url}`;
		} else {
			window.location.href = data.url;
		}
	} catch (error) {
		formSpinner.showError(error.message, loginForm, ['edit', 'resetPassword']);
	}
};
