/* Content Access Page - JP 2021-11-07
 ******************************************************/

class ContentAccessPage {
  constructor() {
    this.currentContentAccessPageNode = null;
    this.overlayCheckboxes = [];
    this.overlayNewsletterCheckboxes = [];
    this.listenersToRemoveOnDeinit = [];
    this.onCloseListeners = [];
    this.originLocationUrl = window.location.href;
    this.pageUrl = null;
    this.pageContextEl = null;
    this.downloadFile = null;
    this.contentAreaCodeAttributeName = 'data-content-access-area-code';
    this.checkboxSelector = '[data-js-check-single-checkbox="content-access"]';
    this.pageIdAttributeName = 'data-content-access-page-id';
    this.sectionTargetAttributeNameName = 'data-content-access-target-name';
    this.sectionTargetAttributeName = 'data-content-access-target';
    this.sectionTargetSelector = '[' + this.sectionTargetAttributeName + ']';
    this.newsletterCheckboxAttributeName = 'data-js-content-access-newsletter';
    this.newsletterCheckboxSelector = '[' + this.newsletterCheckboxAttributeName + ']';
    this.overlayDownloadLinkSelector = '[data-content-access-download-action]';
    this.loginFormActionSelector = '[data-content-access-login-action]';
    this.loginFormForgotPasswordSelector = '[data-forgot-password]';
    this.inputFieldAttributeName = 'data-input-field';
    this.inputFieldSelector = '[' + this.inputFieldAttributeName + ']';
    this.loginFormLoginInvalidMessageBoxSelector = '[data-message-box="login-must-be-valid"]';
    this.loginFormLoginFailedMessageBoxSelector = '[data-message-box="login-failed"]';
    this.linkViaMailFormActionSelector = '[data-content-access-link-via-mail-action]';
    this.linkViaMailInvalidMessageBoxSelector = '[data-message-box="link-via-mail-must-be-valid"]';
    this.closeActionSelector = '[data-content-access-close-action]';
    this.directdownloadLinkAttribute = 'data-js-directdownload';
    this.bodyContentSelector = '.body-content';
    this.downloadSelectionInputSelector = '[data-js-download-selection-input]';
    this.downloadSelectionFileNiceSizeAttributeName = 'data-download-selection-file-nice-size';
    this.downloadSelectionFileUrlAttributeName = 'data-download-selection-file-url';
    this.downloadSelectionFilenameAttributeName = 'data-download-selection-filename';
    this.downloadNowTextSelector = '[data-js-download-now-text]';
    this.downloadNowTextDataSizeAttributeName = 'data-size';
    this.copyLinkAnchorSelector = '[data-js-copy-link]';
    this.shareLinkAnchorSelector = '[data-js-share-link]';
    this.mainCloseButtonSelector = '[data-js-main-close]';
    this.popoverSelector = '[data-js-popover]';
    this.popoverVisibleClass = 'content-access-popover--visible';
    this.popoverHiddenClass = 'content-access-popover--hidden';
    this.ssoEmailPattern = /.+@(erco\.com)$/;

    /**
     * Listener called on a form submission
     *
     * @param {SubmitEvent} event    Submit event object
     * @param {Function}    valid    Callback function called on a valid form
     * @param {Function}    invalid  Callback function called on an invalid form
     * @return {void}
     */
    this.formSubmitListener = (event, valid, invalid) => {
      event.preventDefault();
      const formEl = event.currentTarget;

      const fieldEls = Array.prototype.slice.call(formEl.querySelectorAll(this.inputFieldSelector));

      const fieldValueParams = {};
      let someInvalid = false;

      fieldEls.forEach((fieldEl) => {
        fieldEl.classList.remove('-error');
        const attrVal = fieldEl.getAttribute(this.inputFieldAttributeName);

        fieldValueParams[fieldEl.name] = fieldEl.value;

        switch (attrVal) {
          case 'required':
            if (fieldEl.value === '') {
              someInvalid = true;
              fieldEl.classList.add('-error');
            }
            break;
        }
      });

      if (someInvalid) {
        invalid();
      } else {
        valid(fieldValueParams);
      }
    };
  }

  /**
   * Initializing a content access page in a given context
   *
   * @param {HTMLElement} pageContextEl      Element in which the initialization should happen
   * @param {string}      originLocationUrl  Origin location URL where the page was initialized, to be passed to the backend
   * @param {string}      pageUrl            URL of the standalone version of the current page
   *
   * @reutn {void}
   */
  init(pageContextEl, originLocationUrl, pageUrl) {
    this.pageContextEl = pageContextEl;

    if (originLocationUrl) {
      this.originLocationUrl = originLocationUrl;
    }

    if (pageUrl) {
      this.pageUrl = pageUrl;
    }

    this.activateAccessPage(pageContextEl);
  }

  /**
   * Function to be called to uninitialize the content access page instance
   *
   * @return {void}
   */
  deinit() {
    this.listenersToRemoveOnDeinit.forEach((cb) => cb());
    this.listenersToRemoveOnDeinit = [];
  }

  /**
   * Return the context element used as base for page initialization
   *
   * @return {HTMLElement}  Reference to the HTML element where the content access page was initialized
   */
  getPageContextElement() {
    return this.pageContextEl;
  }

  /**
   * Adding a listener
   *
   * @param {() => void} cb Callback function to be called on page closing
   *
   * @return {void}
   */
  onClose(cb) {
    this.onCloseListeners.push(cb);

    return () => {
      const foundListenerIdx = this.closeListeners.indexOf(cb);
      if (foundListenerIdx >= 0) {
        this.onCloseListeners.splice(foundListenerIdx, 1);
      }
    };
  }

  /**
   * Activate a content access page
   *
   * @param {HTMLElement} pageContextEl Starting element reference
   *
   * @return {void}
   */
  activateAccessPage(pageContextEl) {
    this.currentContentAccessPageNode = pageContextEl;
    this.downloadFile = this.getSelectedDownloadFile();

    const singleCheckboxElements = Array.prototype.slice.call(
      this.currentContentAccessPageNode.querySelectorAll(this.checkboxSelector)
    );

    /* Specific usage for single checkboxes */
    const checkboxSet = new SingleCheckboxSet(singleCheckboxElements, 'exclusive');

    checkboxSet.addChangeListener(this.handleCheckboxClick.bind(this));

    this.bindDownloadSelectionChangeListener();
    this.bindCopyLinkAnchorClickListener();
    this.bindShareLinkAnchorClickListener();
    this.bindLoginFormListener();
    this.bindLinkViaMailFormListener();
    this.bindCloseLinkClickListener();

    this.bindMainCloseListener();

    const escapeCloseUnlistener = this.bindEscapeCloseListener();
    this.listenersToRemoveOnDeinit.push(escapeCloseUnlistener);

    this.updateDownloadLinkHref();
  }

  /**
   * Handle a checkbox click in the content-access overlay
   *
   * @param  {HTMLElement} el     Element representing the checkbox
   *
   * @return {void}
   */
  handleCheckboxClick(el) {
    const newSectionTargetName = el.getAttribute(this.sectionTargetAttributeNameName);

    if (!newSectionTargetName) {
      throw new Error('Missing content-access-target-name attribute for checkbox!');
    }

    const sectionEls = Array.prototype.slice.call(
      this.currentContentAccessPageNode.querySelectorAll(this.sectionTargetSelector)
    );

    sectionEls.forEach((sectionEl) => {
      const sectionTargetName = sectionEl.getAttribute(this.sectionTargetAttributeName);

      sectionEl.style.display = sectionTargetName === newSectionTargetName ? 'block' : 'none';
    });
  }

  /**
   * Handling the newsletter subscription on form submit if the newsletter is desired
   *
   * @return {() => void} Unlistener function to be called to remove added submit listeners
   */
  bindNewsletterCheckboxListener() {
    this.overlayNewsletterCheckboxes = Array.prototype.slice.call(
      this.currentContentAccessPageNode.querySelectorAll(this.newsletterCheckboxSelector)
    );

    const listOfSubmitUnlisteners = this.overlayNewsletterCheckboxes.map((checkboxEl) => {
      const form = checkboxEl.closest('form');
      form.addEventListener('submit', this.newsletterFormSubmitListener);
      return () => {
        form.removeEventListener('submit', this.newsletterFormSubmitListener);
      };
    });

    return () => {
      listOfSubmitUnlisteners.forEach((unlisten) => unlisten());
    };
  }

  /**
   * Update the download button href to the dowload file
   *
   * @return void
   */
  updateDownloadLinkHref() {
    const downloadLinkEl = this.currentContentAccessPageNode.querySelector(
      this.overlayDownloadLinkSelector
    );
    if (this.originLocationUrl.includes('/video') || this.pageUrl?.includes('/video')) {
      const url = new URL(this.originLocationUrl.includes('/video/') ? this.originLocationUrl : this.pageUrl);
      const urlParts = new URL(this.originLocationUrl.includes('/video/') ? this.originLocationUrl : this.pageUrl).pathname.split('/');
      // remove first part, cause it is empty
      urlParts.shift();
      let lang = 'en'
      if (['de', 'en', 'nl', 'fr', 'it', 'es', 'en_us'].includes(urlParts[0])) {
        lang = urlParts[0];
        urlParts.shift();
      }
      downloadLinkEl.href = `${url.origin}/${lang}/contentaccess/show/video/${urlParts[3]}`;
    } else {

      const url = this.downloadFile.url;

      const queryParams = {
        file: url,
        originPageUrl: this.originLocationUrl,
      };

      const query = Object.entries(queryParams)
        .map(([name, val]) => `${name}=${encodeURIComponent(val)}`)
        .join('&');

      downloadLinkEl.href = url + '?' + query;
    }
  }

  /**
   * Determines and return the selected download file data
   *
   * @return {{ url: string, niceSize: string }} Download file info
   */
  getSelectedDownloadFile() {
    const downloadFileSelectInputEl = this.currentContentAccessPageNode.querySelector(
      this.downloadSelectionInputSelector
    );

    return this.extractDownloadFileInfosFromSelectElement(downloadFileSelectInputEl);
  }

  /**
   * Bind a listener to the download selection element on change
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindDownloadSelectionChangeListener() {
    const downloadFileSelectInputEls = this.currentContentAccessPageNode.querySelectorAll(
      this.downloadSelectionInputSelector
    );

    const changeListener = (event) => {
      /* Keep all file selectors in sync (existing in different content-access bodies) */
      downloadFileSelectInputEls.forEach((el) => {
        el.value = event.target.value;
      });

      this.downloadFile = this.extractDownloadFileInfosFromSelectElement(event.target);

      this.updateNiceSizeOutput();
      this.updateDownloadLinkHref();
    };

    downloadFileSelectInputEls.forEach((el) => el.addEventListener('change', changeListener));

    return () => {
      downloadFileSelectInputEls.forEach((el) => el.removeEventListener('change', changeListener));
    };
  }

  /**
   * Extract the download file infos from the currently selected option of a given select element
   *
   * @param  {HTMLElement} selectEl Element reference
   * @return {{ url: string, niceSize: string }} Download file info
   */
  extractDownloadFileInfosFromSelectElement(selectEl) {
    if (!selectEl) {
      return null;
    }

    const selectedOption = selectEl.querySelector('[value="' + selectEl.value + '"]');

    const url = selectedOption.getAttribute(this.downloadSelectionFileUrlAttributeName);
    const niceSize = selectedOption.getAttribute(this.downloadSelectionFileNiceSizeAttributeName);
    const filename = selectedOption.getAttribute(this.downloadSelectionFilenameAttributeName);

    return {
      url: url,
      niceSize: niceSize,
      filename: filename,
    };
  }

  /**
   * Show a new niceSize
   *
   * @param void
   */
  updateNiceSizeOutput() {
    const downloadNowTextEl = this.currentContentAccessPageNode.querySelector(
      this.downloadNowTextSelector
    );
    const niceSize = this.downloadFile.niceSize;

    downloadNowTextEl.setAttribute(this.downloadNowTextDataSizeAttributeName, niceSize);
  }

  /**
   * Bind a listener to the copy link anchor element to be triggered on a click
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindCopyLinkAnchorClickListener() {
    const copyLinkAnchorEl = this.currentContentAccessPageNode.querySelector(
      this.copyLinkAnchorSelector
    );
    if (!copyLinkAnchorEl) {
      return () => {};
    }

    const successVisibleInMs = 1300;

    let successTimeoutHandler = null;

    const clickListener = (e) => {
      const popoverEl = copyLinkAnchorEl.querySelector(this.popoverSelector);

      if (!popoverEl) {
        return;
      }

      window.clearTimeout(successTimeoutHandler);

      let pageUrl = this.pageUrl || this.originLocationUrl;

      if(this.originLocationUrl.includes('/mail?t=')){
        pageUrl = defaultVideoContentAccessUrl;
      }
      navigator.clipboard
        .writeText(pageUrl)
        .then(() => {
          popoverEl.classList.add(this.popoverVisibleClass);
          popoverEl.classList.remove(this.popoverHiddenClass);

          successTimeoutHandler = setTimeout(() => {
            popoverEl.classList.remove(this.popoverVisibleClass);
            popoverEl.classList.add(this.popoverHiddenClass);
          }, successVisibleInMs);
        })
        .catch((err) => {
          console.log('failed to copy link', err);
        });
    };

    copyLinkAnchorEl.addEventListener('click', clickListener);

    return () => {
      copyLinkAnchorEl.removeEventListener('click', clickListener);
    };
  }

  /**
   * Bind a listener to the share link anchor element to be triggered on a click
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindShareLinkAnchorClickListener() {
    const shareLinkAnchorEl = this.currentContentAccessPageNode.querySelector(
      this.shareLinkAnchorSelector
    );
    if (!shareLinkAnchorEl) {
      return () => {};
    }

    const clickListener = (e) => {
      const popoverEl = shareLinkAnchorEl.querySelector(this.popoverSelector);

      if (!popoverEl || popoverEl.classList.contains(this.popoverVisibleClass)) {
        return;
      }

      popoverEl.classList.add(this.popoverVisibleClass);
      popoverEl.classList.remove(this.popoverHiddenClass);

      const blur = (e) => {
        popoverEl.classList.remove(this.popoverVisibleClass);
        popoverEl.classList.add(this.popoverHiddenClass);
        document.removeEventListener('click', blur);
      };
      document.addEventListener('click', blur, true);
    };

    shareLinkAnchorEl.addEventListener('click', clickListener);

    return () => {
      shareLinkAnchorEl.removeEventListener('click', clickListener);
    };
  }

  /**
   * Bind a listener to the login form submit event
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindLoginFormListener() {
    const loginEl = this.currentContentAccessPageNode.querySelector(this.loginFormActionSelector);
    if (!loginEl) {
      return () => {};
    }

    const formEl = loginEl.closest('form');
    const emailInputEl = formEl.querySelector('[type="email"]');
    const passwordInputEl = formEl.querySelector('[type="password"]');
    const forgotPasswordEl = formEl.querySelector(this.loginFormForgotPasswordSelector);
    const messageBoxLoginInvalidEl = this.currentContentAccessPageNode.querySelector(
      this.loginFormLoginInvalidMessageBoxSelector
    );
    const messageBoxLoginFailedEl = this.currentContentAccessPageNode.querySelector(
      this.loginFormLoginFailedMessageBoxSelector
    );

    const valid = (fieldValueParams) => {
      $.ajax({
        method: 'POST',
        url: formEl.getAttribute('action'),
        data: fieldValueParams,
        dataType: 'json',
        xhrFields: {
          withCredentials: true,
        },
      })
        .done(({ data }) => {
          // check if response is a redirect
          if (data?.redirect) {
            window.location.href = data.redirect;
          } else {
            this.triggerNewsletterSubscriptionForLoginContext(formEl);
            this.jumpToLoggedInBodyContent();
          }
        })
        .fail(() => {
          messageBoxLoginFailedEl.classList.remove('hidden');
        });
    };

    const invalid = () => {
      messageBoxLoginInvalidEl.classList.remove('hidden');
    };

    const submitListener = (e) => {
      messageBoxLoginInvalidEl.classList.add('hidden');
      messageBoxLoginFailedEl.classList.add('hidden');
      this.formSubmitListener(e, valid, invalid);
    };

    const emailInputUpdateListener = (e) => {
      if (this.ssoEmailPattern.exec(e.target.value)) {
        passwordInputEl.removeAttribute(this.inputFieldAttributeName);
        passwordInputEl.classList.add('invisible');
        forgotPasswordEl.classList.add('invisible');
      } else {
        passwordInputEl.setAttribute(this.inputFieldAttributeName, 'required');
        passwordInputEl.classList.remove('invisible');
        forgotPasswordEl.classList.remove('invisible');
      }
    };

    formEl.addEventListener('submit', submitListener);
    emailInputEl.addEventListener('blur', emailInputUpdateListener);
    emailInputEl.addEventListener('input', emailInputUpdateListener);

    return () => {
      formEl.removeEventListener('submit', submitListener);
      emailInputEl.removeEventListener('blur', emailInputUpdateListener);
      emailInputEl.removeEventListener('input', emailInputUpdateListener);
    };
  }

  /**
   * Bind a listener to the link-via-mail form submit event
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindLinkViaMailFormListener() {
    const linkViaMailEl = this.currentContentAccessPageNode.querySelector(
      this.linkViaMailFormActionSelector
    );
    if (!linkViaMailEl) {
      return () => {};
    }

    const originPageUrl = this.originLocationUrl;

    const formEl = linkViaMailEl.closest('form');
    const mesageBoxLinkViaMailInvalidEl = this.currentContentAccessPageNode.querySelector(
      this.linkViaMailInvalidMessageBoxSelector
    );

    const contentAreaCode = this.currentContentAccessPageNode.getAttribute(
      this.contentAreaCodeAttributeName
    );
    const pageId = this.currentContentAccessPageNode.getAttribute(this.pageIdAttributeName);

    const self = this;

    const valid = (fieldValueParams) => {
      this.showMailSentWrapper(true);
      const downloadFilename = self.downloadFile?.filename;

      $.ajax({
        method: 'GET',
        url: confEnv['pdb_api_url'] + formEl.getAttribute('action'),
        data: {
          firstname: fieldValueParams['firstname'],
          surname: fieldValueParams['surname'],
          email: fieldValueParams['email'],
          contentAreaCode: contentAreaCode,
          pageId: pageId,
          filename: downloadFilename,
          originPageUrl: originPageUrl,
          url: fieldValueParams['url'],
          test: fieldValueParams['test'],
        },
        dataType: 'json',
        xhrFields: {
          withCredentials: true,
        },
      })
        .done((data) => {
          this.showMailSentWrapper();
          this.subscribeNewsletter(
            fieldValueParams['name'],
            fieldValueParams['email'],
            data.newsletterType
          );
        })
        .fail(() => {
          this.hideMailSentWrapper();
          console.error('Failed to send mail!');
        });
    };

    function invalid() {
      mesageBoxLinkViaMailInvalidEl.classList.remove('hidden');
    }

    const submitListener = (e) => {
      mesageBoxLinkViaMailInvalidEl.classList.add('hidden');
      this.formSubmitListener(e, valid, invalid);
    };

    formEl.addEventListener('submit', submitListener);

    return () => {
      formEl.removeEventListener('submit', submitListener);
    };
  }

  /**
   * Bind a listener to close click event
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindCloseLinkClickListener() {
    const linkEl = this.currentContentAccessPageNode.querySelector(this.closeActionSelector);
    if (!linkEl) {
      return () => {};
    }

    const listener = (e) => {
      e.preventDefault();
      this.hideMailSentWrapper();
    };

    linkEl.addEventListener('click', listener);

    return () => {
      linkEl.removeEventListener('click', listener);
    };
  }

  /**
   * Bind a listener to the main close click event
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindMainCloseListener() {
    const closeBtn = this.currentContentAccessPageNode.querySelector(this.mainCloseButtonSelector);
    if (!closeBtn) {
      return () => {};
    }

    const listener = this.triggerCloseListeners.bind(this);

    closeBtn.addEventListener('click', listener);

    return () => {
      closeBtn.removeEventListener('click', listener);
    };
  }

  /**
   * Bind a listener to a escpe key press
   *
   * @return {() => void} Unlistener function to be called to remove the added listener
   */
  bindEscapeCloseListener() {
    const keyListener = (e) => {
      e = e || window.event;
      var isEscape = false;

      if ('key' in e) {
        isEscape = e.key == 'Escape' || e.key == 'Esc';
      } else {
        isEscape = e.keyCode == 27;
      }

      if (isEscape) {
        this.triggerCloseListeners();
      }
    };

    document.body.addEventListener('keydown', keyListener);

    return () => {
      document.body.removeEventListener('keydown', keyListener);
    };
  }

  /**
   * Trigger all added close listeners
   *
   * @return {void}
   */
  triggerCloseListeners() {
    this.onCloseListeners.forEach((cb) => cb());
  }

  /**
   * Show the LoggedIn overlay content
   *
   * @return {void}
   */
  jumpToLoggedInBodyContent() {
    this.jumpToBodyContentWithClass('logged-in');
  }

  /**
   * Show the MailSent confirmation wrapper
   *
   * @param  {boolean} loading  Show loading indicator
   * @return {void}
   */
  showMailSentWrapper(loading) {
    const formWrapper = this.currentContentAccessPageNode.querySelector('.form-wrapper');
    const mailSentWrapper = this.currentContentAccessPageNode.querySelector('.mail-sent-wrapper');
    const mailSentBody = mailSentWrapper.querySelector('.mail-sent-body');
    const spinner = mailSentWrapper.querySelector('.mail-being-sent-spinner');

    if (!formWrapper || !mailSentWrapper || !mailSentBody || !spinner) {
      return;
    }

    if (!!loading) {
      spinner.classList.remove('hidden');
      mailSentBody.classList.add('hidden');
    } else {
      spinner.classList.add('hidden');
      mailSentBody.classList.remove('hidden');
    }

    formWrapper.classList.add('trans0');
    mailSentWrapper.classList.remove('hidden');
  }

  /**
   * Hide the MailSent confirmation wrapper
   *
   * @return {void}
   */
  hideMailSentWrapper() {
    const formWrapper = this.currentContentAccessPageNode.querySelector('.form-wrapper');
    const mailSentWrapper = this.currentContentAccessPageNode.querySelector('.mail-sent-wrapper');

    if (!formWrapper || !mailSentWrapper) {
      return;
    }

    formWrapper.classList.remove('trans0');
    mailSentWrapper.classList.add('hidden');
  }

  /**
   * Jump to a given body content
   *
   * @param  {string} className  Classname of the body content to show / jump to
   * @return {void}
   */
  jumpToBodyContentWithClass(className) {
    Array.prototype.slice
      .call(this.currentContentAccessPageNode.querySelectorAll(this.bodyContentSelector))
      .forEach((el) => {
        if (el.classList.contains(className)) {
          el.classList.remove('hidden');
        } else {
          el.classList.add('hidden');
        }
      });
  }

  /**
   * Trigger the newsletter subscription checkbox found in the login context
   *
   * @param  {HTMLElement} pageContextEl  Context element for which a newsletter checkbox should be evaluated
   * @return {void}
   */
  triggerNewsletterSubscriptionForLoginContext(pageContextEl) {
    const checkboxEl = pageContextEl.querySelector(this.newsletterCheckboxSelector);

    if (!checkboxEl || !checkboxEl.checked) return;

    const emailEl = pageContextEl.querySelector('[type="email"][data-input-field]');

    const email = (emailEl && emailEl.value) || '';
    const type = checkboxEl.getAttribute(this.newsletterCheckboxAttributeName);

    this.subscribeNewsletter('', email, type);
  }

  /**
   * Subscribe to the newsletter
   *
   * @param  {string} name   Name
   * @param  {string} email  Email address
   * @param  {string} type   Newsletter type
   * @return {void}
   */
  subscribeNewsletter(name, email, type) {
    const payload = {
      lastname: name,
      email: email,
      type: type,
    };

    newsletter.subscribeNewsletter(payload, function () {});
  }
}
