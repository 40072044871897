/*
  Dependencies:
  - window
  - $ (jQuery)
  - confEnv

  Dependents:
  - fullscreen_slideshow - dist/struktur/js/global/fullscreen-slideshow.js
  - dist/struktur/php/templates/global/sidemenue.php
  - dist/struktur/php/templates/global/sidemenue_v2.php
*/

// Share Button for Gallery

function ShareImageButton(url, description) {
  // newicon-0-0 --> Facebook
  // newicon-1-0 --> twitter
  // newicon-8-0 --> pinterest
  // newicon-9-0 --> linkedin
  // newicon-95-0 --> mail
  // newicon-104-0 --> whatsapp
  let _templateContainer = `
    <nav id="site_menu_outer_gallery_container" class="side_menu_gallery_outer">
      <div class="side_menu_gallery_inner">%content%</div>
    </nav>
  `;
  const _template = `
    <div class="side_menu_gallery_section -active social_active" data-side-menu="social">
      </span><span title="Facebook" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "facebook_img" }\' class="newicon-0-0" onclick="window.open(\'http://www.facebook.com/sharer/sharer.php?u=%url%&description=%description%\',\'\',\'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=600,height=270\'); return false;"></span>
      <span title="Pinterest" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "pinterest_img" }\' class="newicon-8-0" onclick="window.open(\'https://pinterest.de/pin/create/button/?description=%description%&amp;media=%url%\',\'\',\'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=800,height=600\'); return false;"></span>
      <span title="LinkedIn" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "linkedin_img" }\' class="newicon-9-0" onclick="window.open(\'http://www.linkedin.com/shareArticle?mini=true&amp;url=%url%\',\'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=990,height=391\'); return false;"></span>
      <span title="E-Mail" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "mailto_img" }\' class="newicon-95-0" onclick="window.location.href=\'mailto:?subject=www.erco.com%20%title%&amp;body=%description%%20%url%\';return false;"></span>
      <span title="WhatsApp" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "whatsapp_img" }\' class="newicon-104-0" onclick="window.location.href=\'whatsapp://send?text=%description%%20%url%\'; return false;"></span>
    </div>
  `;
  let _url = null;
  let _description = '';
  const _selector = '#site_menu_outer_gallery_container [data-side-menu=social]';
  const _title = $('title').text();
  const initialized = false;

  this.setUrl = setUrl;
  this.setDescription = setDescription;
  this.create = create;
  this.destroy = destroy;

  function create(url, description) {
    const regex_url = new RegExp('\%url\%', 'g');
    const regex_description = new RegExp('\%description\%', 'g');
    const regex_title = new RegExp('\%title\%', 'g');
    const regex_twitterDescription = new RegExp('\%twitterDescription\%', 'g');

    _url = url;

    setDescription(description);

    _templateContainer = _templateContainer.replace('%content%', _template);
    _templateContainer = _templateContainer.replace(regex_title, encodeURIComponent(_title));
    _templateContainer = _templateContainer.replace(regex_url, _url);
    _templateContainer = _templateContainer.replace(regex_description, encodeURIComponent(_description));
    _templateContainer = _templateContainer.replace(regex_twitterDescription, _title);

    $('body').append(_templateContainer);

    $(_selector).on('click', () => {
      // Unless this item should be visible all the time, i commented in this line.
      // $(this).toggleClass("social_active");
    });

    intialized = true;
  }

  function setDescription(description) {
    const truncat = 140;
    const descThreshold = 50;
    const defaultDescription = $('meta[name=description]').attr('content');

    _description = _title;

    // Check if there are enough characters left for a little description
    if (truncat - _description.length >= descThreshold) {
      _description += ' - ';
      _description += description || defaultDescription;
    }

    // Truncat the whole string
    _description = _description.substring(0, truncat);

    // Append dots if not already set.
    const lastChars = _description.substr(_description.length - 3);
    if (lastChars != '...') {
      _description += '...';
    }
    _description = _description.replace(/'/g, '`');
  }

  function setUrl(url) {
    _url = url;
    destroy();
    create(url);
  }

  function destroy() {
    if (intialized) {
      $('.side_menu_gallery_outer').remove();
    }

    intialized = false;
  }
}

function ShareImageButtonOnPic(url, description) {
  // newicon-0-0 --> Facebook
  // newicon-1-0 --> twitter
  // newicon-8-0 --> pinterest
  // newicon-9-0 --> linkedin
  // newicon-95-0 --> mail
  // newicon-104-0 --> whatsapp

  let _templateContainer = `
    <nav id="site_menu_outer_gallery_container" class="side_menu_gallery_outer_onpage">
      <div class="side_menu_gallery_inner_onpage">%content%</div>
    </nav>
  `;
  const _template = `
    <div class="side_menu_gallery_section_onpage" data-side-menu="social">
      <span class="newicon-7-0 with-shadow"></span>
      <span title="Facebook" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "facebook_img" }\' class="newicon-0-0" data-share-link=\'%sharejsonFB%\'></span>
      <span title="Pinterest" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "pinterest_img" }\' class="newicon-8-0" data-share-link=\'%sharejsonPINT%\'></span>
      <span title="LinkedIn" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "linkedin_img" }\' class="newicon-9-0" data-share-link=\'%sharejsonLINK%\'></span>
      <span title="E-Mail" data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "mailto_img" }\' class="newicon-95-0" data-share-link=\'%sharejsonMAIL%\'></span>
      <span data-user-action-type="content_shared" data-user-interaction="content_shared" data-user-action-params=\'{ "platform": "whatsapp_img" }\' class="newicon-104-0 -show-mobile" data-share-link=\'%sharejsonWA%\'></span>
    </div>
  `;
  const _title = $('title').text();
  let _description = '';
  const initialized = false;

  const dataJsonObject = {
    imgUrl: '',
    plattform: '',
    description: '',
    title: '',
  };

  this.setDescription = setDescription;
  this.create = create;

  function create(url, description) {
    let tmpJson;

    setDescription(description);

    dataJsonObject.description = _description;
    dataJsonObject.title = _title;
    dataJsonObject.imgUrl = url;

    _templateContainer = _templateContainer.replace('%content%', _template);

    // data-share-link (facebook)
    dataJsonObject.plattform = 'facebook';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonFB%', tmpJson);

    // data-share-link (twitter)
    dataJsonObject.plattform = 'twitter';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonTW%', tmpJson);

    // data-share-link (pinterest)
    dataJsonObject.plattform = 'pinterest';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonPINT%', tmpJson);

    // data-share-link (linkedin)
    dataJsonObject.plattform = 'linkedin';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonLINK%', tmpJson);

    // data-share-link (mail)
    dataJsonObject.plattform = 'mail';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonMAIL%', tmpJson);

    // data-share-link (whatsapp)
    dataJsonObject.plattform = 'whatsapp';
    tmpJson = JSON.stringify(dataJsonObject);
    _templateContainer = _templateContainer.replace('%sharejsonWA%', tmpJson);

    intialized = true;
    return _templateContainer;
  }

  function setDescription(description) {
    const truncat = 140;
    const descThreshold = 50;
    const defaultDescription = $('meta[name=description]').attr('content');

    _description = _title;

    // Check if there are enough characters left for a little description
    if (truncat - _description.length >= descThreshold) {
      _description += ' - ';
      _description += description || defaultDescription;
    }

    // Truncat the whole string
    _description = _description.substring(0, truncat);

    // Append dots if not already set.
    const lastChars = _description.substr(_description.length - 3);
    if (lastChars != '...') {
      _description += '...';
    }

    _description = _description.replace(/'/g, '"');
    _description = _description.replace(/"/g, '"');
  }
}

function setShareLink(imgURL, description, plattform, title) {
  const regex_url = new RegExp('\%url\%', 'g');
  const regex_description = new RegExp('\%description\%', 'g');
  const regex_title = new RegExp('\%title\%', 'g');
  const regex_twitterDescription = new RegExp('\%twitterDescription\%', 'g');

  var imgURL = setShortLink(imgURL);

  switch (plattform) {
    case 'facebook':
      var url = 'http://www.facebook.com/sharer/sharer.php?u=%url%&description=%description%';

      url = url.replace(regex_url, imgURL);
      url = url.replace(regex_description, encodeURIComponent(description));

      window.open(url, '', 'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=600,height=270');
      break;
    case 'pinterest':
      var url = 'https://pinterest.de/pin/create/button/?description=%description%&amp;media=%url%';

      url = url.replace(regex_url, imgURL);
      url = url.replace(regex_description, encodeURIComponent(description));

      window.open(url, '', 'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=800,height=600');
      break;
    case 'linkedin':
      var url = 'http://www.linkedin.com/shareArticle?mini=true&amp;url=%url%';

      url = url.replace(regex_url, imgURL);

      window.open(url, '', 'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=990,height=391');
      break;
    case 'mail':
      var url = 'mailto:?subject=www.erco.com%20%title%&amp;&body=%description%%20%url%';

      url = url.replace(regex_url, imgURL);
      url = url.replace(regex_description, encodeURIComponent(description));
      url = url.replace(regex_title, encodeURIComponent(title));

      window.location.href = url;
      break;
    case 'whatsapp':
      var url = 'whatsapp://send?text=%description%%20%url%';

      url = url.replace(regex_url, imgURL);
      url = url.replace(regex_description, description);

      window.location.href = url;
      break;
    case 'twitter':

      var url = 'https://twitter.com/share?text=%twitterDescription%&via=erco_lighting&url=%url%';

      url = url.replace(regex_url, imgURL);
      url = url.replace(regex_twitterDescription, encodeURIComponent(title));

      window.open(url, '', 'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=600,height=217');

      break;
    default:
      break;
  }
}

function setShortLink(imageUrl) {
  // url zu dem link shortener
  const shortURL = `${confEnv.www_url}/struktur/php/shortener-km/?action=create&input=${encodeURIComponent(imageUrl)}`;
  let response = false;

  // wichtig async: false muss gesetzt werden, sonst wird es als popup gefiltert
  $.ajax({
    url: shortURL,
    contentType: 'application/json',
    async: false,
    success(result) {
      // geshortete Adresse
      response = result.value;
    },
    error(result) {
      false;
    },
  });

  return response || imageUrl;
}
