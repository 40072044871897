/**
 * Scene Switcher
 *
 * @dependencies
 *
 * @dependents
 * cms-backend (dist/kunden/ls2014/vorlagen/html/layout/microsites/distribution_interaction_v2.php)
 *
 */
var sceneSwitcher = {
  indexCurrentScene: null,
  hotSpotLayer: null,
  hotSpots: null,
  imageStrip: null,
  switchButtons: null,
  init: function (intialScene) {
    // Buttons for switching the room
    sceneSwitcher.switchButtons = document.querySelectorAll(
      ".js-switch-bar .js-switchbutton"
    );

    // Imagestrip
    sceneSwitcher.imageStrip = document.querySelector("[data-js-image-strip]");

    if (
      sceneSwitcher.imageStrip === null ||
      sceneSwitcher.imageStrip.getAttribute("data-js-image-strip") ===
        "initialized"
    ) {
      return;
    }

    sceneSwitcher.imageStrip.setAttribute("data-js-image-strip", "initialized");

    sceneSwitcher.renderings = document.querySelectorAll(
      "[data-index-rendering]"
    );
    sceneSwitcher.baseRendering = document.querySelector(
      "[data-base-rendering]"
    );

    // Layers on which the hotspots are positioned
    sceneSwitcher.hotSpotLayer = document.querySelector("[data-hotspot-layer]");

    // Hotspots
    sceneSwitcher.hotSpots =
      sceneSwitcher.hotSpotLayer.querySelectorAll("[data-lightning]");

    // Boxes with info about the room in the desktop view and in the mobile view in addition to lighting
    if (intialScene !== null) {
      sceneSwitcher.indexCurrentScene = 0;
      for (var i = 0; i < sceneSwitcher.switchButtons.length; i++) {
        if (
          sceneSwitcher.switchButtons[i]
            .querySelector("input")
            .getAttribute("id") === intialScene
        ) {
          sceneSwitcher.indexCurrentScene = i;
          break;
        }
      }
    } else {
      sceneSwitcher.indexCurrentScene = 0;
    }
    // Animation of the image strip is finished
    sceneSwitcher.renderings[0].addEventListener("transitionend", function (e) {
      // We want the event to fire only when the animation from the image strip is finished.
      // Otherwise the event will be fired twice. Since the hotspot layer also contains an animation and fires this event.
      if (e.target.classList.contains("image")) {
        sceneSwitcher.handleTransitionEnd();
      }
    });
    // Attach click events to scene selection buttons
    for (var i = 0; i < sceneSwitcher.switchButtons.length; i++) {
      sceneSwitcher.switchButtons[i].addEventListener("click", function (e) {
        // Remember which button was clicked on
        var index = e.currentTarget.getAttribute("data-index-info");
        if (sceneSwitcher.indexCurrentScene != index) {
          sceneSwitcher.selectScene(index);
        }
      });
    }
    sceneSwitcher.selectScene(sceneSwitcher.indexCurrentScene);
    sceneSwitcher.renderings[sceneSwitcher.indexCurrentScene].setAttribute(
      "data-state",
      "active"
    );
    sceneSwitcher.hotSpotLayer.setAttribute("data-state", "active");
    setTimeout(() => {
      sceneSwitcher.baseRendering.setAttribute("data-transisitions", "true");
      for (let i = 0; i < sceneSwitcher.renderings.length; i++) {
        sceneSwitcher.renderings[i].setAttribute("data-transisitions", "true");
      }
    }, 0);
  },
  handleTransitionEnd: function () {
    sceneSwitcher.renderings[sceneSwitcher.indexCurrentScene].setAttribute(
      "data-state",
      "active"
    );

    // Show hotspotlayer
    sceneSwitcher.hotSpotLayer.setAttribute("data-state", "active");
    // sceneSwitcher.hotSpotLayer.classList.add('-visible');
  },
  selectScene: function (index) {
    // Hotspot layer may only be faded out if the scene changes and animation takes place as a result,
    // otherwise the layer will not be faded in again, because the fade in takes place when the animation is finished.
    if (index !== sceneSwitcher.indexCurrentScene) {
      // Hide layer with hotspots
      sceneSwitcher.hotSpotLayer.removeAttribute("data-state");
      sceneSwitcher.renderings[sceneSwitcher.indexCurrentScene].removeAttribute(
        "data-state"
      );
    }

    // for all hotspots...
    for (var j = 0; j < sceneSwitcher.hotSpots.length; j++) {
      // ...reset classes
      sceneSwitcher.hotSpots[j].setAttribute("data-hidden", "true");
    }
    // set index of the current scene
    sceneSwitcher.indexCurrentScene = index;

    // get data, which hotpots are to be displayed
    var dataHotspot = JSON.parse(
      sceneSwitcher.switchButtons[sceneSwitcher.indexCurrentScene].getAttribute(
        "data-hotspot"
      )
    );

    // exists JSON with data?
    if (dataHotspot) {
      var k = 0;
      for (k = 0; k < dataHotspot.length; k++) {
        var hotSpot = document.querySelector(
          "[data-hotspot-layer] [data-lightning=" +
            dataHotspot[k].lightning +
            "]"
        );

        // suitable hotspot...
        // ...make visible
        hotSpot.removeAttribute("data-hidden", "false");

        // ...position
        hotSpot.setAttribute("data-position", dataHotspot[k].position);

        // ...set alignment
        if (dataHotspot[k].align) {
          dataHotspot[k].align.forEach(function (element) {
            hotSpot.classList.add(element);
          });
        }
      }
      // position image strip
      var position =
        sceneSwitcher.switchButtons[
          sceneSwitcher.indexCurrentScene
        ].getAttribute("data-position");
      if (position) {
        sceneSwitcher.imageStrip.setAttribute("data-position", position);
      }
    }
  },
};
