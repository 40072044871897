
/* Force Mute Video
============================================================*/
var forceMuteVideos = (function () {
	var exports = {};
	var data = {
		selector  : 'video',
		muteClass : 'js-force-mute-video'
	}
	
	var mute = function () {
		var el = document.querySelectorAll(data.selector);
		
		for (var i = 0, l = el.length; i < l; i++) {
			if (el[i].muted) continue;
			
			el[i].muted = true;
			el[i].classList.add(data.muteClass);
		}
	};
	
	var unmute = function () {
		var el = document.querySelectorAll('.' + data.muteClass);
		
		for (var i = 0, l = el.length; i < l; i++) {
			el[i].muted = false;
			el[i].classList.remove(data.muteClass);
		}
	};
	
	exports.mute   = mute;
	exports.unmute = unmute;
	
	return exports;
})()



/* Parallax content und sticky footer
============================================================*/
var parallaxContentStickyFooter = function () {

	//Wrapper Element und Content und Footer selektieren
	var elements = document.querySelectorAll('[data-wrapper-parallax]');

	for (var i = 0; i < elements.length; i++) {
		var element = elements[i];

		//Daten aus Data-Attribut holen
		var data = JSON.parse(element.getAttribute('data-wrapper-parallax'));

		//Sticky Footer selektieren
		element.targetElement = document.querySelector(data.target);

		var breakPoint = media.breakpoint.refreshValue();

		//Höhe von Footer berechnen und dem Element mit dem Content der sich darüber schieben soll als Padding hinzufügen
		//element.targetElement.clientHeight - 1: 1px abziehen, damit hintergrund zwischen Content und Footer nicht durchblitzt
		element.style.paddingBottom = (breakPoint === media.breakpoint.XS ? 0 : element.targetElement.clientHeight - 1 + 'px');

		window.addEventListener('holy:resizeDone', () => {
			var breakPoint = media.breakpoint.refreshValue();

			for (var i = 0; i < elements.length; i++) {

				//Höhe von Footer berechnen und dem Element mit dem Content der sich darüber schieben soll als Padding hinzufügen
				//Muss bei jedem Resize des Viewports gemacht werden, da sich die Höhe des Footers, abhängig von der Breite des Viewports, ändert
				//Dies soll jedoch nicht bei mobilen  Endgeräten geschehen
				//element.targetElement.clientHeight - 1: 1px abziehen, damit hintergrund zwischen Content und Footer nicht durchblitzt
				element.style.paddingBottom = (breakPoint === media.breakpoint.XS ? 0 : element.targetElement.clientHeight - 1 + 'px');
			}
		});

	}
}

/* Lichtverteilung und Leistungstraeger
============================================================*/
var comparFade = (function () {
	var exports = {};
	
	var getIndex = function (element) {
		return [].slice.call(element.parentNode.children).indexOf(element);
	};
	
	
	var init = function () {
		var anchors = document.querySelectorAll('.js-compar-fade');
		
		for (var i = 0, l = anchors.length; i < l; i++) (function (i) {
			var anchor = anchors[i];
			var data   = JSON.parse(anchor.getAttribute('data-js'));
			
			var trigger = anchor.querySelectorAll(data.trigger);
			var target  = anchor.querySelectorAll(data.trigger + ", " + data.target);
			var active  = data.active.replace('.', '');
			var hover   = data.hover && data.hover.replace('.', '');
			
			for (var j = 0, k = trigger.length; j < k; j++) (function (j) {
				trigger[j].addEventListener('click', function () {
					var select = getIndex(trigger[j]);

					for (var m = 0, n = target.length; m < n; m++) {
						var idx = getIndex(target[m]);
						
						if (idx === select)
							target[m].classList.add(active);
						else
							target[m].classList.remove(active);
					}
				});
				
				
				if (hover) {
					
					trigger[j].addEventListener('mouseenter', function () {
						var select = getIndex(trigger[j]);
	
						for (var m = 0, n = target.length; m < n; m++) {
							var idx = getIndex(target[m]);
							
							if (idx === select)
								target[m].classList.add(hover);
						}
					});
					
					trigger[j].addEventListener('mouseleave', function () {
						var select = getIndex(trigger[j]);
	
						for (var m = 0, n = target.length; m < n; m++) {
							var idx = getIndex(target[m]);
							
							if (idx === select)
								target[m].classList.remove(hover);
						}
					});
				}
			})(j);
			
			
			trigger[0].click();
		})(i);
		
	};

	exports.init = init;	
	
	return exports;
})();

//comparFade.init();




/* Lichtverteilung mobiler Ansicht
============================================================*/
var comparLichtverteilungMobile = (function () {
	var exports = {};
	
	var init = function () {
		
		//Elemente selektieren, über die eine Slideshow gesteuert werden soll
		var anchors = document.querySelectorAll('[data-js-fade]');
		
		
		for (var i = 0, l = anchors.length; i < l; i++) (function (i) {
			
			//Element wählen
			var anchor = anchors[i];
			
			//JSON aus data-Attribut holen und parsen
			var data   = JSON.parse(anchor.getAttribute('data-js-fade'));
			
			//Element selektieren in der sich die Slides befinden
			var target  = document.querySelector(data.target);
			
			//Element merken
			anchor.fadeTarget = target;
			
			//Klasse die zum Sichbarschalten der Slides angeheftet wird
			var active  = data.active.replace('.', '');
			anchor.fadeActive = active;
			
			//Slides innerhalb Slideshow selektieren
			var children = anchor.fadeTarget.children;
			
			
			var highest = 0;
			//Element herausfinden, welches die größte Höhe hat
			for (var k = 0; k < children.length; k++) {
				
				
				if(children[k].offsetHeight >= children[highest].offsetHeight) {
					highest = k;
				}
			}
			
			//Ersten Slide sichtbar schalten
			children[0].classList.add('-active');
			
			
			//Element mit der größten Höhe wird relativ positioniert, damit das umschließende Element eine Höhe hat.
			children[highest].classList.add('_position-relative');
			
			//Es wird gefadet, wenn im Dropdown ein Element ausgewählt wird
			anchor.addEventListener('change', function(e) {
				e.preventDefault();

				var selectedIndex =  e.target.selectedIndex;
				
				//Element unsichtbar schalten, welches sichtbar ist
				e.target.fadeTarget.querySelector('.' +e.target.fadeActive).classList.remove(e.target.fadeActive);
				
				//Ausgewähltes Element sichbar schalten
				e.target.fadeTarget.children[selectedIndex].classList.add(e.target.fadeActive);

			});
		})(i);
		
		var content = new Flickity('.carousel-content', {
			"prevNextButtons": false
		});
		var imgbtns = new Flickity('.carousel-imgbtns', {
			"asNavFor": ".carousel-content",
			"pageDots": false,
			"prevNextButtons": false,
			"cellAlign": "center",
			"contain": true,
			"imagesLoaded": "true"
		});
		var nav = new Flickity('.carousel-nav', {
			"asNavFor": ".carousel-content",
			"pageDots": false,
			"prevNextButtons": false,
			"cellAlign": "left"
		});


		nav.on('select', function () {
			if (nav.selectedIndex !== content.selectedIndex) {
				content.select(nav.selectedIndex);
			}
		});

		imgbtns.on('select', function () {
			if (imgbtns.selectedIndex !== content.selectedIndex) {
				content.select(imgbtns.selectedIndex);
			}
		});

		nav.on('settle', function () {
			nav.element.querySelectorAll('._button')[nav.selectedIndex].click();
		});
		var background = document.querySelector('.lichtverteilung_.-mobile > ._images');
		
		imgbtns.on( 'scroll', function ( progress ) {
			
			var factor = (background.offsetWidth - background.parentElement.offsetWidth) / background.offsetWidth;
			progress = Math.min(Math.max(progress, 0), 100 * factor);
			progress = progress * factor;
			
			background.style.transform = "translateX(-" + progress * 100 + "%)";
			//background.style.left      = progress * 100 + "%";
		});
		
		// trigger initial scroll
		imgbtns.reposition();
		
	};
	
	
	

	exports.init = init;	
	
	return exports;
})();

//comparLichtverteilungMobile.init();

var btnLichtverteilungClick = (function () {
	var exports = {};
	
	var init = function () {

		var buttons = document.querySelectorAll('[data-compar-trigger]');
		for (var i = 0, l = buttons.length; i < l; i++) (function (i) {
			var button = buttons[i];
			var attr   = button.getAttribute('data-compar-trigger');
			var target = document.querySelector('[data-compar-overlay="' + attr + '"]');
		 	
			button.addEventListener('click', function () {
				if (typeof target !== 'undefined') {
					target.click();
				}
			});
		})(i);
	};
	
	exports.init = init;
	
	return exports;
})();

//btnLichtverteilungClick.init();



/* Leistungsträger Gallery
============================================================*/
var gallery_produktmerkmale = function () {
	$('.js-gallery-produktmerkmale').flickity({"wrapAround": true, "prevNextButton": false, lazyLoad: 1 })
	$('.js-gallery-produktmerkmale-texte').flickity({"wrapAround": true, "prevNextButton": false, cellAlign: "left", watchCSS: true })
	
	slideshowNavi.init( $('.js-gallery-produktmerkmale'));	
};

//onload_functions.push(gallery_produktmerkmale);




/* Video Loop
============================================================*/
var comparLoop = (function () {
	var exports = {};
	
	
	var init = function () {
		var videos = document.querySelectorAll('.js-compar-loop');
		
		for (var i = 0, l = videos.length; i < l; i++) (function (i) {
			var video  = videos[i];
			
			var setMuteClass = function () {
				video.muted = !video.muted;
				checkMuteClass.call(this);
				video.play();
			}
			
			var checkMuteClass = function () {
				var self = (this === window) ? video : this;
				
				if (video.muted) {
					self.classList.add('-muted');
					video.classList.add('-muted');
				} else {
					self.classList.remove('-muted');
					video.classList.remove('-muted');
				}
			}
			
			
			video.addEventListener('click', function () {
				if (media.device.refreshValue() === media.device.MOBILE) {
					
					
					if(video.mozRequestFullScreen && media.breakpoint.refreshValue() === media.breakpoint.XS) {
						video.play();
						video.mozRequestFullScreen();
						
						return;
					}
					
					
					if (video.webkitSupportsFullscreen && media.breakpoint.refreshValue() === media.breakpoint.XS) {
						video.play();
						video.webkitEnterFullscreen();
						
						return;
					}
				}
				
				setMuteClass();
				side_menu_animate.checkMute(checkMuteClass);
			});
			
		
	
			
			var setAudioVolume = function () {
				var box     = video.getBoundingClientRect();
				
				var wTop    = window.innerHeight * -0.16666666;
				var wBottom = window.innerHeight *  0.16666666;
				var wDelta  = wBottom - wTop;
				
				var bTop    = box.top;
				var bBottom = box.bottom;
				var bDelta  = bBottom - bTop;
				
				var delta   = wDelta - bDelta;
				var center  = wTop - bTop;
				
				var percent = center / delta * -100;					
				if (wDelta > bDelta) percent = 100 - percent;
				
				percent = 100 - Math.min(Math.max(percent, 0), 100);
			
				video.volume = percent / 100;
			};
			
			
			
			
			var handleObserveIn = function () {					
				$(window).on('scroll.video-loop', setAudioVolume);
				side_menu_animate.showMute();
				side_menu_animate.assignMute(setMuteClass);
				side_menu_animate.checkMute(checkMuteClass);
			};
			
			var handleObserveOut = function () {
				$(window).off('scroll.video-loop');
				side_menu_animate.hideMute();
				side_menu_animate.assignMute();
			};
			
			
			handleObserveIn();
			$(video).on('holy:observeOutViewport', handleObserveOut);
			$(video).on('holy:observeInViewport',  handleObserveIn );
			

			holy.addToObserve(video).checkObserve();		
			
		})(i);
	}
	
	exports.init = init;
	
	return exports;
})();

//onload_functions.push(comparLoop.init);


/* Video Scroll
============================================================*/
var comparVideoScroll = (function () {
	var exports = {};
	
	
	var init = function () {
		var videos = document.querySelectorAll('.js-compar_scroll');
		
		for (var i = 0, l = videos.length; i < l; i++) (function (i) {
			var video     = videos[i];
			
			
			video.addEventListener('ended',  function () {
				//console.log(video);
			});
			
			var setCurrentTime = function () {
				var box      = video.getBoundingClientRect();
				
				var wTop     = window.innerHeight * 0.9;
				var wBottom  = window.innerHeight * 0.1;
				var wDelta   = wBottom - wTop;
				
				var bTop     = box.top;
				var bBottom  = box.bottom;
				var bDelta   = bBottom - bTop;
				
				var delta    = wDelta - bDelta;
				var center   = wTop - bTop;
				
				var percent = center / delta * -100;					
				if (wDelta > bDelta) percent = 100 - percent;
				
				percent = Math.min(Math.max(percent, 0), 99.25);
				var nextTime = video.duration * percent / 100;
				
				video.currentTime = nextTime.toFixed(3);
			};
			
			var handleObserveIn = function () {	
				//video.pause();		
				$(window).on('scroll.video-progress_' + i, setCurrentTime);
			};
			
			var handleObserveOut = function () {
				//video.play();
				$(window).off('scroll.video-progress_' + i);
			};
			
			handleObserveIn();
			$(video).on('holy:observeOutViewport', handleObserveOut);
			$(video).on('holy:observeInViewport',  handleObserveIn );
			

			holy.addToObserve(video).checkObserve();	
		})(i);
	};
	
	exports.init = init;
	
	return exports;
})();

//onload_functions.push(comparVideoScroll.init);


/* #2 Auswahl
============================================================*/

var comparSelectionView = {
	init: function () {
		var showOverlayButtons = document.querySelectorAll('[data-show-overlay]');
		for (var i=0; i< showOverlayButtons.length; i++) {
			showOverlayButtons[i].addEventListener('click', function(e) {
				e.preventDefault();
				var url          = e.currentTarget.getAttribute('href');
				comparOverlay.loadOverlay(url);
			});
		}

		var semiactiveButtons = document.querySelectorAll('[data-button-semiactive]');

		for (var i=0; i < semiactiveButtons.length; i++) { 

			// Auslesen, welche Hotspots eingeblendet werden sollen
			var dataButton = JSON.parse(semiactiveButtons[i].getAttribute('data-button-semiactive'));

			semiactiveButtons[i].classList.add(dataButton.class);
		}
	}
}

/* #3 Overlay
============================================================*/
var comparOverlay = {
	indexCurrentScene        : null,
	fadeInfoboxesSlideShow   : null,
	hotSpotLayer             : null,
	hotSpots                 : null,
	imageStrip               : null,
	infoBoxes                : null,
	containerLightningMobile : null,
	lastInfoBoxes            : null,
	lastPosition             : null,
	scrollTop                : null,
	switchButtons            : null,
	infoFadeSlideshow        : null,
	lastBreakpoint           : null,


	init: function (intialScene) {
		// Schaltflächen zum Umschaulten des Raums
		comparOverlay.switchButtons = document.querySelectorAll('.js-switch-bar .js-switchbutton');

		// Bildstreifen
		comparOverlay.imageStrip = document.querySelector('.js-image-strip');

		// letzten Raum merken
		comparOverlay.lastPosition = "foyer";

		// Layer auf denen die Hotspots positioniert werden
		comparOverlay.hotSpotLayer = document.querySelector('.js-hotspot-layer');

		// Hotspots
		comparOverlay.hotSpots = document.querySelectorAll('.js-hotspot-layer [data-lightning]');

		// Boxen mit Infos zum Raum in der Desktop-Ansicht und in der mobilen Ansicht zusätzlich zur Beleuchtung
		comparOverlay.infoBoxes = document.querySelectorAll('.js-info-boxes .js-info-box');

		// Boxen mit Infos zur Beleuchtungsart für mobile Ansicht
		comparOverlay.containerLightningMobile = document.querySelector('.js-container-infos-lightning-mobile');

		comparOverlay.lastInfoBoxes = new Array();

		comparOverlay.fadeInfoboxesSlideShow = null; 


		if (intialScene) {
			comparOverlay.indexCurrentScene = 0;
			for (var i = 0; i < comparOverlay.switchButtons.length; i++) {
				if (comparOverlay.switchButtons[i].querySelector('input').getAttribute('id') === intialScene) {
					comparOverlay.indexCurrentScene = i;
					break;
				}
			}
		}
		else {
			comparOverlay.indexCurrentScene = 0;                        
		}


		
		comparOverlay.flickityInfoBox = null;
		comparOverlay.flickityButtons = null;


		//Box mit Infos zur Szene
		var infoBox = document.querySelector('.js-info-slideshow');

		comparOverlay.infoFadeSlideshow = new FadeSlideshow(infoBox);
		comparOverlay.infoFadeSlideshow.init();
		
		
		

		comparOverlay.lastBreakpoint = null;
		
		// Animiation des Bildstreifens ist beendet
		comparOverlay.imageStrip.addEventListener('transitionend', function (e) {

			//Wir wollen, dass das Event nur gefeuert wird, wenn die Animation von dem Bildstreifen beendet ist.
			//Sonst wird das Event zwei mal gefeuert. Da der Hotspotlayer auch eine Animation enthält und dieses Event feuert.
			if (e.target.classList.contains('js-image-strip')) {
				comparOverlay.handleTransitionEnd();
			}
		})

		// Clickevents an Schaltflächen zur Auswahl der Szene anhängen
		for (var i = 0; i < comparOverlay.switchButtons.length; i++) {

			comparOverlay.switchButtons[i].addEventListener('click', function (e) {
				// Merken auf welche Schaltläche geklickt wurde
				var index = e.currentTarget.getAttribute('data-index-info');
				if (comparOverlay.indexCurrentScene != index) {
					comparOverlay.selectScene(index);
				}
			});
		}
		
		media.breakpoint.refreshValue();
		comparOverlay.refreshSection();
		comparOverlay.selectScene(comparOverlay.indexCurrentScene);
		

		window.addEventListener('holy:resizeDone', () => {
			comparOverlay.refreshSection();
		});
	},
	handleTransitionEnd: function () {
		document.body.classList.add('_no-scroll');
		
		//Scroll-Position setzen
		window.scroll(0,  comparOverlay.scrollTop)
		
		// Hostpotlayer einblenden
		comparOverlay.hotSpotLayer.classList.add('-visible');
	},
	selectScene: function (index) {
		
		
		//Hotspotlayer darf nur ausgeblendet werden, wenn die Szene wechselt und damit eine Animation stattfindet, 
		//sonst wird der Layer nicht wieder eingeblendet, da das Einblenden dann stattfindet, wenn die Animation abgeschlossen ist
		if(index !== comparOverlay.indexCurrentScene) {
			
			// Layer mit Hotspots ausblenden
			comparOverlay.hotSpotLayer.classList.remove('-visible');
		}
		
		if(comparOverlay.fadeInfoboxesSlideShow) {
			comparOverlay.fadeInfoboxesSlideShow.destroy();	
		}
		
		//Infoboxen zur Beleuchtungsart, die in der letzten Szene angezeigt wurden, zurück in den Sammelcontainer schieben
		for (var i = 0; i < comparOverlay.lastInfoBoxes.length; i++) {
			comparOverlay.containerLightningMobile.appendChild(comparOverlay.lastInfoBoxes[i])

		}
		
		comparOverlay.lastInfoBoxes = new Array();

		// alle Hotspots...
		for (var j = 0; j < comparOverlay.hotSpots.length; j++) {

			// ...Klassen resetten
			comparOverlay.hotSpots[j].className = 'hotspot-box';
			
			// ...Eventlistener entfernen
			comparOverlay.hotSpots[j].removeEventListener('click', comparOverlay.handleHotspotsClick);
		}
		
		// Index der aktuellen Szene setzen
		comparOverlay.indexCurrentScene = index;
		
		// Auslesen, welche Hotspots eingeblendet werden sollen
		var dataHotspot = JSON.parse(comparOverlay.switchButtons[comparOverlay.indexCurrentScene].getAttribute('data-hotspot'));
		
		//wurde ein JSON mit Daten gefunden?
		if (dataHotspot) {
			var k = 0;
			for (k = 0; k < dataHotspot.length; k++) {

				var infoBoxLightning = document.querySelector('.js-container-infos-lightning-mobile [data-lightning=' + dataHotspot[k].lightning + ']');
				var hotSpot = document.querySelector('.js-hotspot-layer [data-lightning=' + dataHotspot[k].lightning + ']');
				
				// Benötigten Hotspot...
				// ...sichtbar machen
				hotSpot.classList.add('-visible');

				// ...positionieren
				hotSpot.classList.add(dataHotspot[k].position);

				// ...Ausrichtung festlegen
				if (dataHotspot[k].align) {
					dataHotspot[k].align.forEach(function (element) {
						hotSpot.classList.add(element);
					})
				}

				//Befinden wir uns in der mobilen Ansicht
				if (media.breakpoint.value === media.breakpoint.XS) {
					
					//Klick-Listener hinzufügen
					hotSpot.addEventListener('click', comparOverlay.handleHotspotsClick);
				}
				
				//Befinden wir uns auf einem Tablet oder Desktoprechner?
				else {
					// Blendet die passende Infobox mit den Texten zum ausgewählten Raum ein
					comparOverlay.infoFadeSlideshow.fadeIn(comparOverlay.indexCurrentScene);
				}  

				// ...Boxen mit Informationen zur Ausleuchtung merken
				comparOverlay.lastInfoBoxes.push(infoBoxLightning);

				// ...
				comparOverlay.infoBoxes[comparOverlay.indexCurrentScene].appendChild(infoBoxLightning);
			}
		}
		

		// Bildstreifen positionieren
		var position = comparOverlay.switchButtons[comparOverlay.indexCurrentScene].getAttribute('data-position');
		if (position) {
			comparOverlay.imageStrip.classList.remove(comparOverlay.lastPosition);
			comparOverlay.imageStrip.classList.add(position);
			comparOverlay.lastPosition = position;
		}
			
		
		if(media.breakpoint.value === media.breakpoint.XS) {
			// Fadeslideshow für Infos zur Szene und Beleuchtungsinformationen zur aktuellen Szene erstellen
			comparOverlay.fadeInfoboxesSlideShow = new FadeSlideshow(comparOverlay.infoBoxes[comparOverlay.indexCurrentScene]);
			comparOverlay.fadeInfoboxesSlideShow.init();
		}
	},
	
	handleHotspotsClick: function(e) {
		
		//Elemenat auf das geclickt wurde
		var element = e.currentTarget;
		
		var dataLightning =element.getAttribute('data-lightning');
		var wasActive = false;
		

		// Ist der Hotspot, auf den geklickt wurde aktiv
		if (element.classList.contains('-active')) {
			
			//Dann soll die Info zu der Szene angezeigt werden
			comparOverlay.fadeInfoboxesSlideShow.fadeIn(0);
			wasActive = true;
		}

		// Alle Hotspots deaktivieren
		for (var j = 0; j < comparOverlay.hotSpots.length; j++) {

			// Hotspots resetten
			comparOverlay.hotSpots[j].classList.remove('-active');
		}

		// Wird nicht die Info zu der Szene angezeigt?
		if (!wasActive) {

			//Hotspot aktiv setzen
			element.classList.add('-active')
			
			for (var i=comparOverlay.lastInfoBoxes.length-1; i>0; i--) {
				if (comparOverlay.lastInfoBoxes[i].getAttribute('data-lightning') === dataLightning)
				break;
			}
				// Zum angeklickten Hotspot gehörende Infobox einblenden
				comparOverlay.fadeInfoboxesSlideShow.fadeIn(i+1);	
		}
	},
	
	refreshSection: function() {

		//Wir müssen kurz warten, sonst gibt es Kollisionen mit Flickity
		setTimeout(function() {
			media.breakpoint.refreshValue();

			//Hat sich der Breakpoint verändert, nach dem die Größe des Fensters verändert wurde?
			if (comparOverlay.lastBreakpoint  !== media.breakpoint.value) {
				
				if(media.breakpoint.value === media.breakpoint.XS) {
					comparOverlay.infoFadeSlideshow.destroy();
					
					// Button zur Auswahl der Szene unchecken
					comparOverlay.switchButtons[comparOverlay.indexCurrentScene].querySelector('input').checked = false;
					
					comparOverlay.initSlideshow(comparOverlay.indexCurrentScene);
				}
				else {
				//else if(comparOverlay.lastBreakpoint === media.breakpoint.XS) {
					comparOverlay.destroySlideshow();
					comparOverlay.infoFadeSlideshow.init();
					
					comparOverlay.infoFadeSlideshow.fadeIn(comparOverlay.indexCurrentScene);
					
					// Button zur Auswahl der Szene checken
					comparOverlay.switchButtons[comparOverlay.indexCurrentScene].querySelector('input').checked = true;
					
				}
			}
			comparOverlay.lastBreakpoint = media.breakpoint.value;
		}, 80);
	},
	
	initSlideshow: function(index) {
		
		//Box mit Infos zur Szene
		var infoBox = document.querySelector('.js-info-slideshow');
		
		var flickityOptions = {
			cellAlign     : 'left',
			cellSelector  : '.js-cell',
			imagesLoaded  : true,
			initialIndex  : index,
			setGallerySize: true,
		}
		
		//Slideshow mit Infos zur Szene initialisieren
		comparOverlay.flickityInfoBox = new Flickity(infoBox, flickityOptions);

		comparOverlay.flickityInfoBox.on( 'select', function() {
			comparOverlay.selectScene(comparOverlay.flickityInfoBox.selectedIndex);
		});
		
		flickityOptions.pageDots  = false;
		flickityOptions.asNavFor  = '.js-info-slideshow';
		flickityOptions.draggable = false;

		//Slideshow mit Buttons zur Auswahl der Szene
		comparOverlay.flickityButtons = new Flickity('.js-buttons-slideshow', flickityOptions);
		
		comparOverlay.flickityInfoBox.resize();
		
	},
	
	destroySlideshow: function () {
		if(comparOverlay.flickityInfoBox && comparOverlay.flickityButtons) {
			comparOverlay.flickityInfoBox.destroy();
			comparOverlay.flickityButtons.destroy();
		}
	},
	
	loadOverlay: function(url) {
		var initialScene = window.toolkit.getQueryParameter('scene', url);
		$.get( url, function( data ) {
			
			//Ton im Vido muten
			forceMuteVideos.mute()

			// Ajax reinigen
			var $new = $( '<div>'+data.replace(/<body/g , "<div data-ajax-wrap")+'</div>' )   // body-tag ersetzen und selektierbar machen
				.find('[data-ajax-wrap]').empty().removeAttr('data-ajax-wrap')                // selektieren, leeren und data-attribut entfernen
				.addClass('compar-overlay')                                                   // notwendige Klassen anheften
				.append( data.split('<!-- EO header.php -->')[1] )                            // Content von menü befreien
				.find('#newsletter-bottom-bar').remove().end()                                   // Newsletter bottom bar entfernen
				.find('#copyright').remove().end()                                            // copyright entfernen
				.find('script[src]').remove().end()                                          // scripte entfernen
			
			//Scroll-Position merken
			comparOverlay.scrollTop = window.scrollY;
			
			var $element = $new.find('.container').first();
			// var naviWrapper = document.querySelector('.new-menu_._wrap');
			// naviWrapper.classList.add('-forceHide');

			$element.addClass('top-1');

			// Schließen Event anheften
			$new.find('.js-close-overlay').on('click',function(){
				
				//Ton im Video unmuten
				forceMuteVideos.unmute()
				
				//Verknüfung von Event mit Close Buttons auflösen
				side_menu_animate.assignClose();
				
				//Close Button in Sidebar ausblenden
				side_menu_animate.hideClose();
				
				//Button, um an den Anfang der Seite zu scrollen, einblenden
				side_menu_animate.forceHideUpRemove();		
				
				// Mute button einblenden
				side_menu_animate.forceHideMuteRemove();		

				$new.removeClass('-visible');
					document.documentElement.classList.remove('_no-scroll');
					document.body.classList.remove('_no-scroll');

				
				setTimeout(function() {
					$('html, body').stop().animate({         
					  scrollTop: comparOverlay.scrollTop
					}, 1);					
				}, 0)
				
				
				$new.one('transitionend', function() {
					$new.remove();
					// naviWrapper.classList.remove('-forceHide');
					
				})
				
				var currentTitle = document.querySelector('title').innerHTML;
				var currentURL = window.location.href;

				// history.pushState({}, currentTitle, currentURL);
			});
			
			
			setTimeout(function() {
				$new.addClass('-visible');
				
				//Button, um an den Anfang der Seite zu scrollen, ausblenden
				side_menu_animate.forceHideUp();
				
				// Mute button ausblenden (falls er nicht benötigt wird)
				side_menu_animate.forceHideMute();
						
				
				$new.one('transitionend', function() {
					
					//Animationen innerhalb des Overlays aktivieren
					//Animationen müssen initial deaktivert sein, damit die Szene, die ggf. vorausgewählt wird, direkt angezeigt wird
					$new.find('.js-image-strip').addClass('_enable-transitions');
					comparOverlay.handleTransitionEnd();
				});

			}, 0);
			
			
			// history.pushState({}, 'Compar Overlay', url);
			// an den DOM anheften
			$('body').append($new);
			document.documentElement.classList.add('_no-scroll');

			comparOverlay.init(initialScene);
		});
	}
}


onload_functions.push(function () {
	//Befinden wir uns auf der Compar Microsite?
	if (document.querySelector('[data-id-objekt="6347"]')) {

		//Dann initialisieren wir die Objekte
		comparFade.init();
		comparLichtverteilungMobile.init();
		btnLichtverteilungClick.init();
		comparLoop.init();
		comparVideoScroll.init();
		comparSelectionView.init();
	}

	//Aus der If-Bedingung gezogen, da Slideshows auch auf anderen Seiten (ERCO Individual) verwendet werden.
	gallery_produktmerkmale();

});
