window.toolkit = (function () {
  
  var exports = {};
  
  /**
   * Triggert Event an einem bestimmten Element
   * @param {object} el   Element von dem das Event getriggert werden soll
   * @param {string} type Event welches getriggert werden soll
   */
  var triggerEvent = function(el, type) {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      var e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    }
    else {
      // IE 8
      var e = document.createEventObject();
      e.eventType = type;
      el.fireEvent('on' + e.eventType, e);
    }
  };

  /**
   * fügt zwei Objekte zu einem Object zusammen
   * @param   Object obj Zielobject
   * @param   Object src Quellobject
   * @returns Object Zielbojekt in welches das Quellobjekt eingefügt wurde
   */
  var extend = function(obj, src) {
    for (var key in src) {
      if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
  }

  /**
   * Überprüft ob sich die obere Teil des Elements innerhalb des Viewports vom Browser befindet
   * @param   {object}  el DOM Element, das überüft werden soll
   * @returns {boolean} true: Element befindet sich im Viewport; false: Element ist außerhalb des Viewports
   */
  var isElementTopInViewport = function(el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
      el = el[0];
    }
    var rect = el.getBoundingClientRect();
    return (rect.top >= 0 && rect.left >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */ rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */ ); 
  };

  /**
   * Berechnet den Abstand vom oberen Rand des Contents zum übergebenen Element
   * @param   {object} element Element für den der Abstand berechnet werden soll
   * @returns {number} Abstand in Pixeln
   */
  var getOffsetFromTop = function (element) {
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = element.getBoundingClientRect();
      
    
    return (elemRect.top - bodyRect.top);
  };

  /**
   * Gibt die Höhe eines Elements inklusive margins zurück
   * @param   {object} el Element für das die Höhe berechnet werden soll
   * @returns {number} Höhe des Eslements
   */
  function getAbsoluteHeight(el) {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;
    
    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
      parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
  };

  /**
   * Get the value of a querystring
   * @param  {String} field The field to get the value of
   * @param  {String} url   The URL to get the value from (optional)
   * @return {String} The field value
   */
  function getQueryParameter (field, url) {
    var href = url || window.location.href;
    var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
    var string = reg.exec(href);
    return string ? string[1] : null;
  };

  /**
   * Remove a query parameter from an URL
   * @param  {String} field Field to remove from the URL
   * @param  {String} url The URL to get the value from (optional)
   * @return {String} The new URL
   */
  function removeQueryParameter (field, url) {
    var url = url || window.location.href;

    if (!getQueryParameter(field, url)) {
      return url;
    }

    url = url.split('?')[0] + '?';

    var pageUrl = decodeURIComponent(window.location.search.substring(1)),
        urlVars = pageUrl.split('&'),
        parameterName,
        i;
 
    for (i = 0; i < urlVars.length; i++) {
      parameterName = urlVars[i].split('=');
      if (parameterName[0] != field) {
        url = url + parameterName[0] + '=' + parameterName[1] + '&'
      }
    }
    return url.substring(0, url.length-1);
  }

  /**
   * Add a key/value to an URL
   * @param  {String} key The field to add
   * @param  {String} val The value for the field
   * @param  {String} url The URL to add the value to (optional)
   * @return {String} The new URL
   */
  function addQueryParameter (key, val, url) {
    url = url || window.location.href;

    if (getQueryParameter(key, url)) {
      return replaceQueryParameter(key, val, url);
    }

    if (checkIfQueryExists(url)) {
      url += '&' + key + '=' + val;
    } else {
      url += '?' + key + '=' + val;
    }

    return url;
  };

  /**
   * Replace a query parameter with a new value
   * @param  {String} key The field to replace the value of
   * @param  {String} val The value to replace
   * @param  {String} url The URL to add the value to (optional)
   * @return {String} The new URL
   */
  function replaceQueryParameter (key, val, url) {
    url = url || window.location.href;

    if (getQueryParameter(key, url) === val) {
      return url;
    }

    if (getQueryParameter(key, url)) {
      var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
      var separator = url.indexOf('?') !== -1 ? '&' : '?';
      if (url.match(re)) {
        return url.replace(re, '$1' + key + '=' + val + '$2');
      } else {
        return url + separator + key + '=' + val;
      }
    } else {
      return addQueryParameter(key, val, url);
    }

    return url;
  }
 
  /**
   * Check if the current URL has a query
   * @param  {String} url The URL you want to check (optional)
   * @return {Boolean} If a query exists or not
   */
  function checkIfQueryExists (url) {
    var url = url || window.location.href;
    if(url.indexOf('?') != -1) return true;
    return false;
  };

  /**
   * Rundet eine Zahl auf eine bestimmte Anzahl von Nachkommastellen
   * @param  {Number} value Zahl die gerundet werden soll 
   * @param  {Number} digits Anzahl der Nachkommastellen
   * @return {Number} gerundete Zahl
   */
  function round(value, digits) {
    value = parseFloat(value);
    if (!value) return 0;

    digits = parseInt(digits);
    if (!digits) digits=0;

    let factor = Math.pow(10,digits);

    return Math.round(value * factor) / factor;
  }

  /**
   * Get the value of a hash
   * @param {String} url 
   * @return {String} The value (or null)
   */
  function getHashValue ( url ) {
    var href = url ? url : window.location.href;
    var reg = new RegExp( '#' + '([0-9a-zA-Z+-]*)', 'i' );
    var string = reg.exec(href);
    return string ? string[1] : null;
  }
  
  // Polyfill for closest
  this.Element && function(ElementPrototype) {
    ElementPrototype.closest = ElementPrototype.closest ||
    function(selector) {
      var el = this;
      while (el.matches && !el.matches(selector)) el = el.parentNode;
      return el.matches ? el : null;
    }
  }(Element.prototype);

  // Getting the coordinates of an element
  function getCoords(elm) {
    var box = elm.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  /**
   * Download a single file xBrowser
   * @param {String} url - File URL
   * @param {Boolean} directLink - Is this a direct link to the file or is the backend pushing?
   * @param {String?} fileName - The name of the file 
   */
  function downloadFile(url, directLink, fileName) {
    // Testing if the target device is running iOS
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // If the device is running iOS a download via iFrame will not work
    if (iOS) {
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } else {
      // Direct link, download via anchor Click or MS Blob
      if (directLink) {
        // We need to check if the client is IE
        if(!window.navigator.msSaveOrOpenBlob) {
          // If it's not IE we can just download using an anchor
          var a = document.createElement('a');
          var href = url;
          a.setAttribute('href', url);
          a.setAttribute('download', fileName || url.substr(url.lastIndexOf('/') + 1));
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          // If we are in IE we have to try...:(
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';

          xhr.onload = function(e) {
            var blob = xhr.response;
            let blobFileName = fileName;
            var contentType = xhr.getResponseHeader('content-type');

            if (!blobFileName) {
              // IE/EDGE seems not returning some response header
              if (xhr.getResponseHeader('content-disposition')) {
                var contentDisposition = xhr.getResponseHeader('content-disposition');
                blobFileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1);
              } else {
                blobFileName = url.substr(url.lastIndexOf('/') + 1);
              }
            }

            if (window.navigator.msSaveOrOpenBlob) {
              // Internet Explorer
              window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), blobFileName);
            } else {
              var el = document.getElementById('target');
              el.href = window.URL.createObjectURL(blob);
              el.download = blobFileName;
              el.click();
            }
          };
          xhr.send();
        }
      } else {
        // Backend is Pushing, download via iFrame
        // If we are on any other device we will focedownload the file via an iFrame
        var iframe = document.createElement('iframe');
        iframe.id = 'direct-download';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        document.getElementById('direct-download').src = url;
        setTimeout(function() {
          document.getElementById('direct-download').parentNode.removeChild(document.getElementById('direct-download'));
        }, 30000);
      }
    }
  }

  /**
   * Clears an Element
   * @param {DOM Node} elm 
   */
  function clear(elm) {
    while (elm.firstChild) {
      elm.removeChild(elm.firstChild);
    };
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function () {
      var now = Date.now();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  };


  /**
   * Get an object from local Storage
   * @param  {String} key The name of the object
   * @return {Object | null} The object or null
   */
  function getLocalStorageObject(key) {
    const obj = JSON.parse(localStorage.getItem(key));
    if (obj && obj.expiration) {
      // returns the object, if it has not expired - returns null otherwise
      return checkLocalStorageObjectExpiration(obj, key);
    } else if (obj) {
      return obj.value;
    }
    return null;
  };

  /**
   * Check if an object from localStorage has expired
   * @param  {Object} obj The object
   * @param  {String} key The name of the object
   * @return {Object | null} The object or null
   */
  function checkLocalStorageObjectExpiration(obj, key) {
    if (new Date().getTime() > obj.expiration) {
      // The object has expired and is removed
      localStorage.removeItem(key);
      return null;
    }
    return obj.value;
  };

  /**
   * Set an object in local Storage
   * @param  {String} key The name of the object
   * @param  {Object} value The object to be saved
   * @param  {Number} expiration The expiration time of the object in minutes
   * @return {Object} The new object
   */
  function setLocalStorageObject(key, value, expiration) {
    const storageObject = {
      value,
    };
    if (expiration) {
      storageObject.expiration = new Date().getTime() + expiration * 60 * 1000;
    }
    localStorage.setItem(key, JSON.stringify(storageObject));
    return storageObject;
  };

  return {
    triggerEvent,
    isElementTopInViewport,
    getOffsetFromTop,
    getAbsoluteHeight,
    getQueryParameter,
    removeQueryParameter,
    addQueryParameter,
    replaceQueryParameter,
    checkIfQueryExists,
    round,
    getHashValue,
    getCoords,
    downloadFile,
    clear,
    extend,
    getLocalStorageObject,
    setLocalStorageObject,
    debounce,
    throttle,
  };
})();

// Polyfills for _
window._ = {
  throttle: window.toolkit.throttle,
  debounce: window.toolkit.debounce,
  extend: window.toolkit.extend,
};


window.toggleSwitch = (function () {
  var exports        = {};
  var toggleSwitches = null;

  /**
   * Initialisiert die toggle switches
   */
  var init = function() {
    
    toggleSwitches = document.querySelectorAll('[data-toggle-switch]');
    
    for (var i = 0; i < toggleSwitches.length; i++) {
      var toggleSwitch = toggleSwitches[i];
      
      //JSON aus data Attribut holen
      var data = JSON.parse(toggleSwitch.getAttribute('data-toggle-switch'));
  
      //Wurde toggle switch schon initialisert?
      if(data.initialized)
        continue;
      
      //Element holen, dessen Text bei klick auf switch geändert wird
      var target = document.querySelector(data.target);
      if (!target)
        continue;

      //Ist switch inital eingeschaltet?
      if(data.initialOn == 'true') {
        target.innerHTML = data.textOn;
      }
      else {
        target.innerHTML = data.textOff;
      }
      
      //Eigenschaften an den switch anheften
      toggleSwitch.switchTarget = target;
      toggleSwitch.switchData   = data;
      
      //JSON in data attribute schreiben. Wir merken uns, dass switch initialisiert wurde
      data.initialized = true;
      toggleSwitch.setAttribute('data-toggle-switch', JSON.stringify(data));
      
      toggleSwitch.addEventListener('click', function(e) {
        
        var data = e.currentTarget.switchData;
        e.currentTarget.switchTarget.innerHTML = (e.currentTarget.switchTarget.innerHTML === data.textOn) ? data.textOff : data.textOn;
      });
    }
  };
  exports.init = init;
  return exports;
})();

/**
 * Fixes the body so that it can no longer be scrolled.
 * E.g. when an overlay is shown.
 */
window.fixedBody = (function () {
  const $body        = document.querySelector('body');
  let exports        = {};
  let scrollPosition = null;
  let isFixed        = false;

  /**
   * fix the body
   */
  let enable = function() { 
    if(isFixed) {
      return;
    }
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
    isFixed = true;
    
  };

  /**
   * unfix the body
   */
  let disable = function() { 
    if(!isFixed) {
      return;
    }
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
    isFixed = false;
  };

  exports.enable = enable;
  exports.disable = disable;

  return exports;
})();
