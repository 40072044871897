/*
erco.js
Klickmeister GmbH für ERCO GmbH
*/

/* Config
------------------------------------------------------*/
var spezial_js_url = {
  articletable: Array("/struktur/lib/jquery.fileDownload.js"),
  //productsearch : Array("/struktur/js/products/articletable-km.js", "/struktur/js/products/product-search-km.js", "/struktur/lib/jquery.fileDownload.js"),
  productsearch: Array(
    "/struktur/js/products/product-search-km.js",
    "/struktur/lib/jquery.fileDownload.js"
  ),
  application_studies: Array("/struktur/js/guide/application-studies-km.js"),
  html_5: Array("/struktur/js/guide/html-5/html-5-km.js"),
  videojs: Array("/struktur/lib/video-js/video.js"),
  isotope_tools: Array("/struktur/js/global/isotope_tools.js"),
  clientIsConnectedToVPN:
    "/struktur/php/spezifikation/service/clientIsConnectedToVPN.php",
};

var config_data = {
  ie_lt_9: false,
  ie_lt_10: false,
  collectable: true,
  cookie_domain: !confEnv.cookie_domain ? ".erco.com" : confEnv.cookie_domain,
};

/* Main
------------------------------------------------------*/
const ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};


ready(() => {
  // Loading the newsletter bottombat
  NewsletterBottom.init();

  TechnicalEnviromentMessage.init();

  CookieMessage.registerObserverCallback(function (action) {
    if (action === CookieMessage.ACTION_ALLOW) {
      // enable/disable tracking
      Tracking.start();

      // allow third-party services (YouTube, GMaps etc.)
      ThirdParty.activate();

      // A/B Testing
      window.ABTesting.init();
    }
  });

  ImageFadeOver.init();

  // Show/Hide the cookie message
  CookieMessage.check();

  // Event listener for closing the newsletter bottombar
  const newsletterBottomBarClose = document.querySelector('#newsletter-bottom-bar .js-close');
  if (newsletterBottomBarClose) {
    newsletterBottomBarClose.addEventListener('click', function (e) {
      e.preventDefault();
      NewsletterBottom.close();
    });
  }

  // Event listener for submitting via the newsletter bottombar
  const newsletterSubmit = document.querySelector('#submit_newsletter');
  if (newsletterSubmit) {
    newsletterSubmit.addEventListener('click', function (e) {
      e.preventDefault();
      NewsletterBottom.submit();
    });
  }

  // Adding event listener to the contact sidebar menu
  ExpandingSidebar.init();

  // Removing the warning color from the form elements on input
  document.addEventListener('focus', function (e) {
    if (e.target.classList && e.target.classList.contains('empty')) {
      e.target.classList.remove('empty');
    }
  });

  var fadeElements = document.querySelectorAll("[data-fade-slide-show]");
  if (fadeElements) {
    for (var i = 0; i < fadeElements.length; i++) {
      var fadeSlideShowWithNav = new FadeSlideShowWithNavi(fadeElements[i]);
      fadeSlideShowWithNav.init();
    }
  }

  listenToTriggerClickEvents();

  // Appending the checkboxes to the specified forms
  newsletter.init();

  media.device.refreshValue();
  //Wird die Seite mit einem Smartphone oder Tablet besucht?
  if (media.device.value === "mobile")
    document.body.classList.add("mobile-device");
  else document.body.classList.add("desktop-device");

  // holy scroll zeugs
  //holy.init({ notifyer: '[data-nav-container]' })

  // oller Browser?
  if ($(".lt-ie9").length > 0) {
    config_data.ie_lt_9 = true;
  }
  if ($(".lt-ie10").length > 0) {
    config_data.ie_lt_10 = true;
  }

  // Spezialskripte nachladen?
  spezial_js_laden.check();

  // simple Init-funktionen
  header.start();

  // initiate the maintenance banner
  Maintenance.init();

  productoverview.generateOnePageNavigation();
  productoverview.initStickyNavigation();

  navigation.start();
  transition.start(); // ss muss nach navigation.start() sonst werden die events überschrieben von navigation.start()
  // neue header-gallery
  header_gallery.init();
  grid_gallery.init();
  video_js.start();
  view_switch.setDefaultView();
  overwrite_send_mail.init();
  anchors_scroll_to.init();
  videosWithSubtitles.init();

  parallaxContentStickyFooter();

  // beim ajaxen erneut auszuführen
  if (!config_data.ie_lt_9) {
    listener.start();
  }

  // suche
  build_spotlight();
  search_unlock.start();

  // Sprachwahl
  sprachwahl.start();

  // Layout kosmetik
  layout.init();

  after_ajax.addLoadedClass();

  // Do we have an URL with tabanchors?
  // TODO: refactor this
  $('.nav-tabs a[href="' + location.hash + '"]').tab('show');

  // newsflags anzeigen
  newsflags.init();

  // TODO: in function auslagern
  // ss 20140605 Aufrufe zum öffnen der Modalbox
  if (location.href.match(/\?mb=(.*?)$/)) {
    var url = location.href.split("?mb=")[1];
    modaldata.func.trigger(url);
  }

  // ss+ms 20140220
  // nachladen auf startseite
  if (typeof deferredobj != "undefined") {
    deferred_load.init(deferredobj);
    deferred_load.set_tapete("/struktur/php/tapete.php");
  }

  // lighfinder plus
  // FG (2023-03-27) probebly not existent
  if (typeof plus != "undefined") {
    plus.init();
  }

  // init fullscreen images
  fullscreen_slideshow.init();

  // eventmanager shared-link
  eventmanager_scrollto();

  // ss 201606208 Sharebuttonbar anzeigen
  side_menu_animate.showSocial();

  filter_unavailable_youtube_videos();

  // holy scroll zeugs nach derredobj damit firefox richtig lädt
  holy.init({ notifyer: "[data-nav-container]" });

  dotscroll.init();

  backLinkReferrer.init();

  if (media.breakpoint.refreshValue() != media.breakpoint.XS) {
    productoverview.sectionAnimations();
  }

  globals.renderMustacheTemplates();

  productoverview.initOnePageNavigation();
  productoverview.initCollapseContent();
  productoverview.initCarousels();
  productoverview.initContactForms();
  productoverview.injectsSuperScript();

  sceneSwitcher.init();

  specialFeatures.init();

  //AdForm Tracking for page visits
  Tracking.adform.addConversionPixel(Tracking.adform.pixelType.PAGE);

  //AdForm Tracking for download buttons
  document
    .querySelectorAll("[data-adform-download-tracking]")
    .forEach((element) => {
      element.addEventListener("click", (e) => {
        Tracking.adform.addConversionPixel(
          Tracking.adform.pixelType.DOWNLOAD,
          e.currentTarget
        );
      });
    });

  // OnePager aufruf ausgelagert, um den dedizierter adressierbar zu machen
  onepager.init();

  toggleSwitch.init();

  // Checking if a specsheet query exists on pageload
  Specsheets.checkQuery();

  // Bind the content-access check to all download links on page load
  contentAccess.init();

  // Initializing the direct download
  DirectDownloads.checkParams();

  // deferred ans ende... damit events richtig angehangen werden
  deferred.start();

  // check if old implementation of OverlayHandler is used
  // (hardcoded in template: microsites/overlay.php)
  if (typeof OverlayHandler === "undefined") {
    Overlay.init();
  }

  genusoverview.initStickyNavigation();
  genusoverview.initOnePageNavigation();
  genusoverview.projectSectionsInit();
  genusoverview.initGattungsseite();
  genusoverview.showfirst();

  // Initiate ProductHeaderTooltip
  productHeaderTooltip.preinit();

  // ugly bugfix - remove asap ss
  if(/^(7210|7227|7226|7243|7244|7245|7262)$/.test(k3vars.PKM_Objekt)){
    document.getElementsByTagName('body')[0].setAttribute('data-bool-gattungsobjekt','j');
  }


  document.querySelectorAll("[data-js-check-checkboxes]").forEach((element) => {
    new CheckboxGroup(element);
  });

  document.querySelectorAll("[data-js-check-radioboxes]").forEach((element) => {
    new RadioboxGroup(element);
  });

  document.querySelectorAll("[data-js-hotspot-group]").forEach((element) => {
    const config = element.getAttribute("data-js-hotspot-group");
    if (config) {
      const configJSON = JSON.parse(config);
      new HotspotGroup(element, configJSON);
    } else {
      new HotspotGroup(element);
    }
  });

  document.querySelectorAll("[data-js-animate-rep-text]").forEach((element) => {
    new AnimationRepTextControl(element);
  });
});

setTimeout(() => {
  // Initilises modules 2.1
  document.dispatchEvent(new CustomEvent('initModule-2-1'));
}, 150);

// links mit attr. data-window werden in neuem tab geöffnet
document.addEventListener('click', (e) => {
  if (e.target.matches('a[data-window], [data-window-children] a')) {
    if (detect.breakpoint() !== 'lg') return;
    e.preventDefault();
    open(e.target.href);
  }
});

activateTooltips();

window.addEventListener('holy:resizeDone', () => {
  detect.breakpoint();
});

window.addEventListener('load', () => {
  detect.breakpoint();
});
