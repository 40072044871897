/**
 * Single item in a data table.
 * @class DataTableItemElement
 */
class DataTableItemElement extends DataTableCommon {

  /**
   * Template for DataTableItemElement.
   * @param {string[]} columns List of column ids.
   * @param {string[]} sticky List of column ids that should be sticky.
   * @param {string} numIndex Index of the item in the data table.
   * @returns {string} DOM that should be rendered into the shadow root.
   * @protected
   */
  static template(columns = [], sticky = [], numIndex = '') {
    return /*html*/ `
      <template>
        <style>
          ${super.styles}

          :host {
            display: contents;
          }

          :host(:hover) .inner {
            background-color: var(--color-contrast-1);
          }

          .table__row--expandable {
            display: none;
          }

          .table__row--expandable .table__cell {
            padding-bottom: calc(var(--cell-padding-x) * 2);
            border-top: none;
          }

          :host([expanded]) .table__row--expandable {
            display: table-row;
          }


        </style>
        <tr class="table__row" part="row" role="row" aria-index="${numIndex}">
          ${columns.map(id => {
            return /* html */`
              <td
                role="cell"
                part="col-${id} col"
                data-col="${id}"
                class="table__cell table__cell--head ${sticky?.includes(id) ? 'table__cell--sticky' : ''}"
                ${sticky?.includes(id) ? `
                  style="--sticky-cell-offset-left: var(--sticky-cell-offset-left-${id},0); --sticky-cell-offset-right: var(--sticky-cell-offset-right-${id},0)"
                  data-sticky-active="false"
                ` : ''}
              >
                <div class="inner">
                  <slot name="${id}"></slot>
                </div>
              </td>
              `
          }).join('') ?? ''}
        </tr>
        <tr class="table__row table__row--expandable">
          <td class="table__cell" colspan="${columns.length}" part="expandable">
            <slot>no content</slot>
          </td>
        </tr>
      </template>
    `;
  }

  constructor() {
    super();    
  }

  render() {
    const columns = this.dataTableElement.columns;
    const sticky = this.dataTableElement.sticky;

    const numIndex = this._calcRowIndex();

    this.shadowRoot.innerHTML = this.constructor.template(columns, sticky, numIndex);

    const template = this.shadowRoot.querySelector('template');
    const clone = template.content.cloneNode(true);
    this.shadowRoot.appendChild(clone);

    this.dataTableElement.addEventListener('main-slot-change', this._onSlotChange.bind(this));
  }

  static get observedAttributes() {
    return [
      'expanded'
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) {
      return;
    }
    this[name] = newValue;
  }

  get expanded() {
    const value = this.getAttribute('expanded');

    return value === '' || !!value;
  }

  set expanded(value) {
    if (value === '' || !!value) {
      this.setAttribute('expanded', '');
    } else {
      this.removeAttribute('expanded');
    }
  }
  
  _calcRowIndex() {
    const numIndex = Array.from(this.dataTableElement.children)
      .filter(child => child.tagName === 'DATA-TABLE-ITEM')
      .indexOf(this) + 1;

    return numIndex;
  }

  _onSlotChange () {
    console.log('slot change');
    this.shadowRoot.querySelector('[role="row"]')
      .setAttribute('aria-index', this._calcRowIndex());
  }
}


window.DataTableItemElement = DataTableItemElement;
customElements.define('data-table-item', DataTableItemElement);