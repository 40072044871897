/* Product Header Tooltip - hb 2021

  Reads data from js/global/product-header-tooltip-config.js and creates
  tooltips on the corresponding product pages

------------------------------------------------------*/

let productHeaderTooltip = new Object();

productHeaderTooltip = {
  // Data from product-header-tooltip-config.js
  tooltipData: {},
  productHeaderTooltipShown: null,
  dboTranslateContext: 'product-header-tooltip',
  phaseoutTooltipsJsonPath: '/cdn/products/phaseout-tooltips.json',
  translations: {},

  loadData: async function () {
    const phaseoutTooltipsData = await fetch(productHeaderTooltip.phaseoutTooltipsJsonPath)
      .then((response) => response.json())
      .catch((error) => console.warn(error));
    const tooltipData = phaseoutTooltipsData;
    tooltipData.general = productHeaderTooltipData;
    return tooltipData;
  },

  // Pre Initialize product header tooltip by ensuring existence of translations
  preinit: function () {
    productHeaderTooltip.loadData().then((data) => {
      productHeaderTooltip.tooltipData = data;
      if (globals?.trans && globals.trans[productHeaderTooltip.dboTranslateContext]) {
        productHeaderTooltip.init();
      } else {
        jsTranslation.getContext(
          productHeaderTooltip.dboTranslateContext,
          productHeaderTooltip.init
        );
      }
    });
  },

  // Initialize product header tooltip
  init: function () {
    const currentLanguage = k3vars.S_Sprache;
    const region = currentLanguage === 'en_us' ? 'us' : 'eu';
    const tooltipData = [
      ...productHeaderTooltip.tooltipData[region],
      ...productHeaderTooltip.tooltipData.general,
    ];

    // Set translations based on context
    productHeaderTooltip.translations = globals.trans[productHeaderTooltip.dboTranslateContext];

    // Get distinct values of originalIds
    const pkmObjectIds = tooltipData.map((item) => item.pkmObjectId);

    // Currently selected pkmObjectId
    const currentPkmObjectId = +k3vars.PKM_Objekt;

    productHeaderTooltip.productHeaderTooltipShown = window.toolkit.getLocalStorageObject('productHeaderTooltipShown');
    if (productHeaderTooltip.productHeaderTooltipShown === null) {
      productHeaderTooltip.productHeaderTooltipShown = [];
    }

    if (!pkmObjectIds.includes(currentPkmObjectId)) {
      return;
    }

    // Create tooltip and insert after headline
    productHeaderTooltip.insertcode(currentPkmObjectId);

    // Init tooltip
    $(`#product-header-tooltip-${currentPkmObjectId}`).tooltip();

    // Event Listener: Click underlined product header tooltip span
    // Show the ok button when tooltip is toggled
    document
      .querySelector(`#product-header-tooltip-${currentPkmObjectId}`)
      .addEventListener('click', function () {
        // Check if button already exists
        const button = document.getElementById(`product-header-tooltip-${currentPkmObjectId}-ok`);
        if (!button) {
          productHeaderTooltip.addTooltipButton(currentPkmObjectId);
        }
      });

    // Check if the tooltip has already been shown
    if (!productHeaderTooltip.productHeaderTooltipShown.includes(currentPkmObjectId)) {
      // Show tooltip
      $(`#product-header-tooltip-${currentPkmObjectId}`).tooltip('show');
      // Add OK button
      productHeaderTooltip.addTooltipButton(currentPkmObjectId);
    }
  },

  // Add the OK button to the tooltip
  addTooltipButton: function (pkmObjectId) {
    const okButton = document.createElement('button');
    const okButtonText = globals.trans[productHeaderTooltip.dboTranslateContext]['help_label_skip'];

    okButton.classList.add('btn', 'btn-primary');
    okButton.id = `product-header-tooltip-${pkmObjectId}-ok`;
    okButton.innerText = okButtonText;

    okButton.onclick = function () {
      // Check if the tooltip was already shown (automatically) for this product
      if (!productHeaderTooltip.productHeaderTooltipShown.includes(pkmObjectId)) {
        productHeaderTooltip.productHeaderTooltipShown.push(pkmObjectId);
        window.toolkit.setLocalStorageObject('productHeaderTooltipShown', productHeaderTooltip.productHeaderTooltipShown, 180 * 24 * 60); // expires in 180 days
      }

      $(`#product-header-tooltip-${pkmObjectId}`).tooltip('hide');
    };

    // Append button to tooltip
    const phaseOutTooltip = document.querySelector('[id^="product-header-tooltip"]');
    const phaseOutTooltipInner = phaseOutTooltip.nextSibling.querySelector('.tooltip-inner');
    phaseOutTooltipInner.appendChild(okButton);
    phaseOutTooltipInner.classList.add('-margin-left-xs');
  },

  // Get translation of config item property
  getConfigItemTranslation: function (property, currentLanguage = null) {
    // first look for a translation inside the property itself
    if (currentLanguage && property[currentLanguage]) {
      return property[currentLanguage];
    }
    // fallback, if no translation identifier is set
    if (!property.translationIdentifier) {
      return property.default;
    }
    // look for a translation inside the translations object
    const contentTranslation = productHeaderTooltip.translations[property.translationIdentifier];
    // return translation, if possible, use default otherwise
    return contentTranslation ? contentTranslation : property.default;
  },

  // Create tooltip content and prepend to headline
  insertcode: function (pkmObjectId) {
    const currentLanguage = k3vars.S_Sprache;
    const tooltipPosition = 'bottom';
    let productHeaderTooltipItem = productHeaderTooltip.tooltipData.general.find(
      (item) => item.pkmObjectId === pkmObjectId
    );
    if (productHeaderTooltipItem === undefined) {
      const region = currentLanguage === 'en_us' ? 'us' : 'eu';
      productHeaderTooltipItem = productHeaderTooltip.tooltipData[region].find(
        (item) => item.pkmObjectId === pkmObjectId
      );
    }

    // Configure Tooltip Title
    let title = productHeaderTooltip.getConfigItemTranslation(productHeaderTooltipItem.title);

    // Configure Tooltip Content
    let content = '';

    switch (productHeaderTooltipItem.type) {
      // DEFAULT tooltip content
      case ProductHeaderTooltipType.DEFAULT:
        content = productHeaderTooltip.getConfigItemTranslation(productHeaderTooltipItem.content);

        content = content.replace('%date%', productHeaderTooltipItem.title.date);
        break;

      // PHASEOUT tooltip content
      case ProductHeaderTooltipType.PHASEOUT:
        let alternativeText = '';

        if (productHeaderTooltipItem.phaseOutData.alternative) {
          const { alternative } = productHeaderTooltipItem.phaseOutData;
          alternativeText = productHeaderTooltip.translations['phase_out_alternative_text'];

          let alternativeName = productHeaderTooltip.getConfigItemTranslation(
            alternative.name,
            currentLanguage
          );

          alternativeLinkValue = alternative.link + currentLanguage + '/';

          const alternativeLinkHtml = `<a href=&quot;${alternativeLinkValue}&quot;>${alternativeName}</a>`;

          alternativeText = alternativeText.replace('%link%', alternativeLinkHtml);
        }
        content = productHeaderTooltip.translations['phase_out_text'];
        content = content.replace('%date%', productHeaderTooltipItem.phaseOutData.date);
        content = content.replace('%alternative%', alternativeText);
        break;

      default:
        break;
    }

    /* Create Tooltip HTML based on aggregated data */
    const tooltipElement = document.createElement('div');
    tooltipElement.setAttribute('data-js-close-tooltip', '');
    tooltipElement.classList.add('pull-left', 'product-header-tooltip');
    tooltipElement.innerHTML = `
      <span
        id="product-header-tooltip-${pkmObjectId}"
        class="tool-tip trans100 text-underlined"
        data-placement="${tooltipPosition}"
        data-html="true"
        data-trigger="click"
        data-tooltip=""
        data-toggle="tooltip"
        data-original-title="
          <div class='content-box'>
            <p class='text -smallfont'>
              ${content}
            </p>
          </div>
        "
      >
        ${title}
      </span>
    `;

    const [headline] = document.getElementsByClassName('headline');
    headline.parentNode.insertBefore(tooltipElement, headline.nextSibling);
    headline.classList.add('-full-width');
  },
};
