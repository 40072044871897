/**
 * Deferred item definition
 * @typedef  {Object}   ThirdPartyDeferred
 * @property {string}   thirdPartyName Deferred action name
 * @property {Function} activator      Function called on enabled cookies
 * @property {Function} [init]         Function called initially if cookies are not enabled
 * @property {boolean}  [done]         Indicator if an activator was already called;
 *                                     optional because it is only needed and handled internally
 */


/**
 * The ThirdParty object is declared and defined globally so we are able to reference it from anywhere.
 *
 * Because of some third-party code being injected and loaded asynchronously and the requirement for
 * the user to be able to activate all available external media on a site globally, we need a mechanism
 * to support those async actions by deferring them.
 *
 * The are two ways of deferring an action:
 *   1. Pushing into the ThirdParty.deferred array if the ThirdParty object does *not* exist globally
 *   2. Calling the defer function if the ThirdParty object exists in its original and complete form globally
 *
 * Both ways of deferring an action require the passing of three parameters:
 *   {string}   thirdPartyName  Name of an action or area from where an action is triggered;
 *                              exists to be on the safe side for future usage
 *   {Function} init            Function called during the ThirdParty initialization process;
 *                              always called if cookies are not enabled globally
 *   {Function} activator       Function called when cookies are enabled or later if a user activates all
 *                              cookies globally
 *
 * The init and activator functions are both called with the ThirdParty object reference as their first parameter.
 * This allows for calling specially prepared and existing init and activation functions for the desired
 * services like YT and GMaps.
 *
 *
 * Example usage (YT video):
 *
 *  <script>
 *    (function(w) {
 *      const videoId = '<video-id>';
 *
 *      // We need a reference to the target element;
 *      //  here we query the element through a data-attribute with a unique value.
 *      //  In this case we use the video id as a unique value.
 *      //  One has to keep in mind, that the value really has to be unique page-wide.
 *      // In cases where the same video is used multiple time on a page, the unique value should be
 *      //  generated (for example with `uniqid()`) and used as the data-attribute value and for the
 *      //  attribute-selector (see next line for the latter one).
 *      const targetEl = document.querySelector('[data-yt-embed-target="' + videoId + '"]');
 *
 *      // We need to cover the case, where the ThirdParty object does not currently exist.
 *      // For that we reuse an existing instance or create a minimal version of the instance with
 *      //  `deferred` as the only crucial properties needed to hold one or more actions.
 *      w.ThirdParty = w.ThirdParty || { deferred: [] };
 *
 *      // Declaring and defining the three needed action parameters / values
 *
 *      const name = 'YouTube';
 *      const init = function(TPInstance) {
 *        // Calling a provided YT-init method to create a notice / placeholder block in a given
 *        //  target element; also passing basic styling instructions
 *        TPInstance.youtube.init(targetEl, videoId, [TPInstance.style.HIDE_BORDER]);
 *      };
 *      const activator = function(TPInstance) {
 *        // Calling a provided YT-iFrame-init method to finally inject and embed a YT-Video throug
 *        //  an iframe element
 *        TPInstance.youtube.initIFrame(targetEl, videoId, {
 *          width: 560,
 *          height: 315,
 *          frameborder: 0,
 *          allowfullscreen: true,
 *        });
 *      };
 *
 *      // By checking for `ThirdParty.defer` we can be assured, that the ThirdParty object exists
 *      //  globally in its original and complete form. So we can call the defer-function with the
 *      //  three action parameters.
 *      if (w.ThirdParty.defer) {
 *        w.ThirdParty.defer(name, init, activator);
 *      } else {
 *       // Alternatively we push into the `ThirdParty.deferred` array. The array is then
 *       //  automatically processed by the original and complete form of the ThirdParty instance,
 *       //  when it takes over and its initialization routine is started.
 *        w.ThirdParty.deferred.push({
 *          thirdPartyName: name,
 *          init: init,
 *          activator: activator,
 *        });
 *      }
 *    }(window));
 *  </script>
 *
 * @type     {Object}
 * @property {boolean} initialized Holds the init status
 * @property {boolean} activated   Holds the activation status (true if all deferred actions were processed)
 * @property {Object}  texts       Holds the all needed translated texts
 * @property {Array}   deferred    Array with all deferred actions (name, init- and activator-function)
 * @property {Object}  style       Object containing properties used as "constants" to control some
 *                                 styling aspects
 */
ThirdParty = {
  initialized: false,
  activated: false,
  texts: {},
  /* Taking previously manually deferred actions into consideration or initialize with an empty array */
  deferred: (window.ThirdParty && window.ThirdParty.deferred) || [],

  /* "const" values for styling purposes */
  style: {
    STYLE_THEME_DARK: 'STYLE_THEME_DARK',
    ALIGN_VERTICAL_CENTER: 'ALIGN_VERTICAL_CENTER',
    HIDE_BORDER: 'HIDE_BORDER',
  },

  /**
   * Defer the 'addition' and 'running' of third-party logic
   *
   * @param  {string}   name      Third-party service name with support for additional data transportation (colon separated)
   * @param  {Function} init      Function to be called for initialization (preparation, output of information and notice)
   * @param  {Function} activator Function to be called when a third-party service logic should be triggered / started
   *
   * @return {void}
   */
  defer(name, init, activator) {
    /** @type {ThirdPartyDeferred} */
    const deferredItem = {
      thirdPartyName: name,
      activator: activator,
      init: init,
      done: false,
    };

    this.deferred.push(deferredItem);

    if (this.activated) {
      this.processDeferred(deferredItem);
    } else {
      this.processInit(deferredItem);
    }
  },

  /**
   * Process all init functions of all deferred third-party service usages
   *
   * @return {void}
   */
  processAllInit() {
    if (this.isEnabled()) return;

    const processInit = this.processInit.bind(this);
    this.deferred.forEach(processInit);
  },

  /**
   * Process the initialization of a single deferred item
   *
   * @param  {ThirdPartyDeferred} deferredItem [description]
   * @return {void}
   */
  processInit(deferredItem) {
    if (typeof deferredItem.init === 'function') {
      deferredItem.init(this);
    }
  },

  /**
   * Process all deferred and non-processed (pending) third-party service usages
   *
   * @return {void}
   */
  processAllDeferred() {
    const deferredItems = this.deferred.filter(
      (deferredItems) => !deferredItems.done,
    );

    const processDeferred = this.processDeferred.bind(this);
    deferredItems.forEach(processDeferred);
  },

  /**
   * Process a given deferred item
   *
   * @param  {ThirdPartyDeferred} deferredItem [description]
   * @return {void}
   */
  processDeferred(deferredItem) {
    if (deferredItem.activator) {
      /* Here would be the best place to evaluate the deferredItem-name */
      deferredItem.activator(this);

      /* Mark as done */
      deferredItem.done = true;
    }
  },

  /**
   * Fetch some needed texts
   *
   * @param  {Function} callback Callback called when done
   * @return {void}
   */
  fetchTranslations(callback) {
    const lang = k3vars.S_Sprache || 'en';

    fetch(confEnv['pdb_api_url'] + '/productdata/translate/' + lang + '/context/browsercomp/layout/json')
      .then((resp) => resp.json())
      .then((data) => {
        this.texts = data;

        callback.call(this);
      });
  },

  /**
   * Return a freshly created placeholder element with custom headline and body-text
   *
   * @param  {string}  headlineText   Headline for the placeholder
   * @param  {string}  bodyText       Body text for the placeholder
   * @param  {Array}   [styleParams]  Params to control style
   * @return {HTMLElement}            Reference of the freshly created placeholder element
   */
  getInitPlaceholderElement(headlineText, bodyText, styleParams) {
    const cleanStyleParams = styleParams || [];

    const styleEl = document.createElement('div');
    styleEl.style.height = '100%';
    styleEl.setAttribute('data-scoped-css-v3', '');

    let className = 'theme-light';

    if (cleanStyleParams.includes(ThirdParty.style.STYLE_THEME_DARK)) {
      className = 'theme-dark';
    }

    styleEl.classList.add(className);


    const baseClassName = 'third-party__placeholder';

    const placeholderEl = document.createElement('div');
    placeholderEl.classList.add(baseClassName);



    if (!cleanStyleParams.includes(ThirdParty.style.HIDE_BORDER)) {
      placeholderEl.classList.add('b-d',  'p-5');
    }

    const placeholderHolderEl = document.createElement('div');
    placeholderHolderEl.classList.add(baseClassName + '-holder');

    const headlineTextEl = document.createElement('h4');
    headlineTextEl.classList.add(baseClassName + '-headline');
    headlineTextEl.classList.add('heading-font-3');
    headlineTextEl.innerHTML = headlineText;

    const bodyTextEl = document.createElement('p');
    bodyTextEl.classList.add(baseClassName + '-text', 'p-t-2');
    bodyTextEl.innerHTML = bodyText;

    const activateCookiesBtnEl = document.createElement('button');
    activateCookiesBtnEl.classList.add(baseClassName + '-btn', 'button', 'button--primary', 'button--medium', 'm-t-5');
    activateCookiesBtnEl.textContent = this.texts['third_party_activate_cookies'] || '';
    activateCookiesBtnEl.addEventListener('click', () => CookieMessage.allowCookies());

    styleEl.appendChild(placeholderEl);
    placeholderEl.appendChild(placeholderHolderEl);
    placeholderHolderEl.appendChild(headlineTextEl);
    placeholderHolderEl.appendChild(bodyTextEl);
    placeholderHolderEl.appendChild(activateCookiesBtnEl);

    return styleEl;
  },

  /**
   * Check for a previously accepted cookie banner
   *
   * @return {Boolean} Cookie banner was previously accepted
   */
  isEnabled() {
    /* Check for an existing cookie message object in local storage,
        as an indicator for an accepted cookie banner */
    return CookieMessage.cookiesAreEnabled();
  },

  /**
   * Initialize all init functions
   *
   * @return {void}
   */
  init() {
    if (this.initialized) return;

    this.fetchTranslations(() => {
      if (this.activated) return;

      this.processAllInit();
      this.initialized = true;
    });
  },

  /**
   * Start the activation of third-party services
   *
   * @return {void}
   */
  activate() {
    this.processAllDeferred();
    this.activated = true;
  },

  /* namespaced methods for the youtube context */
  youtube: {
    /**
     * Initialize a hint block with the possibility to activate cookies
     *
     * @param  {HTMLElement} targetEl       Destination element reference
     * @param  {string}.     [videoId]      Id of video to be embedded
     * @param  {Array}       [styleParams]  Params to control style
     *
     * @return {void}
     */
    init(targetEl, videoId, styleParams) {
      if (targetEl === null) return;

      const dataProtectionUrl = confEnv['www_url'] + '/' + ThirdParty.texts['url_data_protection_declaration'];
      const errorMessage = ThirdParty.texts['third_party_embedded_youtube_error_message'].replace('%s', dataProtectionUrl);

      const placeholderEl = ThirdParty.getInitPlaceholderElement(
        ThirdParty.texts['third_party_embedded_youtube_error_message_headline'],
        errorMessage,
        styleParams,
      );
      targetEl.innerHTML = '';
      targetEl.appendChild(placeholderEl);
    },

    /**
     * Initialize a YouTube iFrame in a given taget element with given parameters
     *
     * @param  {HTMLElement} targetEl    Destination element reference
     * @param  {string} videoId          Id of video to be embedded
     * @param  {Object} iFrameParams     Object with attribute values to be set on the iFrame element
     * @param  {Object} [ytQueryParams]  Object with parameters to be set as query parameters
     *
     * @return {void}
     */
    initIFrame(targetEl, videoId, iFrameParams, ytQueryParams) {
      if (targetEl === null) return;

      const youTubeNoCookieUrl = '//www.youtube-nocookie.com/embed/';
      const iFrameEl = document.createElement('iframe');

      const queryParamsStr = Object.keys(ytQueryParams || {}).map((key) => {
        const value = encodeURIComponent(ytQueryParams[key]);
        return key + '=' + value;
      }).join('&');

      const url = youTubeNoCookieUrl + videoId + (queryParamsStr.length > 0 ? '?' + queryParamsStr : '');

      iFrameEl.src = url;

      Object.keys(iFrameParams).forEach((paramName) => iFrameEl.setAttribute(paramName, iFrameParams[paramName]));

      targetEl.innerHTML = '';

      targetEl.appendChild(iFrameEl);
    },
  },

  /* namespaced methods for the google maps context */
  gmaps: {
    config: {
      key: 'AIzaSyDjRsKmzDVDZpVOsOFmcHddHJrKPrrRQw0',
    },

    /* default init and iframe methods to be called on normal maps */
    default: {
      /**
       * Initialize a hint block with the possibility to activate cookies
       *
       * @param  {HTMLElement} targetEl  Destination element reference
       * @param  {Array}       [styleParams]  Params to control style
       *
       * @return {void}
       */
      init(targetEl, styleParams) {
        if (targetEl === null) return;
        
        const dataProtectionUrl = confEnv['www_url'] + '/' + ThirdParty.texts['url_data_protection_declaration'];
        const errorMessage = ThirdParty.texts['third_party_embedded_gmaps_error_message'].replace('%s', dataProtectionUrl);

        const placeholderEl = ThirdParty.getInitPlaceholderElement(
          ThirdParty.texts['third_party_embedded_gmaps_error_message_headline'],
          errorMessage,
          styleParams,
        );
        targetEl.innerHTML = '';
        targetEl.appendChild(placeholderEl);
      },

      /**
       * Initialize the Google-Maps for a given Element
       *
       * We need to trigger the existing map init code on a successfull google maps script load
       *
       * @param  {HTMLElement} targetEl Destination element reference
       *
       * @return {void}
       */
      initMap(targetEl) {
        const key = ThirdParty.gmaps.config.key;

        const headEl = document.getElementsByTagName('head')[0];
        const gmapsScriptEl = document.createElement('script');

        gmapsScriptEl.addEventListener('load', () => {
          targetEl.innerHTML = '';
          maps.init(); /* See 'global/erco.js' for more */
        });

        gmapsScriptEl.async = 1;
        gmapsScriptEl.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&v=3.exp';
        headEl.appendChild(gmapsScriptEl);
      },
    },

    /* special init and iframe methods to be called for the map on the sales contact page */
    salesContact: {
      /**
       * Initialize a hint block with the possibility to activate cookies
       *
       * Reusing the default init function
       *
       * @param  {HTMLElement} targetEl  Destination element reference
       * @param  {Array}       [styleParams]  Params to control style
       *
       * @return {void}
       */
      init(targetEl, styleParams) {
        ThirdParty.gmaps.default.init(targetEl, styleParams);
      },

      /**
       * Initialize the Google-Maps for a given Element based on a given API key
       *
       * @param  {HTMLElement} targetEl  Destination element reference
       *
       * @return {void}
       */
      initMap(targetEl) {
        const key = ThirdParty.gmaps.config.key;

        const headEl = document.getElementsByTagName('head')[0];
        const gmapsScriptEl = document.createElement('script');

        gmapsScriptEl.addEventListener('load', () => {
          targetEl.innerHTML = '';
          contact_map.checkIEVersion();
          contact_map.init();
        });

        gmapsScriptEl.async = 1;
        gmapsScriptEl.src = 'https://maps.googleapis.com/maps/api/js?key=' + key;
        headEl.appendChild(gmapsScriptEl);
      },
    },
  },

};

/* trigger the initialization */
ThirdParty.init();
