/* Holy Snap Helper
============================================================*/
var holySnapHelper = (function () {
	
	// variablen
	var exports  = {};
	var store    = {};
	
	// funktionen
	var init = function () {
		store.container = window;
	};
	
	
	var startAnimation = function (value, duration, callback) {
		window.scrollBy({
			top: value,
			behavior: 'smooth'
		});
	};
	
	// funktionen veröffentlichen
	exports.init           = init;
	exports.startAnimation = startAnimation;
	
	
	// rückgabe
	return exports;
})();


/* Holy Snap
============================================================*/
var holySnap = (function () {
	
	// variablen
	var helper   = holySnapHelper;
	var exports  = {};
	var store    = {};
	var settings = {
		stop: true,
		debounce: 2
	};
	
	// funktionen
	var init = function (customSettings) {
		
		// merge Settings
		for (var key in customSettings) { settings[key] = customSettings[key]; }
		
		// initiale Werte festlegen
		store = {
			snaps: document.querySelectorAll('.js-holy-snap'),
			wheel: 0,
			offset: getOffset(),
			animating: false,
			scrolling: false,
			direction: undefined,
			scrollTimer: undefined,
			lock: undefined,
			step: -1,
			stepDelta: 0
		};
		
		// keine snappoints = kein holysnapper
		if (store.snaps.length === 0) return;
		
		// helper initialisieren
		helper.init();
		
		// eventlistener
		window.addEventListener('resize', handleResize);
		window.addEventListener('wheel' , handleWheel);
	}
	
	var evaluateWheel = function (currentWheel) {
		
		// delta und richtung
		var currentDirection = currentWheel < 0 ? 'up' : 'down';
		
		// richtungsänderung oder beschleunigung
		if (currentDirection !== store.direction || Math.abs(currentWheel) > Math.abs(store.wheel)) {
			store.scrolling = false;
		}
		
		// scroll starten
		if (!store.scrolling) {
			store.scrolling = true;
			store.animating = false;
			store.lock      = undefined;
			store.step      = -1;
		}
		
		// kontinuierliches scrollen messen
		clearTimeout(store.scrollTimer);
		store.scrollTimer = setTimeout(function () {
			store.scrolling = false;
		}, 50);
		
		// Richtung speichern
		store.direction = currentDirection;
		store.wheel     = currentWheel;
		store.step      = (store.step + 1) % settings.debounce;
	};
	
	
	var executeWheel = function () {
		
		// abbrechen falls animation aktiv
		if (store.animating) return; 
		
		// wenn wir das scroll event debouncen müssen wir das delta speichern
		// store.stepDelta += store.wheel;  
		
		// alle snaps checken
		if (store.step === 0) for (var i = 0, l = store.snaps.length; i < l; i++) {
		
			// position des aktuellen elements ermitteln
			var top            = Math.round(store.snaps[i].getBoundingClientRect().top);

			var lockPosition   = false;
			var durationFactor = 1;
			
			
			if (store.snaps[i].classList.contains('js-holy-snap-stop-down') && store.direction === 'down') {
				lockPosition = (top > 0 && top <= store.offset / 4); // store.stepDelta);
				durationFactor = 0.5;
			} else if (store.snaps[i].classList.contains('js-holy-snap-stop-up') && store.direction === 'up') {
				lockPosition = (top < 0 && top >= -store.offset / 4); // store.stepDelta);
				durationFactor = 0.5;
			} else {
				lockPosition = (store.direction === 'down' && top <  store.offset && top > 0) || (store.direction === 'up'   && top > -store.offset && top < 0)
			}
			
			// step delta nullen
			// store.stepDelta = 0;
		
			// lock überprüfen
			if (i === store.lock && (top >= store.offset / 2 || top <= -store.offset / 2)) {
				store.lock =  undefined;
				continue;
			}
			
			// scrollposition überprüfen
			if (lockPosition) {
				
				// store anpassen
				store.animating = true;
				store.lock      = i;
				
				// animationsdauer berechnen
				var duration = Math.min(15000 / Math.abs(store.wheel), 750) * durationFactor; 
				
				// animation starten
				helper.startAnimation(top, duration, function () {
					
					// stopp: ja oder nein?
					if (!settings.stop) {
						store.animating = false;
						store.lock      = undefined;
					}
				});
					
				// und beenden
				return;
			}
		}
	
		// ansonsten: seite scrollen
		window.scrollBy(0, store.wheel);
	}

	
	
	var getOffset = function () {
		return window.innerHeight;
	}
	
	var getStore = function () {
		return store;
	}
	
	var handleResize = function () {
		store.offset = getOffset();
	}
	
	var handleWheel = function (e) {
		
		// verhalten verhindern
		e.preventDefault();
		e.stopPropagation();
		
		evaluateWheel(e.deltaY);
		executeWheel();
	};
	
	// funktionen veröffentlichen
	exports.init     = init;
	exports.getStore = getStore;
	
	// Rückgabe
	return exports;
})();
