/**
 * Overlay Container
 * 
 * @dependencies
 * * jquery
 * * holy
 * 
 * @dependents
 * * isoTools.change (global/isotope_tools.js)
 * * .overlay-container_ class is present in several files in pdb-api
 * 
 * @commit Initial commit 2.0
 */

var overlay_container = (function () {
  const exports = {};
  let $container = {};

  function onResize() {
    if ($container.length === 0) return;

    $container.removeClass('_hover');

    $container.each(function () {
      var self = $(this);
      var innr = self.find('._inner');

      self.css({ maxHeight: 'none !important' }).css({ maxHeight: self.height() + 'px' });

      if (innr.length)
        innr.css({ maxHeight: 'none !important' }).css({ maxHeight: innr.get(0).scrollHeight + 'px' });
    });
  }

  var init = function () {
    $container = $('.overlay-container_');
    var timer;

    $container.each(function () {
      var self = $(this);
      var innr = self.find('._text');
      var head = self.find('._head');

      if (self.hasClass('_init')) return;

      self.addClass('_init');
      innr.wrap('<div class="_inner">');
      head.clone().addClass('_spacer').appendTo(self);
    });

    window.removeEventListener('holy:resizeDone', onResize);
    window.addEventListener('holy:resizeDone', onResize);
    onResize();

    $(window).off('holy:scrollStart.overlay').on('holy:scrollStart.overlay', function () {
      $container.addClass('_disable-scroll');
    });

    $(window).off('holy:scrollDone.overlay').on('holy:scrollDone.overlay', function () {
      $container.removeClass('_disable-scroll');
    });

    $container.off('click.overlay').on('click.overlay', function (e) {
      var prop = window.getComputedStyle(this, ':before').getPropertyValue('content');
      var self = $(this);
      var mob = 'ontouchstart' in window;

      if (!mob) return;
      if (prop.indexOf('disabled') !== -1) return;
      if (self.hasClass('_hover')) return;

      $container.removeClass('_hover _scrollable');
      self.addClass('_hover');

      clearTimeout(timer);
      timer = setTimeout(function () {
        self.addClass('_scrollable');
      }, 500);


      e.preventDefault();
      e.stopPropagation();
    });

    $container.off('mouseenter.overlay').on('mouseenter.overlay', function (e) {
      var self = $(this);

      self.addClass('_hover');

      clearTimeout(timer);
      timer = setTimeout(function () {
        self.addClass('_scrollable');
      }, 300);
    });

    $container.off('mouseleave.overlay').on('mouseleave.overlay', function (e) {
      $(this).removeClass('_hover _scrollable');
      clearTimeout(timer);
    });
  };

  var deinit = function () {
    const $initializedContainer = $('.overlay-container_._init');

    $initializedContainer.each(function () {
      var self = $(this);
      var innr = self.find('._inner');
      var spcr = self.find('._spacer');

      self.removeClass('_init');
      self.css({ maxHeight: '' });
      innr.children().unwrap();
      spcr.remove();
    });

    window.removeEventListener('holy:resizeDone', onResize);
    $(window).off('holy:scrollStart.overlay');
    $(window).off('holy:scrollDone.overlay');

    $initializedContainer.off('click.overlay');
    $initializedContainer.off('mouseenter.overlay');
    $initializedContainer.off('mouseleave.overlay');
  };

  var deferred_init = function () {
    setTimeout(init, 0);
  };

  // veröffentlichen
  exports.init = init;
  exports.deinit = deinit;

  // Overlays initialisieren
  $(window).on('load.overlay', init);

  return exports;
})();