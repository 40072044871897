/*
 * Single-Checkbox - JP 21.03.2021
 *
 * Used in:
 *   - ContentAccess
 *
 * * * * * * * * * * * * * * */

class SingleCheckboxSet {
  /**
   * @param  {Array<HTMLElement>}     elements  List of single checkbox elements
   * @param  {'exclusive'|'multiple'} behaviour Checkbox click behaviour
   */
  constructor(elements, behaviour) {
    this.elements = elements;
    this.behaviour = behaviour;
    this.activeClass = '-active';
    this.inputSelector = 'input[type=checkbox]';
    this.listeners = [];
    this.activeCheckboxes = elements.filter(element => !!element.querySelector(this.inputSelector + ':checked'));

    this.activeCheckboxes.forEach(el => el.classList.add(this.activeClass));

    this.elements.forEach((el) => {
      el.addEventListener('click', this.handleCheckboxClick.bind(this));
    });

  }

  /**
   * Click listener for checkboxes
   *
   * @param  {MouseEvent} evt Event occuring as part of a click on a checkbox
   * @return void
   */
  handleCheckboxClick(evt) {
    let currentTarget = evt.currentTarget;

    if (this.behaviour === 'exclusive') {
      this.resetAllCheckboxes();
      this.activateCheckbox(currentTarget);
    } else {
      currentTarget.classList.toggle(this.activeClass);
    }

    this.triggerChangeListeners(currentTarget, this.checkboxIsActive(currentTarget));
  }

  /**
   * Deactivate all checkboxes
   *
   * @return void
   */
  resetAllCheckboxes() {
    this.elements.forEach((el) => {
      el.classList.remove(this.activeClass);
      el.querySelector(this.inputSelector).checked = false;
    });
  }

  /**
   * Activate a checkbox
   *
   * @param  {HTMLElement} el Element representing the checkbox to be activated
   * @return void
   */
  activateCheckbox(el) {
    el.classList.add(this.activeClass);
    el.querySelector(this.inputSelector).checked = true;
  }

  /**
   * Deactivate a checkbox
   *
   * @param  {HTMLElement} el Element representing the checkbox to be activated
   * @return void
   */
  deactivateCheckbox(el) {
    el.classList.remove(this.activeClass);
    el.querySelector(this.inputSelector).checked = false;
  }

  /**
   * Checking if a checkbox element is activated / checked
   *
   * @param  {HTMLElement} el Element representing the checkbox for which the active status is checked
   * @return void
   */
  checkboxIsActive(el) {
    return el.classList.contains(this.activeClass) && el.querySelector(this.inputSelector).checked;
  }

  /**
   * Toggle the activation state of a checkbox
   *
   * @param  {HTMLElement} el Element representing the checkbox, for which the state is to be toggled
   * @return void
   */
  toggleCheckbox(el) {
    if (this.checkboxIsActive(el)) {
      this.deactivateCheckbox(el);
    } else {
      this.activateCheckbox(el);
    }
  }

  /**
   * Register a listener function
   *
   * @param {(el?: HTMLElement, checked?: boolean) => void} listener Listener function to be registered
   * @return void
   */
  addChangeListener(listener) {
    if (typeof listener != 'function') {
     throw new Error('Listener is not a function!');
    }

    this.listeners.push(listener);
    return () => { this.removeChangeListener(listener); return listener; }
  }

  /**
   * Trigger all registered change listeners with the target element an its checked status
   *
   * @param  {HTMLElement} el      Single checkbox element
   * @param  {boolean}     checked Current checked status
   * @return void
   */
  triggerChangeListeners(el, checked) {
    this.listeners.forEach((listener) => listener(el, checked));
  }

  /**
   * Remove a bound listener
   *
   * @param  {(el?: HTMLElement, checked?: boolean) => void} listener Listener function to be removed
   * @return void
   */
  removeChangeListener(listener) {
    this.listeners = this.listeners.filter((currListener) => currListener !== listener);
  }

  /**
   * Return a list of all bound checkboxes with their current checked status
   *
   * @return {Array<{ el: HTMLElement, checked: boolean }>} List of checkboxes with their checked status
   */
  getAllCheckboxesWithStatus() {
    return this.elements.map((el) => ({ el: el, checked: el.querySelector('input[type=checkbox]:checked').checked }));
  }
}