/* Class for managing Flickity slideshows. */
class FlickitySlideshow {

  /** Creates a FlickitySlideshow.
   * @param {HTMLElement} slideShowElement - The DOM element representing the slideshow.
   */
  constructor(slideShowElement) {
    this.flickitySlideShow = {};
    this.slideShowElement = slideShowElement;

    if (!this.slideShowElement.children.length) {
      return;
    }

    this.slideshowConfig = this.slideShowElement.getAttribute('data-js-flickity');
    this.slideShowConfigJSON = this.slideshowConfig ? JSON.parse(this.slideshowConfig) : {};
    this.slideShowConfigJSON.arrowShape = { 
      x0: 25,
      x1: 55, y1: 30,
      x2: 65, y2: 25,
      x3: 40
    };

    this.buttonPrevNextContainer = this.slideshowConfig ? this.slideShowConfigJSON.buttonPrevNextContainer : false;
    this.flickitySlideShow = new Flickity(this.slideShowElement, this.slideShowConfigJSON);
    this.flickitySlides = this.flickitySlideShow.cells;
    this.numberOfCellsInViewport = 0;
    this.numberOfCellsOutsideViewport = 0;
    
    if (this.slideShowConfigJSON.countCellsOnContainerWidth) {
      setTimeout(() => {
        this.setupNavigation();
        this.setupResizeListener()
      }, 0);
      if (this.slideShowConfigJSON.wrapAround === false) {
        this.flickitySlideShow.on('select', this.onSelect);
      }
    }

    this.flickitySlideShow.prevButton.element.classList.add('slide-show__nav-button');
    this.flickitySlideShow.nextButton.element.classList.add('slide-show__nav-button');

    // move prev next buttons to the container configured in the config
    if (this.buttonPrevNextContainer) {
      const buttonContainer = document.querySelector(this.buttonPrevNextContainer);
      if (buttonContainer) { 
        buttonContainer.appendChild(this.flickitySlideShow.prevButton.element);
        buttonContainer.appendChild(this.flickitySlideShow.nextButton.element);  
      }
    }

    setTimeout(() => {
      this.flickitySlideShow.reposition();
      this.flickitySlideShow.resize();
      this.flickitySlideShow.element.setAttribute('data-flickity-initialized', 'true');
      }
    , 2500)
  }

  setupNavigation = () => {
    const computedStyleContainer = window.getComputedStyle(this.slideShowElement);
    const containerWidth = parseFloat(computedStyleContainer.width);
    const computedStyleCell = window.getComputedStyle(this.flickitySlides[0].element);
    const cellWidth = parseFloat(computedStyleCell.width);
    this.numberOfCellsInViewport = Math.floor(containerWidth / cellWidth);
    this.numberOfCellsOutsideViewport = this.flickitySlideShow.cells.length - this.numberOfCellsInViewport;
    if (this.numberOfCellsOutsideViewport <= 0 ) {
      this.deactivateFlickity();
    } else {
      this.activateFlickity();
    }
  }

  deactivateFlickity = () => {
    //hide next and prev buttons
    this.flickitySlideShow.prevButton.element.style.visibility = 'hidden';
    this.flickitySlideShow.nextButton.element.style.visibility = 'hidden';
    this.flickitySlideShow.options.draggable = false;
    this.flickitySlideShow.updateDraggable();

  }

  activateFlickity = () => {
    //show next and prev buttons
    this.flickitySlideShow.prevButton.element.style.visibility = 'visible';
    this.flickitySlideShow.nextButton.element.style.visibility = 'visible';
    this.flickitySlideShow.options.draggable = true;
    this.flickitySlideShow.updateDraggable();
  }

  onSelect = () => {
    if (this.flickitySlideShow.selectedIndex >= this.numberOfCellsOutsideViewport) {
      this.flickitySlideShow.nextButton.isEnabled = false;
      this.flickitySlideShow.nextButton.element.setAttribute('disabled', 'disabled');
    } else {
      this.flickitySlideShow.nextButton.isEnabled = true;
      this.flickitySlideShow.nextButton.element.removeAttribute('disabled');
    }
  }

  setupResizeListener() {
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {

        // remove the flickity-initialized attribute to remove the 100% height of the content
        this.flickitySlideShow.element.removeAttribute('data-flickity-initialized');

        this.setupNavigation();

        // height of the viewport is set to null to allow the height to be calculated based on the content
        this.flickitySlideShow.element.querySelector('.flickity-viewport').style.height = null;

        // reposition and resize the slideshow
        this.flickitySlideShow.resize();
        this.flickitySlideShow.reposition();

        // add the flickity-initialized attribute to set the height of the content to 100%
        this.flickitySlideShow.element.setAttribute('data-flickity-initialized', 'true');
     }, 250);
    });
  }

  static initSlideShows() {
    const slideShows = document.querySelectorAll('[data-js-flickity]');
    slideShows.forEach(slideShow => {

      // check if flickity is already initialized
      if (slideShow.classList.contains('flickity-enabled')) { 
        return false;
      }
      new FlickitySlideshow(slideShow);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  FlickitySlideshow.initSlideShows();
});
