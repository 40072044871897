
/* Content Access - JP 2021-11-08
 ******************************************************/

class ContentAccess {

  constructor() {
    let statusLoadingAttributeName = 'data-download-status-loading';

    this.activeContentAccessPage = null;
    this.originLocationUrl = window.location.href;
    this.popStateUnListener = () => {};
    this.backdropEl = null;

    this.downloadLinkSelector = '.downloadliste a, .download-items a, .downloaddata a, a.download-item, a.download-link';
    this.downloadLinkElementListenerRemovers = [];
    this.directdownloadLinkAttribute = 'data-js-directdownload';

    this.langCode = k3vars.S_Sprache || 'en';

    /**
     * Click listener for anchor elements used for referencing downloadable files
     *
     * @param {ClickEvent} event           Click event object
     * @param {boolean}    directdownload  Is it a direct download?
     *
     * @return {void}
     */
    this.clickListener = (event, directdownload) => {
      event.preventDefault();
      let currentTarget = event.currentTarget;
      let downloadUrl = currentTarget.getAttribute('href');
      if (!!currentTarget.getAttribute(statusLoadingAttributeName)) {
        return;
      }

      currentTarget.setAttribute(statusLoadingAttributeName, true);
      this.triggerDownloadStatusAction(downloadUrl, directdownload, () => {
        currentTarget.removeAttribute(statusLoadingAttributeName);
      });
    };
  }

  /**
   * Initialize the content access support
   *
   * @return {void}
   */
  init() {
    this.addDownloadLinkElements(document, true);

    this.popStateUnListener = this.addPopStateListener();
  }

  /**
   * Deinitialize the content access support
   *
   * @return {void}
   */
  deinit() {
    this.downloadLinkElementListenerRemovers.forEach((removeListener) => removeListener());
    this.downloadLinkElementListenerRemovers = [];

    this.popStateUnListener();
    this.popStateUnListener = () => {};
  }

  /**
   * Add download elements incrementally
   *
   * @param {HTMLElement} context        Context element reference to search for download links in
   * @param {boolean}     directdownload Downloads should be direct downloads
   *
   * @return {void}
   */
  addDownloadLinkElements(context, directdownload) {
    let foundDownloadLinkElements = Array.prototype.slice.call(context.querySelectorAll(this.downloadLinkSelector));

    let newElementListenerRemovers = foundDownloadLinkElements.map((el) => {
      if (typeof directdownload === 'undefined') {
         directdownload = el.getAttribute(this.directdownloadLinkAttribute) !== null;
      }

      let listener = (event) => this.clickListener.bind(el)(event, directdownload);

      el.addEventListener('click', listener);

      return () => el.removeEventListener('click', listener);
    });

    this.downloadLinkElementListenerRemovers.concat(newElementListenerRemovers);
  }

  /**
   * Trigger the backend request to fetch access infos for the download file
   *
   * @param  {string}   downloadUrl    Direct path to a download file
   * @param  {boolean}  directdownload Is a direct download
   * @param  {Function} done           Callback function called after response evaluation
   *
   * @return {void}
   */
  triggerDownloadStatusAction(downloadUrl, directdownload, done) {
    const params = {
      kind: 'file-url',
      file: downloadUrl,
      langCode: this.langCode,
    };

    const onSuccess = (status) => {
      if (status.accessLevel !== 'none') {
        const pageUrl = this.getPageUrlForHistory(this.originLocationUrl, status.pageUrl);

        this.showPage(status);
        this.pushHistoryState(params.kind, [ downloadUrl, directdownload ], pageUrl);
      } else {
        window.toolkit.downloadFile(downloadUrl, directdownload);
      }
    };

    this.startStatusRequest(params, onSuccess, done);

  }


  /**
   * Trigger the backend request to fetch access infos for a page
   *
   * @param  {string}   contentAreaCode  Code of content area in where the page resides
   * @param  {string}   pageId           Page id
   * @param  {Function} done             Callback function always called after the action finished
   *
   * @return {void}
   */
  triggerContentAreaPageInfoAction(contentAreaCode, pageId, done) {
    const params = {
      kind: 'contentarea-page',
      contentAreaCode: contentAreaCode,
      pageId: pageId,
      langCode: this.langCode,
    };

    const onSuccess = (status) => {
      const pageUrl = this.getPageUrlForHistory(this.originLocationUrl, status.pageUrl);

      this.showPage(status);
      this.pushHistoryState(params.kind, [ contentAreaCode, pageId ], pageUrl);
    };

    this.startStatusRequest(params, onSuccess, done);
  }


  /**
   * Start the actual request with custom parameters
   *
   * @param  {Object}    params     Custom params passed as payload
   * @param  {Function}  onSuccess  Callback function called on success
   * @param  {Function}  done       Callback function always called after the response was received
   *
   * @return {void}
   */
  startStatusRequest(params, onSuccess, done) {
    $.ajax({
      method: 'GET',
      url: confEnv['pdb_api_url'] + '/contentaccess/status',
      data: params,
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
    }).done((status) => {
      onSuccess(status);
    }).always(done);
  }


  /**
   * Inject page markup found in the status object and show it in an overlay
   *
   * @param  {Object} status  Page status containing the access level and the markup
   *
   * @return {void}
   */
  showPage(status) {
    const fragment = document.createRange().createContextualFragment(status.contentMarkup);
    const sectionEl = fragment.querySelector('[data-js-content-access-section]');

    this.backdropEl = this.createBackdropElement(fragment)
    document.body.appendChild(this.backdropEl);

    this.activeContentAccessPage = new ContentAccessPage();
    this.activeContentAccessPage.init(sectionEl, this.originLocationUrl, status.pageUrl);

    this.showBackdrop();

    this.pageIsOpen = true;

    this.activeContentAccessPage.onClose(() => {
      window.history.back();
      this.closePage();
    });

    this.toggleBodyChange();
  }


  /**
   * Helper function for backdrop element creation
   *
   * @param  {HTMLElement} contentEl  Reference to a HTML element to be placed inside the backdrop
   *
   * @return {HTMLElement}            Reference to the newly created backdrop element
   */
  createBackdropElement(contentEl) {
    const backdropEl = document.createElement('div');
    backdropEl.classList.add('content-access-backdrop');

    const backdropInnerEl = document.createElement('div');
    backdropInnerEl.classList.add('content-access-backdrop__inner', 'container');
    backdropEl.appendChild(backdropInnerEl);

    backdropInnerEl.appendChild(contentEl);

    return backdropEl;
  }


  /**
   * Toggle the backdrop visible
   *
   * @return {void}
   */
  showBackdrop() {
    if (this.backdropEl) {
      this.backdropEl.classList.add('content-access-backdrop--visible');
    }
  }


  /**
   * Removes the backdrop element from the DOM
   */
  removeBackdrop() {
    if (this.backdropEl) {
      document.body.removeChild(this.backdropEl);
    }
  }


  /**
   * History pop state event listener used act on history changes
   *
   * @return {Function}  Callback used to unbind added listener
   */
  addPopStateListener() {
    const onPopState = (e) => {
      if (e.state && e.state.contentAccessStateKind && !this.activeContentAccessPage) {
        switch (e.state.contentAccessStateKind) {
          case 'file-url':
            this.triggerDownloadStatusAction.apply(this, e.state.params);
            break;

          case 'contentarea-page':
            this.triggerContentAreaPageInfoAction.apply(this, e.state.params);
            break;
        }
      } else {
        this.closePage();
      }
    };

    window.addEventListener('popstate', onPopState);

    return () => { window.removeEventListener('popstate', onPopState); }
  }


  /**
   * Helper function to determine the correct url to be shown in the address bar,
   * considering the subdomain restrictions.
   *
   * @param  {string}  originLocationUrl  Original page url for subdomain check
   * @param  {string}  pageUrl            URL to be show in the address bar
   *
   * @return {string}                     Safe version of the URL to be shown in the address bar
   */
  getPageUrlForHistory(originLocationUrl, pageUrl) {
    const pOLUrl = new URL(originLocationUrl);
    const parsedPageUrl = new URL(pageUrl);

    if (pOLUrl.host === parsedPageUrl.host) {
      return pageUrl;
    }

    /* We need to use the current host ... */
    parsedPageUrl.host = pOLUrl.host;
    /* ... but we also need a little redirect */
    parsedPageUrl.pathname = '/redirect' + parsedPageUrl.pathname;

    return parsedPageUrl.toString();
  }


  /**
   * Closing a page, if one is currently open
   *
   * @return {void}
   */
  closePage() {
    if (this.activeContentAccessPage) {
      this.activeContentAccessPage.deinit();
      this.activeContentAccessPage = null;

      this.removeBackdrop();
      this.backdropEl = null;

      this.toggleBodyChange();
    }
  }

  /**
   * Helper function for pushing a state to the history
   *
   * @param {string}  kind     The kind telling for which kind of action it currently handles
   *                           (page request based on a file url or by a content area code and page id)
   * @param {string}  params   Custom params needed to pass to the matching function for reshowing a page
   * @param {string}  pageUrl  URl to show in the address bar
   *
   * @return {void}
   */
  pushHistoryState(kind, params, pageUrl) {
    if (!window.history.state || window.history.state.contentAccessStateKind !== kind) {
      window.history.pushState({
        contentAccessStateKind: kind,
        params: params,
      }, '', pageUrl);
    }
  }

  /**
   * Helper function to prepare the body for when a page overlay is shown
   *
   * @return {void}
   */
  toggleBodyChange() {
    document.body.classList.toggle('_no-scroll');
  }

};

(function(window) {
  /* Creation of a global contentAccess object used for example in the download area
   * to directly open a page overlay for a certain document */
  window.contentAccess = new ContentAccess();
}(window));
