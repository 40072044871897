/**
 * Dotscroll
 * 
 * @dependencies
 * * holy.js
 * * detect (erco.js)
 * * toolkit (erco.js)
 * 
 * @dependents
 * * after_ajax.start (erco.js)
 * * $document.ready (erco.js)
 * * data attributes [data-animation] [data-animate-section] is used in several templates in cms-backend and pdb-api (dist/testcourt/index.php)
 */

dotscroll = {
  init: function () {
    if (detect.breakpoint() === 'xs') { return; }

    if (document.querySelector('[data-animation="fade"]')) {
      document.querySelectorAll('article, [data-animate-section]').forEach((el) => {

        // Is the top edge of the element in the viewport?
        if (!window.toolkit.isElementTopInViewport(el)) {
          el.classList.add('_below-active');
        }

        Array.from(el.children).forEach((el) => {
          // exit if not a div
          if (el.tagName !== 'DIV') { return; }

          el.classList.add('animation1a');
        });

        // pass to holy
        holy.addToObserve(el);

        el.addEventListener('holy:observeIn', () => {
          el.classList.remove('_below-active');
        });
      });

      // ss 20161020 scroll to the top, does a new initializing of animation classes
      window.addEventListener('holy:edgeTop', function () {
        document.querySelectorAll('article[data-id-seite], [data-animate-section]').forEach(function (el) {

          // Is the top edge of the element in the viewport?
          if (!window.toolkit.isElementTopInViewport(el)) {
            el.classList.add('_below-active');
          }
        });
      });
    }

    if (!document.querySelector('ul.onepage-pagination')) {
      const ul = document.createElement('ul');
      ul.classList.add('onepage-pagination');
      document.body.prepend(ul);
    }

    document.querySelectorAll('article[data-id-seite]').forEach(function (el) {
        
        // Is the top edge of the element in the viewport?
        if (!window.toolkit.isElementTopInViewport(el)) {
          el.classList.add('_below-active');
        }
      }
    );

    // pagination
    const paginationPages = document.querySelectorAll('article[data-id-seite].js-create-pagination');
    const paginationItems = [];
    paginationPages.forEach(function (el, index) {
      const li = document.createElement('li');
      const a = document.createElement('a');
      a.dataset.index = index + 1;
      a.dataset.dot = el.dataset.idSeite;
      a.href = '#' + index + 1;
      li.appendChild(a);
      paginationItems.push(li);
    });

    document.querySelectorAll('ul.onepage-pagination').forEach((pagination) => {
      paginationItems.forEach((item) => {
        pagination.appendChild(item);
      });
    });

    // marker
    const allMarker = document.querySelectorAll('article[data-id-seite]');

    // pass to holy
    holy.addToObserve(allMarker);

    // each marker reacts individually
    allMarker.forEach((marker) => {
      marker.addEventListener('holy:observeIn', function (e) {
        const id = this.dataset.idSeite;
        const pagination = document.querySelector('[data-dot="' + id + '"]');
        if (!pagination) { return; }
        pagination.classList.add('active');
        pagination.parentElement.parentElement.querySelectorAll('a').forEach(function (el) {
          el.classList.remove('active');
        });

        
        // all elements above the current one are marked as active
        let prev = this.previousElementSibling;
        while (prev) {
          prev.classList.remove('_below-active');
          prev = prev.previousElementSibling;
        }
      });
    });

    // dot clicks
    document.querySelectorAll('ul.onepage-pagination li a').forEach(function (el) {
      el.addEventListener('click', function (e) {
        const current = document.querySelector('.onepage-pagination .active').dataset.index || 0;
        const next = e.target.dataset.index;
        const nextDot = e.target.dataset.dot;

        const barHeight = document.querySelector('.new-menu_._bar').offsetHeight;
        const offset = (next < current) ? barHeight : 0;

        holy.scrollTo(
          document.querySelector('[data-id-seite="' + nextDot + '"]'),
          undefined,
          offset
        );

        let fired = false;
        window.addEventListener('holy:scrollDone', function () {
          if (!fired) {
            fired = true;
            el.classList.add('active');
            el.parentElement.parentElement.querySelectorAll('a').forEach(function (el) {
              el.classList.remove('active');
            });
          }
        });
      });
    });
  }
};
